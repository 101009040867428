import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { addBlockedTransfersRoom } from 'api/blockedtransfersrooms';
import { getPokerRooms } from 'api/pokerrooms';

const AddBlockedRoomDialog: React.FC<DialogProps> = (props) => {
    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            id: '',
        }
    });

    const roomsQuery = useQuery('poker_rooms', getPokerRooms, {
        initialData: []
    });

    const addBlockedQuery = useMutation(addBlockedTransfersRoom, {
        onSuccess: () => {
            queryClient.invalidateQueries(['blockedTransfersRooms', playerId]);
            props.onClose({}, 'backdropClick');
            reset();
        }
    });

    const onSubmit = handleSubmit((data) => addBlockedQuery.mutate({...data, userId: playerId }));

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить рум без трансферов</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="id"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Рум"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    roomsQuery.data?.filter(room => room.internal_transfers && room.active && !room.virtual).map(room => (
                                        <MenuItem key={room.id} value={room.id}>
                                            {room.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={addBlockedQuery.isLoading}
                        >Добавить</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddBlockedRoomDialog;
