import React from 'react';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Card, Paper } from '@mui/material';
import { toast } from 'react-toastify';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { getGeneralStats } from 'api/stats';
import { getResidenceRegions } from 'api/residenceregions';
import {
    getUserInfo,
    updateUserInfo,
    getPlayerFundFlowList,
    getPlayerActiveVacation,
    migrateUserToNewTerms,
    getPlayerUntrackedTournaments,
    getPlayerDeductibles,
} from 'api/players';
import { getCuratorsList } from 'api/curators';
import { getVideoAccessLevels } from 'api/videogallery';

import PersonalFunds from './components/PersonalFunds/PersonalFunds';
import Accounts from './components/Accounts/Accounts';
import Contracts from './components/Contracts/Contracts';
import BlockedTransfersRooms from './components/BlockerTransfers/BlockedTransfers';
import CashFlowTable from './components/CashFlowTable/CashFlowTable';
import VacationDialog from './components/VacationDialog/VacationDialog';
import VacationsTable from './components/VacationsTable/VacationsTable';
import UntrackedTournamentsTable from './components/UntrackedTournamentsTable/UntrackedTournamentsTable';
import UntrackedTournamentsDialog from './components/UntrackedTournamentsDialog/UntrackedTournamentsDialog';
import DeductiblesTable from './components/DeductiblesTable/DeductiblesTable';
import DeductibleAddDialog from './components/DeductibleAddDialog/DeductibleAddDialog';
import Divider from 'components/Divider/Divider';
import DeductiblesTotal from './components/DeductiblesTotal/DeductiblesTotal';

const TabContent = ({
    index,
    fundFlowQuery,
    deductiblesQuery,
    activeVacationQuery,
    untrackedTournamentsQuery,
    setVacationOpen,
    setDeductibleOpen,
    setUntrackedOpen,
}) => {
    switch (index) {
        case 0:
            return (
                <div>
                    <Card>
                        <CashFlowTable items={fundFlowQuery.data} />
                    </Card>
                </div>
            );
        case 1:
            return (
                <div>
                    <div className="flex justify-end">
                        <Button className="mb-2" variant="contained" onClick={() => setDeductibleOpen(true)}>
                            Добавить
                        </Button>
                    </div>
                    <Card>
                        <DeductiblesTable items={deductiblesQuery.data} />
                    </Card>
                </div>
            );
        case 2:
            return (
                <div>
                    <div className="flex justify-end">
                        <Button className="mb-2" variant="contained" onClick={() => setVacationOpen(true)}>
                            Создать отпуск
                        </Button>
                    </div>
                    <Card>
                        <VacationsTable items={activeVacationQuery.data} />
                    </Card>
                </div>
            );
        case 3:
            return (
                <div>
                    <div className="flex justify-end">
                        <Button className="mb-2" variant="contained" onClick={() => setUntrackedOpen(true)}>
                            Внести турниры
                        </Button>
                    </div>
                    <Card>
                        <UntrackedTournamentsTable items={untrackedTournamentsQuery.data} />
                    </Card>
                </div>
            );
        default:
            return null;
    }
};

const Tabs = ({ fundFlowQuery, deductiblesQuery, activeVacationQuery, untrackedTournamentsQuery, setVacationOpen, setDeductibleOpen, setUntrackedOpen }) => {
    const [currentTab, setCurrentTab] = React.useState(0);

    return (
        <div>
            <div className="flex border-b">
                <button className={`tab px-4 py-2 cursor-pointer ${currentTab === 0 ? 'border-b-2 border-indigo-500' : ''}`} onClick={() => setCurrentTab(0)}>
                    Движение ЛС
                </button>
                <button className={`tab px-4 py-2 cursor-pointer ${currentTab === 1 ? 'border-b-2 border-indigo-500' : ''}`} onClick={() => setCurrentTab(1)}>
                    Долги
                </button>
                <button className={`tab px-4 py-2 cursor-pointer ${currentTab === 2 ? 'border-b-2 border-indigo-500' : ''}`} onClick={() => setCurrentTab(2)}>
                    Отпуски игрока
                </button>
                <button className={`tab px-4 py-2 cursor-pointer ${currentTab === 3 ? 'border-b-2 border-indigo-500' : ''}`} onClick={() => setCurrentTab(3)}>
                    Турниры
                </button>
            </div>
            <TabContent
                index={currentTab}
                fundFlowQuery={fundFlowQuery}
                deductiblesQuery={deductiblesQuery}
                activeVacationQuery={activeVacationQuery}
                untrackedTournamentsQuery={untrackedTournamentsQuery}
                setVacationOpen={setVacationOpen}
                setDeductibleOpen={setDeductibleOpen}
                setUntrackedOpen={setUntrackedOpen}
            />
        </div>
    );
};

const renderTextField = (field, label, errors, disabled = false) => (
    <TextField label={label} size="small" error={!!errors?.message} helperText={errors?.message} fullWidth required disabled={disabled} {...field} />
);

const renderSelectField = (field, label, errors, data, valueField, displayFn) => (
    <TextField label={label} error={!!errors?.message} helperText={errors?.message} size="small" select fullWidth required {...field}>
        {data?.map(item => (
            <MenuItem key={item[valueField]} value={item[valueField]}>
                {displayFn(item)}
            </MenuItem>
        ))}
    </TextField>
);

const renderCheckbox = (field, label) => (
    <FormControlLabel label={label} control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />} />
);

const Player: React.FC = () => {
    const { playerId } = useParams();
    const queryClient = useQueryClient();

    const currentDate = moment();

    const [vacationOpen, setVacationOpen] = React.useState(false);
    const [untrackedOpen, setUntrackedOpen] = React.useState(false);
    const [deductibleOpen, setDeductibleOpen] = React.useState(false);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            shark_player_group: '',
            telegram_username: '',
            player_level: '',
            birthday: '',
            email: '',
            user_curator: '',
            video_level: '',
            can_request_deposit: true,
            can_request_pokerok_deposit: true,
            is_active: true,
            has_videos_access: true,
            has_tournaments_access: true,
            has_team_access: true,
            skrill_residence: '',
        },
    });

    const statsQuery = useQuery('stats', getGeneralStats, {
        initialData: { player_levels: [] },
    });

    const activeVacationQuery = useQuery(['player-active-vacation', playerId], getPlayerActiveVacation, {
        initialData: [],
    });

    const untrackedTournamentsQuery = useQuery(['player-untracked-tournaments', playerId, null, null], getPlayerUntrackedTournaments, {
        initialData: [],
    });

    const deductiblesQuery = useQuery(['player-deductibles', playerId], getPlayerDeductibles, {
        initialData: [],
    });

    const curatorsQuery = useQuery('curators', getCuratorsList, {
        initialData: [],
    });

    const residenceRegionsQuery = useQuery('residence-regions', getResidenceRegions, {
        initialData: [],
    });

    const videoAccessLevelsQuery = useQuery('videoAccessLevels', getVideoAccessLevels, {
        initialData: [],
    });

    const userQuery = useQuery(['user', playerId], getUserInfo, {
        enabled: !!playerId,
        initialData: {},
    });

    const fundFlowQuery = useQuery(['fundflowlist-data', playerId], getPlayerFundFlowList, {
        initialData: [],
    });

    const userMutation = useMutation(updateUserInfo, {
        onSuccess: () => {
            queryClient.invalidateQueries(['user', playerId]);
            toast.success('Сохранено');
        },
    });

    const userTermsMigrationMutation = useMutation(migrateUserToNewTerms, {
        onSuccess: () => {
            queryClient.invalidateQueries(['user', playerId]);
        },
    });

    React.useEffect(() => {
        if (userQuery) {
            setValue('player_level', userQuery.data.player_level);
            setValue('shark_player_group', userQuery.data.shark_player_group);
            setValue('telegram_username', userQuery.data.telegram_username);
            setValue('email', userQuery.data.email);
            setValue('user_curator', userQuery.data.user_curator);
            setValue('video_level', userQuery.data.video_level);
            setValue('can_request_deposit', userQuery.data.can_request_deposit);
            setValue('can_request_pokerok_deposit', userQuery.data.can_request_pokerok_deposit);
            setValue('is_active', userQuery.data.is_active);
            setValue('birthday', userQuery.data.birthday);
            setValue('has_videos_access', userQuery.data.has_videos_access);
            setValue('has_tournaments_access', userQuery.data.has_tournaments_access);
            setValue('has_team_access', userQuery.data.has_team_access);
            setValue('skrill_residence', userQuery.data.skrill_residence);
        }
    }, [userQuery.data, setValue]);

    const updatePlayer = handleSubmit(data => userMutation.mutate({ playerId, ...data }));

    return (
        <div className="w-full grid grid-cols-12 gap-4">
            <div className="col-span-12">
                <Paper className="w-full h-full p-5 font-roboto">
                    <form noValidate className="space-y-6" onSubmit={updatePlayer}>
                        <div className="w-full space-x-3 flex">
                            <Controller
                                name="birthday"
                                control={control}
                                render={({ field }) => renderTextField(field, 'Дата Рождения', errors.birthday, true)}
                            />
                            <Controller
                                name="telegram_username"
                                control={control}
                                render={({ field }) => renderTextField(field, 'Telegram', errors.telegram_username)}
                            />
                            <Controller
                                name="shark_player_group"
                                control={control}
                                render={({ field }) => renderTextField(field, 'SharkScope алиас', errors.shark_player_group, true)}
                            />
                            <Controller name="email" control={control} render={({ field }) => renderTextField(field, 'Почта', errors.email)} />
                        </div>
                        <div className="w-full space-x-4 flex">
                            <Controller
                                name="player_level"
                                control={control}
                                render={({ field }) =>
                                    renderSelectField(
                                        field,
                                        'Уровень',
                                        errors.player_level,
                                        statsQuery.data?.player_levels.sort((a, b) => Number(a.name) < Number(b.name)),
                                        'id',
                                        item => `${item.name} ${item.group.name}`
                                    )
                                }
                            />
                            <Controller
                                name="video_level"
                                control={control}
                                render={({ field }) =>
                                    renderSelectField(
                                        field,
                                        'Уровень доступа к видео',
                                        errors.video_level,
                                        videoAccessLevelsQuery.data,
                                        'id',
                                        item => item.name
                                    )
                                }
                            />
                            <Controller
                                name="user_curator"
                                control={control}
                                render={({ field }) =>
                                    renderSelectField(
                                        field,
                                        'Куратор',
                                        errors.user_curator,
                                        curatorsQuery.data?.sort((a, b) => a.curator_nickname > b.curator_nickname),
                                        'id',
                                        item => item.curator_nickname
                                    )
                                }
                            />
                            <Controller
                                name="skrill_residence"
                                control={control}
                                render={({ field }) =>
                                    renderSelectField(
                                        field,
                                        'Резиденция Skrill',
                                        errors.skrill_residence,
                                        residenceRegionsQuery.data?.sort((a: any, b: any) => a.name > b.name),
                                        'id',
                                        item => item.name
                                    )
                                }
                            />
                        </div>
                        <div className="w-full space-x-4 flex">
                            <Controller name="is_active" control={control} render={({ field }) => renderCheckbox(field, 'Активен')} />
                            <Controller name="can_request_deposit" control={control} render={({ field }) => renderCheckbox(field, 'Депозиты')} />
                            <Controller name="can_request_pokerok_deposit" control={control} render={({ field }) => renderCheckbox(field, 'PokerOK Депозиты')} />
                            <Controller name="has_videos_access" control={control} render={({ field }) => renderCheckbox(field, 'Видео')} />
                            <Controller name="has_tournaments_access" control={control} render={({ field }) => renderCheckbox(field, 'Расписатор')} />
                            <Controller name="has_team_access" control={control} render={({ field }) => renderCheckbox(field, 'Команда')} />
                        </div>
                        <div className="pt-5 flex justify-between items-center">
                            <div>
                                {userQuery.data?.is_migrated_to_new_terms ? null : (
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        loading={userTermsMigrationMutation.isLoading}
                                        onClick={() => userTermsMigrationMutation.mutate(playerId)}
                                    >
                                        Перевести на новые условия 2024
                                    </Button>
                                )}
                                <span>
                                    {
                                        activeVacationQuery.data
                                            ?.filter(
                                                vacation => moment(vacation.start_datetime) <= currentDate && moment(vacation.end_datetime) >= currentDate
                                            )
                                            .map((vacation, index) => (
                                                <span key={index} className="mx-3 font-roboto font-medium text-sm">
                                                    Текущий отпуск: {moment(vacation.start_datetime).format('DD MMM YYYY HH:mm')} -{' '}
                                                    {moment(vacation.end_datetime).format('DD MMM YYYY HH:mm')}
                                                </span>
                                            ))
                                    }
                                </span>
                            </div>
                            <Button
                                variant="contained"
                                type="submit"
                                className={`font-bold py-2 px-4 rounded ${userMutation.isLoading ? 'bg-green-300 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
                                    }`}
                                disabled={userMutation.isLoading}
                            >
                                {userMutation.isLoading ? 'Loading...' : 'Сохранить'}
                            </Button>
                        </div>
                    </form>
                </Paper>
            </div>

            <PersonalFunds />
            <DeductiblesTotal />
            <Accounts />
            <Contracts />
            <VacationDialog open={vacationOpen} onClose={() => setVacationOpen(false)} />
            <DeductibleAddDialog open={deductibleOpen} onClose={() => setDeductibleOpen(false)} />
            <UntrackedTournamentsDialog open={untrackedOpen} onClose={() => setUntrackedOpen(false)} />
            <BlockedTransfersRooms />

            <div className="w-full col-span-12 mt-10">
                <Divider title="Информация" />
            </div>

            <div className="w-full col-span-12 mt-10">
                <Tabs
                    fundFlowQuery={fundFlowQuery}
                    deductiblesQuery={deductiblesQuery}
                    activeVacationQuery={activeVacationQuery}
                    untrackedTournamentsQuery={untrackedTournamentsQuery}
                    setVacationOpen={setVacationOpen}
                    setDeductibleOpen={setDeductibleOpen}
                    setUntrackedOpen={setUntrackedOpen}
                />
            </div>

            <div style={{ minHeight: '300px' }} />

            {/*<div className="w-full col-span-12 mt-10">
                <p className="col-span-12 text-lg font-medium">Движение ЛС</p>
                <Card>
                    <CashFlowTable items={fundFlowQuery.data} />
                </Card>
            </div>
            <div className="w-full col-span-12 mt-10">
                <p className="col-span-12 text-lg font-medium">Долги</p>
                <div className="flex justify-end">
                    <Button className="mb-2" variant="contained" onClick={() => setDeductibleOpen(true)}>
                        Добавить
                    </Button>
                </div>
                <Card>
                    <DeductiblesTable items={deductiblesQuery.data} />
                </Card>
            </div>
            <div className="w-full col-span-12 mt-10">
                <p className="col-span-12 text-lg font-medium">Активные отпуски игрока</p>
                <div className="flex justify-end">
                    <Button className="mb-2" variant="contained" onClick={() => setVacationOpen(true)}>
                        Создать отпуск
                    </Button>
                </div>
                <Card>
                    <VacationsTable items={activeVacationQuery.data} />
                </Card>
            </div>
            <div className="w-full col-span-12 mt-10">
                <p className="col-span-12 text-lg font-medium">Турниры из румов без шарка</p>
                <div className="flex justify-end">
                    <Button className="mb-2" variant="contained" onClick={() => setUntrackedOpen(true)}>
                        Внести турниры
                    </Button>
                </div>
                <Card>
                    <UntrackedTournamentsTable items={untrackedTournamentsQuery.data} />
                </Card>
            </div>*/}
        </div>
    );
};

export default Player;
