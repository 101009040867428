import { FC, useState } from 'react';
import {
    Box,
    Stack,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import moment from 'moment';

type Props = {
    items: any;
};

const MessagesInfoTable: FC<Props> = ({ items }) => {

    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState<number>(10);

    const columns: GridColDef[] = [
        {
            field: "text",
            headerName: "Текст",
            flex: 0.9,
            minWidth: 100
        },
        // {
        //     field: "created_at",
        //     headerName: "Отправлено",
        //     valueGetter: (params: GridValueGetterParams) => `${moment(params.row.created_at).format('DD.MM.YYYY HH:mm')}`,
        //     flex: 0.15,
        //     minWidth: 100
        // },
        {
            field: "succeeded",
            headerName: "Доставлено",
            flex: 0.1,
            minWidth: 100,
        },
        {
            field: "failed",
            headerName: "Не доставлено",
            flex: 0.1,
            minWidth: 100,
        },
    ];

    return (
        <>
            <Box
                className="h-auto overflow-auto"
                sx={{
                    '& .active': {
                        backgroundColor: '#fcecd5',
                        color: '#1a3e72',
                    },
                }}
            >
                <DataGrid
                    autoHeight={true}
                    rows={items.messages}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5]}
                    pagination
                    disableSelectionOnClick
                    disableColumnMenu
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Данные отсутствуют
                            </Stack>
                        ),
                    }}
                />
            </Box>

        </>
    );
};

export default MessagesInfoTable;
