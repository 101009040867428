import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getPlayerPersonalFunds } from 'api/players';
import ChangePersonalFundsDialog from './ChangePersonalFundsDialog';
import Divider from 'components/Divider/Divider';

const PersonalFunds: React.FC = () => {
    const { playerId } = useParams();
    const [changePersonalFundsDialogOpen, setChangePersonalFundsDialogOpen] = React.useState(false);

    const { data, isLoading } = useQuery(['player-personalfunds', playerId], getPlayerPersonalFunds, {
        enabled: !!playerId,
    });

    if (isLoading) return null;

    return (
        <>
            <div className="w-full col-span-12 mt-10">
                <Divider title="Личные средства" />
            </div>
            {data.map(val => (
                <div className="col-span-3 p-3 m-2 rounded bg-gray-200 text-gray-700 shadow-sm" key={val.id}>
                    <div className="flex flex-col items-center">
                        <span className="font-semibold text-xl mb-1">{val.amount}</span>
                        <span className="font-normal text-base">{val.currency_name}</span>
                    </div>
                </div>
            ))}
            <div className="flex items-center justify-end space-x-4">
                <button
                    type="button"
                    className="px-4 py-2 font-medium text-white bg-blue-500 rounded hover:bg-gray-400"
                    onClick={() => setChangePersonalFundsDialogOpen(true)}
                >
                    Изменить
                </button>
            </div>
            <ChangePersonalFundsDialog open={changePersonalFundsDialogOpen} onClose={() => setChangePersonalFundsDialogOpen(false)} />
        </>
    );
};

export default PersonalFunds;
// import React from 'react';
//
// import InfoCard from 'components/InfoCard/InfoCard';
// import Button from '@mui/material/Button';
//
// import { useMutation, useQuery, useQueryClient } from 'react-query';
// import { useParams } from 'react-router-dom';
//
// import { getPlayerPersonalFunds } from 'api/players';
// import ChangePersonalFundsDialog from './ChangePersonalFundsDialog';
//
// const PersonalFunds: React.FC = () => {
//     const { playerId } = useParams();
//     const [changePersonalFundsDialogOpen, setChangePersonalFundsDialogOpen] = React.useState(false);
//
//     const { data, isLoading } = useQuery(['player-personalfunds', playerId], getPlayerPersonalFunds, {
//         enabled: !!playerId,
//     });
//
//     if (isLoading) return null;
//
//     return (
//         <>
//             <p className="col-span-12 text-lg font-medium">Личные средства</p>
//             {data.map(val => (
//                 <div className="col-span-3" key={val.id}>
//                     <InfoCard color="bg-purple-500">
//                         <div className="flex items-center">
//                             <div className="flex-1">
//                                 <span className="font-bold text-3xl p-3">{val.amount}</span>
//                                 <span className="font-bold text-3xl p-3">{val.currency_name}</span>
//                             </div>
//                         </div>
//                     </InfoCard>
//                 </div>
//             ))}
//             <div className="flex items-center justify-end space-x-4">
//                 <Button type="button" variant="contained" onClick={() => setChangePersonalFundsDialogOpen(true)}>
//                     Изменить
//                 </Button>
//             </div>
//
//             <ChangePersonalFundsDialog open={changePersonalFundsDialogOpen} onClose={() => setChangePersonalFundsDialogOpen(false)} />
//         </>
//     );
// };
//
// export default PersonalFunds;
