import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { IconButton, DialogTitle, DialogContent, TextField, CircularProgress, Box, InputLabel, Select, MenuItem } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from 'react-query';
import { getMarks } from 'api/marks';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * Функция сохранения лейбла
     */
    handleUpdateLabel(days?: string, label_id?: string, player?: string, is_hidden?: boolean): void

    /**
     * Флаг загрузки
     */
    loading?: boolean;
}

/**
 * Диалоговое окно, в котором админ может поставить любой лейбл на игрока на любое кол-во дней
 */
const ManualLabelsModal: FC<Props> = ({ handleUpdateLabel, loading, ...props }) => {
    const { data: marks, isFetching } = useQuery(['marks-data'], getMarks, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    const { control, handleSubmit } = useForm({
        defaultValues: {
            label: '',
            days: 120,
        },
    });

    const onSubmit = handleSubmit(data => {
        handleUpdateLabel(data.days ? String(data.days) : null, data.label);
    });

    return (
        <>
            <DialogTitle>
                Ручное добавление лейбла
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {isFetching && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}><CircularProgress /></Box>}
                {!isFetching && (
                    <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                        <Controller
                            control={control}
                            name={`label`}
                            defaultValue=''
                            render={({ field }) => (
                                <Box className='flex flex-col gap-2'>
                                    <InputLabel id="select-label">Лейбл</InputLabel>
                                    <Select labelId="select-label" {...field} size="small" className='mb-4' required>
                                        {marks.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            )}
                        />
                        <Controller
                            control={control}
                            name='days'
                            render={({ field }) => <TextField {...field} label='Кол-во дней' fullWidth />}
                        />

                        <div className='pt-4'>
                            <Button
                                fullWidth
                                variant='contained'
                                size='large'
                                type='submit'
                                disabled={loading}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </form>
                )}
            </DialogContent>
        </>
    );
};

export default ManualLabelsModal;
