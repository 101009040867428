import axios from 'helpers/axios';
import moment from 'moment';
import { groupBy } from 'lodash';

const buildCustomStats = (items: any, username?: string) => {
    const data = items.map(val => ({
        ...val,
        revision_date: moment(val.revision_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        revision_id: val.revision,
    }));

    const columns = data.map(val => ({ id: val.revision_id, revision_date: val.revision_date }));

    return {
        columns,
        username,
        data: Object.values(
            groupBy(
                Object.values(
                    groupBy(
                        data
                            .map(revision => {
                                return revision.custom_stats.map(stat => ({
                                    ...stat,
                                    revision_date: revision.revision_date,
                                }));
                            })
                            .flat(),
                        item => [item.category.order, item.category_order]
                    )
                ).map(group => {
                    return {
                        category: group[0].category,
                        columns: [group[0].name, ...group],
                    };
                }),
                'category.order'
            )
        ).map(val => ({
            category: val[0].category,
            rows: val.map(row => row.columns),
        })),
    } as any;
};

export const getPLayers = async ({ queryKey }) => {
    const [, curatorFilter] = queryKey;

    var params = new URLSearchParams();

    if (curatorFilter?.id) {
        params.append('curatorId', curatorFilter?.id);
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/playersv2/', request);

    return data;
};

export const getCurators = async () => {
    const { data } = await axios.get('/api/adminapi/curators/');

    return data;
};

export const getStats = async ({ queryKey }) => {
    const [, id, revision] = queryKey;

    return axios.get(`/api/adminapi/users/${id}/customstats/?numRev=${revision}`).then(({ data }) => [buildCustomStats(data)]);
};

export const getCustomStatsFields = async () => {
    const { data } = await axios.get('/api/adminapi/customstats/');

    return Object.values(groupBy(data, 'category.order'));
};

export const createCustomStats = async ({ playerId, ...body }) => {
    return axios.post(`/api/adminapi/users/${playerId}/customstats/`, body);
};

export const deleteRevisionCustomStats = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/customstats/revisions/${id}/`);

    return data;
};

export const editCustomStats = async ({ id, ...body }) => {
    return axios.put(`/api/adminapi/customstats/${id}/`, body);
};

export const getStatsFieldInfo = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/customstats/${id}/`);

    return data;
};
