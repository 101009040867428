const Divider = ({ title }) => (
    <div className="relative my-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
            <span className="px-2 bg-white text-lg text-gray-500">{title}</span>
        </div>
    </div>
);

export default Divider;
