import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRooms } from 'api/rooms';
import { Card, CircularProgress } from '@mui/material';
import RoomsFilter from 'components/RoomsFilter/RoomsFilter';
import RoomHelper from 'helpers/RoomHelper';
import { getSchedulerRoiCorrection } from 'api/schedulersettings';
import SchedulerRoiCorrectionTable from './SchedulerRoiCorrectionTable';

/**
 * Вкладка содержащая настройки ROI коррекции в расписаторе.
 */
const SchedulerRoiCorrection: FC = () => {
    const [filter, setFilter] = useState([]);
    const [result, setResult] = useState();

    const { data: rooms } = useQuery(['scheduler-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: correction, isFetching} = useQuery(['scheduler-roi-correction'], getSchedulerRoiCorrection, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    useEffect(() => {
        if(filter.length > 0) {
            setResult(correction.roi_corrections.filter(({network}) => filter.find(item => item.network === network)));
        } else {
            setResult(correction?.roi_corrections ?? []);
        }
    }, [correction, filter]);

    return (
        <>
            <RoomsFilter setFilter={setFilter} data={filter} rooms={RoomHelper.withoutDefault(rooms)} />
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><SchedulerRoiCorrectionTable items={result}/></Card>}
        </>
    );
}

export default SchedulerRoiCorrection;

