import { useState, FC } from 'react';
import { Box, Button, Chip, Dialog, useMediaQuery } from '@mui/material';
import SchedulerSettings from './components/SchedulerSettings/SchedulerSettings';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import theme from 'themes/lightTheme';
import SchedulerCoefficientDialog from './components/SchedulerSettings/components/SchedulerCoefficient/SchedulerCoefficientDialog';
import SchedulerAbilityDialog from './components/SchedulerSettings/components/SchedulerAbility/SchedulerAbilityDialog';
import SchedulerExceptionsDialog from './components/SchedulerSettings/components/SchedulerExceptions/SchedulerExceptionsDialog';
import SchedulerSeriesDialog from './components/SchedulerSettings/components/SchedulerSeries/SchedulerSeriesDialog';
import SchedulerRoiCorrectionDialog from './components/SchedulerSettings/components/SchedulerRoiCorrection/SchedulerRoiCorrectionDialog';
import SchedulerRoiGridDialog from './components/SchedulerSettings/components/SchedulerRoiGrid/SchedulerRoiGridDialog';
import SchedulerTournaments from './components/SchedulerSettings/components/SchedulerTournaments/SchedulerTournaments';
import SchedulerRoiTypeLimitsDialog from './components/SchedulerSettings/components/SchedulerRoiTypeLimits/SchedulerRoiTypeLimitsDialog';
import SchedulerCustomTournamentDialog from './components/SchedulerSettings/components/SchedulerCustomTournaments/SchedulerCustomTournamentDialog';
import SchedulerBase from './components/SchedulerSettings/components/SchedulerBase/SchedulerBase';
import Results from './components/Results/Results';

/**
 * Страница расписатора.
 */
const Scheduler: FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [settingIndex, setSettingIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    function handleChangeTab(newIndex: number) {
        if (tabIndex === newIndex) {
            return;
        }

        setTabIndex(newIndex);
    }

    function handleChangeSettingTab(newIndex: number) {
        if (settingIndex === newIndex) {
            return;
        }

        setSettingIndex(newIndex);
    }

    return (
        <>
            <Box className='flex flex-row justify-between'>
                <Box className='flex flex-col gap-4'>
                    <Box className='flex gap-1'>
                        <Chip label='Настройки' color={tabIndex === 0 ? 'primary' : 'default'} onClick={() => handleChangeTab(0)} clickable />
                        <Chip label='Турниры' color={tabIndex === 1 ? 'info' : 'default'} onClick={() => handleChangeTab(1)} clickable />
                        <Chip label='Результаты' color={tabIndex === 2 ? 'info' : 'default'} onClick={() => handleChangeTab(2)} clickable />
                        <Chip label='Расписатор' color={tabIndex === 3 ? 'info' : 'default'} onClick={() => handleChangeTab(3)} clickable />
                    </Box>
                    {tabIndex === 0 &&
                        <Box className='flex gap-1'>
                            <Chip label='Сетка байинов' color={settingIndex === 0 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(0)} clickable />
                            <Chip label='Серии' color={settingIndex === 1 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(1)} clickable />
                            <Chip label='Кэф абилити' color={settingIndex === 2 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(2)} clickable />
                            <Chip label='ROI корр' color={settingIndex === 3 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(3)} clickable />
                            <Chip label='Сетка абилити' color={settingIndex === 4 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(4)} clickable />
                            <Chip label='Исключения' color={settingIndex === 5 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(5)} clickable />
                            <Chip label='Сетка ROI' color={settingIndex === 6 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(6)} clickable />
                            <Chip label='Лимиты ROI' color={settingIndex === 7 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(7)} clickable />
                            <Chip label='Кастомные турниры' color={settingIndex === 8 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(8)} clickable />
                        </Box>
                    }
                </Box>
                <Box>
                    {tabIndex === 0 && settingIndex === 1 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая серия</Button>}
                    {tabIndex === 0 && settingIndex === 2 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новый коэффициент</Button>}
                    {tabIndex === 0 && settingIndex === 3 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая коррекция</Button>}
                    {tabIndex === 0 && settingIndex === 4 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая сетка ABI</Button>}
                    {tabIndex === 0 && settingIndex === 5 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новое исключение</Button>}
                    {tabIndex === 0 && settingIndex === 6 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая сетка ROI</Button>}
                    {tabIndex === 0 && settingIndex === 7 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новый лимит ROI</Button>}
                    {tabIndex === 0 && settingIndex === 8 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новый турнир</Button>}
                </Box>
            </Box>
            <Box className="mt-4 p-1">
                {tabIndex === 0 && <SchedulerSettings active={settingIndex} />}
                {tabIndex === 1 && <SchedulerTournaments />}
                {tabIndex === 2 && <Results />}
                {tabIndex === 3 && <SchedulerBase />}
            </Box>
            <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
                {tabIndex === 0 && settingIndex === 1 && <SchedulerSeriesDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 2 && <SchedulerCoefficientDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 3 && <SchedulerRoiCorrectionDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 4 && <SchedulerAbilityDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 5 && <SchedulerExceptionsDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 6 && <SchedulerRoiGridDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 7 && <SchedulerRoiTypeLimitsDialog close={() => setOpen(false)} />}
                {tabIndex === 0 && settingIndex === 8 && <SchedulerCustomTournamentDialog close={() => setOpen(false)} />}
            </Dialog>
        </>
    );
}

export default Scheduler;
