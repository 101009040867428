import axios from 'helpers/axios';

/**
 * Список игроков для таблицы ручных оценок.
 */
export const getHandleAssessment = async ({ queryKey }) => {
    const [, network, tournamentId, filter] = queryKey;

    if (!network || !tournamentId) {
        return;
    }

    var params = new URLSearchParams();
    params.append('network', network);
    params.append('tournamentId', tournamentId);
    params.append('filter', filter);


    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/statsscoring/players/manual/', request);

    return data;
};

/**
 * Присвоение ручного лейбла для игрока
 */
export const putHandleAssessment = async ({ ...body }) => {
    const { data } = await axios.put(`/api/adminapi/statsscoring/manuallabels/`, body);

    return data;
};

/**
 * Удаление ручного лейбла.
 */
export const deleteHandleAssessment = async (id) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/manuallabels/${id}/`);

    return data;
}

export const setPlayerOptedOut = async ({ network, player }) => {
    const body = {
        network: network,
        player: player,
    };

    const { data } = await axios.post(`/api/adminapi/tournamentsmanagement/optedout/`, body);

    return data;
}

export const setPlayerOptedIn = async ({ network, player }) => {

    const params = new URLSearchParams();
    params.append('network', network);
    params.append('player', player);

    const request = {
        params: params,
    };

    const { data } = await axios.delete(`/api/adminapi/tournamentsmanagement/optedout/`, request);

    return data;
}
