import { FC } from 'react';

import { IconButton, DialogTitle, DialogContent, Dialog } from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { createCustomTournamentsFromCsv } from 'api/customgridtournaments';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    open: boolean;
    close: () => void;
};

const HhUploadDialog: FC<Props> = ({ ...props }) => {
    const queryClient = useQueryClient();

    const { register, handleSubmit } = useForm();

    const newItemMutation = useMutation(createCustomTournamentsFromCsv, {
        onSuccess: () => {
            queryClient.invalidateQueries('custom-tournaments-data');
            props.close();
        },
    });

    const onSubmit = handleSubmit(data => {
        if (data.file.length < 1) {
            return;
        }
        const reader = new FileReader();
        reader.addEventListener('load', event => {
            data.file[0].src = event.target.result;
        });
        reader.readAsText(data.file[0]);
        newItemMutation.mutate({ file: data.file[0].src });
    });

    return (
        <>
            <Dialog {...props} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Загрузить майнинг
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            props.close();
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form className="flex flex-col gap-4 pt-2" onSubmit={onSubmit}>
                        <input {...register('file', { required: true })} type="file" accept=".txt" />
                        <div className="pt-4 text-right">
                            <Button type="submit" variant="contained" size="large" loading={newItemMutation.isLoading}>
                                Загрузить
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default HhUploadDialog;
