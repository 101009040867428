import axios from 'helpers/axios';


export const getPokerRooms = async () => {
    const { data } = await axios.get('/api/adminapi/pokerrooms/');

    return data;
};


export const getPokerRoomsForUser = async ({ queryKey }) => {
    const [, userId] = queryKey;

    var params = new URLSearchParams();

    params.append('userId', userId);

    const { data } = await axios.get('/api/adminapi/pokerrooms/', { params: params });

    return data;
};


export const getRoomUsers = async ({ queryKey }) => {
    const [, id] = queryKey;

    // const { data } = await axios.get(`/api/adminapi/player/${id}/accounts/`);
    const { data } = await axios.get(`/api/adminapi/pokerrooms/${id}/users/`);

    return data;
};


export const getRoomGroups = async () => {
    const { data } = await axios.get('/api/adminapi/roomgroups/');

    return data;
};
