import { FC } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import { useQueryClient, useMutation, useQuery } from 'react-query';
import { CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import lightTheme from 'themes/lightTheme';
import { editCustomStats, getStatsFieldInfo } from 'api/playerstats';

type Props = {
    /**
     * ID ячейки
     */
    id: number;

    /**
     * Состояние диалогового окна
     */
    open: boolean;

    /**
     * Закрытие диалогового окна
     */
    onClose: () => void;
}

/**
 * Модальное окно с редактированием ячейки кастомных статистик игрока
 */
const EvStatsEditModal: FC<Props> = ({ id, open, onClose }) => {
    const queryClient = useQueryClient();

    const fullScreen = useMediaQuery(lightTheme.breakpoints.down('sm'));

    const { control, handleSubmit } = useForm();

    const { data, isLoading: fieldLoading } = useQuery(['ev-analysis-stats-fields-info', id], getStatsFieldInfo, {
        cacheTime: 0,
    });

    const { mutate, isLoading } = useMutation(editCustomStats, {
        onSuccess: () => {
            queryClient.invalidateQueries('ev-analysis-stats-players');

            closeDialog();
        },
    });

    const closeDialog = () => {
        onClose();
    };

    const onSubmit = handleSubmit(data => {
        mutate({
            ...data,
            id,
        });
    });

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth='sm' fullWidth fullScreen={fullScreen}>
            <DialogTitle>
                Редактировать статистику
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
                {fieldLoading ?
                    (
                        <div className="flex flex-1 items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                            <CircularProgress />
                        </div>
                    )
                    : (
                        <form className='flex-1 flex flex-col p-2' onSubmit={onSubmit}>
                            <Controller
                                control={control}
                                name='value'
                                defaultValue={data.value}
                                render={({ field }) => <TextField {...field} label={data.stat_name} size='small' type='number' fullWidth />}
                            />
                            <div className='w-full pt-4'>
                                <LoadingButton type='submit' variant='contained' loading={isLoading} fullWidth>
                                    Редактировать
                                </LoadingButton>
                            </div>
                        </form>
                    )
                }

            </DialogContent>
        </Dialog>
    );
};

export default EvStatsEditModal;
