import { FC } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { IconButton, DialogTitle, DialogContent, Select, MenuItem } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { createGroupFormatsRule } from 'api/tournamentformatgroups';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * Список румов
     */
    rooms: string[];
}

/**
 * Диалоговое окно для создания группы форматов.
 */
const TournamentFormatGroupsDialog: FC<Props> = ({ rooms, ...props }) => {
    const queryClient = useQueryClient();

    const newItemMutation = useMutation(createGroupFormatsRule, {
        onSuccess: () => {
            queryClient.invalidateQueries('format-group-list');

            props.close();
        },
    });

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: rooms[0],
        },
    });

    /**
     * Функция сохранения формы.
     */
    const onSubmit = handleSubmit(data => {
        newItemMutation.mutate(data);
    });

    return (
        <>
            <DialogTitle>
                Новая группа форматов
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name='name'
                        render={({ field }) => (
                            <Select {...field}>
                                {rooms.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={newItemMutation.isLoading}
                        >
                            Добавить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default TournamentFormatGroupsDialog;
