import React from 'react';
import clsx from 'clsx';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import Add from '@mui/icons-material/Add';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StopIcon from '@mui/icons-material/Stop';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog } from '@mui/material';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { getPlayerAccounts, getPlayerEwallets } from 'api/players';
import { updateAccount, updateAccountRecord } from 'api/accounts';
import NewAccountDialog from '../NewAccountDialog/NewAccountDialog';
import NewAccountRecordDialog from '../NewAccountRecordDialog/NewAccountRecordDialog';
import EditAccountRecordDialog from '../EditAccountRecordDialog/EditAccountRecordDialog';
import EditAccountRecordLimitsDialog from '../EditAccountRecordLimitsDialog/EditAccountRecordLimitsDialog';
import Divider from 'components/Divider/Divider';
import TransferBalanceDialog from './TransferBalanceDialog';

const Accounts: React.FC = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { playerId } = useParams();
    const queryClient = useQueryClient();

    const [accountRecordId, setAccountRecordId] = React.useState(null);
    const [chipCurrencyId, setChipCurrencyId] = React.useState(null);
    const [newAccountOpen, setNewAccountOpen] = React.useState(false);
    const [newRecordOpen, setNewRecordOpen] = React.useState(false);
    const [roomAccount, setRoomAccount] = React.useState(null);
    const [editAccountRecordOpen, setEditAccountRecordOpen] = React.useState(null);
    const [editAccountRecordLimitsOpen, setEditAccountRecordLimitsOpen] = React.useState(null);
    const [transferBalanceOpen, setTransferBalanceOpen] = React.useState(null);

    const { data, isLoading } = useQuery(['player-accounts', playerId], getPlayerAccounts, {
        enabled: !!playerId,
    });

    const accountMutation = useMutation(updateAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-accounts', playerId]);
        },
    });

    const accountRecordMutation = useMutation(updateAccountRecord, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-accounts', playerId]);
        },
    });

    React.useEffect(() => {
        if (data && roomAccount) setRoomAccount(data.find(val => val.id === roomAccount.id));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (isLoading) return null;

    return (
        <React.Fragment>
            <div className="w-full col-span-12 mt-10">
                <Divider title="Аккаунты" />
            </div>
            {data.map(val => (
                <div className="col-span-4" key={val.id}>
                    <div
                        onClick={() => setRoomAccount(val)}
                        className={clsx(
                            'w-full h-full p-5 border-2 shadow-md cursor-pointer rounded',
                            val.is_active ? 'bg-lime-500 border-lime-500' : 'bg-gray-300 border-gray-300',
                            val.id === roomAccount?.id ? 'border-red-500' : ''
                        )}
                    >
                        <div className={clsx('w-full flex items-center h-full rounded', val.is_active ? 'bg-lime-500' : 'bg-gray-300')}>
                            <p className="text-base flex-1">
                                <span className="font-medium">{val.room.short_name}</span> {val.name}
                            </p>
                            {val.ewallet ? (
                                <Tooltip title={val.ewallet.address}>
                                    <span>{val.ewallet.type.name}</span>
                                </Tooltip>
                            ) : (
                                ''
                            )}
                            <div className="ml-4">
                                <Tooltip title="Изменить статус аккаунта">
                                    <IconButton
                                        type="button"
                                        onClick={() =>
                                            accountMutation.mutate({ id: val.id, name: val.name, ewallet: val.ewallet?.id, is_active: !val.is_active })
                                        }
                                    >
                                        {val.is_active ? <Cancel /> : <CheckCircle />}
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="col-span-4">
                <div
                    onClick={() => setNewAccountOpen(true)}
                    className="py-2 h-full flex items-center cursor-pointer justify-center border-4 border-dashed rounded"
                >
                    <Add className="text-gray-300" />
                </div>
            </div>
            <p className="col-span-12 text-lg font-medium">Записи</p>
            {roomAccount ? (
                <>
                    {roomAccount.account_records.map(val => (
                        <div className="col-span-4" key={val.id}>
                            <div
                                className={clsx(
                                    'w-full h-full p-5 border-2 shadow-md cursor-pointer rounded',
                                    val.is_active ? 'bg-lime-500 border-lime-500' : 'bg-gray-300 border-gray-300'
                                )}
                            >
                                <p className="flex">
                                    <div className="flex-1">
                                        <p>ID: {val.id}</p>
                                        <p>Currency: {val.chip_currency.currency.name}</p>
                                        <p>Rate: {val.chip_currency.chip_rate}</p>
                                        <p>Limits: {val.transfer_limit === null ? '-' : val.transfer_limit}</p>
                                        {/*{!val.active ? <p>Result: ${val.usd_result}</p> : null}*/}
                                    </div>
                                    <div className="w-1/5">
                                        <p>Club: {val.club?.name}</p>
                                        <p>Balance: {val.last_balance}</p>
                                        <p>Result: {val.result}</p>
                                        {/*<p>start: {val.start}</p>*/}
                                        {/*{!val.active ? <p>end: {val.end}</p> : null}*/}
                                    </div>
                                    <div className="ml-4 self-center">
                                        {val.is_active ? (
                                            <Tooltip title="Изменить лимиты">
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setAccountRecordId(val.id);
                                                        setChipCurrencyId(val.chip_currency.id);
                                                        setEditAccountRecordLimitsOpen(true);
                                                    }}
                                                >
                                                    <StopIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                    <div className="ml-4 self-center">
                                        {val.is_active ? (
                                            <Tooltip title="Изменить баланс">
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setAccountRecordId(val.id);
                                                        setChipCurrencyId(val.chip_currency.id);
                                                        setEditAccountRecordOpen(true);
                                                    }}
                                                >
                                                    <AccountBalanceIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                    <div className="ml-4 self-center">
                                        {val.is_active ? (
                                            <Tooltip title="Перевод средств">
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setAccountRecordId(val.id);
                                                        setChipCurrencyId(val.chip_currency.id);
                                                        setTransferBalanceOpen(true);
                                                    }}
                                                >
                                                    <SyncAltIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                    <div className="ml-4 self-center">
                                        <Tooltip title="Изменить статус записи">
                                            <IconButton type="button" onClick={() => accountRecordMutation.mutate({ id: val.id, is_active: !val.is_active })}>
                                                {val.is_active ? <Cancel /> : <CheckCircle />}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </p>
                            </div>
                        </div>
                    ))}
                    <div className="col-span-4">
                        <div
                            onClick={() => setNewRecordOpen(true)}
                            className="py-2 h-full flex items-center cursor-pointer justify-center border-4 border-dashed rounded"
                        >
                            <Add className="text-gray-300" />
                        </div>
                    </div>
                </>
            ) : (
                <p className="col-span-12 text-center">Выберите аккаунт</p>
            )}

            <NewAccountDialog open={newAccountOpen} onClose={() => setNewAccountOpen(false)} />

            <NewAccountRecordDialog open={newRecordOpen} onClose={() => setNewRecordOpen(false)} accountId={roomAccount?.id} roomId={roomAccount?.room.id} />

            <EditAccountRecordDialog open={editAccountRecordOpen} onClose={() => setEditAccountRecordOpen(false)} accountRecordId={accountRecordId} />
            <EditAccountRecordLimitsDialog
                open={editAccountRecordLimitsOpen}
                onClose={() => setEditAccountRecordLimitsOpen(false)}
                accountRecordId={accountRecordId}
            />

            <Dialog fullWidth maxWidth='sm' open={transferBalanceOpen} onClose={() => setTransferBalanceOpen(false)} fullScreen={fullScreen}>
                <TransferBalanceDialog
                    close={() => setTransferBalanceOpen(false)}
                    playerId={playerId}
                    cashoutAccountRecordId={accountRecordId}
                    chipCurrencyId={chipCurrencyId}
                />
            </Dialog>
        </React.Fragment>
    );
};

export default Accounts;
