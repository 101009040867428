import { FC, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import DeleteDialog from 'components/DeleteDialog';
import { IconButton, Box, Card, CircularProgress, Stack } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteScheduledHiddenTournament, getScheduledHiddenTournaments, newSchedulediddenTournament } from 'api/schedulersettings';

type Props = {
    /**
     * Hash турнира.
     */
    hash: string;
};

/**
 * Отображает таблицу скрытых турниров в расписаторе.
 */
const HiddenTable: FC<Props> = ({ hash }) => {
    const queryClient = useQueryClient();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [hiddenId, setHiddenId] = useState(null);

    const { control, handleSubmit } = useForm({
        defaultValues: {
            start_at: null,
            end_at: null,
        },
    });

    const { data: tournaments, isFetching } = useQuery(['scheduled-tournaments-group-hidden', pageSize, page, hash], getScheduledHiddenTournaments, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    });

    const createMutation = useMutation(newSchedulediddenTournament, {
        onSuccess: () => {
            queryClient.invalidateQueries(['scheduled-tournaments-group-hidden']);
            queryClient.invalidateQueries(['scheduler-tournaments-data']);
        },
    });

    const deleteMutation = useMutation(deleteScheduledHiddenTournament, {
        onSuccess: () => {
            queryClient.invalidateQueries(['scheduled-tournaments-group-hidden']);
            queryClient.invalidateQueries(['scheduler-tournaments-data']);
            setDeleteDialog(false);
        },
    });

    const columns: GridColDef[] = [
        {
            field: 'start_at',
            headerName: 'Начало',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.start_at).format('DD.MM.YYYY HH:mm')}`,
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'end_at',
            headerName: 'Конец',
            valueGetter: (params: GridValueGetterParams) => (params.row.end_at ? `${moment(params.row.end_at).format('DD.MM.YYYY HH:mm')}` : '-'),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: params => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setHiddenId(params.row.id);
                                setDeleteDialog(true);
                            }}
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    const onSubmit = handleSubmit((data: any) => {
        createMutation.mutate({
            tournament_group_hash: hash,
            start_at: data.start_at?.toISOString() ?? moment(),
            end_at: data.end_at?.toISOString() ?? null,
        });
    });

    return (
        <>
            {isFetching && !tournaments && (
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                    <CircularProgress />
                </Card>
            )}
            {tournaments && (
                <Box
                    className="h-auto overflow-auto"
                    sx={{
                        '& .active': {
                            backgroundColor: '#fcecd5',
                            color: '#1a3e72',
                        },
                    }}
                >
                    <div>
                        <form className="flex flex-row justify-between items-center space-x-4 p-4" onSubmit={onSubmit} noValidate>
                            <Controller
                                name="start_at"
                                control={control}
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        renderInput={props => <TextField {...props} label="Дата начала" size="small" required fullWidth />}
                                    />
                                )}
                            />
                            <Controller
                                name="end_at"
                                control={control}
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        renderInput={props => <TextField {...props} label="Дата конца" size="small" required fullWidth />}
                                    />
                                )}
                            />
                            <Button type="submit" variant="contained" size="medium" loading={createMutation.isLoading}>
                                Создать
                            </Button>
                        </form>
                    </div>
                    <div>
                        <DataGrid
                            autoHeight={true}
                            rows={tournaments.hidden_groups ?? []}
                            columns={columns}
                            pageSize={pageSize}
                            rowCount={tournaments.count ?? 0}
                            page={page}
                            paginationMode="server"
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => {
                                setPageSize(newPageSize);
                                setPage(0);
                            }}
                            rowsPerPageOptions={[25, 50, 100, 250]}
                            pagination
                            disableSelectionOnClick
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Данные отсутствуют
                                    </Stack>
                                ),
                            }}
                        />

                        <DeleteDialog
                            close={() => setDeleteDialog(false)}
                            open={deleteDialog}
                            loading={deleteMutation.isLoading}
                            handleAgreeAction={() => deleteMutation.mutate(hiddenId)}
                        />
                    </div>
                </Box>
            )}
        </>
    );
};

export default HiddenTable;
