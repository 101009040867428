import { Navigate } from 'react-router-dom';

import Dashboard from 'layouts/Dashboard/Dashboard';

import Login from 'pages/Login/Login';
import Home from 'pages/Home/Home';
import Players from 'pages/Players/Players';
import Balances from 'pages/Balances/Balances';
import Transfers from 'pages/Transfers/Transfers';
import NotFound from 'pages/NotFound/NotFound';
import Player from 'pages/Player/Player';
import Transfer from 'pages/Transfer/Transfer';
import Accounting from 'pages/Accounting/Accounting';
import BuyinLimits from 'pages/BuyinLimits/BuyinLimits';
import TournamentsGrid from 'pages/TournamentsGrid/TournamentsGrid';
import Stats from 'pages/Stats/Stats';
import EvStats from 'pages/EvStats/EvStats';
import Analytics from 'pages/Analytics/Analytics';
import HandHistories from 'pages/HandHistories/HandHistories';
import TournamentsRates from 'pages/TournamentsRates/TournamentsRates';
import Reconciliation from 'pages/Reconciliation/Reconciliation';
import ManualLabels from 'pages/ManualLabels/ManualLabels';
import Team from 'pages/Team/Team';
import ScoringGGNetwork from 'pages/ScoringGGNetwork/ScoringGGNetwork';
import Scheduler from 'pages/Raspisator/Scheduler';
import Messaging from 'pages/Messaging/Messaging';

const conditionalRedirect = (shouldRedirect: boolean, path: string, element: React.ReactElement) => {
    return shouldRedirect ? <Navigate to={path} /> : element;
};

const routes = (isLoggedIn: boolean, permissions: string[]) => [
    {
        path: '/login',
        element: conditionalRedirect(isLoggedIn, '/', <Login />),
    },
    {
        path: '/',
        element: conditionalRedirect(!isLoggedIn, 'login', <Dashboard />),
        children: [
            { path: '/', element: <Home />, permission: '4e10e602-6d28-40ca-afb9-3bbe1922d2cb' },
            { path: '/players', element: <Players />, permission: '95cff11e-9605-4773-87af-da34bbfafed0' },
            { path: '/players/:playerId', element: <Player />, permission: 'b25bd51e-7265-415b-8047-c2969c775082' },
            // { path: '/balances', element: <Balances />, permission: '7bbfece8-5456-4d7b-a1a0-c2efdcf8afcc' },
            { path: '/transfers', element: <Transfers />, permission: '498425ba-9499-48fe-8d59-2bbc7d6e07c7' },
            { path: '/transfers/:transferId', element: <Transfer />, permission: 'd1acc9f7-a47e-41b5-974d-f8deea65ddc0' },
            // { path: '/accounting', element: <Accounting />, permission: 'a13f40a5-f890-45d5-ac35-024a1a516503' },
            { path: '/buyinlimits', element: <BuyinLimits />, permission: '54fb736e-fbc7-4ef2-b9db-c5ea92152039' },
            { path: '/tournamentsgrid', element: <TournamentsGrid />, permission: '9c822c33-083f-4af6-a5fe-31773c397173' },
            { path: '/stats', element: <Stats />, permission: '54c74276-2bf7-45ca-9282-b068778492d8' },
            { path: '/evanalysis', element: <EvStats />, permission: '196866ad-575d-422e-87ee-ab56ab257fbe' },
            { path: '/handhistories', element: <HandHistories />, permission: '4d7c665f-c30b-429b-84ef-96c5a948906f' },
            { path: '/analytics', element: <Analytics />, permission: '653e9dd0-ae72-41cf-8ce0-008392adce2f' },
            { path: '/tournamentsrates', element: <TournamentsRates />, permission: 'fc6952d0-8e22-4884-b2ae-7bb3ce3d0703' },
            { path: '/reconciliation', element: <Reconciliation />, permission: 'b0acc62a-1724-42ff-bcdc-91399aa16325' },
            { path: '/manuallabels', element: <ManualLabels />, permission: 'f1e08427-6c16-4c99-9bbf-97c49b690a52' },
            { path: '/scheduler', element: <Scheduler />, permission: '6787a8c5-92ac-4a2f-b1bc-f1a12bdc67cd' },
            { path: '/messaging', element: <Messaging />, permission: '5472f36d-c97c-452f-9637-990d69802b8d' },
            { path: '/scoringggnetwork', element: <ScoringGGNetwork />, permission: '5f9258c6-ab30-4b84-878b-9dc8cf39de72' },
            // { path: '/team', element: <Team />, permission: '050dbba8-1b48-4252-9730-2753f7ac907c' },
        ].filter(val => permissions.includes(val.permission)),
    },
    { path: '*', element: <NotFound /> },
];

export default routes;
