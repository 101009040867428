import { FC, Dispatch, SetStateAction } from 'react';

import { Box, IconButton, InputBase, Paper } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { Controller, useForm } from 'react-hook-form';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Массив примененных фильтров.
     */
    data: any;

    /**
     * Массив румов.
     */
    rooms: [{ id: string; network: string }];

    /**
     * Функция поиска турнира.
     */
    setSearch: Dispatch<SetStateAction<string>>;

    /**
     * Hash турнира в поиске.
     */
    search: string;
};

/**
 * Компонент содержит фильтры для таблицы турниров расписатора.
 */
const ResultsFilters: FC<Props> = ({
    data,
    setFilter,
    setSearch,
    rooms,
    search,
}) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            hash: search,
        },
    });

    function changeFilter(item) {
        let filter = data.slice();
        const id = filter.findIndex(data => data.id === item.id);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            filter.push(item);
        }

        setFilter(filter);
    }

    function filterCheck(roomId) {
        const id = data.filter(val => val.id === roomId);

        return id[0] ? true : false;
    }

    function filterValue(data?) {
        if (data && data.length > 0) {
            const separator = data.length > 1 ? ' ,' : '';
            return data.map(x => x.network) + separator;
        }
        return undefined;
    }

    const onSubmit = handleSubmit(data => {
        setSearch(data.hash);
    });

    return (
        <Box className="flex flex-col">
            <Box className="flex flex-row flex-wrap gap-4 items-center mb-3">
                {/* Поиск по hash турнира */}
                <Paper component="form" className="flex w-[280px] h-[48px] bg-gray-50 items-center" sx={{ p: '2px 4px' }} onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name="hash"
                        render={({ field }) => {
                            return (
                                <>
                                    <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Поиск турнира по хэшу" {...field} />
                                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                        <Search />
                                    </IconButton>
                                </>
                            );
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default ResultsFilters;
