import axios from 'helpers/axios';

/**
 * Список румов для которых создана сетка.
 */
export const getRoomsAbility = async () => {
    const { data } = await axios.get('/api/adminapi/grid/abilitygrid/networks/');

    return data;
};

/**
 * Список ABI выбранного рума.
 */
 export const getAbility = async ({ queryKey }) => {
    const [, selectRoom, gridType] = queryKey;

    const { data } = await axios.get(`/api/adminapi/grid/abilitygrid/?gridType=${gridType}&networkId=${selectRoom.id}`);

    return data;
};

/**
 * Редактирование значения ABI.
 */
export const updateAbility = async ({ id, body }) => {
    const { data } = await axios.put(`/api/adminapi/grid/abilitygrid/${id}/`, body);

    return data;
};

/**
 * Создание сетки ABI.
 */
export const createAbilityItem = async (id) => {
    const { data } = await axios.post(`/api/adminapi/grid/abilitygrid/networks/${id}/`);

    return data;
}

/**
 * Удаление сетки ABI.
 */
 export const deleteAbilityItem = async (id) => {
    const { data } = await axios.delete(`/api/adminapi/grid/abilitygrid/networks/${id}/`);

    return data;
}

/**
 * Импорт значения ABI.
 */
 export const importAbility = async ({ body }) => {
    const { data } = await axios.put(`/api/adminapi/grid/abilitygrid/clone/?fromNetworkId=${body.fromNetworkId}&toNetworkId=${body.toNetworkId}&fromType=${body.fromType}&toType=${body.toType}`);

    return data;
};
