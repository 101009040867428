import { useState, FC } from 'react';
import { Box, Chip } from '@mui/material';
import Risk from './components/Risk/Risk';
import Scoring from './components/Scoring/Scoring';

/**
 * Страница с аналитикой.
 */
const Analytics: FC = () => {
    const [tabIndex, setTabIndex] = useState(1);

    function handleChangeTab(newIndex: number) {
        if (tabIndex === newIndex) {
            return;
        }

        setTabIndex(newIndex);
    }

    return (
        <>
            <Box className='flex flex-row justify-between'>
                <Box className='flex gap-1'>
                    <Chip label='Игроки Риск' color={tabIndex === 0 ? 'primary' : 'default'} onClick={() => handleChangeTab(0)} clickable />
                    <Chip label='Игроки Скоринг' color={tabIndex === 1 ? 'primary' : 'default'} onClick={() => handleChangeTab(1)} clickable />
                </Box>
            </Box>
            <Box className="mt-4 p-1">
                {tabIndex === 0 && <Risk />}
                {tabIndex === 1 && <Scoring />}
            </Box>
        </>
    );
}

export default Analytics;
