import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { getPlayersRevisionMaxBuyins } from 'api/ggnetworkscoring';
import { Card, CircularProgress } from '@mui/material';
import ScoringFilters from './ScoringFilters';
import ScoringTable from './ScoringTable';

/**
 * Таблица скоринга.
 */
const Scoring: FC = () => {
    // Фильтр по ревизии
    const [revision, setRevision] = useState();
    // Поиск игрока
    const [search, setSearch] = useState('');
    // Фильтр по кураторам
    // const [curatorFilter, setCuratorFilter] = useState<any>(null);
    // Пагинация
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const { data: maxBuyin, isFetching } = useQuery(['player-ggnetwork-maxbuyin', revision, pageSize, page], getPlayersRevisionMaxBuyins, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    return (
        <>
            <ScoringFilters
                setSearch={setSearch}
                search={search}
                revision={revision}
                setRevision={setRevision}

            />
            {isFetching && !maxBuyin && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {maxBuyin &&
                <Card>
                    <ScoringTable
                        items={maxBuyin}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        page={page}
                        setPage={setPage}
                    />
                </Card>
            }
        </>
    );
}

export default Scoring;
