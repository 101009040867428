import React from 'react';

import { useNavigate } from 'react-router-dom';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/lab/LoadingButton';

import { useMutation, useQueryClient } from 'react-query';

import { deleteTransfer } from 'api/transfers';

interface ConfirmTransferDeleteDialogInterface extends DialogProps {
        transferId: string;
}

const ConfirmTransferDeleteDialog: React.FC<ConfirmTransferDeleteDialogInterface> = (props) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const deleteTransferMutation = useMutation(deleteTransfer, {
        onSuccess: (_) => {
            navigate(`/transfers`);
            queryClient.invalidateQueries(['transfer', props.transferId]);
        }
    });

    return (
        <Dialog {...props} maxWidth="sm">
            <DialogTitle>Удалить трансфер?</DialogTitle>
            <DialogActions>
                <Button color="secondary" disabled={deleteTransferMutation.isLoading} onClick={() => props.onClose({}, 'backdropClick')}>
                    Отмена
                </Button>
                <Button color="primary" type="submit" disabled={deleteTransferMutation.isLoading} onClick={() => deleteTransferMutation.mutate({ transferId: props.transferId })}>
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmTransferDeleteDialog;
