import axios from 'helpers/axios';

/**
 * Список групп.
 */
 export const getGroupFormatsRulesList = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/tournamentformatgroups/');

    return data;
};

/**
 * Удалить группу
 */
 export const deleteGroupFormatsRule = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/tournamentformatgroups/${id}/`);

    return data;
};

/**
 * Создать группу
 */
export const createGroupFormatsRule = async (group) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/tournamentformatgroups/`, group);

    return data;
};

/**
 * Данные по конкретной группе.
 */
export const getGroupFormatsRulesDetails = async ({ queryKey }) => {
    const [, id] = queryKey;
    if (!id) {
        return;
    }

    const { data } = await axios.get(`/api/adminapi/statsscoring/tournamentformatgroups/${id}/`);

    return data;
};

/**
 * Добавить правило в группе 
 */
export const createGroupFormatsRulesItem = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/tournamentformatgroups/${id}/formatrules/`, body);

    return data;
};

/**
 * Удалить правило в группе
 */
export const deleteGroupFormatsRulesItem = async ({ group_id, rule_id }) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/tournamentformatgroups/${group_id}/formatrules/${rule_id}/`);

    return data;
};