import axios from 'helpers/axios';


export const getTournamentEvaluations = async () => {
    const { data } = await axios.get('/api/adminapi/tournamentevals/');

    return data;
};


export const getBuyinLimits = async ({ queryKey }) => {
    const [, groupId] = queryKey;
    const { data } = await axios.get('/api/adminapi/buyinlimits/', {params: {groupId: groupId}});

    return data;
};


export const updateBuyinLimits = async ({ groupId, levelId, ...body }) => {
    const { data } = await axios.put('/api/adminapi/buyinlimits/', body, {params: {levelId: levelId, groupId: groupId}});

    return data;
};
