import { FC } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { IconButton, DialogTitle, DialogContent, Select, MenuItem, InputLabel, TextField, Box } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { getTournamentFormatsList } from 'api/tournamentformats';
import { createGroupFormatsRulesItem } from 'api/tournamentformatgroups';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * ID маркера
     */
    id: string;
}

/**
 * Диалоговое окно для создания правила форматов в группе.
 */
const TournamentFormatGroupsNewItemDialog: FC<Props> = ({ close, id }) => {
    const queryClient = useQueryClient();

    const { data: formats, isFetching } = useQuery(['tournament-formats'], getTournamentFormatsList, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    const { mutate } = useMutation(createGroupFormatsRulesItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('format-group-list-data');
            close();
        },
    });

    const { control, handleSubmit } = useForm();

    const onSubmit = (data) => {
        const body = {
            tournament_format_id: data.format,
            include_tags: data.include_tags === '' ? [] : data.include_tags.split(','),
            exclude_tags: data.exclude_tags === '' ? [] : data.exclude_tags.split(','),
        }

        if (!body.tournament_format_id || !body.include_tags || !body.exclude_tags) {
            return;
        }

        mutate({ id, ...body })
    };

    return (
        <>
            <DialogTitle>
                Добавление правил формата для группы
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {!isFetching && (
                <DialogContent>
                    <form className='flex flex-col gap-6'>
                        <Controller
                            control={control}
                            name={`format`}
                            defaultValue=''
                            render={({ field }) => (
                                <Box className='flex flex-col gap-2'>
                                    <InputLabel id="select-label">Формат</InputLabel>
                                    <Select labelId="select-label" {...field} size="small" className='mb-4'>
                                        {formats.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            )}
                        />

                        <Controller
                            name={`include_tags`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="Включить тэги" size="small" helperText="Введите список тэгов, с разделением в виде запятой" />}
                        />

                        <Controller
                            name={`exclude_tags`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="Исключить тэги" size="small" helperText="Введите список тэгов, с разделением в виде запятой" />}
                        />

                        <Box>
                            <div className='pt-4'>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    type='button'
                                    size="small"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </Box>
                    </form>
                </DialogContent>
            )}
        </>
    );
};

export default TournamentFormatGroupsNewItemDialog;
