import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    Box,
    Stack,
} from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef } from '@mui/x-data-grid';
import { updateSchedulerRoiTypeLimits } from 'api/schedulersettings';
import { SchedulerQueryKeys } from 'constants/QueryKeys/schedulerKeys';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Список элементов.
     */
    items: any;
};

/**
 * Отображает таблицу ROI в расписаторе.
 */
const SchedulerRoiTypeLimitsTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const editItemMutation = useMutation(updateSchedulerRoiTypeLimits, {
        onSuccess: () => {
            queryClient.invalidateQueries(SchedulerQueryKeys.ROI_TYPE_LIMITS_KEY);
        },
    });

    const columns: GridColDef[] = [
        {
            field: 'level',
            headerName: 'Уровень',
            flex: 1,
            minWidth: 100,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'separator_1',
            headerName: 'TOP',
            flex: 1,
            minWidth: 100,
            type: 'number',
            editable: true,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'separator_2',
            headerName: 'AVG',
            flex: 1,
            minWidth: 100,
            type: 'number',
            editable: true,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'separator_3',
            headerName: 'Weak',
            flex: 1,
            minWidth: 100,
            type: 'number',
            editable: true,
            align: 'left',
            headerAlign: 'left',
        },
    ];

    function editCell(params: any) {
        if (Number(params.formattedValue) !== Number(params.value)) {
            const editId = params.id;
            const body = {
                separator: params.field.charAt(params.field.length - 1),
                value: params.value,
            }

            editItemMutation.mutate({ id: editId, body })
        }
    }

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                autoHeight={true}
                rows={items ?? []}
                columns={columns}
                hideFooter
                disableSelectionOnClick
                disableColumnMenu
                onCellEditCommit={(params: GridCellEditCommitParams) => editCell(params)}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default SchedulerRoiTypeLimitsTable;
