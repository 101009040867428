import axios from 'helpers/axios';

export const createAccountRecord = async ({accountId, ...body}) => {
    // const { data } = await axios.post('/api/adminapi/accounts/', body);
    const { data } = await axios.post(`/api/adminapi/roomaccounts/${accountId}/accountrecords/`, body);

    return data;
};


export const getAccountRecordLatestBalance = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/accountrecords/${id}/latestbalance/`);

    return data;
};


export const getAccountRecordExcessFunds = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/accountrecords/${id}/excessfunds/`);

    return data;
};
