import { FC, Dispatch, SetStateAction } from 'react';
import Dropdown from 'components/Dropdown';
import { Box, MenuItem, ListItemIcon } from '@mui/material';
import { Check } from '@mui/icons-material';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Массив примененных фильтров.
     */
    data: any;

    /**
     * Массив румов.
     */
    rooms: [{ id: string, network: string }];
};

/**
 * Компонент содержит фильтр румов для расписатора.
 */
const SchedulerRoomsFilter: FC<Props> = ({ data, setFilter, rooms }) => {
    function changeFilter(item) {
        return setFilter([item])
    }

    function filterCheck(item) {
        const check = data.filter(val => val === item);

        return check[0] ? true : false;
    }

    return (
        <Box className='mb-3'>
            <Dropdown
                btnLabel='Рум'
                btnValue={data}
                renderContent={() => (
                    rooms?.map((item, index) => {
                        return (
                            <MenuItem onClick={() => changeFilter(item)} key={index} >
                                {filterCheck(item) &&
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                }
                                {item}
                            </MenuItem>
                        )
                    })
                )}
            />
        </Box>
    );
};

export default SchedulerRoomsFilter;
