import React from 'react';

import { useQuery } from 'react-query';

import StatsCards from './StatsCards';
import { getPlayerUntrackedTournamentsCount, getPlayerProfit } from 'api/players';

type Props = {
    playerId: number;
    roomId: number;
    startDate: string | null;
    endDate: string | null;
    playerLevel: string;
};

const Stats: React.FC<Props> = ({ playerId, roomId, startDate, endDate, playerLevel }) => {
    const untrackedTournamentsQuery = useQuery(['player-untracked-tournaments-count', playerId, roomId, startDate, endDate], getPlayerUntrackedTournamentsCount, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const playerProfitQuery = useQuery(['player-profit', playerId, roomId, startDate, endDate], getPlayerProfit, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    return (
        <>
            {
                <StatsCards
                    stats={
                        [
                            {
                                'name': 'Total Count',
                                'value': untrackedTournamentsQuery.data
                            },
                            {
                                'name': 'Profit USD',
                                'value': playerProfitQuery.data
                            },
                            {
                                'name': 'Player Level',
                                'value': playerLevel
                            },
                        ]
                    }
                />
            }
        </>
    )
}

export default Stats;
