import { FC, useState } from 'react';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

import {
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from 'components/DeleteDialog';
import { deleteSchedulerCoefficientItem } from 'api/schedulersettings';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу Коэффициентов в распиторе.
 */
const SchedulerCoefficientTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const [pageSize, setPageSize] = useState<number>(10);
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<number>(0);

    const { mutate, isLoading } = useMutation(deleteSchedulerCoefficientItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-coefficient');
        },
    });

    const currentDate = moment().toISOString();

    const columns: GridColDef[] = [
      { field: 'network', headerName: 'Рум', flex: 0.3, minWidth: 100},
      {
        field: 'start_at',
        headerName: 'Дата начала',
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.start_dt).format('DD.MM.YYYY HH:mm')}`,
        flex: 0.3,
        minWidth: 150,
      },
      {
        field: 'end_at',
        headerName: 'Дата окончания',
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.end_dt).format('DD.MM.YYYY HH:mm')}`,
        flex: 0.3,
        minWidth: 150,
      },
      {
        field: 'min_buyin',
        headerName: 'Мин байин',
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: 'max_buyin',
        headerName: 'Макс байин',
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: 'mod1',
        headerName: 'Кэф утро',
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: 'mod2',
        headerName: 'Кэф вечер',
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            function deliteItem(id: number) {
              mutate(item);
              setOpen(false);
            }

            function openDialog (id: number) {
              setItem(id);
              setOpen(true);
            }

            return (
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton onClick={() => openDialog(params.row.id)} disabled={isLoading}>
                  <DeleteIcon />
                </IconButton>
                <DeleteDialog close={() => setOpen(false)} open={open} handleAgreeAction={() => deliteItem(params.row.id)}/>
              </Box>
            );
        }
      }
    ];

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
      <DataGrid
          autoHeight={true}
          rows={items}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          getCellClassName={(params: GridCellParams<number>) => {
            return params.row.end_dt > currentDate && params.row.start_dt < currentDate ? 'active' : '';
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default SchedulerCoefficientTable;
