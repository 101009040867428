import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  IconButton,
  Box,
  Stack,
  useMediaQuery,
  Typography,
  Button,
  CircularProgress,
  Dialog,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from 'components/DeleteDialog';
import moment from 'moment';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import theme from 'themes/lightTheme';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { deletePrizeDistributionGroup, getPrizeDistributionGroupDetails } from 'api/prizedistributiongroups';
import PrizeDistributionNewItemDialog from './PrizeDistributionNewItemDialog';
import PrizeDistributionDetails from './PrizeDistributionDetails';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;

  /**
   * ID группы для детального просмотра
   */
  prizeGroupView: string; 
    
  /**
   * Изменить группу для детального просмотра
   */
  setPrizeGroupView: Dispatch<SetStateAction<string>>;
};

/**
 * Отображает таблицу со всеми группами, содержащих распределения призов.
 */
const PrizeDistributionTable: FC<Props> = ({ items, prizeGroupView, setPrizeGroupView }) => {
    const queryClient = useQueryClient();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [pageSize, setPageSize] = useState<number>(10);
    const [open, setOpen] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [item, setItem] = useState<number>(0);

    const { mutate, isLoading } = useMutation(deletePrizeDistributionGroup, {
        onSuccess: () => {
            queryClient.invalidateQueries('prize-distribution');
        },
    });

    const {data: groupDetails, isFetching} = useQuery(['group-list-prize-distribution', prizeGroupView], getPrizeDistributionGroupDetails, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Группа', flex: 1, minWidth: 100, sortable: true },
      {
        field: 'created_at',
        headerName: 'Дата создания',
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.created_at).format('DD.MM.YYYY HH:mm')}`,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "view",
        headerName: "Просмотр группы",
        sortable: false,
        disableColumnMenu: true,
        minWidth: 150,
        renderCell: (params) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton onClick={() => setPrizeGroupView(params.row.id)} disabled={isLoading}>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Box>
            );
        }
      },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            function deliteItem(id: number) {
              mutate(item);
              setOpen(false);
            }

            function openDialog (id: number) {
              setItem(id);
              setOpen(true);
            }

            return (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton onClick={() => openDialog(params.row.id)} disabled={isLoading}>
                  <DeleteIcon />
                </IconButton>
                <DeleteDialog close={() => setOpen(false)} open={open} handleAgreeAction={() => deliteItem(params.row.id)}/>
              </Box>
            );
        }
      }
    ];

  return (
    <>
        {prizeGroupView && (
            <Box className='flex flex-col gap-4 p-3'>
                <Box className='flex flex-row items-center justify-between'>
                    <Box className='flex flex-row items-center'>
                        <Box>
                            <IconButton onClick={() => setPrizeGroupView(null)} disabled={isLoading} size="small">
                                <ArrowBackOutlinedIcon />
                            </IconButton>
                        </Box>
                        <Typography className='ml-2'>
                            {groupDetails?.name}
                        </Typography>
                    </Box>
                    <Button onClick={() => setNewItem(true)} variant="contained" className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Добавить призы</Button>
                </Box>
                <Box>
                    {isFetching && <Box className='flex items-center justify-center h-32'><CircularProgress /></Box>}
                    {!isFetching && groupDetails && (
                        <PrizeDistributionDetails data={groupDetails.ratios} markId={groupDetails.id} />
                    )}
                </Box>
            </Box>
        )}
        {!prizeGroupView && (
            <Box
                className="h-auto overflow-auto"
                sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
                }}
            >
                <DataGrid
                    autoHeight={true}
                    rows={items}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pagination
                    disableSelectionOnClick
                    disableColumnMenu
                    initialState={{
                      sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
                    }}
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              Данные отсутствуют
                            </Stack>
                        ),
                    }}
                />
          </Box>
        )}
        <Dialog fullWidth maxWidth='sm' open={newItem} onClose={() => setNewItem(false)} fullScreen={fullScreen}>
            <PrizeDistributionNewItemDialog close={() => setNewItem(false)} id={prizeGroupView} />
        </Dialog>
    </>
    
  );
};

export default PrizeDistributionTable;
