import { FC, useState } from 'react';
import { useMutation } from 'react-query';

import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';

import { Box, Select, MenuItem } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { getPlayerLabels, getPlayersLabelsCsv } from 'api/scoring_service';

type Props = {
    /**
     * Список румов
     */
    rooms: string[];
}

const PlayerStats: FC<Props> = ({ rooms }) => {
    const [playerStats, setPlayerStats] = useState(null);

    const { mutate, isLoading } = useMutation(getPlayerLabels, {
        onSuccess: (data) => {
            setPlayerStats(data);
        }
    });

    const DownloadCsv = useMutation(getPlayersLabelsCsv, {
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `labels.csv`)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    });

    const { control, handleSubmit, getValues } = useForm({
        defaultValues: {
            network: rooms[0],
            player: '',

        },
    });

    const onSubmit = handleSubmit(data => {
        mutate({
            network: data.network,
            players: [data.player],
        });
    });

    return (
        <>
            <Box className='flex flex-col'>
                <Box className='flex flex-row flex-wrap gap-4 items-center mb-3'>
                    <form className='flex flex-row gap-4 pt-2' onSubmit={onSubmit}>
                        <Controller
                            control={control}
                            name='network'
                            render={({ field }) => (
                                <Select {...field} >
                                    {rooms.map((network) => (
                                        <MenuItem
                                            key={network}
                                            value={network}
                                        >
                                            {network}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="player"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Имя игрока"
                                    required
                                    style={{ minWidth: '200px' }}
                                    {...field}
                                />
                            )}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={isLoading}
                        >
                            Загрузить
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            size="large"
                            style={{ minWidth: '250px' }}
                            onClick={() => {
                                DownloadCsv.mutate({ network: getValues('network') })
                            }}
                            disabled={DownloadCsv.isLoading}
                        >
                            Все игроки рума CSV
                        </Button>
                    </form>
                </Box>
                <div>
                    {playerStats && <pre>{JSON.stringify(playerStats, null, 2)}</pre>}
                </div>
            </Box>
        </>
    );
}

export default PlayerStats;
