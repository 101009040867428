import { FC, useState } from 'react';
import { Box, Card } from '@mui/material';
import HandsFilter from './HandsFilter';
import HandsTable from './HandsTable';

const HandHistories: FC = () => {
    const [playerIdFilter, setPlayerIdFilter] = useState<any>(null);

    return (
        <>
            <Box>
                <HandsFilter playerFilter={playerIdFilter} setPlayerFilter={setPlayerIdFilter} />
            </Box>
            <Card className="mt-4">
                <HandsTable playerId={playerIdFilter?.id} />
            </Card>
        </>
    );
};

export default HandHistories;
