import { FC, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { getPlayerGGNetworkCurrentManualMaxBuyin, createPlayerGGNetworkManualMaxBuyin, disablePlayerGGNetworkManualMaxBuyin } from 'api/ggnetworkscoring';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import DeleteDialog from 'components/DeleteDialog';

type Props = {
    playerId: any;
};

const ManualMaxBuyin: FC<Props> = ({ playerId, }) => {
    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false);

    const manualMaxBuyinQuery = useQuery(['ggnetwork-player-current-manual-max-buyin', playerId], getPlayerGGNetworkCurrentManualMaxBuyin, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const disableMaxBuyinMut = useMutation(disablePlayerGGNetworkManualMaxBuyin, {
        onSuccess: (_) => {
            queryClient.invalidateQueries(['ggnetwork-player-current-manual-max-buyin', playerId]);
            queryClient.invalidateQueries(['player-ggnetwork-scoring-history', playerId]);
            setOpen(false);
        }
    });

    const setMaxBuyinMut = useMutation(createPlayerGGNetworkManualMaxBuyin, {
        onSuccess: (_) => {
            queryClient.invalidateQueries(['ggnetwork-player-current-manual-max-buyin', playerId]);
            queryClient.invalidateQueries(['player-ggnetwork-scoring-history', playerId]);
        }
    });

    return (
        <>
            <Box className="flex flex-row flex-wrap items-center gap-2">
                <Typography variant="subtitle1">Ручной байин: {manualMaxBuyinQuery.data?.manual_max_buyin?.max_buyin || "-"}</Typography>
                {
                    manualMaxBuyinQuery.data?.manual_max_buyin?.id &&
                    < Box className="flex flex-row gap-2">
                        <IconButton onClick={() => setOpen(true)} disabled={
                            manualMaxBuyinQuery.isLoading || disableMaxBuyinMut.isLoading || setMaxBuyinMut.isLoading
                        }>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                <IconButton onClick={() => {
                    const userInput = prompt("Новое значение:");
                    if (userInput !== null) {
                        setMaxBuyinMut.mutate({ playerId: playerId, maxBuyin: userInput });
                    }
                }} disabled={manualMaxBuyinQuery.isLoading || disableMaxBuyinMut.isLoading || setMaxBuyinMut.isLoading}>
                    <EditIcon />
                </IconButton>
                <DeleteDialog close={() => setOpen(false)} open={open} loading={
                    manualMaxBuyinQuery.isLoading || disableMaxBuyinMut.isLoading || setMaxBuyinMut.isLoading
                } handleAgreeAction={() => disableMaxBuyinMut.mutate({ playerId: playerId })} />
            </Box>
        </>
    );
};

export default ManualMaxBuyin;
