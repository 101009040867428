import { useState, FC } from 'react';
import { Box, Button, Chip, Dialog, useMediaQuery } from '@mui/material';
import theme from 'themes/lightTheme';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Marks from './components/Marks/Marks';
import MarksDialog from './components/Marks/MarksDialog';
import TournamentsRules from './components/TournamentsRules/TournamentsRules';
import TournamentsRulesDialog from './components/TournamentsRules/TournamentsRulesDialog';
import PlayerStats from './components/PlayerStats/PlayerStats';
import TournamentStats from './components/TournamentStats/TournamentStats';
import { useQuery } from 'react-query';
import { getRulesRoomList } from 'api/tournamentsrules';
import UnknownPlayers from './components/UnknownPlayers/UnknownPlayers';
import UnknownPlayersDialog from './components/UnknownPlayers/UnknownPlayersDialog';
import UntrackedPlayers from './components/UntrackedPlayers/UntrackedPlayers';
import UntrackedPlayersDialog from './components/UntrackedPlayers/UntrackedPlayersDialog';
import TournamentFormats from './components/TournamentFormats/TournamentFormats';
import TournamentFormatsDialog from './components/TournamentFormats/TournamentFormatsDialog';
import PrizeDistributionDialog from './components/PrizeDistribution/PrizeDistributionDialog';
import PrizeDistribution from './components/PrizeDistribution/PrizeDistribution';
import TournamentFormatGroupsDialog from './components/TournamentFormatGroups/TournamentFormatGroupsDialog';
import TournamentFormatGroups from './components/TournamentFormatGroups/TournamentFormatGroups';
// import HandleAssessment from './components/HandleAssessment/HandleAssessment';

/**
 * Страница с таблицами оценки турниров.
 */
const TournamentsRates: FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [tabIndex, setTabIndex] = useState(0);
    const [settingIndex, setSettingIndex] = useState(0);

    const [open, setOpen] = useState(false);
    const [rulesView, setRulesView] = useState<string>(null)
    const [groupView, setGroupView] = useState<string>(null)
    const [untrackedGroupView, setUntrackedGroupView] = useState<string>(null)
    const [prizeGroupView, setPrizeGroupView] = useState<string>(null)
    const [formatView, setFormatView] = useState<string>(null)

    function handleChangeTab(newIndex: number) {
        if (tabIndex === newIndex) {
            return;
        }

        setTabIndex(newIndex);
    }

    function handleChangeSettingTab(newIndex: number) {
        if (settingIndex === newIndex) {
            return;
        }

        setSettingIndex(newIndex);
    }

    const { data: rooms, isFetching } = useQuery(['tournaments-rulesets-rooms'], getRulesRoomList, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    return (
        <>
            <Box className='flex flex-row justify-between'>
                <Box className='flex flex-col gap-4'>
                    <Box className='flex gap-1'>
                        <Chip label='Настройка турниров' color={tabIndex === 0 ? 'primary' : 'default'} onClick={() => handleChangeTab(0)} clickable disabled={isFetching} />
                        <Chip label='Игрок' color={tabIndex === 1 ? 'primary' : 'default'} onClick={() => handleChangeTab(1)} clickable disabled={isFetching} />
                        <Chip label='Турнир' color={tabIndex === 2 ? 'primary' : 'default'} onClick={() => handleChangeTab(2)} clickable disabled={isFetching} />
                        {/* <Chip label='Ручная оценка' color={tabIndex === 3 ? 'primary' : 'default'} onClick={() => handleChangeTab(3)} clickable disabled={isFetching} /> */}
                    </Box>
                    {tabIndex === 0 &&
                        <Box className='flex gap-1'>
                            <Chip label='Метки' color={settingIndex === 0 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(0)} clickable />
                            <Chip label='Правила Оценки' color={settingIndex === 1 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(1)} clickable disabled={isFetching} />
                            <Chip label='Форматы турниров' color={settingIndex === 4 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(4)} clickable disabled={isFetching} />
                            <Chip label='Правила форматов' color={settingIndex === 6 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(6)} clickable disabled={isFetching} />
                            <Chip label='Распределение null игроков' color={settingIndex === 2 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(2)} clickable disabled={isFetching} />
                            <Chip label='Распределение untracked игроков' color={settingIndex === 3 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(3)} clickable disabled={isFetching} />
                            <Chip label='Распределение призов' color={settingIndex === 5 ? 'info' : 'default'} onClick={() => handleChangeSettingTab(5)} clickable disabled={isFetching} />
                        </Box>
                    }
                </Box>
                {tabIndex === 0 && (
                    <Box>
                        {settingIndex === 0 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая метка</Button>}
                        {settingIndex === 1 && !rulesView && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />} disabled={isFetching}>Новое правило</Button>}
                        {settingIndex === 2 && !groupView && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />} disabled={isFetching}>Новая группа игроков</Button>}
                        {settingIndex === 3 && !groupView && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />} disabled={isFetching}>Новая группа игроков</Button>}
                        {settingIndex === 4 && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />} disabled={isFetching}>Новый формат</Button>}
                        {settingIndex === 5 && !prizeGroupView && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />} disabled={isFetching}>Новая группа распределения</Button>}
                        {settingIndex === 6 && !formatView && <Button variant="contained" onClick={() => setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />} disabled={isFetching}>Новая группа форматов</Button>}
                    </Box>
                )}
            </Box>
            <Box className="mt-4 p-1">
                {settingIndex === 0 && tabIndex === 0 && <Marks />}
                {settingIndex === 1 && tabIndex === 0 && <TournamentsRules rulesView={rulesView} setRulesView={setRulesView} />}
                {tabIndex === 1 && <PlayerStats rooms={rooms.networks} />}
                {tabIndex === 2 && <TournamentStats rooms={rooms.networks} />}
                {/* {tabIndex === 3 && <HandleAssessment rooms={rooms.networks} />} */}
                {settingIndex === 2 && tabIndex === 0 && <UnknownPlayers groupView={groupView} setGroupView={setGroupView} />}
                {settingIndex === 3 && tabIndex === 0 && <UntrackedPlayers groupView={untrackedGroupView} setGroupView={setUntrackedGroupView} />}
                {settingIndex === 4 && tabIndex === 0 && <TournamentFormats />}
                {settingIndex === 5 && tabIndex === 0 && <PrizeDistribution prizeGroupView={prizeGroupView} setPrizeGroupView={setPrizeGroupView} />}
                {settingIndex === 6 && tabIndex === 0 && <TournamentFormatGroups formatView={formatView} setFormatView={setFormatView} />}
            </Box>
            <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
                {settingIndex === 0 && <MarksDialog close={() => setOpen(false)} />}
                {settingIndex === 1 && <TournamentsRulesDialog close={() => setOpen(false)} rooms={rooms.networks} />}
                {settingIndex === 2 && <UnknownPlayersDialog close={() => setOpen(false)} rooms={rooms.networks} />}
                {settingIndex === 3 && <UntrackedPlayersDialog close={() => setOpen(false)} rooms={rooms.networks} />}
                {settingIndex === 4 && <TournamentFormatsDialog close={() => setOpen(false)} />}
                {settingIndex === 5 && <PrizeDistributionDialog close={() => setOpen(false)} rooms={rooms.networks} />}
                {settingIndex === 6 && <TournamentFormatGroupsDialog close={() => setOpen(false)} rooms={rooms.networks} />}
            </Dialog>
        </>
    );
}

export default TournamentsRates;
