import axios from 'helpers/axios';

/**
 * Список форматов турнира.
 */
export const getTournamentFormatsList = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/tournamentformats/');

    return data;
};

/**
 * Удалить формат турнира
 */
export const deleteTournamentFormat = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/tournamentformats/${id}/`);

    return data;
};

/**
 * Создать формат турнира
 */
export const createTournamentFormat = async (format) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/tournamentformats/`, format);

    return data;
};