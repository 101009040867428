import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import DeleteDialog from 'components/DeleteDialog';
import { deleteRuleMarks } from 'api/tournamentsrules';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Детали правила.
   */
  rulesets: any;

  /**
   * ID метки
   */
  markId: string;
};

/**
 * Таблица содержащая информацию о метках и ее значениях
 */
const TournamentsRulesDetails: FC<Props> = ({ rulesets, markId }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<string>();

  const queryClient = useQueryClient();  
  const { mutate, isLoading } = useMutation(deleteRuleMarks, {
      onSuccess: () => {
          queryClient.invalidateQueries('tournaments-rulesets-data');
      },
    });

  function deliteItem() {
    mutate({ rulegroup_id: markId, ruleset_id: item });
    setOpen(false);
  }

  function openDialog (id: string) {
    setItem(id);
    setOpen(true);
  }
  return (
      <Box>
          <Box>
              {rulesets.length === 0 && <Box className='flex items-center justify-center h-32'><Typography>Значения отсутствуют</Typography></Box>}
              {rulesets.length > 0 && (
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={300}>Метка</TableCell>
                      <TableCell>Правила</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rulesets.map(item => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Box className='flex flex-row items-center'>
                            <Box>
                              <IconButton onClick={() => openDialog(item.id)} disabled={isLoading} size="small">
                                <DeleteIcon />
                              </IconButton>
                              <DeleteDialog close={() => setOpen(false)} open={open} handleAgreeAction={() => deliteItem()}/>
                            </Box>
                            <Typography variant='body1'>{item.label.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className='flex flex-col gap-2'>
                            <Box className='flex flex-row gap-6'>
                              {item.rules.map(item => (
                                <Box className='flex flex-row gap-1' key={item.id}>
                                  <Typography sx={{fontWeight: 500}}>{item.stat.name}</Typography>
                                  <Typography>{item.lower_bound}-{item.upper_bound}</Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
          </Box>
      </Box>
  )
}

export default TournamentsRulesDetails;
