import React from 'react'
import moment from 'moment';

import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';
import Select from 'components/Select/Select';

import { useQuery } from 'react-query';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { getTransfers } from 'api/transfers';

import NewTransferDialog from './components/NewTransferDialog/NewTransferDialog';
import { useNavigate } from 'react-router-dom';

const selectTypes = [
    { value: 'pending', label: 'Актуальные' },
    { value: 'month', label: 'За месяц' },
];

const Transfers: React.FC = () => {
    const navigate = useNavigate();
    const [selectType, setSelectType] = React.useState('pending')

    const [transferDialogOpen, setTransferDialogOpen] = React.useState(false);

    const { data, isLoading } = useQuery(['transfers', selectType], getTransfers);

    const goToTransfer = ({ data }: any) => {
        // navigate(`/transfers/${data.id}`);
        window.open(`/transfers/${data.id}`, '_blank');
    };


    const formatDate = ({ value }: { value: string }) => {
        return moment(value).format('DD MMM YYYY HH:mm');
    };

    const getStatusClass = ({ value }: { value: string }) => {
        if (value === 'hold') return 'bg-amber-500';
        if (value === 'sent') return 'bg-lime-500';
        if (value === 'received') return 'bg-cyan-500';

        return '';
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-end space-x-4">
                <Button type="button" variant="contained" onClick={() => setTransferDialogOpen(true)}>Создать трансфер</Button>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 mb-8">
                <div className="w-full">
                    <Select
                        label=""
                        options={['pending', 'month']}
                        value={selectType}
                        onChange={setSelectType}
                        multiple={false}
                    />
                </div>
            </div>

            <div className="w-full relative shadow-md rounded overflow-hidden" style={{ height: 800 }}>
                <AgGridReact
                    rowData={data || []}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        floatingFilter: true,
                        suppressMenu: true,
                        floatingFilterComponentParams: { suppressFilterButton: true }
                    }}
                    onRowClicked={goToTransfer}
                >
                    <AgGridColumn headerName="Рум" field="room_name" filter />
                    <AgGridColumn headerName="Отправитель" field="sender_name" filter />
                    <AgGridColumn headerName="Получатель" field="receiver_name" filter />
                    <AgGridColumn headerName="Сумма" field="amount" />
                    <AgGridColumn headerName="Дата" field="created_datetime" sort="desc" valueFormatter={formatDate} />
                    <AgGridColumn headerName="Статус" field="status" cellClass={getStatusClass} />
                </AgGridReact>
                {
                    isLoading ? (
                        <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                            <CircularProgress />
                        </div>
                    ) : null
                }
            </div>

            <NewTransferDialog
                open={transferDialogOpen}
                onClose={() => setTransferDialogOpen(false)}
            />

        </div>
    );
};

export default Transfers;
