import { all, call, put, takeLatest } from 'redux-saga/effects';

import { authenticateRequest } from './requests';
import { auth_authenticate, auth_set_loading, AUTH_LOGIN, AuthLogin } from './auth';

export function* authenticateSaga(action: AuthLogin) {
    try {
        const { data: authData } = yield call(authenticateRequest, action.payload.username, action.payload.password);
        
        yield put(auth_authenticate(authData));
    } catch (err) {
        yield put(auth_set_loading(false));

        console.error(err);
    }
}

export function* watchAuth() {
    yield all([takeLatest(AUTH_LOGIN, authenticateSaga)]);
}
