import axios from 'helpers/axios';

// old, disable later
export const getCurators = async () => {
    const { data } = await axios.get('/api/players/curators/');

    return data;
};

export const getCuratorGroups = async () => {
    const { data } = await axios.get('/api/players/curatorgroups/');

    return data;
};

export const getCuratorsList = async() => {
    const { data } = await axios.get('/api/adminapi/curators/');

    return data;
}
