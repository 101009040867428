import React from 'react';
import { Box, Card } from '@mui/material';

import HistoryTable from './components/HistoryTable';
import HistoryFilter from './components/HistoryFilter';
import { initialState, filterReducer } from './state';

const History: React.FC = () => {
    const [state, dispatch] = React.useReducer(filterReducer, initialState);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState<number>(25);

    return (
        <>
            <Box className='flex flex-row justify-between gap-2 items-center'>
                <Box>
                    <HistoryFilter
                        playerFilter={state.playerIdFilter}
                        dispatch={dispatch}
                    />
                </Box>
            </Box>
            <Card className="mt-4">
                {state.playerIdFilter &&
                    <HistoryTable
                        playerId={state.playerIdFilter?.id}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        page={page}
                        setPage={setPage}
                    />
                }
            </Card>
        </>
    )

}

export default History;
