import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import DateTimePicker from '@mui/lab/DateTimePicker';
import moment from 'moment'

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import { createManualResults } from 'api/manualresults';
import { getPokerRoomsForUser } from 'api/pokerrooms';

interface Props {
    playerId: number;
    roomId: number;
    close: () => void;
}

const schema = yup.object().shape({
    user_id: yup.number().required().min(1),
    room_id: yup.number().required().min(1),
    abi: yup.number().min(0),
    roi: yup.number().nullable(),
    afs: yup.number().nullable(),
    reentry_perc: yup.number().nullable(),
    period_start: yup.date().required(),
    period_end: yup.date().required(),
});

const ManualResultsAddDialog: React.FC<Props> = (props: Props) => {
    const queryClient = useQueryClient();

    const { control, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            user_id: props.playerId,
            room_id: props.roomId,
            abi: null,
            roi: null,
            afs: null,
            reentry_perc: null,
            period_start: null,
            period_end: null,
        },
    });

    const roomsQuery = useQuery(['poker_rooms', props.playerId], getPokerRoomsForUser, {
        initialData: [],
    });

    const { mutate, isLoading } = useMutation(createManualResults, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-manual-results', props.playerId], { exact: false });

            reset();
            props.close();
        },
    });

    const onSubmit = handleSubmit(data => mutate(data));

    return (
        <>
            <DialogTitle>Создание результатa</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="room_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Room" select fullWidth required {...field}>
                                {roomsQuery.data
                                    ?.filter(room => room.active)
                                    .map(room => (
                                        <MenuItem key={room.id} value={room.id}>
                                            {room.short_name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    />
                    <Controller
                        name="abi"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="ABI"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="roi"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="ROI"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="afs"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="AFS"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="reentry_perc"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Reentry %"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="period_start"
                        control={control}
                        render={({ field }) => (
                            <DateTimePicker
                                views={['month']}
                                {...field}
                                onChange={(date) => {
                                    const localStartOfMonth = moment(date).startOf('month');
                                    const startOfMonth = moment.utc(`${localStartOfMonth.format('YYYY-MM-DD')}T00:00:00`);
                                    field.onChange(startOfMonth.toDate());

                                    // Set end date to first day of the next month
                                    const startOfNextMonth = moment.utc(startOfMonth).add(1, 'month');
                                    setValue("period_end", startOfNextMonth.toDate());
                                }}
                                renderInput={props => <TextField {...props} label="Начало периода" required fullWidth />}
                            />
                        )}
                    />
                    <Controller
                        name="period_end"
                        control={control}
                        render={({ field }) => (
                            <DateTimePicker {...field} disabled={true} renderInput={props => <TextField {...props} label="Окончание периода" required fullWidth />} />
                        )}
                    />

                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </>
    )
}

export default ManualResultsAddDialog;
