import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPlayerDeductiblesCategories } from 'api/players';

import Divider from 'components/Divider/Divider';

const DeductiblesTotal: React.FC = () => {
    const { playerId } = useParams();

    const categoriesQuery = useQuery(['player-deductible-categories-list', playerId], getPlayerDeductiblesCategories, {
        initialData: [],
    });

    return (
        <>
            <div className="w-full col-span-12 mt-10">
                <Divider title="Долги" />
            </div>
            {categoriesQuery.data
                .filter(val => val.balance > 0)
                .map(val => (
                    <div className="col-span-3 p-3 m-2 rounded bg-gray-200 text-gray-700 shadow-sm" key={val.id}>
                        <div className="flex flex-col items-center">
                            <span className="font-semibold text-xl mb-1">{val.balance}</span>
                            <span className="font-normal text-base">{val.name}</span>
                        </div>
                    </div>
                ))}
        </>
    );
};

export default DeductiblesTotal;
