import React from 'react';
import { Box, Card } from '@mui/material';

import ReconciliationFilter from './components/ReconciliationFilter';
import Stats from './components/Stats/Stats';
import UntrackedTournamentsTable from './components/UntrackedTournamentsTable';
import RoomAccounts from './components/RoomAccounts/RoomAccounts';
import ManualResults from './components/ManualResults/ManualResults';
import { initialState, filterReducer } from './state';

const Reconciliation: React.FC = () => {
    const [state, dispatch] = React.useReducer(filterReducer, initialState);

    return (
        <>
            <Box className='flex flex-row justify-between gap-2 items-center'>
                <Box>
                    <ReconciliationFilter
                        playerFilter={state.playerIdFilter}
                        roomFilter={state.roomIdFilter}
                        startDateFilter={state.startDateFilter}
                        endDateFilter={state.endDateFilter}
                        dispatch={dispatch}
                    />
                </Box>
            </Box>
            <Card className="mt-4">
                {state.playerIdFilter && state.roomIdFilter &&
                    <Stats
                        playerId={state.playerIdFilter?.id}
                        roomId={state.roomIdFilter?.id}
                        startDate={state.startDateFilter?.toISOString()}
                        endDate={state.endDateFilter?.toISOString()}
                        playerLevel={state.playerIdFilter?.player_level.name + ' ' + state.playerIdFilter?.player_level.group.name}
                    />
                }
                {state.playerIdFilter && state.roomIdFilter &&
                    <UntrackedTournamentsTable
                        playerId={state.playerIdFilter?.id}
                        roomId={state.roomIdFilter?.id}
                        startDate={state.startDateFilter?.toISOString()}
                        endDate={state.endDateFilter?.toISOString()}
                    />
                }
            </Card>
            {state.playerIdFilter &&
                <RoomAccounts
                    playerId={state.playerIdFilter?.id}
                />
            }
            {
                state.playerIdFilter &&
                <ManualResults
                    playerId={state.playerIdFilter?.id}
                    roomId={state.roomIdFilter?.id}
                />
            }
        </>
    )

}

export default Reconciliation;
