import axios from 'helpers/axios';

export const updateAccount = async ({ id, ...body }) => {
    // const { data } = await axios.put(`/api/adminapi/accounts/${id}`, body);
    const { data } = await axios.put(`/api/adminapi/roomaccounts/${id}`, body);

    return data;
};

export const createAccount = async ({ create_default_account_record, ...body }) => {
    // const { data } = await axios.post('/api/adminapi/accounts/', body);
    const { data } = await axios.post('/api/adminapi/roomaccounts/', body, { params: { createRecord: create_default_account_record } });

    return data;
};

export const updateAccountRecord = async ({ id, ...body }) => {
    const { data } = await axios.put(`/api/adminapi/accountrecords/${id}`, body);

    return data;
};

export const updateAccountRecordBalance = async ({ accountRecordId, ...body }) => {
    // const { data } = await axios.post('/api/adminapi/bundles/', body);
    const { data } = await axios.post(`/api/adminapi/accountrecords/${accountRecordId}/accountbalances/`, body);

    return data;
};

export const updateAccountRecordLimits = async ({ accountRecordId, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/accountrecords/${accountRecordId}/transferlimits/`, body);

    return data;
};
