import HomeIcon from '@mui/icons-material/HomeOutlined';
import UsersIcon from '@mui/icons-material/GroupOutlined';
import BalancesIcon from '@mui/icons-material/AccountBalance';
import TransfersIcon from '@mui/icons-material/PaymentOutlined';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import CalculateIcon from '@mui/icons-material/Calculate';
import QueryStats from '@mui/icons-material/QueryStats';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SendIcon from '@mui/icons-material/Send';

const menu = [
    {
        icon: HomeIcon,
        name: 'Главная',
        route: '/',
        permission: '4e10e602-6d28-40ca-afb9-3bbe1922d2cb',
    },
    {
        icon: UsersIcon,
        name: 'Игроки',
        route: '/players',
        permission: '95cff11e-9605-4773-87af-da34bbfafed0',
    },
    // {
    //     icon: BalancesIcon,
    //     name: 'Балансы',
    //     route: '/balances',
    //     permission: '7bbfece8-5456-4d7b-a1a0-c2efdcf8afcc',
    // },
    {
        icon: TransfersIcon,
        name: 'Трансферы',
        route: '/transfers',
        permission: '498425ba-9499-48fe-8d59-2bbc7d6e07c7',
    },
    // {
    //     icon: CalculateIcon,
    //     name: 'Аккаунтинг',
    //     route: '/accounting',
    //     permission: 'a13f40a5-f890-45d5-ac35-024a1a516503',
    // },
    {
        icon: SendIcon,
        name: 'Рассылка',
        route: '/messaging',
        permission: '5472f36d-c97c-452f-9637-990d69802b8d',
    },
    {
        icon: FolderIcon,
        name: 'Руки игроков',
        route: '/handhistories',
        permission: '4d7c665f-c30b-429b-84ef-96c5a948906f',
    },
    {
        icon: DoneAllIcon,
        name: 'Сверка',
        route: '/reconciliation',
        permission: 'b0acc62a-1724-42ff-bcdc-91399aa16325',
    },
    {
        icon: Grid4x4Icon,
        name: 'Сетка байинов',
        route: '/buyinlimits',
        permission: '54fb736e-fbc7-4ef2-b9db-c5ea92152039',
    },
    {
        icon: Grid4x4Icon,
        name: 'Турниры',
        route: '/tournamentsgrid',
        permission: '9c822c33-083f-4af6-a5fe-31773c397173',
    },
    {
        icon: GradingOutlinedIcon,
        name: 'Оценка турниров',
        route: '/tournamentsrates',
        permission: 'fc6952d0-8e22-4884-b2ae-7bb3ce3d0703',
    },
    {
        icon: PersonSearchIcon,
        name: 'Оценка игроков',
        route: '/manuallabels',
        permission: 'f1e08427-6c16-4c99-9bbf-97c49b690a52',
    },
    {
        icon: AnalyticsOutlinedIcon,
        name: 'Расписатор',
        route: '/scheduler',
        permission: '6787a8c5-92ac-4a2f-b1bc-f1a12bdc67cd',
    },
    {
        icon: QueryStats,
        name: 'Статы игроков',
        route: '/stats',
        permission: '54c74276-2bf7-45ca-9282-b068778492d8',
    },
    {
        icon: QueryStats,
        name: 'Анализ EV',
        route: '/evanalysis',
        permission: '196866ad-575d-422e-87ee-ab56ab257fbe',
    },
    {
        icon: AnalyticsOutlinedIcon,
        name: 'Аналитика',
        route: '/analytics',
        permission: '653e9dd0-ae72-41cf-8ce0-008392adce2f',
    },
    {
        icon: AnalyticsOutlinedIcon,
        name: 'Скоринг ГГ',
        route: '/scoringggnetwork',
        permission: '5f9258c6-ab30-4b84-878b-9dc8cf39de72',
    },
];

export default menu;
