import { FC } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@mui/material';

type Props = {
    /**
     * Статус окна.
     */
    open: boolean;

    /**
     * Закрытие диалогового окна.
     */
    close: () => void;

    /**
     * Подтверждение действия.
     */
    handleAgreeAction: () => void;
}

/**
 * Диалоговое окно подтверждения удаления
 */
const EvStatsRevisionDeleteDialog: FC<Props> = ({ open, close, handleAgreeAction }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Вы уверены, что хотите удалить ревизию?"}
            </DialogTitle>
            <DialogActions>
                <Button autoFocus onClick={close}>
                    Отмена
                </Button>
                <Button onClick={handleAgreeAction} autoFocus>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EvStatsRevisionDeleteDialog;
