import { FC, Dispatch, SetStateAction, useEffect } from 'react';

import { Autocomplete, Box, Button, IconButton, InputBase, Paper, TextField } from '@mui/material';
import Search from "@mui/icons-material/Search";
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { getCurators } from 'api/playerstats';
import { getRevision, getScoringCsv } from 'api/scoring';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';

type Props = {
    /**
     * Функция поиска игрока.
     */
    setSearch: Dispatch<SetStateAction<string>>;

    /**
     * Никнейм игрока в поиске.
     */
    search: string;

    /**
     * Выбранное значение фильтрации по куратору.
     */
    curatorFilter: any;

    /**
     * Задать значение фильтрации по куратору.
     */
    setCuratorFilter: Dispatch<any>;

    /**
     * Выбранное значение фильтрации по ревизии.
     */
    revision: {id: number, created_datetime: string};

     /**
      * Задать значение фильтрации по ревизии.
      */
    setRevision: Dispatch<SetStateAction<string>>;
};

/**
 * Компонент содержит фильтры таблицы скоринга игроков.
 */
const ScoringFilters: FC<Props> = ({ setSearch, search, curatorFilter, setCuratorFilter, revision, setRevision }) => {
    const {data: curators, isFetching: curatorsFetching} = useQuery(['scoring-curators'], getCurators, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: revisions, isFetching: revisionsFetching} = useQuery(['scoring-revision'], getRevision, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const DownloadCsv = useMutation(getScoringCsv, {
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data])) 
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `scoring ${moment(revision.created_datetime).format('DD MMM YYYY HH:mm')}.csv`)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    });

    useEffect(() => {
        if(revisions) {
            setRevision(revisions[0])
        }
        
    }, [revisions, setRevision]);

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: search,
        },
    });

    const onSubmit = handleSubmit(data => {
        setSearch(data.name)
    });

    const defaultPropsCurators = {
        options: curators ?? [],
        getOptionLabel: (option) => option.curator_nickname ?? '',
    };

    const defaultPropsRevision = {
        options: revisions ?? [],
        getOptionLabel: (option) => moment(option.created_datetime).format('DD MMM YYYY HH:mm') ?? '',
    };

    return (
        <Box className='flex flex-col'>
            <Box className='flex flex-row flex-wrap gap-4 items-center mb-3'>
                {/* Фильтр по ревизии */}
                <Autocomplete
                    {...defaultPropsRevision}
                    disablePortal
                    id="revisions"
                    sx={{ width: 200 }}
                    disabled={revisionsFetching}
                    disableClearable
                    value={revision ?? null}
                    onChange={(event: any, newValue: string | null) => {
                        setRevision(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Ревизия" />}
                    className="bg-white"
                />
                {/* Фильтр по куратору */}
                <Autocomplete
                    {...defaultPropsCurators}
                    disablePortal
                    id="curators"
                    sx={{ width: 250 }}
                    disabled={curatorsFetching}
                    value={curatorFilter ?? null}
                    onChange={(event: any, newValue: string | null) => {
                        setCuratorFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Ник куратора" />}
                    className="bg-white"
                />
                {/* Поиск по никнейму игрока */}
                <Paper
                    component="form"
                    className='flex w-[280px] h-[48px] bg-gray-50 items-center'
                    sx={{ p: '2px 4px'}}
                    onSubmit={onSubmit}
                >
                    <Controller
                        control={control}
                        name='name'
                        render={({ field }) => {
                            return (
                                <>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Игрок"
                                        {...field}
                                    />
                                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                        <Search />
                                    </IconButton>
                                </>
                            )
                        }}
                    />
                </Paper>
                {/* Скачать таблицу csv */}
                <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    size="large"
                    onClick={() => {
                        DownloadCsv.mutate({ revisionId: revision.id })
                    }}
                    disabled={!revision || DownloadCsv.isLoading}
                >
                    Скачать таблицу CSV
                </Button>
            </Box>
        </Box>
    );
};

export default ScoringFilters;
