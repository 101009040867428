import axios from 'helpers/axios';
import blobAxios from 'helpers/blobAxios';

export const getPlayersHandHistories = async ({ queryKey }) => {
    const [, perPage, page, playerId] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (playerId) {
        params.append('playerId', playerId);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/handhistories/', request);

    return data;
};

export const downloadHandHistory = async ({ hhId }) => {
    const response = await blobAxios.get(`/api/adminapi/handhistories/${hhId}/`);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'hh.zip';

    if (contentDisposition) {
        // Check for the RFC 5987 style of encoding with filename*=
        const filenameStarMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
        // Check for the standard filename=
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);

        if (filenameStarMatch && filenameStarMatch[1]) {
            // If filename* is present, decode the URI component
            fileName = decodeURIComponent(filenameStarMatch[1]);
        } else if (filenameMatch && filenameMatch[1]) {
            // If only filename is present, use it directly
            fileName = filenameMatch[1];
        }
    }

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    return response.data;
};

export const getPLayers = async ({ queryKey }) => {
    const [, curatorFilter] = queryKey;

    var params = new URLSearchParams();

    if (curatorFilter?.id) {
        params.append('curatorId', curatorFilter?.id);
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/playersv2/', request);

    return data;
};
