import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

import { getRooms } from 'api/rooms';
import { getTournaments } from 'api/tournamentsgroups';
import { Box, Card, CircularProgress, IconButton, Stack, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';
import ClearAll from '@mui/icons-material/ClearAll';
import Scheduled from './Scheduled/Scheduled';
import Estimate from './Estimate/Estimate';
import Hidden from './Hidden/Hidden';
import Charts from './Charts/Charts';
import TournamentsFilters from './TournamentsFilters';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import BlockIcon from '@mui/icons-material/Block';

/**
 * Отображает таблицу турниров.
 */
const Tournaments: FC = () => {
    // Фильтр по дням недели
    const [filters, setFilter] = useState([]);
    // Строка поиска
    const [search, setSearch] = useState('');
    // Турниры с оценкой от куратора
    const [curatorEstimate, setCuratorEstimate] = useState<boolean>(false);
    // Турниры с кастомными уровнями
    const [customLevels, setCustomLevels] = useState<boolean>(false);
    // Только текущие турниры
    const [current, setCurrent] = useState<boolean>(false);
    // Только скрытые турниры
    const [hidden, setHidden] = useState<boolean>(false);
    // Запланированные турниры
    const [scheduledTournaments, setScheduledTournaments] = useState<{ id: number; name: string } | undefined>();
    // Ручная оценка турнира
    const [editEstimante, setEditEstimante] = useState<{ id: number; name: string } | undefined>();
    // Редактирование видимости турнира
    const [editHidden, setEditHidden] = useState<{ id: number; name: string } | undefined>();
    // Диапазон байинов
    const [rangeBuyin, setRangeBuyin] = useState();
    // Правила сортировки.
    const [sortOptions, setSortOptions] = useState({ sortModel: null });
    // Страница с графиком турниров
    const [charts, setCharts] = useState<{ id: number; name: string } | undefined>();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions({ sortModel: [...sortModel] });
    }, []);

    const { data: rooms } = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const { data: tournaments, isFetching } = useQuery(
        ['tournaments-data', pageSize, page, filters, search, curatorEstimate, customLevels, current, hidden, rangeBuyin, sortOptions],
        getTournaments,
        {
            refetchOnWindowFocus: true,
            keepPreviousData: true,
        }
    );

    const columns: GridColDef[] = [
        {
            field: 'network_name',
            headerName: 'Рум',
            sortable: false,
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: 'buyin',
            headerName: 'Байин',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'currency',
            headerName: 'Валюта',
            sortable: false,
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'last',
            headerName: 'Последний',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.last_scheduled_at).format('DD.MM.YYYY HH:mm')}`,
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: 'non_dst_start_time',
            headerName: 'Время начала',
            flex: 0.2,
            minWidth: 120,
        },
        {
            field: 'name',
            headerName: 'Турнир',
            sortable: false,
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 180,
            renderCell: params => {
                function selectItem(params: any) {
                    const data = {
                        id: params.row.id,
                        name: params.row.name,
                    };
                    return data;
                }
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton onClick={() => setEditHidden(selectItem(params))}>
                            <BlockIcon color={params.row.is_hidden ? 'warning' : 'inherit'} />
                        </IconButton>
                        <IconButton onClick={() => setEditEstimante(selectItem(params))}>
                            <SettingsOutlined color={params.row.is_modified ? 'warning' : 'inherit'} />
                        </IconButton>
                        <IconButton onClick={() => setCharts(selectItem(params))}>
                            <InsertChartOutlined />
                        </IconButton>
                        <IconButton onClick={() => setScheduledTournaments(selectItem(params))}>
                            <ClearAll />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    if (editEstimante) {
        return <Estimate id={editEstimante.id} name={editEstimante.name} close={() => setEditEstimante(undefined)} />;
    }

    if (scheduledTournaments) {
        return <Scheduled id={scheduledTournaments.id} name={scheduledTournaments.name} close={() => setScheduledTournaments(undefined)} />;
    }

    if (editHidden) {
        return <Hidden id={editHidden.id} name={editHidden.name} close={() => setEditHidden(undefined)} />;
    }

    if (charts) {
        return <Charts id={charts.id} name={charts.name} close={() => setCharts(undefined)} />;
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    return (
        <>
            <TournamentsFilters
                setFilter={setFilter}
                data={filters}
                rooms={rooms}
                setSearch={setSearch}
                search={search}
                curatorEstimate={curatorEstimate}
                setCuratorEstimate={setCuratorEstimate}
                customLevels={customLevels}
                setCustomLevels={setCustomLevels}
                current={current}
                setCurrent={setCurrent}
                hidden={hidden}
                setHidden={setHidden}
                rangeBuyin={rangeBuyin}
                setRangeBuyin={setRangeBuyin}
            />
            {isFetching && !tournaments && (
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                    <CircularProgress />
                </Card>
            )}
            {tournaments && (
                <Card>
                    <Box
                        className="h-auto overflow-auto"
                        sx={{
                            '& .active': {
                                backgroundColor: '#fcecd5',
                                color: '#1a3e72',
                            },
                        }}
                    >
                        <DataGrid
                            autoHeight={true}
                            rows={tournaments.data ?? []}
                            columns={columns}
                            pageSize={pageSize}
                            rowCount={tournaments.count ?? 0}
                            page={page}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => {
                                setPageSize(newPageSize);
                                setPage(0);
                            }}
                            onSortModelChange={handleSortModelChange}
                            rowsPerPageOptions={[25, 50, 100]}
                            pagination
                            disableSelectionOnClick
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Данные отсутствуют
                                    </Stack>
                                ),
                            }}
                            componentsProps={{
                                pagination: {
                                    ActionsComponent: TablePaginationActions,
                                },
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: sortOptions?.sortModel,
                                },
                            }}
                        />
                    </Box>
                </Card>
            )}
        </>
    );
};

export default Tournaments;
