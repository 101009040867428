import { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import TournamentFormatGroupsTable from './TournamentFormatGroupsTable';
import { getGroupFormatsRulesList } from 'api/tournamentformatgroups';

type Props = {
    /**
     * ID группы для детального просмотра
     */
    formatView: string; 
    
    /**
     * Изменить группу для детального просмотра
     */
    setFormatView: Dispatch<SetStateAction<string>>;
}

/**
 * Вкладка с группами правил форматов.
 */
const TournamentFormatGroups: FC<Props> = ({ formatView, setFormatView }) => {
    const {data: list, isFetching} = useQuery(['format-group-list'], getGroupFormatsRulesList, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    return (
        <>
            {isFetching && !list && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && list && <Card><TournamentFormatGroupsTable items={list} formatView={formatView} setFormatView={setFormatView} /></Card>}
        </>
    );
}

export default TournamentFormatGroups;
