import { FC } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import MarksTable from './MarksTable';
import { getMarks } from 'api/marks';

/**
 * Вкладка с метками.
 */
const Marks: FC = () => {
    const {data: marks, isFetching} = useQuery(['marks-data'], getMarks, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    return (
        <>
            {isFetching && !marks && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && marks && <Card><MarksTable items={marks}/></Card>}
        </>
    );
}

export default Marks;
