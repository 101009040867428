import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getRooms } from 'api/rooms';
import { Box, Card, CircularProgress, IconButton, Stack, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import ResultsFilters from './ResultsFilters';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { getPlayerTournamentsRoi } from 'api/schedulersettings';
import { darken, lighten, styled } from '@mui/material/styles';

import { SchedulerQueryKeys } from 'constants/QueryKeys/schedulerKeys';
import { loadOnceConfig } from 'constants/QueryKeys/queryConfig';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .error': {
        backgroundColor: lighten(theme.palette.error.main, 0.8),
        '&:hover': {
            backgroundColor: lighten(theme.palette.warning.main, 0.7),
        },
    },
    '& .warning': {
        backgroundColor: lighten(theme.palette.warning.main, 0.8),
        '&:hover': {
            backgroundColor: lighten(theme.palette.warning.main, 0.7),
        },
    },
}));

const Results: FC = () => {
    // Фильтр по дням недели
    const [filters, setFilter] = useState([]);
    // Строка поиска
    const [search, setSearch] = useState('');
    // Правила сортировки.
    const [sortOptions, setSortOptions] = useState({ sortModel: null });

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions({ sortModel: [...sortModel] });
    }, []);

    const { data: rooms } = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const { data: tournaments, isFetching } = useQuery(
        SchedulerQueryKeys.schedulerPlayerTournamentsRoi(pageSize, page, filters, search, sortOptions),
        getPlayerTournamentsRoi,
        loadOnceConfig,
    );

    const columns: GridColDef[] = [
        {
            field: 'network',
            headerName: 'Рум',
            sortable: false,
            flex: 0.3,
            minWidth: 100,
            renderCell: params => {
                const handleCopy = () => {
                    navigator.clipboard.writeText(params.row.tournament_group_hash);
                };

                return (
                    <div>
                        <span onClick={handleCopy} className="cursor-pointer">{params.row.network}</span>
                    </div>
                )
            }
        },
        {
            field: 'normalized_name',
            headerName: 'Турнир',
            sortable: false,
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'day_number',
            headerName: 'День',
            sortable: false,
            flex: 0.1,
            minWidth: 80,
        },
        {
            field: 'non_dst_start_hour',
            headerName: 'Час',
            sortable: false,
            flex: 0.1,
            minWidth: 80,
        },
        {
            field: 'buyin',
            headerName: 'Байин',
            sortable: false,
            flex: 0.2,
            minWidth: 80,
        },
        {
            field: 'currency',
            headerName: 'Валюта',
            sortable: false,
            flex: 0.2,
            minWidth: 80,
        },
        {
            field: 'total_entries',
            headerName: 'Всего входов',
            sortable: false,
            flex: 0.3,
            minWidth: 80,
        },
        {
            field: 'total_buyin',
            headerName: 'Всего БИ',
            sortable: false,
            flex: 0.3,
            minWidth: 80,
        },
        {
            field: 'total_prize',
            headerName: 'Всего выиграно',
            sortable: false,
            flex: 0.3,
            minWidth: 80,
        },
        {
            field: 'roi',
            headerName: 'ROI',
            sortable: false,
            flex: 0.2,
            minWidth: 60,
            valueFormatter: (params) => {
                const valueFormatted = Number(params.value * 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                return `${valueFormatted} %`;
            },
        },
    ];

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    function getRowId(row) {
        return `${row.tournament_group_hash}-${row.day_number}`
    }

    return (
        <>
            <ResultsFilters
                setFilter={setFilter}
                data={filters}
                rooms={rooms}
                setSearch={setSearch}
                search={search}
            />
            {isFetching && !tournaments && (
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                    <CircularProgress />
                </Card>
            )}
            {tournaments && (
                <Card>
                    <Box
                        className="h-auto overflow-auto"
                        sx={{
                            '& .active': {
                                backgroundColor: '#fcecd5',
                                color: '#1a3e72',
                            },
                        }}
                    >
                        <StyledDataGrid
                            autoHeight={true}
                            rows={tournaments.tournaments ?? []}
                            columns={columns}
                            pageSize={pageSize}
                            rowCount={tournaments.count ?? 1000}
                            page={page}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => {
                                setPageSize(newPageSize);
                                setPage(0);
                            }}
                            onSortModelChange={handleSortModelChange}
                            rowsPerPageOptions={[25, 50, 100]}
                            getRowId={getRowId}
                            getRowClassName={(params: any) => {
                                if (params.row.roi > 0) {
                                    return 'success';
                                } else if (params.row.roi < 0) {
                                    return 'error';
                                }
                            }}
                            pagination
                            disableSelectionOnClick
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Данные отсутствуют
                                    </Stack>
                                ),
                            }}
                            componentsProps={{
                                pagination: {
                                    ActionsComponent: TablePaginationActions,
                                },
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: sortOptions?.sortModel,
                                },
                            }}
                        />
                    </Box>
                </Card>
            )}
        </>
    );
};

export default Results;
