import { FC, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCoefficientItem } from 'api/coefficient';
import EditVacationDialog from '../VacationDialog/EditVacationDialog';

import { getPlayerActiveVacation, deleteVacation } from 'api/players';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteDialog from 'components/DeleteDialog';

type Props = {
  items: any;
};

const VacationsTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();
    const { playerId } = useParams();

    const [pageSize, setPageSize] = useState<number>(5);

    const [editVacationOpen, setEditVacationOpen] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [vacationId, setVacationId] = useState(null);
    const [vacationStart, setVacationStart] = useState(null);
    const [vacationEnd, setVacationEnd] = useState(null);

    const deleteMutation = useMutation(deleteVacation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-active-vacation', playerId]);
            setDeleteDialog(false);
        }
    });


    const columns: GridColDef[] = [
        {   field: 'start_datetime',
            headerName: 'Старт',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.start_datetime).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100
        },
        {   field: 'end_datetime',
            headerName: 'Окончание',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.end_datetime).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <IconButton onClick={() => {
                            setVacationId(params.row.id);
                            setVacationStart(params.row.start_datetime);
                            setVacationEnd(params.row.end_datetime);
                            setEditVacationOpen(true);
                        }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            setVacationId(params.row.id);
                            setDeleteDialog(true)
                        }}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    </Box>
                );
            }
        }
    ];

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
        <DataGrid
            autoHeight={true}
            rows={items}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            disableSelectionOnClick
            disableColumnMenu
            components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
            }}
        />

        <EditVacationDialog
            open={editVacationOpen}
            onClose={() => setEditVacationOpen(false)}
            vacationId={vacationId}
            start={vacationStart}
            end={vacationEnd}
        />

        <DeleteDialog
            close={() => setDeleteDialog(false)}
            open={deleteDialog}
            loading={deleteMutation.isLoading}
            handleAgreeAction={() => deleteMutation.mutate(vacationId)}
        />
    </Box>

  );
};

export default VacationsTable;
