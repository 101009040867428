import { ChangeEvent, FC, useState } from 'react';

import { createExceptionItem } from 'api/gridexceptions';
import { getRooms } from 'api/rooms';
import { IconButton, DialogTitle, DialogContent, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;
}

/**
 * Диалоговое окно для создания записи в таблице исключений.
 */
const ExceptionsDialog: FC<Props> = ({ ...props }) => {
    const queryClient = useQueryClient();
    const [selectedRooms, setSelectedRooms] = useState([]);

    const {data: rooms} = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const { control, handleSubmit } = useForm({
        defaultValues: {
            value: '',
            is_regex: false,
        },
    });

    const newItemMutation = useMutation(createExceptionItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('gridexceptions-data');

            props.close();
        },
    });

    /**
     * Сохранить значение чекбокса.
     */
    function selectRoom(event: ChangeEvent<HTMLInputElement>) {
        let filter = selectedRooms.slice();
        const id = filter.findIndex(data => data.id === event.target.value);
        const default_id = filter.findIndex(data => data.id === "13");  // check if default network is selected.

        if (event.target.value === "13") {
            filter = [{ id: event.target.value, name: event.target.name }]
            setSelectedRooms(filter);
            return
        }

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            if (default_id >= 0) {
                filter = [{ id: event.target.value, name: event.target.name }]
            } else {
                filter.push({ id: event.target.value, name: event.target.name });
            }
        }

        setSelectedRooms(filter);
    }

    /**
     * Функция сохранения формы.
     */
    const onSubmit = handleSubmit(data => {
        const exceptions = selectedRooms.reduce(function(acc, curr) {
            const newSession = {
                // name: curr.name,
                network_id: curr.id,
                ...data
            }

            return [...acc, newSession];
          }, []);

        newItemMutation.mutate(exceptions);
    });

    /**
     * Проверка элемента на выбор.
     */
    function checkSelected(id: string) {
        const ids = selectedRooms.map(el => el.id);
        return ids.includes(String(id));
    }

    return (
        <>
            <DialogTitle>
                Новое исключение
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name='value'
                        render={({ field }) => <TextField {...field} label='Значение' rows={2} multiline fullWidth />}
                    />
                    <Controller
                        name='is_regex'
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Regex"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <FormGroup
                        onChange={selectRoom}
                    >
                        {rooms?.map((item) => (
                            <FormControlLabel
                                className="pl-2 pr-2 m-0"
                                key={item.id}
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        name={item.network}
                                        checked={checkSelected(item.id)}
                                    />
                                }
                                label={item.network}
                                value={item.id}
                            />

                        ))}
                    </FormGroup>

                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={newItemMutation.isLoading}
                        >
                            Добавить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default ExceptionsDialog;
