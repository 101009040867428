import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createPersonalFundTransfer, getPlayerPersonalFundsUSD } from 'api/personalfunds';
import { getCurrencies } from 'api/currencies';

const ChangePersonalFundsDialog: React.FC<DialogProps> = (props) => {
    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            currency_id: null,
            amount: 0,
            comment: ''
        }
    });

    // const { data: currenciesData, isLoading: currenciesIsLoading } = useQuery('currencies', getCurrencies);
    const currenciesQuery = useQuery('currencies', getCurrencies);

    const personalFundsUSD = useQuery(['player-personalfunds-usd', playerId], getPlayerPersonalFundsUSD, {
        enabled: !!playerId,
        initialData: null
    });

    const { mutate, isLoading } = useMutation(createPersonalFundTransfer, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-personalfunds', playerId]);
            queryClient.invalidateQueries(['player-personalfunds-usd', playerId]);
            queryClient.invalidateQueries(['fundflowlist-data', playerId]);
            props.onClose({}, 'backdropClick');
            reset();
        }
    });

    const onSubmit = handleSubmit((data) => {
        if (data.comment === 'Обмен ЛС' && data.amount >= 0) {
            return
        }
        mutate({...data, user_id: playerId });
    });

    React.useEffect(() => {
        if (currenciesQuery.data) {
            setValue('currency_id', 1);
        }
    }, [currenciesQuery.data]);

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Изменение ЛС</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="currency_id"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Валюта"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    currenciesQuery.data?.filter(currency => ["USD", "EUR"].includes(currency.name)).map(currency => (
                                        <MenuItem key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Сумма (списание со знаком минус)"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Комментарий"
                                rows={3}
                                multiline
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <div className="pt-4 flex justify-between text-right">
                        <Button
                            size="large"
                            loading={personalFundsUSD.isLoading}
                            onClick={() => {
                                setValue('currency_id', 1);
                                setValue('amount', -1 * personalFundsUSD.data);
                            }}
                        >Max ${personalFundsUSD.data ? personalFundsUSD.data : '-'}</Button>
                        <Button
                            size="large"
                            loading={personalFundsUSD.isLoading}
                            onClick={() => {
                                setValue('comment', 'Обмен ЛС');
                            }}
                        >Обмен ЛС</Button>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                        >Изменить</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePersonalFundsDialog;
