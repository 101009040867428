import axios from 'helpers/axios';
import moment from 'moment';

/**
 * Список турниров.
 */
export const getTournaments = async ({ queryKey }) => {
    const [, perPage, page, filters, search, curatorEstimate, customLevels, current, hidden, rangeBuyin, sortOptions] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('networkName', String(value.network)));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    if (search) {
        params.append('name', String(search));
    }

    if (curatorEstimate) {
        params.append('scoringType', 'custom');
    }

    if (customLevels) {
        params.append('customLevels', 'true');
    }

    if (current) {
        params.append('filter', 'current');
    }

    if (hidden) {
        params.append('hidden', 'true');
    }

    if (rangeBuyin?.min_buyin) {
        params.append('minBuyin', rangeBuyin?.min_buyin);
    }

    if (rangeBuyin?.max_buyin) {
        params.append('maxBuyin', rangeBuyin?.max_buyin);
    }

    if (sortOptions?.sortModel?.length > 0) {
        params.append('orderBy', `${sortOptions?.sortModel[0].field}`);
        params.append('sort', `${sortOptions?.sortModel[0].sort}`);
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/grid/tournamentgroups/', request);

    return data;
};

/**
 * Список запланированных турниров.
 */
export const getScheduledTournaments = async ({ queryKey }) => {
    const [, perPage, page, filters, id] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('day', String(value.id)));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/grid/tournamentgroups/${id}/scheduledtournaments/`, request);

    return data;
};

/**
 * Список ручных оценок для турнира.
 */
export const getEstimateTournaments = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/grid/tournamentgroups/${id}/customscorings/`);

    return data;
};

/**
 * Запись новой оценки для турнира по дням недели.
 */
export const newEstimateTournaments = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/grid/tournamentgroups/${id}/customscorings/`, body);

    return data;
};

/**
 * Редактирование оценки для турнира по дням недели.
 */
export const editEstimateTournaments = async ({ rowId, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/grid/tournamentgroups/customscorings/${rowId}/`, body);

    return data;
};

/**
 * Удаление оценки для турнира по дням недели.
 */
export const deleteEstimateTournaments = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/tournamentgroups/customscorings/${id}/`);

    return data;
};

/**
 * Список ручных уровней для турнира.
 */
export const getLevelTournaments = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/grid/tournamentgroups/${id}/customlevels/`);

    return data;
};

/**
 * Запись нового уровня для турнира по дням недели.
 */
export const newLevelTournaments = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/grid/tournamentgroups/${id}/customlevels/`, body);

    return data;
};

/**
 * Редактирование уровня для турнира по дням недели.
 */
export const editLevelTournaments = async ({ rowId, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/grid/tournamentgroups/customlevels/${rowId}/`, body);

    return data;
};

/**
 * Удаление уровня для турнира по дням недели.
 */
export const deleteLevelTournaments = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/tournamentgroups/customlevels/${id}/`);

    return data;
};

/**
 * Оценки группы турниров.
 */
export const getChartsTournaments = async ({ queryKey }) => {
    const [, id, filters, rangeDate] = queryKey;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('day', String(value.id)));
    }

    if (rangeDate?.start_dt) {
        params.append('startDate', moment(rangeDate?.start_dt).format('DD-MM-YYYY'));
    }

    if (rangeDate?.end_dt) {
        params.append('endDate', moment(rangeDate?.end_dt).format('DD-MM-YYYY'));
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/grid/tournamentgroups/${id}/scorings/`, request);

    return data;
};

/**
 * Список скрытых турниров.
 */
export const getHiddenTournaments = async ({ queryKey }) => {
    const [, perPage, page, id] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/grid/tournamentgroups/${id}/hidden/`, request);

    return data;
};

/**
 * Запись нового скрытого турнира.
 */
export const newHiddenTournament = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/grid/tournamentgroups/${id}/hidden/`, body);

    return data;
};

/**
 * Удаление скрытого турнира.
 */
export const deleteHiddenTournament = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/tournamentgroups/hidden/${id}/`);

    return data;
};
