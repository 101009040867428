import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getRooms } from 'api/rooms';
import { Card, CircularProgress } from '@mui/material';
import RoomsFilter from 'components/RoomsFilter/RoomsFilter';
import RoomHelper from 'helpers/RoomHelper';
import SchedulerCoefficientTable from './SchedulerCoefficientTable';
import { getSchedulerCoefficient } from 'api/schedulersettings';

/**
 * Вкладка содержащая настройки коэф абилити в расписаторе.
 */
const SchedulerCoefficient: FC = () => {
    const [filter, setFilter] = useState([]);
    const [result, setResult] = useState();

    const { data: rooms } = useQuery(['scheduler-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: coefficient, isFetching} = useQuery(['scheduler-coefficient'], getSchedulerCoefficient, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    useEffect(() => {
        if(filter.length > 0) {
            setResult(coefficient.modifiers.filter(({network}) => filter.find(item => item.network === network)));
        } else {
            setResult(coefficient?.modifiers ?? []);
        }
    }, [coefficient, filter]);

    return (
        <>
            <RoomsFilter setFilter={setFilter} data={filter} rooms={RoomHelper.withoutDefault(rooms)} />
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><SchedulerCoefficientTable items={result}/></Card>}
        </>
    );
}

export default SchedulerCoefficient;

