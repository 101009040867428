import { Dispatch, FC, SetStateAction } from 'react';

import { Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Список элементов.
     */
    items: any;

    /**
     * Кол-во элементов в таблице.
     */
    pageSize: number;

    /**
     * Задать кол-во элементов в таблице.
     */
    setPageSize: Dispatch<SetStateAction<number>>;

    /**
     * Страница.
     */
    page: number;

    /**
     * Задать страницу
     */
    setPage: Dispatch<SetStateAction<number>>;
};

/**
 * Отображает таблицу скоринга игроков.
 */
const ScoringTable: FC<Props> = ({ items, pageSize, setPageSize, page, setPage }) => {
    const columns: GridColDef[] = [
        {
            field: 'player_username',
            headerName: 'Игрок',
            flex: 0.3,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'evbb15_60',
            headerName: 'EV15-60',
            flex: 0.3,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                var color = value >= 12.5 ? '#70db70' : value >= 7 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'evbb60plus',
            headerName: 'EV60+',
            flex: 0.3,
            minWidth: 90,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 10 ? '#70db70' : value >= 5.5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'general_score',
            headerName: 'Общие',
            flex: 0.3,
            minWidth: 70,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 1.25 ? '#70db70' : value >= 0.75 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'preflop_score',
            headerName: 'Префлоп',
            flex: 0.3,
            minWidth: 90,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'postflop_score',
            headerName: 'Постфлоп',
            flex: 0.3,
            minWidth: 90,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'fair_roi_lafs_score',
            headerName: 'FAIR ROI',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 11 ? '#70db70' : value >= 6.5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'risk_to_income_score',
            headerName: 'R/I',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 8 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'tournaments_per_month_score',
            headerName: 'T/Mo',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 3 ? '#70db70' : value >= 1 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'score',
            headerName: 'Score',
            flex: 0.3,
            minWidth: 60,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const color = value >= 60 ? '#70db70' : value >= 40 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value === null ? null : params.value.toFixed(0)}
                    </Box>
                );
            },
        },
        {
            field: 'score_abi',
            headerName: 'ABIμ',
            flex: 1,
            minWidth: 90,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value === null ? null : params.value.toFixed(0)}
                    </Box>
                );
            },
        },
        {
            field: 'qot',
            headerName: 'QoT',
            flex: 1,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value === null ? null : params.value.toFixed(0)}
                    </Box>
                );
            },
        },
        {
            field: 'reentries',
            headerName: 'Reentry',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => (params.row.reentries === null ? null : (params.row.reentries * 100).toFixed(0)),
            renderCell: (params: GridCellParams) => {
                const value = params.row.reentries as number;
                const color = value > 0.1 ? '#ff6666' : null;
                if (value === null) return null;
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'level',
            headerName: 'LVL',
            flex: 1,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                const ev = params.row.ev_level as number;
                const color = value > ev ? '#70db70' : value < ev ? '#ff6666' : value === null ? null : null;
                return (
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'ev_level',
            headerName: 'EVLVL',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
    ];

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell:not(:first-child)': {
                        padding: '0 !important',
                        margin: '0 !important',
                    },
                }}
                autoHeight={true}
                rows={items.data ?? []}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                disableColumnMenu
                page={page}
                rowCount={items.count ?? 0}
                paginationMode="server"
                onPageChange={newPage => setPage(newPage)}
                onPageSizeChange={newPageSize => {
                    setPageSize(newPageSize);
                    setPage(0);
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default ScoringTable;
