import React from 'react';
import Chip from '@mui/material/Chip';
import { useQuery } from 'react-query';

import { getRoomGroups } from 'api/pokerrooms';
import LimitsTable from './components/LimitsTable';

const BuyinLimit: React.FC = () => {
    const [roomGroup, setRoomGroup] = React.useState(null);

    const roomGroupsQuery = useQuery(['room=groups', roomGroup], getRoomGroups, {
        initialData: []
    });

    return (
        <div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 mb-8">
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Рум</p>
                    <div className="flex flex-wrap">
                        {
                            roomGroupsQuery.data.map(val => (
                                <div className="mr-2 mb-2" key={val.id}>
                                    <Chip
                                        label={val.name}
                                        className={val.id === roomGroup ? 'bg-blue-400' : 'bg-gray-300'}
                                        color={val.id === roomGroup ? 'primary' : 'default'}
                                        onClick={() => setRoomGroup(val.id)}
                                        clickable
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <LimitsTable groupId={roomGroup}/>
        </div>
    );
}

export default BuyinLimit;
