import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Assignment from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { getPlayerAccountRecords } from 'api/players';
import { getRoomUsers } from 'api/pokerrooms';
import { updateTransferInfo } from 'api/transfers';
import AvailableBalancesDialog from './AvailableBalancesDialog';

interface EditSenderPropsInterface extends DialogProps {
    transferId: string;
    roomId: number;
    chipCurrencyId: number;
    amount: number;
}

const EditSenderDialog: React.FC<EditSenderPropsInterface> = (props) => {
    const queryClient = useQueryClient();

    const [availableBalancesOpen, setAvailableBalancesOpen] = React.useState(false);

    const { control, watch, setValue, handleSubmit, reset } = useForm({
        defaultValues: {
            sender_id: '',
            send_account_record: '',
        }
    });
    const watchSenderId = watch('sender_id', null);

    const playersQuery = useQuery(['room-players', props.roomId], getRoomUsers, {
        enabled: !!props.roomId,
        initialData: []
    })

    const { data } = useQuery(['player-accountrecords', watchSenderId, true, null], getPlayerAccountRecords, {
        enabled: !!watchSenderId
    });

    const setSenderData = (playerId: number, accRecordId: number) => {
        setValue('sender_id', playerId.toString(), { shouldDirty: true, shouldValidate: true });
        setValue('send_account_record', accRecordId.toString(), { shouldDirty: true, shouldValidate: true });
    }

    const updateTransferMutation = useMutation(updateTransferInfo, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['transfer', props.transferId]);
            props.onClose({}, 'backdropClick');
            reset();
        }
    });

    const onSubmit = handleSubmit((data) => updateTransferMutation.mutate({ transferId: props.transferId, ...data }));

    return (
        <>
            <Dialog {...props} maxWidth="sm" fullWidth>
                <DialogTitle>Изменить отправителя</DialogTitle>
                <DialogContent>
                    <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                        <div className="flex items-center">
                            <div className="flex-grow">
                                <Controller
                                    name="sender_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            disablePortal
                                            options={playersQuery.data}
                                            getOptionLabel={(option: any) => option?.username}
                                            renderInput={(params) =>
                                                <TextField
                                                    label="Отправитель трансфера"
                                                    fullWidth
                                                    required
                                                    {...params}
                                                />
                                            }
                                            onChange={(_event, data) => setValue('sender_id', data.id)}
                                        />
                                    )}
                                />
                            </div>
                            <IconButton onClick={() => setAvailableBalancesOpen(true)}>
                                <Assignment />
                            </IconButton>
                        </div>

                        <Controller
                            name="send_account_record"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Аккаунт списания"
                                    select
                                    fullWidth
                                    required
                                    disabled={!watchSenderId}
                                    {...field}
                                >
                                    {
                                        data?.filter(ar => ar.is_active && ar.room_id === props.roomId && ar.chip_currency.id === props.chipCurrencyId).map(ar => (
                                            <MenuItem key={ar.id} value={ar.id}>
                                                {ar.account_record_name}, {ar.chip_currency.name}
                                            </MenuItem>
                                        )) ?? []
                                    }
                                </TextField>
                            )}
                        />

                        <div className="pt-4 text-right">
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                loading={updateTransferMutation.isLoading}
                            >Сохранить</Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="lg" open={availableBalancesOpen} onClose={() => setAvailableBalancesOpen(false)}>
                <AvailableBalancesDialog
                    roomId={props.roomId}
                    chipCurrencyId={props.chipCurrencyId}
                    amount={props.amount}
                    close={() => setAvailableBalancesOpen(false)}
                    setData={setSenderData}
                />
            </Dialog>
        </>
    );
};

export default EditSenderDialog;
