import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef } from '@mui/x-data-grid';
import { updateSchedulerRoiGridLimits } from 'api/schedulersettings';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу ROI лимитов в расписаторе.
 */
const SchedulerRoiGridLimitTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const editItemMutation = useMutation(updateSchedulerRoiGridLimits, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-roi-grid-limits-data');
        },
    });

    const columns: GridColDef[] = [
      {
        field: 'separator_4',
        headerName: 'Отличные',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
        sortable: false,
        align: 'left',
        headerAlign: 'left',
      },
      {
        field: 'separator_3',
        headerName: 'Хорошие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
        sortable: false,
        align: 'left',
        headerAlign: 'left',
      },
      {
        field: 'separator_2',
        headerName: 'Средние',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
        sortable: false,
        align: 'left',
        headerAlign: 'left',
      },
      {
        field: 'separator_1',
        headerName: 'Плохие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
        sortable: false,
        align: 'left',
        headerAlign: 'left',
      },
    ];

    function editCell(params) {
        if (Number(params.formattedValue) !== Number(params.value)) {
            const editId = params.id;
            const body = {
              separator: params.field.charAt(params.field.length - 1),
              value: params.value,
            }

            editItemMutation.mutate({id: editId, body})
        }
    }

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
      <DataGrid
          autoHeight={true}
          rows={items ?? []}
          columns={columns}
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          onCellEditCommit={(params: GridCellEditCommitParams) => editCell(params)}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default SchedulerRoiGridLimitTable;
