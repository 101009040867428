import axios from 'helpers/axios';

/**
 * Таблица риска
 */
export const getRisk = async ({ queryKey }) => {
    const [, revision, search, curatorFilter, perPage, page] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    let params = new URLSearchParams();

    if (search) {
        params.append('name', search);
    }

    if (curatorFilter) {
        params.append('curatorId', curatorFilter.id);
    }

    if (perPage) {
        params.append("limit", perPage);
    }

    if (offset) {
        params.append("offset", String(offset));
    }

    const request = {
        params: params,
    };

    if (revision && revision.id) {
        const { data } = await axios.get(`/api/adminapi/analytics/players/risk/revisions/${revision.id}/stats/`, request);

        return data;
    }
};

/**
 * Список ревизий
 */
 export const getRevision = async () => {
    const { data } = await axios.get(`/api/adminapi/analytics/players/risk/revisions/`);

    return data;
};