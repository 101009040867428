import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import ManualResultsAddDialog from './ManualResultsAddDialog';
import ManualResultsTable from './ManualResultsTable';

interface Props {
    playerId: number | null;
    roomId: number | null;
}

const ManualResults: React.FC<Props> = (props: Props) => {

    const [showCreateDialog, setShowCreateDialog] = React.useState(false);

    return (
        <>
            <div className="flex justify-end">
                <Button className="mb-2" variant="contained" onClick={() => setShowCreateDialog(true)}>
                    Внести результат
                </Button>
            </div>
            <Card className="mt-4">
                <ManualResultsTable playerId={props.playerId} roomId={props.roomId} />
            </Card>
            <Dialog fullWidth maxWidth="sm" open={showCreateDialog} onClose={() => setShowCreateDialog(false)}>
                <ManualResultsAddDialog playerId={props.playerId} roomId={props.roomId} close={() => setShowCreateDialog(false)} />
            </Dialog>
        </>
    )
}

export default ManualResults;
