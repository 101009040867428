import { FC, Fragment, useState } from 'react';
import { Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteRevisionCustomStats, getStats } from 'api/playerstats';
import DeleteIcon from '@mui/icons-material/Delete';
import StatsRevisionDeleteDialog from './StatsRevisionDeleteDialog';
import StatsEditModal from './StatsEditModal';

type Props = {
    /**
     * ID игрока
     */
    id?: string;

    /**
     * Кол-во ревизий
     */
    revision: string;
}
/**
 * Таблица с статистикой игроков.
 */
const StatsTable: FC<Props> = ({ id, revision }) => {
    const queryClient = useQueryClient();
    
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState<number>(null);
    const [editDialog, setEditDialog] = useState(false);
    const [editItem, setEditItem] = useState<number>(null);

    const {data: stats, isFetching } = useQuery(['stats-players', id, revision], getStats, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const { mutate } = useMutation(deleteRevisionCustomStats, {
        onSuccess: () => {
            queryClient.invalidateQueries('stats-players');
        },
    });

    function handleOpenDeleteDialog(id: number) {
        setSelectedId(id);
        setDeleteDialog(true);
    }

    function handleDeleteRevision() {
        mutate(selectedId);
        setDeleteDialog(false);
    }

    const handleCellEdit = (id?: number) => {
        if (id) {
            setEditItem(id);
            setEditDialog(true);
        }
    };

    function handleCellEditClose() {
        setEditItem(null);
        setEditDialog(false);
    }

    if (isFetching) {
        return (
            <Box className='flex flex-1 flex-col items-center justify-center h-80'>
                <CircularProgress color="primary" />
            </Box>
        )
    }
   
    return (
        <Box>
            {stats &&
                <Box className='w-full space-y-10'>
                    {stats.map((data: any, i: number) => {
                        if (!data.columns.length) {
                            return (
                                <Box className='flex flex-1 flex-col items-center justify-center h-80' key={i}>
                                    <Typography variant="h5" component="span" className='text-slate-600'>Данные по игроку отсутствуют</Typography>
                                </Box>
                            );
                        };
                        
                        return (
                            <Box key={i} className='w-full'>
                                <Box className='bg-white overflow-auto'>
                                    <Table className='w-full' size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='text-gray-700 font-bold text-sm py-4'>Статистика</TableCell>
                                                {data.columns.map((val, i) => (
                                                    <TableCell className='text-gray-700 font-bold text-sm py-4' key={i}>
                                                        <div className='flex flex-row gap-2 items-center '>
                                                            <div>
                                                                {val.revision_date}
                                                            </div>
                                                            <div>
                                                                <IconButton className='bg-white' onClick={() => handleOpenDeleteDialog(val.id)}>
                                                                    <DeleteIcon fontSize='small' />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.data.map(({ category, rows }, i) => (
                                                <Fragment key={i}>
                                                    <TableRow>
                                                        <TableCell className='text-center font-semibold bg-gray-200' colSpan={data.columns.length + 1}>
                                                            {category.name}
                                                        </TableCell>
                                                    </TableRow>
                                                    {rows.map((row, rowI) => (
                                                        <TableRow key={rowI}>
                                                            {row.map((col, i) => {
                                                                if (typeof col === 'string') return <TableCell key={i}>{col}</TableCell>;

                                                                if (col.note) {
                                                                    return (
                                                                        <Tooltip title={col.note} key={`${col.id} + ${i}`}>
                                                                            <TableCell className='relative' onDoubleClick={() => handleCellEdit(col?.value_id)}>
                                                                                <div
                                                                                    className='inset-0 absolute opacity-50'
                                                                                    style={{ backgroundColor: col.color || 'white' }}
                                                                                />
                                                                                <p className='relative'>
                                                                                    <span>{col.value}</span>
                                                                                </p>
                                                                            </TableCell>
                                                                        </Tooltip>
                                                                    );
                                                                }

                                                                return (
                                                                    <TableCell className='relative' key={i} onDoubleClick={() => handleCellEdit(col?.value_id)}>
                                                                        <div
                                                                            className='inset-0 absolute opacity-50'
                                                                            style={{ backgroundColor: col.color || 'white' }}
                                                                        />
                                                                        <p className='flex-grow flex-shrink-0 relative'>
                                                                            <span>{col.value}</span>
                                                                        </p>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            }
            <StatsRevisionDeleteDialog close={() => setDeleteDialog(false)} open={deleteDialog} handleAgreeAction={handleDeleteRevision}/>
            {editDialog && <StatsEditModal id={editItem} open={editDialog} onClose={handleCellEditClose} />}
        </Box>
    );
}

export default StatsTable;
