import React, { FC } from 'react';
import { useQuery } from 'react-query';

import { getPlayersMaxBuyins } from 'api/ggnetworkscoring';
import { Card, CircularProgress, Box } from '@mui/material';
import ScoringPlayerFilters from './ScoringPlayerFilters';
import ScoringPlayerTable from './ScoringPlayerTable';
import ManualMaxBuyin from './ManualMaxBuyin';
import { initialState, filterReducer } from './state';

const ScoringPlayer: FC = () => {
    const [state, dispatch] = React.useReducer(filterReducer, initialState);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState<number>(25);

    const { data: maxBuyins, isFetching } = useQuery(['player-ggnetwork-maxbuyins', state.playerIdFilter?.id, pageSize, page], getPlayersMaxBuyins, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        enabled: !!state.playerIdFilter?.id,
    })

    return (
        <>
            <Box className='flex flex-row justify-between gap-2 items-center'>
                <Box>
                    <ScoringPlayerFilters
                        playerFilter={state.playerIdFilter}
                        dispatch={dispatch}
                    />
                </Box>
            </Box>
            {state.playerIdFilter &&
                <Card className="mt-4 p-4">
                    <ManualMaxBuyin playerId={state.playerIdFilter?.id} />
                </Card>
            }
            {isFetching && !maxBuyins && !state.playerIdFilter.id && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {maxBuyins && state.playerIdFilter &&
                <Card className="mt-4">
                    <ScoringPlayerTable
                        items={maxBuyins}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        page={page}
                        setPage={setPage}
                    />
                </Card>
            }
        </>
    );
}

export default ScoringPlayer;
