import { useState, FC } from 'react';
import { Box, Chip } from '@mui/material';
import Scoring from './components/Scoring/Scoring';
import ScoringPlayer from './components/ScoringPlayer/ScoringPlayer';
import History from './components/History/History';
import DefaultMaxBuyin from './components/DefaultMaxBuyin/DefaultMaxBuyin';

/**
 * Страница с аналитикой.
 */
const ScoringGGNetwork: FC = () => {
    const [tabIndex, setTabIndex] = useState(0);

    function handleChangeTab(newIndex: number) {
        if (tabIndex === newIndex) {
            return;
        }

        setTabIndex(newIndex);
    }

    return (
        <>
            <Box className='flex flex-row justify-between'>
                <Box className='flex gap-1'>
                    <Chip label='Скоринг Ревизии' color={tabIndex === 0 ? 'primary' : 'default'} onClick={() => handleChangeTab(0)} clickable />
                    <Chip label='Скоринг Игрок' color={tabIndex === 1 ? 'primary' : 'default'} onClick={() => handleChangeTab(1)} clickable />
                    <Chip label='История' color={tabIndex === 2 ? 'primary' : 'default'} onClick={() => handleChangeTab(2)} clickable />
                    <Chip label='Дефолтные байины' color={tabIndex === 3 ? 'primary' : 'default'} onClick={() => handleChangeTab(3)} clickable />
                </Box>
            </Box>
            <Box className="mt-4 p-1">
                {tabIndex === 0 && <Scoring />}
                {tabIndex === 1 && <ScoringPlayer />}
                {tabIndex === 2 && <History />}
                {tabIndex === 3 && <DefaultMaxBuyin />}
            </Box>
        </>
    );
}

export default ScoringGGNetwork;
