import { Dispatch, FC, SetStateAction } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import moment from 'moment';

import { Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { getPlayerGGNetworkMaxBuyinHistory } from 'api/ggnetworkscoring';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Список элементов.
     */
    playerId: number;

    /**
     * Кол-во элементов в таблице.
     */
    pageSize: number;

    /**
     * Задать кол-во элементов в таблице.
     */
    setPageSize: Dispatch<SetStateAction<number>>;

    /**
     * Страница.
     */
    page: number;

    /**
     * Задать страницу
     */
    setPage: Dispatch<SetStateAction<number>>;
};

/**
 * Отображает таблицу скоринга игроков.
 */
const HistoryTable: FC<Props> = ({ playerId, pageSize, setPageSize, page, setPage }) => {
    const queryClient = useQueryClient();

    const playerHistoryQuery = useQuery(['player-ggnetwork-scoring-history', playerId], getPlayerGGNetworkMaxBuyinHistory, {
        initialData: [],
    });

    const columns: GridColDef[] = [
        {
            field: 'max_buyin',
            headerName: 'Макс. БИ',
            flex: 0.3,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'from',
            headerName: 'С даты',
            flex: 0.3,
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.from).format('DD.MM.YYYY HH:mm')}`,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'type',
            headerName: 'Тип',
            flex: 0.3,
            minWidth: 200,
            sortable: false,
        },
    ];

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell:not(:first-child)': {
                        padding: '0 !important',
                        margin: '0 !important',
                    },
                }}
                autoHeight={true}
                rows={playerHistoryQuery.data?.history ?? []}
                columns={columns}
                pageSize={pageSize}
                getRowId={row => row.from}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                disableColumnMenu
                page={page}
                rowCount={playerHistoryQuery.data?.count ?? 0}
                paginationMode="server"
                onPageChange={newPage => setPage(newPage)}
                onPageSizeChange={newPageSize => {
                    setPageSize(newPageSize);
                    setPage(0);
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default HistoryTable;
