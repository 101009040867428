import { FC } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { IconButton, DialogTitle, DialogContent, TextField } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateBundle } from 'api/bundles';
import { useParams } from 'react-router-dom';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * Имя поля для редактирования
     */
    field: string;

    /**
     * Данные контракта
     */
    contract: any;
}

/**
 * Диалоговое окно для изменение значения контракта.
 */
const ContractEditFieldDialog: FC<Props> = ({ field, contract, ...props }) => {
    const queryClient = useQueryClient();
    const { playerId } = useParams();

    function defaultContractValue() {
        switch (field) {
            case 'personal_share':
                return contract.personal_share;
            case 'payout':
                return contract.payout;
            default:
                return '';
        }
    }

    const { control, handleSubmit } = useForm({
        defaultValues: {
            value: defaultContractValue(),
        },
    });

    const bundleMutation = useMutation(updateBundle, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-contracts', playerId]);
            props.close();
        },
    });


    /**
     * Функция сохранения формы.
     */
    const onSubmit = handleSubmit(data => {
        bundleMutation.mutate({ id: contract.id, [field]: data.value });
    });

    function getLabel() {
        switch (field) {
            case 'share':
                return 'доли игрока в текущем пакете';
            case 'payout':
                return 'процента игрока в текущем пакете';
            default:
                return '';
        }
    }

    return (
        <>
            <DialogTitle>
                {`Редактирование ${getLabel()}`}
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name='value'
                        render={({ field }) => <TextField {...field} label="Новое значение" fullWidth type="number" />}
                    />

                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={bundleMutation.isLoading}
                        >
                            Редактировать
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default ContractEditFieldDialog;
