import { FC, useCallback, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getRooms } from 'api/rooms';
import { getCustomTournaments, deleteCustomTournament } from 'api/customgridtournaments';
import { Box, Card, CircularProgress, IconButton, Stack, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';
import ClearAll from '@mui/icons-material/ClearAll';
import CustomTournamentsFilters from './CustomTournamentsFilters';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Delete from '@mui/icons-material/Delete';
import CsvUploadDialog from './CsvUploadDialog';

/**
 * Отображает таблицу турниров.
 */
const Tournaments: FC = () => {
    // Фильтр по дням недели
    const [filters, setFilter] = useState([]);
    const [showOld, setShowOld] = useState<boolean>(false);
    // Строка поиска
    const [search, setSearch] = useState('');

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [csvDialogOpen, setCsvDialogOpen] = useState(false);

    const queryClient = useQueryClient();

    const { data: rooms } = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const { data: tournaments, isFetching } = useQuery(['custom-tournaments-data', pageSize, page, filters, search, showOld], getCustomTournaments, {
        refetchOnWindowFocus: true,
        keepPreviousData: true,
        retry: false,
    });

    const deleteTournamentMutation = useMutation(deleteCustomTournament, {
        onSuccess: () => {
            queryClient.invalidateQueries('custom-tournaments-data');
        },
    });

    const columns: GridColDef[] = [
        {
            field: 'network_name',
            headerName: 'Рум',
            sortable: false,
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: 'name',
            headerName: 'Название',
            sortable: false,
            flex: 0.7,
            minWidth: 100,
        },
        {
            field: 'time',
            headerName: 'Время UTC',
            sortable: false,
            flex: 0.2,
            minWidth: 100,
        },
        {
            field: 'days',
            headerName: 'Дни',
            sortable: false,
            flex: 0.2,
            minWidth: 100,
        },
        {
            field: 'buyin',
            headerName: 'Байин',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'buyin_currency_name',
            headerName: 'Валюта',
            sortable: false,
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'tags',
            headerName: 'Тип',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'min_player_level_name',
            headerName: 'Min Уровень',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'score',
            headerName: 'Оценка',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'begin_datetime',
            headerName: 'Начинается UTC',
            valueGetter: (params: GridValueGetterParams) =>
                params.row.begin_datetime ? `${moment.utc(params.row.begin_datetime).format('DD.MM.YYYY HH:mm')}` : null,
            flex: 0.4,
            minWidth: 60,
        },
        {
            field: 'expire_datetime',
            headerName: 'Истекает UTC',
            valueGetter: (params: GridValueGetterParams) =>
                params.row.expire_datetime ? `${moment.utc(params.row.expire_datetime).format('DD.MM.YYYY HH:mm')}` : null,
            flex: 0.4,
            minWidth: 60,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: params => {
                function selectItem(params: any) {
                    const data = {
                        id: params.row.id,
                        name: params.row.name,
                    };
                    return data;
                }
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton onClick={() => deleteTournamentMutation.mutate(params.row.id)}>
                            <Delete color={params.row.is_modified ? 'warning' : 'inherit'} />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    return (
        <>
            <CustomTournamentsFilters
                setFilter={setFilter}
                showOld={showOld}
                setShowOld={setShowOld}
                data={filters}
                rooms={rooms}
                setSearch={setSearch}
                search={search}
                setCsvUploadOpen={() => setCsvDialogOpen(true)}
            />
            {isFetching && !tournaments && (
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                    <CircularProgress />
                </Card>
            )}
            {tournaments && (
                <Card>
                    <Box
                        className="h-auto overflow-auto"
                        sx={{
                            '& .active': {
                                backgroundColor: '#fcecd5',
                                color: '#1a3e72',
                            },
                        }}
                    >
                        <DataGrid
                            autoHeight={true}
                            rows={tournaments.data ?? []}
                            columns={columns}
                            pageSize={pageSize}
                            rowCount={tournaments.count ?? 0}
                            page={page}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => {
                                setPageSize(newPageSize);
                                setPage(0);
                            }}
                            rowsPerPageOptions={[25, 50, 100]}
                            pagination
                            disableSelectionOnClick
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Данные отсутствуют
                                    </Stack>
                                ),
                            }}
                            componentsProps={{
                                pagination: {
                                    ActionsComponent: TablePaginationActions,
                                },
                            }}
                        />
                    </Box>
                </Card>
            )}
            <CsvUploadDialog open={csvDialogOpen} close={() => setCsvDialogOpen(false)} />
        </>
    );
};

export default Tournaments;
