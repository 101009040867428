import { FC } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { getPLayersV2 } from 'api/players';
import { getPokerRooms } from 'api/pokerrooms';
import DateTimePicker from '@mui/lab/DateTimePicker'
import {
    Action,
    SET_PLAYER_ID_FILTER,
    SET_ROOM_ID_FILTER,
    SET_START_DATE_FILTER,
    SET_END_DATE_FILTER,
} from '../state'

type Props = {
    playerFilter: any;
    roomFilter: any;
    startDateFilter: any;
    endDateFilter: any;
    dispatch: React.Dispatch<Action>;
};

const ReconciliationFilter: FC<Props> = ({
    playerFilter,
    roomFilter,
    startDateFilter,
    endDateFilter,
    dispatch,
}) => {
    const playersQuery = useQuery(['players-list', null], getPLayersV2, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const roomsQuery = useQuery(['networks-list'], getPokerRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const defaultPropsPlayers = {
        options: playersQuery.data ?? [],
        getOptionLabel: option => option.username ?? '',
    };

    const defaultPropsRooms = {
        options: roomsQuery.data ?? [],
        getOptionLabel: option => option.name ?? '',
    };

    return (
        <>
            <Box className="flex flex-row flex-wrap gap-2 ">
                <Autocomplete
                    {...defaultPropsPlayers}
                    disablePortal
                    id="players"
                    sx={{ width: 250 }}
                    disabled={playersQuery.isFetching}
                    value={playerFilter ?? null}
                    onChange={(event: any, newValue: any) => {
                        dispatch({ type: SET_PLAYER_ID_FILTER, payload: newValue })
                    }}
                    renderInput={params => <TextField {...params} label="Ник игрока" />}
                    className="bg-white"
                />
                <Autocomplete
                    {...defaultPropsRooms}
                    disablePortal
                    id="rooms"
                    sx={{ width: 250 }}
                    disabled={roomsQuery.isFetching}
                    value={roomFilter ?? null}
                    onChange={(event: any, newValue: any) => {
                        dispatch({ type: SET_ROOM_ID_FILTER, payload: newValue })
                    }}
                    renderInput={params => <TextField {...params} label="Рум" />}
                    className="bg-white"
                />
                <DateTimePicker
                    label="От UTC"
                    inputFormat="DD/MM/yyyy HH:mm"
                    value={startDateFilter}
                    onChange={(newValue) => {
                        dispatch({ type: SET_START_DATE_FILTER, payload: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DateTimePicker
                    label="До UTC"
                    inputFormat="DD/MM/yyyy HH:mm"
                    value={endDateFilter}
                    onChange={(newValue) => {
                        dispatch({ type: SET_END_DATE_FILTER, payload: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Box>
        </>
    );
};

export default ReconciliationFilter;
