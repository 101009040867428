import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getRooms } from 'api/rooms';
import { getCoefficient } from 'api/coefficient';
import { Card, CircularProgress } from '@mui/material';
import RoomsFilter from 'components/RoomsFilter/RoomsFilter';
import CoefficientTable from './CoefficientTable';
import RoomHelper from 'helpers/RoomHelper';

/**
 * Вкладка с информацией о коэффициентах турниров.
 */
const Coefficient: FC = () => {
    const [filter, setFilter] = useState([]);
    const [result, setResult] = useState();

    const {data: rooms} = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: coefficient, isFetching} = useQuery(['coefficient-data'], getCoefficient, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    useEffect(() => {
        if(filter.length > 0) {
            setResult(coefficient.filter(({network_id}) => filter.find(item => item.id === network_id)));
        } else {
            setResult(coefficient);
        }
    }, [coefficient, filter]);

    return (
        <>
            <RoomsFilter setFilter={setFilter} data={filter} rooms={RoomHelper.withoutDefault(rooms)} />
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><CoefficientTable items={result}/></Card>}
        </>
    );
}

export default Coefficient;
