import React from 'react';
import { useQuery } from 'react-query';
import { Box, Card, Typography } from '@mui/material';

import DefaultMaxBuyinTable from './components/DefaultMaxBuyinTable';
import { getDefaultMaxBuyins } from 'api/ggnetworkscoring';

const DefaultMaxBuyin: React.FC = () => {

    const { data: maxBuyinsData } = useQuery('ggnetworkscoring-default-max-buyins', getDefaultMaxBuyins, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    return (
        <>
            {maxBuyinsData && maxBuyinsData.default_max_buyins && (
                <Box className="mt-4">
                    <Typography variant='h6' className='mb-1'>Default Max Buyins</Typography>
                    <Card>
                        <DefaultMaxBuyinTable items={maxBuyinsData.default_max_buyins} />
                    </Card>
                </Box>
            )}
        </>
    )

}

export default DefaultMaxBuyin;
