import { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import UntrackedPlayersTable from './UntrackedPlayersTable';
import { getGroupListUnknownPlayers } from 'api/untrackedlabelratiogroups';

type Props = {
    /**
     * ID группы для детального просмотра
     */
    groupView: string;

    /**
     * Изменить группу для детального просмотра
     */
    setGroupView: Dispatch<SetStateAction<string>>;
}

/**
 * Вкладка с распределением неизвестных игроков.
 */
const UntrackedPlayers: FC<Props> = ({ groupView, setGroupView }) => {
    const { data: list, isFetching } = useQuery(['group-list-untracked-players'], getGroupListUnknownPlayers, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    return (
        <>
            {isFetching && !list && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && list && <Card><UntrackedPlayersTable items={list} groupView={groupView} setGroupView={setGroupView} /></Card>}
        </>
    );
}

export default UntrackedPlayers;
