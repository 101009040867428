import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useQuery } from 'react-query';
//import { useNavigate } from 'react-router-dom';

import { getAvailableBalances } from 'api/availablebalances';

interface AssignPlayerDialogProps {
    roomId: number;
    chipCurrencyId: number;
    amount: number;

    close: () => void;
    setData: (userId: number, accRecordId: number) => void;
}

const AvailableBalancesDialog: React.FC<AssignPlayerDialogProps> = ({ roomId, chipCurrencyId, amount, ...props }) => {

    const { data, isLoading } = useQuery(['available-balances', roomId, chipCurrencyId, amount], getAvailableBalances, {
        enabled: !!roomId,
        initialData: []
    });

    const getPlayerClass = ({ data }: any) => {
        if (data.online) return 'bg-green-500';

        return '';
    };

    function customComparator(valueA, valueB, nodeA, nodeB, isInverted) {
        const onlineA = nodeA.data['online'];
        const onlineB = nodeB.data['online'];
        if (onlineA && !onlineB) {
            return 1;
        }
        if (!onlineA && onlineB) {
            return -1;
        }
        return (valueA > valueB) ? 1 : -1;
    }

    return (
        <div className="ag-theme-material w-full relative shadow-md rounded overflow-hidden" style={{ height: 600 }}>
            <AgGridReact
                rowData={data || []}
                defaultColDef={{
                    flex: 1,
                    sortable: true,
                    floatingFilter: true,
                    suppressMenu: true,
                    floatingFilterComponentParams: { suppressFilterButton: true }
                }}
                onRowClicked={
                    (e) => {
                        props.setData(e.data.player_id, e.data.account_record_id);
                        props.close();
                    }
                }
            >
                <AgGridColumn headerName="Игрок" field="player_name" cellClass={getPlayerClass} filter />
                <AgGridColumn headerName="Аккаунт" field="account_record_name" filter />
                <AgGridColumn headerName="Баланс" field="balance" comparator={customComparator} sort='desc' />
                <AgGridColumn headerName="Доля игрока" field="personal_share" />
                <AgGridColumn headerName="ABI" field="abi" />
                <AgGridColumn headerName="# Accs" field="num_account_records" />
                <AgGridColumn headerName="Ср-ва команды" field="team_funds" />
                <AgGridColumn headerName="online" field="online" hide />
            </AgGridReact>
            {
                isLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                        <CircularProgress />
                    </div>
                ) : null
            }
        </div>
    );
};

export default AvailableBalancesDialog
