import { FC, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IconButton, Box, Stack, Dialog, useMediaQuery } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import theme from 'themes/lightTheme';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import AddUntrackedTournamentsDialog from './AddUntrackedTournamentsDialog';
import { deleteUntrackedTournaments } from 'api/players';
import DeleteDialog from 'components/DeleteDialog';
import EditUntrackedTournamentDialog from './EditUntrackedTournamentDialog';
import { getPlayerUntrackedTournaments } from 'api/players';

type Props = {
    playerId: number;
    roomId: number;
    startDate: string | null;
    endDate: string | null;
};

const UntrackedTournamentsTable: FC<Props> = ({ playerId, roomId, startDate, endDate }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const queryClient = useQueryClient();

    const [pageSize, setPageSize] = useState<number>(5);
    const [addDialog, setAddDialog] = useState(false);

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const [untrackedId, setUntrackedId] = useState(null);

    const untrackedTournamentsQuery = useQuery(['player-untracked-tournaments', playerId, roomId, startDate, endDate], getPlayerUntrackedTournaments, {
        initialData: [],
    });

    const deleteMutation = useMutation(deleteUntrackedTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-untracked-tournaments', playerId], { exact: false });
            queryClient.invalidateQueries(['player-untracked-tournaments-count', playerId], { exact: false });
            setDeleteDialog(false);
        },
    });

    const columns: GridColDef[] = [
        {
            field: 'room_name',
            headerName: 'Рум',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'count',
            headerName: 'Кол-во',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'datetime',
            headerName: 'Дата',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.datetime).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'id',
            headerName: '',
            renderHeader: params => {
                return (
                    <Box sx={{ padding: 1 }}>
                        <IconButton
                            onClick={() => {
                                setAddDialog(true);
                            }}
                        >
                            <AddIcon color="success" />
                        </IconButton>
                    </Box>
                );
            },
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: params => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setUntrackedId(params.row.id);
                                setEditDialog(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setUntrackedId(params.row.id);
                                setDeleteDialog(true);
                            }}
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <>
            <Box
                className="h-auto overflow-auto"
                sx={{
                    '& .active': {
                        backgroundColor: '#fcecd5',
                        color: '#1a3e72',
                    },
                }}
            >
                <DataGrid
                    autoHeight={true}
                    rows={untrackedTournamentsQuery.data}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pagination
                    disableSelectionOnClick
                    disableColumnMenu
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Данные отсутствуют
                            </Stack>
                        ),
                    }}
                />

                <DeleteDialog
                    close={() => setDeleteDialog(false)}
                    open={deleteDialog}
                    loading={deleteMutation.isLoading}
                    handleAgreeAction={() => deleteMutation.mutate(untrackedId)}
                />
                <EditUntrackedTournamentDialog
                    open={editDialog}
                    onClose={() => setEditDialog(false)}
                    tournamentId={untrackedId}
                    playerId={playerId}
                />
            </Box>

            <Dialog fullWidth maxWidth='sm' open={addDialog} onClose={() => setAddDialog(false)} fullScreen={fullScreen}>
                <AddUntrackedTournamentsDialog onClose={() => setAddDialog(false)} playerId={playerId} roomId={roomId} />
            </Dialog>
        </>
    );
};

export default UntrackedTournamentsTable;
