import { FC, useState } from 'react';

import { Breadcrumbs, Card, Link, Typography } from '@mui/material';
import HiddenTable from './HiddenTable';

type Props = {
    /**
     * Имя турнира.
     */
    name: string;

    /**
     * ID турнира.
     */
    id: number;

    /**
     * Закрытие вкладки с информацией о заблокированных турнирах.
     */
    close: () => void;
};

/**
 * Вкладка с информацией о заблокированных турнирах.
 */
const Hidden: FC<Props> = ({ name, id, close }) => {
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className="mb-4">
                <Link underline="hover" color="inherit" onClick={() => close()} className="cursor-pointer">
                    Список турниров
                </Link>
                <Typography color="text.primary">{name}</Typography>
            </Breadcrumbs>
            <Card className="mt-2">
                <HiddenTable id={id} />
            </Card>
        </>
    );
};

export default Hidden;
