import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';

import { getPLayersV2, getPlayerAccountRecords, getUserTransfersAsSender } from 'api/players';
import { createTransfer } from 'api/transfers';
import { getRoomUsers } from 'api/pokerrooms';
import { getAccountRecordLatestBalance, getAccountRecordExcessFunds } from 'api/accountrecords';

const NewTransferDialog: React.FC<DialogProps> = props => {
    const queryClient = useQueryClient();

    const { control, watch, setValue, getValues, handleSubmit, reset } = useForm({
        defaultValues: {
            sender_id: null,
            room_id: null,
            send_account_record_id: '',
            receiver_id: null,
            receive_account_record_id: '',
            amount: '',
            is_sent: false,
            is_received: false,
            comment: null,
        },
    });
    const watchSenderId = watch('sender_id', null);
    const watchRoomId = watch('room_id', null);
    const watchSenderAccountRecordId = watch('send_account_record_id', null);
    const watchReceiverId = watch('receiver_id', null);
    const watchReceiverAccountRecordId = watch('receive_account_record_id', null);

    const [sendChipCurrency, setSendChipCurrency] = React.useState(0);
    const [sendTransferLimit, setSendTransferLimit] = React.useState(null);
    const [networkSenderAmount, setNetworkSenderAmount] = React.useState(0);

    const playersQuery = useQuery('players-list', getPLayersV2, {
        initialData: [],
    });

    const senderAccRecordsQuery = useQuery(['player-accountrecords', watchSenderId, true, null], getPlayerAccountRecords, {
        enabled: !!watchSenderId,
    });

    const receiverAccRecordsQuery = useQuery(['player-accountrecords', watchReceiverId, true, null], getPlayerAccountRecords, {
        enabled: !!watchReceiverId,
    });

    const receiversQuery = useQuery(['room-players', watchRoomId], getRoomUsers, {
        enabled: !!watchRoomId,
        initialData: [],
    });

    const senderTransfersQuery = useQuery(['user-transfers-sender', watchSenderId, false, watchRoomId], getUserTransfersAsSender, {
        enabled: !!watchSenderId && !!watchRoomId,
        initialData: [],
    });

    const recordLatestBalanceQuery = useQuery(['account-record-latest-balance', watchSenderAccountRecordId], getAccountRecordLatestBalance, {
        enabled: !!watchSenderAccountRecordId && !!watchReceiverAccountRecordId,
        initialData: null,
    });

    const recordExcessBalanceQuery = useQuery(['account-record-excess-funds', watchSenderAccountRecordId], getAccountRecordExcessFunds, {
        enabled: !!watchSenderAccountRecordId && !!watchReceiverAccountRecordId,
        initialData: -1,
    });

    const newTransferMutation = useMutation(createTransfer, {
        onSuccess: _ => {
            queryClient.invalidateQueries(['transfers', 'pending']);
            queryClient.invalidateQueries(['transfers', 'month']);
            props.onClose({}, 'backdropClick');
            reset();
        },
    });

    const newTransferWithoutCloseMutation = useMutation(createTransfer, {
        onSuccess: _ => {
            queryClient.invalidateQueries(['transfers', 'pending']);
            queryClient.invalidateQueries(['transfers', 'month']);
            const senderId = watchSenderId;
            const comment = getValues('comment');
            receiversQuery.remove();
            reset();
            setValue('receiver_id', null);
            setValue('sender_id', senderId);
            setValue('comment', comment);
        },
    });

    /***
    Sets sender account record chip currency id to filter receiver account records with same currency.
    Sets send_account_record_id and receive_account_record_id if there is only one account record in filtered array.
    ***/
    React.useEffect(() => {
        senderAccRecordsQuery.data &&
            senderAccRecordsQuery.data.forEach((i: any) => {
                if (i.id === watchSenderAccountRecordId) {
                    setSendChipCurrency(i.chip_currency.id);
                    setSendTransferLimit(i.transfer_limit);
                }
            });

        if (watchSenderId && watchRoomId && senderAccRecordsQuery.data) {
            const accs = senderAccRecordsQuery.data?.filter((ar: any) => ar.is_active && ar.room_id === watchRoomId);
            if (accs.length === 1) {
                setValue('send_account_record_id', accs[0].id);
            }
        }

        if (watchReceiverId && watchRoomId && receiverAccRecordsQuery.data) {
            const accs = receiverAccRecordsQuery.data?.filter((ar: any) => ar.is_active && ar.room_id === watchRoomId && ar.chip_currency.id === sendChipCurrency);
            if (accs.length === 1) {
                setValue('receive_account_record_id', accs[0].id);
            }
        }

        // sum all player transfers in this room
        if (senderTransfersQuery.data && senderTransfersQuery.data.length > 0) {
            let amount = 0;
            senderTransfersQuery.data.forEach((i: any) => {
                amount += i.amount;
            });
            setNetworkSenderAmount(amount);
        }
    }, [watchSenderAccountRecordId, watchSenderId, watchRoomId, senderAccRecordsQuery.data, watchReceiverId, receiverAccRecordsQuery.data, senderTransfersQuery.data]);

    const onSubmit = handleSubmit(data => newTransferMutation.mutate(data));

    /***
    Removes endtries with duplicate rooms from array.
    ***/
    const uniqueRooms = (arr: any) => {
        let found = [];
        let ids = [];
        arr.forEach((i: any) => {
            if (!ids.includes(i.room_id) && i.is_active) {
                found.push(i);
                ids.push(i.room_id);
            }
        });
        return found;
    };

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Создать трансфер</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="sender_id"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                disablePortal
                                options={playersQuery.data}
                                getOptionLabel={(option: any) => option?.username}
                                renderInput={params => <TextField label="Отправитель трансфера" fullWidth required {...params} />}
                                onChange={(_event, data) => setValue('sender_id', data.id)}
                            />
                        )}
                    />
                    <Controller
                        name="room_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Рум" select fullWidth required disabled={!watchSenderId} {...field}>
                                {senderAccRecordsQuery.data
                                    ? uniqueRooms(senderAccRecordsQuery.data).map(ar => (
                                        <MenuItem key={ar.room_id} value={ar.room_id}>
                                            {ar.room_name}
                                        </MenuItem>
                                    ))
                                    : null}
                            </TextField>
                        )}
                    />
                    {senderTransfersQuery.data && senderTransfersQuery.data.length > 0 ? (
                        <span className="font-bold text-red-500">неотправленные трансферы в руме на сумму {networkSenderAmount}</span>
                    ) : null}
                    <Controller
                        name="send_account_record_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Аккаунт списания" select fullWidth required disabled={!watchRoomId} {...field}>
                                {senderAccRecordsQuery.data
                                    ?.filter(ar => ar.is_active && ar.room_id === watchRoomId)
                                    .map(ar => (
                                        <MenuItem key={ar.id} value={ar.id}>
                                            {ar.account_record_name}, {ar.chip_currency.name}{' '}
                                            {ar.ewallet_type_name ? `| ${ar.ewallet_type_name}, ${ar.ewallet_address}` : ''}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    />
                    {watchSenderAccountRecordId === null || watchSenderAccountRecordId === "" ? null : (
                        <p>
                            Лимит: {sendTransferLimit === null ? "стандартный" : sendTransferLimit}
                        </p>
                    )}
                    <Controller
                        name="receiver_id"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                disablePortal
                                options={receiversQuery.data
                                    ?.filter(pl => pl.id != watchSenderId)
                                    .sort((a, b) => {
                                        if (a.is_staff && !b.is_staff) {
                                            return -1;
                                        } else if (!a.is_staff && b.is_staff) {
                                            return 1;
                                        } else {
                                            return a.username > b.username;
                                        }
                                    })}
                                getOptionLabel={(option: any) => option?.username}
                                disabled={!watchSenderAccountRecordId}
                                renderInput={field => <TextField label="Получатель трансфера" fullWidth required {...field} />}
                                onChange={(_event, data) => setValue('receiver_id', data.id)}
                            />
                        )}
                    />
                    <Controller
                        name="receive_account_record_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Аккаунт зачисления" select fullWidth required disabled={!watchReceiverId} {...field}>
                                {receiverAccRecordsQuery.data
                                    ?.filter(ar => ar.is_active && ar.room_id === watchRoomId && ar.chip_currency.id === sendChipCurrency)
                                    .map(ar => (
                                        <MenuItem key={ar.id} value={ar.id}>
                                            {ar.account_record_name}, {ar.chip_currency.name}{' '}
                                            {ar.ewallet_type_name ? `| ${ar.ewallet_type_name}, ${ar.ewallet_address}` : ''}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    />
                    {recordLatestBalanceQuery.data === null ? null : (
                        <p>
                            Макс. сумма: ${recordLatestBalanceQuery.data}
                            <Button
                                variant="text"
                                size="small"
                                loading={recordLatestBalanceQuery.isLoading}
                                onClick={() => setValue('amount', recordLatestBalanceQuery.data)}
                            >
                                Макс
                            </Button>
                        </p>
                    )}
                    {
                        // TODO: WTF this check is retarded
                        recordExcessBalanceQuery.data === -1 ? null : (
                            <p>Излишки: {typeof recordExcessBalanceQuery.data === 'object' ? 'нет аби' : recordExcessBalanceQuery.data}</p>
                        )
                    }
                    <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => <TextField label="Сумма" fullWidth required disabled={!watchReceiverAccountRecordId} {...field} />}
                    />
                    <Controller
                        name="is_sent"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Пометить отправленным"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <Controller
                        name="is_received"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Пометить полученным"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Инструкции к переводу(будут отображаться у игрока вместо адреса перевода)"
                                rows={3}
                                multiline
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />

                    <div className="pt-4 text-right space-x-4">
                        <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            color="success"
                            loading={newTransferMutation.isLoading || newTransferWithoutCloseMutation.isLoading}
                        >
                            Создать
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            color="warning"
                            loading={newTransferMutation.isLoading || newTransferWithoutCloseMutation.isLoading}
                            onClick={() => {
                                newTransferWithoutCloseMutation.mutate(getValues());
                            }}
                        >
                            Создать и продолжить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewTransferDialog;
