import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import { useQuery } from 'react-query';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';

import { getPLayers } from 'api/players';

const Players: React.FC = () => {
    const navigate = useNavigate();

    const { data, isLoading } = useQuery('players', getPLayers);

    const goToPlayer = ({ data }: any) => {
        navigate(`/players/${data.id}`);
    };

    return (
        <div className="w-full relative shadow-md rounded overflow-hidden" style={{ height: 800 }}>
            <AgGridReact
                rowData={data?.table_data || []}
                defaultColDef={{
                    flex: 1,
                    sortable: true,
                    floatingFilter: true,
                    suppressMenu: true,
                    floatingFilterComponentParams: { suppressFilterButton: true }
                }}
                onRowClicked={goToPlayer}
            >
                <AgGridColumn headerName="Игрок" field="player" filter />
                <AgGridColumn headerName="Уровень" field="player_level" />
                <AgGridColumn headerName="ABI" field="player_abi" />
                <AgGridColumn headerName="Shark ID" field="shark_player_group" />
                <AgGridColumn headerName="Telegram" field="telegram_username" />
            </AgGridReact>
            {
                isLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                        <CircularProgress />
                    </div>
                ) : null
            }
        </div>
    );
};

export default Players;