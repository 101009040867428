import React from 'react';
import { Box, Card } from '@mui/material';

import { useQuery } from 'react-query';

import { getPlayerAccounts } from 'api/players'

type Props = {
    playerId: number;
}

const RoomAccounts: React.FC<Props> = ({ playerId }) => {
    const accsQuery = useQuery(['player-accounts', playerId], getPlayerAccounts, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    return (
        <Box className='flex flex-row justify-between gap-2 items-center p-4'>
            {accsQuery.data ? (
                <p className="text-lg text-gray-800">
                    {accsQuery.data
                        .filter(val => !val.room.virtual)
                        .sort((a, b) => a.room.name.localeCompare(b.room.name))
                        .map(val => (
                            <span
                                key={val.id}
                                className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 ${val.is_active ? 'bg-green-200 text-gray-600' : 'bg-gray-300 text-gray-600'}`} >
                                {`${val.room.name}: ${val.name}`}
                            </span>
                        ))
                    }
                </p>
            ) : (
                <p className="text-lg text-gray-400 italic">
                    No data available.
                </p>
            )
            }
        </Box >
    )
}

export default RoomAccounts;
