import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { getRisk } from 'api/risk';
import { Card, CircularProgress } from '@mui/material';
import RiskTable from './RiskTable';
import RiskFilters from './RiskFilters';

/**
 * Таблица рисков игроков.
 */
const Risk: FC = () => {
    // Фильтр по ревизии
    const [revision, setRevision] = useState();
    // Поиск игрока
    const [search, setSearch] = useState('');
    // Фильтр по кураторам
    const [curatorFilter, setCuratorFilter] = useState<any>(null);

    // Пагинация
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const {data: risk, isFetching} = useQuery(['player-risk', revision, search, curatorFilter, pageSize, page], getRisk, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    return (
       <>
        <RiskFilters
            setSearch={setSearch}
            search={search}
            curatorFilter={curatorFilter}
            setCuratorFilter={setCuratorFilter}
            revision={revision}
            setRevision={setRevision}
            
        />
        {isFetching && !risk && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
        {risk && 
            <Card>
                <RiskTable 
                    items={risk} 
                    pageSize={pageSize} 
                    setPageSize={setPageSize} 
                    page={page}
                    setPage={setPage}
                />
            </Card>}
       </>
    );
}

export default Risk;
