import { ChangeEvent, FC, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { createAbilityItem, getRoomsAbility } from 'api/ability';
import { getRooms } from 'api/rooms';
import { IconButton, DialogTitle, DialogContent, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;
}

/**
 * Диалоговое окно для создания сетки ABI.
 */
const AbilityDialog: FC<Props> = ({ ...props }) => {
    const queryClient = useQueryClient();
    const [selectedRooms, setSelectedRooms] = useState([]);

    const { handleSubmit } = useForm();

    const {data: rooms} = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: abilityRooms} = useQuery(['ability-rooms'], getRoomsAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const freeRooms = rooms.filter(room => abilityRooms.every(item => item.id !== room.id));

    const newItemMutation = useMutation(createAbilityItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('ability-rooms');

            props.close();
        },
    });

    /**
     * Сохранить значение чекбокса.
     */
    function selectRoom(event: ChangeEvent<HTMLInputElement>) {
        let filter = selectedRooms.slice();
        const id = filter.findIndex(data => data.id === event.target.value);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            return setSelectedRooms([{ id: event.target.value, name: event.target.name }]);
        }

        setSelectedRooms(filter);
    }

    /**
     * Проверка элемента на выбор.
     */
    function checkSelected(id: string) {
        const ids = selectedRooms.map(el => el.id);
        return ids.includes(String(id));
    }

    /**
     * Функция сохранения формы.
     */
     const onSubmit = handleSubmit(data => {
        newItemMutation.mutate(Number(selectedRooms[0].id))
    });

    return (
        <>
            <DialogTitle>
                Новая сетка ABI
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-2' onSubmit={onSubmit}>
                    <FormGroup
                        onChange={selectRoom}
                    >
                        {freeRooms?.map((item) => (
                            <FormControlLabel
                                className="pl-2 pr-2 m-0"
                                key={item.id}
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        name={item.network}
                                        checked={checkSelected(item.id)}
                                    />
                                }
                                label={item.network}
                                value={item.id}
                            />
                            
                        ))}
                    </FormGroup>
                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={newItemMutation.isLoading}
                        >
                            Добавить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default AbilityDialog;
