import { FC, useState } from 'react';

import {
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef/* , GridValueGetterParams */ } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
// import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { deleteExceptionItem } from 'api/gridexceptions';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу Серия турниров.
 */
const ExceptionsTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const [pageSize, setPageSize] = useState<number>(10);

    const { mutate, isLoading } = useMutation(deleteExceptionItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('gridexceptions-data');
        },
    });

    const columns: GridColDef[] = [
      { field: 'network_name', headerName: 'Рум', flex: 0.3, minWidth: 100},
      { field: 'value', headerName: 'Значение', flex: 0.3, minWidth: 100 },
      { field: 'is_regex', headerName: 'Regex', flex: 0.3, minWidth: 100 },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton onClick={() => mutate(params.row.id)} disabled={isLoading}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
        }
      }
    ];

  return (
    <Box className="h-auto overflow-auto">
      <DataGrid
          autoHeight={true}
          rows={items}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default ExceptionsTable;
