import React from 'react';
import * as yup from 'yup';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { getGeneralStats } from 'api/stats';
import { createPlayer } from 'api/players';
import { getCuratorsList } from 'api/curators';

const schema = yup.object().shape({
    username: yup.string().trim().matches(/^\d{4}_.+$/, 'pattern is 0000_username'),
    password: yup.string().required().min(8),
    email: yup.string().email().required(),
    player_level: yup.number().when('is_template', {
        is: false,
        then: yup.number().required(),
        otherwise: yup.number().nullable(true),
    }),
    telegram: yup.string().required().min(2),
    user_curator: yup.number().nullable(true),
    is_template: yup.boolean().default(false),
});

const NewPlayerDialog: React.FC<DialogProps> = (props) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const statsQuery = useQuery('stats', getGeneralStats, {
        initialData: { player_levels: [] },
    });

    const curatorsQuery = useQuery('curators', getCuratorsList, {
        initialData: [],
    });

    const newPlayerMutation = useMutation(createPlayer, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('players');
            props.onClose({}, 'backdropClick');
            reset();

            // navigate(`/players/${data}`);
        }
    });

    const { control, handleSubmit, formState: { errors }, reset, watch } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            username: '',
            password: '',
            email: '',
            player_level: null,
            telegram: '',
            user_curator: null,
            is_template: false,
        }
    });
    const isTemplate = watch('is_template');

    const onSubmit = handleSubmit((data) => newPlayerMutation.mutate(data));

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить игрока</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Имя Пользователя"
                                error={!!errors.username?.message}
                                helperText={errors.username?.message}
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Пароль"
                                error={!!errors.password?.message}
                                helperText={errors.password?.message}
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Gmail почта"
                                error={!!errors.email?.message}
                                helperText={errors.email?.message}
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="player_level"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Уровень"
                                error={!!errors.player_level?.message}
                                helperText={errors.player_level?.message}
                                select
                                fullWidth
                                required
                                disabled={isTemplate}
                                {...field}
                            >
                                {
                                    statsQuery.data?.player_levels.map(level => (
                                        <MenuItem key={level.id} value={level.id}>
                                            {level.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="telegram"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Телеграм"
                                error={!!errors.telegram?.message}
                                helperText={errors.telegram?.message}
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="user_curator"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Куратор"
                                error={!!errors.user_curator?.message}
                                helperText={errors.user_curator?.message}
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    curatorsQuery.data?.map(curator => (
                                        <MenuItem key={curator.id} value={curator.id}>
                                            {curator.curator_nickname}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="is_template"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Старт"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={newPlayerMutation.isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewPlayerDialog;
