import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, setFilter } from 'store/configureStore';
import moment from 'moment-timezone';
import { useMutation, useQuery } from 'react-query';
import { getScheduledCsv, getScheduledTournaments, getScheduledTournamentsPlayers } from 'api/schedulersettings';
import { Autocomplete, Button, Chip, CircularProgress, MenuItem, TextField } from '@mui/material';
import { TimePicker } from '@mui/lab';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import timezones from 'constants/timezones';
import MaxNumberFilter from './MaxNumberFilter';
import RoomLogo from './RoomLogo';
import AccuracyLogo from './AccuracyLogo';
import { getPLayersV2 } from 'api/players';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';

const weekDays = [
    { value: 'Monday', label: 'Понедельник' },
    { value: 'Tuesday', label: 'Вторник' },
    { value: 'Wednesday', label: 'Среда' },
    { value: 'Thursday', label: 'Четверг' },
    { value: 'Friday', label: 'Пятница' },
    { value: 'Saturday', label: 'Суббота' },
    { value: 'Sunday', label: 'Воскресенье' },
];

/**
 * Вкладка с расписатором.
 */
const SchedulerBase: FC = () => {
    const filters = useSelector((state: AppState) => state.tournaments);
    const dispatch = useDispatch();

    const [tournaments, setTournaments] = useState<any>([]);
    const [startTime, setStartTime] = useState<any>(moment.tz(filters.tz.value).subtract(30, 'minutes'));
    const [startDay, setStartDay] = useState<any>(weekDays[moment.tz(filters.tz.value).weekday()]);
    const [endTime, setEndTime] = useState<any>(moment.tz(filters.tz.value).add(12, 'hours'));
    const [endDay, setEndDay] = useState<any>(weekDays[moment.tz(filters.tz.value).add(12, 'hours').weekday()]);
    const [player, setPlayer] = useState({});

    const { data: roomsQuery, isLoading: isRoomLoading } = useQuery(['scheduler-rooms'], getScheduledTournamentsPlayers, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const { data: playersData, isLoading: isPlayersLoading } = useQuery(['scheduler-players'], getPLayersV2, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const mutation = useMutation(getScheduledTournaments, {
        onSuccess: (data) => {
            setTournaments(data);
        },
    });

    const DownloadCsv = useMutation(getScheduledCsv, {
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data])) 
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `scheduler.csv`)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    });

    useEffect(() => {
        if (playersData) {
            setPlayer(playersData[0])
        }
    }, [playersData])

    useEffect(() => {
        if (roomsQuery && roomsQuery.networks && !filters.rooms.length) dispatch(setFilter({ rooms: roomsQuery.networks }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomsQuery]);

    useEffect(() => { 
        if (playersData && player && tournaments && tournaments.length === 0) {
            handleSearchClick() 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player, tournaments, playersData])

    const customTimeSort = (a: string, b: string, { data: aData }, { data: bData }) => {
        if (aData.dayNum > bData.dayNum) return 1;
        if (aData.dayNum < bData.dayNum) return -1;
        if (aData.timestamp > bData.timestamp) return 1;
        if (aData.timestamp < bData.timestamp) return -1;

        return 0;
    };

    const toggleRoom = (room: string) => {
        const rooms = filters.rooms.slice();

        if (rooms.includes(room)) rooms.splice(rooms.indexOf(room), 1);
        else rooms.push(room);

        dispatch(setFilter({ rooms }));
    };

    const changeTimezone = (value: any) => {
        const m = moment.tz(value).subtract(30, 'minutes');

        setStartTime(m.clone());
        setStartDay(weekDays[m.weekday()]);
        setEndTime(m.clone().add(12, 'hours'));
        setEndDay(weekDays[m.clone().add(12, 'hours').weekday()]);

        dispatch(setFilter({ tz: { value, label: value } }));
    };

    const handleCopy = useCallback((text) => {
        navigator.clipboard.writeText(text);
    }, []);

    const nameRenderer = useCallback(({ data }) => {
        const handleCopyClick = () => {
            handleCopy(data.hash);
        };

        return (
            <span onClick={handleCopyClick} className="cursor-pointer">{data.name.toString()}</span>
        );
    }, [handleCopy]);

    const handleSearchClick = () => {
        if ((moment(startTime, 'HH:mm', true).isValid() && moment(endTime, 'HH:mm', true).isValid())) {
            mutation.mutate({ filters, startTime, startDay, endTime, endDay, player });
        }
    };

    return (
        <div className="flex-1 flex flex-col">
            <div className='flex flex-row justify-end mb-2'>
                <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    size="small"
                    sx={{width: "190px"}}
                    onClick={() => {
                        DownloadCsv.mutate({filters, startTime, startDay, endTime, endDay, player})
                    }}
                    disabled={mutation.isLoading || DownloadCsv.isLoading}
                >
                    Скачать таблицу CSV
                </Button>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-2 lg:mb-8 mb-4">
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Рум</p>
                    <div className="flex flex-wrap">
                        {!isRoomLoading && roomsQuery && roomsQuery.networks.map((val, index) => (
                            <div className="mr-2 mb-2" key={index}>
                                <Chip
                                    label={val}
                                    className={filters.rooms.find((item: any) => item === val) ? 'bg-blue-400' : 'bg-gray-300'}
                                    color={filters.rooms.find((item: any) => item === val) ? 'primary' : 'default'}
                                    onClick={() => toggleRoom(val)}
                                    clickable
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Тип</p>
                    <div className="flex w-2/5 space-x-2">
                        <Chip
                            label="Regular"
                            className={filters.typeFilterRegular ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterRegular ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterRegular: !filters.typeFilterRegular }))}
                            clickable
                        />
                        <Chip
                            label="Turbo"
                            className={filters.typeFilterTurbo ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterTurbo ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterTurbo: !filters.typeFilterTurbo }))}
                            clickable
                        />
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Баунти</p>
                    <div className="flex w-2/5 space-x-2">
                        <Chip
                            label="Freezeout"
                            className={filters.typeFilterFreezeout ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterFreezeout ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterFreezeout: !filters.typeFilterFreezeout }))}
                            clickable
                        />
                        <Chip
                            label="Knockout/Bounty"
                            className={filters.typeFilterBounty ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterBounty ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterBounty: !filters.typeFilterBounty }))}
                            clickable
                        />
                        <Chip
                            label="Rebuy"
                            className={filters.typeFilterRebuy ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterRebuy ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterRebuy: !filters.typeFilterRebuy }))}
                            clickable
                        />
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Рейтинг</p>
                    <div className="flex w-2/5 space-x-2">
                        <Chip
                            label="Лучшие"
                            className={filters.filterTop ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterTop ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterTop: !filters.filterTop }))}
                            clickable
                        />
                        <Chip
                            label="Хорошие"
                            className={filters.filterGood ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterGood ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterGood: !filters.filterGood }))}
                            clickable
                        />
                        <Chip
                            label="Средние"
                            className={filters.filterAverage ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterAverage ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterAverage: !filters.filterAverage }))}
                            clickable
                        />
                        <Chip
                            label="Плохие"
                            className={filters.filterBad ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterBad ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterBad: !filters.filterBad }))}
                            clickable
                        />
                        <Chip
                            label="Запрещенные"
                            className={filters.filterForbidden ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterForbidden ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterForbidden: !filters.filterForbidden }))}
                            clickable
                        />
                    </div>
                </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 items-start mb-2 w-full">
                <div className="w-full">
                    <Autocomplete
                        disablePortal
                        options={playersData ?? []}
                        value={player}
                        getOptionLabel={(option: any) => option.username ?? ''}
                        fullWidth
                        onChange={(event, newValue) => {
                            if (newValue && newValue.id) {
                                setPlayer(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Игрок" />}
                        clearIcon={false}
                        disabled={isPlayersLoading}
                    />
                </div>
                <div className="w-full">
                    <TimePicker
                        className="w-full mt-0"
                        label={`Время начала турнира (${filters.tz.label})`}
                        ampm={false}
                        value={startTime}
                        onChange={setStartTime}
                        renderInput={params => <TextField {...params} variant="outlined" fullWidth />}
                    />
                </div>
                <div className="w-full">
                    <TextField label="День" value={startDay.value} onChange={(e) => setStartDay(e.target)} select fullWidth >
                        {weekDays.map((data) => <MenuItem value={data.value} key={`startDay+${data.value}`}>{data.label}</MenuItem>)}
                    </TextField>
                </div>
                <div className="w-full">
                    <TimePicker
                        className="w-full my-0"
                        ampm={false}
                        label={`Время окончания турнира (${filters.tz.label})`}
                        value={endTime}
                        onChange={setEndTime}
                        renderInput={params => <TextField {...params} variant="outlined" fullWidth />}
                    />
                </div>
                <div className="w-full">
                    <TextField label="День" value={endDay.value} onChange={(e) => setEndDay(e.target)} select fullWidth >
                        {weekDays.map((data) => <MenuItem value={data.value} key={`endDay+${data.value}`}>{data.label}</MenuItem>)}
                    </TextField>
                </div>
                <div className="w-full">
                    <TextField label="Часовой пояс" value={filters.tz.value} onChange={tz => changeTimezone(tz.target.value)} select fullWidth >
                        {timezones.map((data) => <MenuItem value={data.value} key={data.label}>{data.label}</MenuItem>)}
                    </TextField>
                </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 items-start mb-2 w-full">
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Минимальный buy-in"
                        type="number"
                        value={filters.biStart ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ biStart: target.value }))}
                    />
                </div>
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Максимальный buy-in"
                        type="number"
                        value={filters.biEnd ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ biEnd: target.value }))}
                    />
                </div>
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Минимально входов"
                        type="number"
                        value={filters.minEntries ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ minEntries: target.value }))}
                    />
                </div>
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Максимально входов"
                        type="number"
                        value={filters.maxEntries ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ maxEntries: target.value }))}
                    />
                </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 items-start mb-2 w-full">
                <div className="flex flex-row w-full justify-end">
                    <Button variant="outlined" disabled={mutation.isLoading} size='large' startIcon={<SearchIcon />} onClick={handleSearchClick}>Поиск</Button>
                </div>
            </div>
            <div className="w-full relative shadow-md rounded overflow-hidden" style={{ height: 800 }}>
                {mutation.isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                        <CircularProgress />
                    </div>
                )}
                {!mutation.isLoading && (
                    <AgGridReact
                        rowData={tournaments?.items ?? []}
                        defaultColDef={{
                            flex: 1,
                            sortable: true,
                            floatingFilter: true,
                            suppressMenu: true,
                            floatingFilterComponentParams: { suppressFilterButton: true },
                        }}
                        rowClassRules={{
                            'bg-green-100': ({ data }) => data.score === 1,
                            'bg-green-200': ({ data }) => data.score === 2,
                            'bg-red-100': ({ data }) => data.score === -1,
                            'bg-gray-400': ({ data }) => data.score === -2,
                            'bg-yellow-100': ({ data }) => data.score === 0,
                        }}
                        frameworkComponents={{
                            MaxNumberFilter: MaxNumberFilter,
                        }}
                        components={{
                            logoRenderer: RoomLogo,
                            accuracyRenderer: AccuracyLogo,
                        }}
                        enableCellTextSelection
                    >
                        <AgGridColumn 
                            headerName="Рум" 
                            field="network" 
                            tooltipValueGetter={({ value }) => value} 
                            width={25} 
                            cellRenderer="logoRenderer" 
                            flex={0}
                            cellStyle={{ padding: 0 }}
                        />
                        <AgGridColumn 
                            headerName="Турнир" 
                            field="name" 
                            width={300} 
                            resizable 
                            flex={null} 
                            filter 
                            sortable={false} 
                            cellRendererFramework={nameRenderer}
                        />
                        <AgGridColumn headerName="День" field="day_number" resizable comparator={customTimeSort} valueGetter={(params) => {
                            return weekDays[params.data.day_number].label;
                        }} />
                        <AgGridColumn headerName="Старт" field="start_time" resizable sort="asc" comparator={customTimeSort} />
                        <AgGridColumn headerName="Длительность" field="duration" resizable />
                        <AgGridColumn headerName="БИ" field="buyin" resizable filter="agNumberColumnFilter" floatingFilterComponent="MaxNumberFilter" />
                        <AgGridColumn headerName="БИ USD" field="usd_buyin" resizable filter="agNumberColumnFilter" floatingFilterComponent="MaxNumberFilter" />
                        <AgGridColumn headerName="Входы" field="total_entries" resizable />
                        <AgGridColumn headerName="ROI" field="roi" resizable sortable={true} valueGetter={(params) => {
                            return Math.round(params.data.roi);
                        }} />
                        <AgGridColumn headerName="$/trn" field="profit_per_tournament" resizable sortable={true} valueGetter={(params) => {
                            return params.data.profit_per_tournament.toFixed(2);
                        }} />
                    </AgGridReact>
                )}
            </div>
        </div>
    );
}

export default SchedulerBase;
