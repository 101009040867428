import axios from 'helpers/axios';


export const getPlayerLevels = async () => {
    const { data } = await axios.get('/api/adminapi/playerlevels/');

    return data;
};


