import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';

import { getManualResults, deletemanualResults } from 'api/manualresults';
import DeleteDialog from 'components/DeleteDialog';

interface Props {
    playerId: number;
    roomId: number | null;
}

const ManualResultsTable: React.FC<Props> = (props: Props) => {
    const queryClient = useQueryClient();

    const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false);
    const [resultId, setResultId] = React.useState<number | null>(null);
    const [pageSize, setPageSize] = React.useState<number>(5);

    const manualResultsQuery = useQuery(['player-manual-results', props.playerId, props.roomId], getManualResults, {
        initialData: [],
    });

    const deleteMutation = useMutation(deletemanualResults, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-manual-results', props.playerId], { exact: false });
            setDeleteDialog(false);
        },
    });

    const columns: GridColDef[] = [
        {
            field: 'room_name',
            headerName: 'Рум',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'abi',
            headerName: 'ABI',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'roi',
            headerName: 'ROI',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'afs',
            headerName: 'AFS',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'reentry_perc',
            headerName: 'Reentry %',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'period_start',
            headerName: 'Начало периода',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.period_start).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'period_end',
            headerName: 'Конец периода',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.period_end).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'created_datetime',
            headerName: 'Дата добавления',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.created_datetime).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'untracked_tournaments',
            headerName: 'Турниров',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: params => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setResultId(params.row.id);
                                setDeleteDialog(true);
                            }}
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <>
            <Box
                className="h-auto overflow-auto"
                sx={{
                    '& .active': {
                        backgroundColor: '#fcecd5',
                        color: '#1a3e72',
                    },
                }}
            >
                <DataGrid
                    autoHeight={true}
                    rows={manualResultsQuery.data}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pagination
                    disableSelectionOnClick
                    disableColumnMenu
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Данные отсутствуют
                            </Stack>
                        ),
                    }}
                />

                <DeleteDialog
                    close={() => setDeleteDialog(false)}
                    open={deleteDialog}
                    loading={deleteMutation.isLoading}
                    handleAgreeAction={() => deleteMutation.mutate(resultId)}
                />
            </Box>
        </>
    )
}

export default ManualResultsTable;
