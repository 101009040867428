import axios from 'helpers/axios';

/**
 * Список исключений
 */
export const getExceptions = async () => {
    const { data } = await axios.get('/api/adminapi/grid/exceptions/');

    return data;
};


/**
 * Удалить исключение
 */
export const deleteExceptionItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/exceptions/${id}/`);

    return data;
};


/**
 * Создать событие исключения
 */
export const createExceptionItem = async (exceptions) => {
    const { data } = await axios.post(`/api/adminapi/grid/exceptions/`, exceptions);

    return data;
};
