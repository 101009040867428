import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { patchUntrackedTournaments } from 'api/players';

const schema = yup.object().shape({
    count: yup.number().required().min(1),
});

interface IProps extends DialogProps {
    tournamentId: number;
    playerId: number;
}

const EditUntrackedTournamentDialog: React.FC<IProps> = (props: IProps) => {
    const { tournamentId } = props;

    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            count: 0,
        },
    });

    const { mutate, isLoading } = useMutation(patchUntrackedTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-untracked-tournaments', props.playerId], { exact: false });
            queryClient.invalidateQueries(['player-untracked-tournaments-count', props.playerId], { exact: false });

            props.onClose({}, 'backdropClick');
            reset();
        },
    });

    const onSubmit = handleSubmit(data => mutate({ ...data, tournamentId }));

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Редактирование #{tournamentId}</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller name="count" control={control} render={({ field }) => <TextField label="Кол-во турниров" fullWidth required {...field} />} />
                    <div className="pt-4 text-right">
                        <Button type="submit" variant="contained" size="large" loading={isLoading}>
                            Изменить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditUntrackedTournamentDialog;
