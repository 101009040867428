import axios from 'helpers/axios';

export const createBundle = async (body) => {
    // const { data } = await axios.post('/api/adminapi/bundles/', body);
    const { data } = await axios.post('/api/adminapi/bundlesv2/', body);

    return data;
};

export const updateBundle = async ({ id, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/bundles/${id}`, body);

    return data;
};

export const makeBundlePayout = async ({ bundleId, ...body }) => {
    // const { data } = await axios.post('/api/adminapi/bundles/', body);
    const { data } = await axios.post(`/api/adminapi/bundles/${bundleId}/payout/`, body);

    return data;
};


export const getLastPlayerBundle = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${id}/lastbundle/`);

    return data;
};


export const getBundlePayoutInfo = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/bundles/${id}/payout/`);

    return data;
};
