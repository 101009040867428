import { FC, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { IconButton, DialogTitle, DialogContent, Select, MenuItem, InputLabel, TextField, Box } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { createRuleMarks, getStatRules } from 'api/tournamentsrules';
import { getMarks } from 'api/marks';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * ID маркера
     */
    id: string;
}

/**
 * Диалоговое окно для создания правила в метке.
 */
const TournamentsRulesNewItemDialog: FC<Props> = ({ close, id }) => {
    const [rules, setRules] = useState([{ stat_id: '', lower_bound: '', upper_bound: '' }]);

    const queryClient = useQueryClient();

    const { data: stats, isFetching } = useQuery(['tournaments-rulesets-stats'], getStatRules, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    const { data: marks, isFetching: markFetching } = useQuery(['tournaments-marks-data'], getMarks, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    const { mutate } = useMutation(createRuleMarks, {
        onSuccess: () => {
            queryClient.invalidateQueries('tournaments-rulesets-data');
            close();
        },
    });

    const { control, handleSubmit } = useForm();

    const handleAddField = () => {
        setRules([...rules, { stat_id: '', lower_bound: '', upper_bound: '' }]);
    };

    const onSubmit = (data) => {
        const formattedRules = rules.map((rule, index) => ({
            stat_id: data[`stat_id${index}`],
            lower_bound: data[`lower_bound${index}`],
            upper_bound: data[`upper_bound${index}`],
        }));

        const body = {
            label_id: data.mark,
            rules: formattedRules,
        }

        const updatedRules = body.rules.filter(rule => {
            return Object.values(rule).every(value => value !== "");
        });

        body.rules = updatedRules;

        if (!body.label_id || body.rules.length === 0) {
            return;
        }

        mutate({ id, ...body })
    };

    return (
        <>
            <DialogTitle>
                Новое правило для метки
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {!isFetching && !markFetching && (
                <DialogContent>
                    <form className='flex flex-col gap-6'>
                        <Controller
                            control={control}
                            name={`mark`}
                            defaultValue=''
                            render={({ field }) => (
                                <Box className='flex flex-col gap-2'>
                                    <InputLabel id="select-label">Метка</InputLabel>
                                    <Select labelId="select-label" {...field} size="small" className='mb-4'>
                                        {marks.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            )}
                        />
                        {rules.map((rule, index) => (
                            <Box key={index} className='flex flex-1 flex-row gap-2 items-center'>
                                <Controller
                                    control={control}
                                    name={`stat_id${index}`}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <Box style={{ flex: 1, marginTop: '-47px' }}>
                                            <InputLabel id="select-label">Стата</InputLabel>
                                            <Select labelId="select-label" {...field} size="small" fullWidth>
                                                {stats.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    )}
                                />
                                <Controller
                                    name={`lower_bound${index}`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Нижнее значение" type="number" helperText="* Включительно" size="small" FormHelperTextProps={{ style: { color: 'red', marginLeft: 0 } }} />}
                                />
                                <Controller
                                    name={`upper_bound${index}`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        validate: (value) => !isNaN(value) || value.toLowerCase() === 'inf' || 'Enter a valid number or "inf"'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            label="Верхнее значение"
                                            type="text"
                                            helperText={fieldState.error ? fieldState.error.message : "* Не включительно"}
                                            size="small"
                                            FormHelperTextProps={{ style: { color: 'red', marginLeft: 0 } }}
                                        />
                                    )}
                                />
                            </Box>
                        ))}
                        <Box>
                            <Button onClick={handleAddField} variant="contained" color="primary" size="small" fullWidth>
                                Добавить значение
                            </Button>
                            <div className='pt-4'>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    type='button'
                                    size="small"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </Box>
                    </form>
                </DialogContent>
            )}
        </>
    );
};

export default TournamentsRulesNewItemDialog;
