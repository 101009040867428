import axios from 'helpers/axios';
import moment from 'moment';

/**
 * Запросы сетки байинов
 */
export const getSchedulerBuyin = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/abilitylimits/');

    return data;
};

export const updateSchedulerBuyin = async ({ ...body }) => {
    const { data } = await axios.put('/api/adminapi/scheduling/abilitylimits/', body);

    return data;
};

/**
 * Запросы коэф абилити
 */
export const getSchedulerCoefficient = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/abilitymodifiers/');

    return data;
};

export const createSchedulerCoefficientItem = async (coefficient) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/abilitymodifiers/`, coefficient);

    return data;
};

export const deleteSchedulerCoefficientItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/abilitymodifiers/${id}/`);

    return data;
};

/**
 * Запросы для сетки ABI
 */

export const getSchedulerRoomsAbility = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/abilitygrids/networks/');

    return data;
};

export const createSchedulerAbilityItem = async (network) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/abilitygrids/`, network);

    return data;
}

export const getSchedulerAbility = async ({ queryKey }) => {
    const [, selectRoom, gridType] = queryKey;

    let params = new URLSearchParams();
    params.append('grid_type', gridType);
    params.append('network', selectRoom);


    const { data } = await axios.get(`/api/adminapi/scheduling/abilitygrids/`, { params: params });

    return data;
};

export const deleteSchedulerAbilityItem = async (network) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/abilitygrids/networks/${network}/`);

    return data;
}

export const updateSchedulerAbility = async ({ id, body }) => {
    const { data } = await axios.put(`/api/adminapi/scheduling/abilitygrids/${id}/`, body);

    return data;
};

export const importSchedulerAbility = async ({ body }) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/abilitygrids/clone/`, body);

    return data;
};

/**
 * Запросы для турнирных исключений
 */

export const createSchedulerExceptionItem = async (exceptions) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/ignoredtournaments/`, exceptions);

    return data;
};

export const deleteSchedulerExceptionItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/ignoredtournaments/${id}/`);

    return data;
};

export const getSchedulerExceptions = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/ignoredtournaments/');

    return data;
};

/**
 * Запросы для серий
 */

export const createSchedulerSeriesItem = async (series) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/series/`, series);

    return data;
};

export const getSchedulerSeries = async ({ queryKey }) => {
    const [, network, end_at_from] = queryKey;

    var params = new URLSearchParams();

    if (network.length > 0) {
        params.append("network", network.map(item => item.name));
    }

    if (end_at_from) {
        params.append("end_at_from", moment(end_at_from).format());
    }

    const request = {
        params: params
    };

    const { data } = await axios.get('/api/adminapi/scheduling/series/', request);

    return data;
};

export const deleteSchedulerSeriesItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/series/${id}/`);

    return data;
};

/**
 * Запросы ROI коррекции
 */
export const getSchedulerRoiCorrection = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/roicorrections/');

    return data;
};

export const createSchedulerRoiCorrectionItem = async (correction) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/roicorrections/`, correction);

    return data;
};

export const deleteSchedulerRoiCorrectionItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/roicorrections/${id}/`);

    return data;
};

/**
 * Запросы для сетки ROI
 */

export const getSchedulerRoomsRoiGrid = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/roigrids/networks/');

    return data;
};

export const createSchedulerRoiGridItem = async (network) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/roigrids/`, network);

    return data;
}

export const getSchedulerRoiGrid = async ({ queryKey }) => {
    const [, selectRoom] = queryKey;

    let params = new URLSearchParams();
    params.append('network', selectRoom);

    const { data } = await axios.get(`/api/adminapi/scheduling/roigrids/`, { params: params });

    return data;
};

export const deleteSchedulerRoiGridItem = async (network) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/roigrids/networks/${network}/`);

    return data;
}

export const updateSchedulerRoiGrid = async ({ id, body }) => {
    const { data } = await axios.put(`/api/adminapi/scheduling/roigrids/${id}/`, body);

    return data;
};

export const importSchedulerRoiGrid = async ({ body }) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/roigrids/clone/`, body);

    return data;
};

export const updateSchedulerRoiGridLimits = async ({ id, body }) => {
    const { data } = await axios.put(`/api/adminapi/scheduling/roigrids/limits/${id}/`, body);

    return data;
};

export const getSchedulerRoiGridlimits = async ({ queryKey }) => {
    const [, selectRoom] = queryKey;

    let params = new URLSearchParams();
    params.append('network', selectRoom);

    const { data } = await axios.get(`/api/adminapi/scheduling/roigrids/limits`, { params: params });

    return data;
};

/**
 * Запросы для Типов ROI
 */

export const getSchedulerRoomsRoiTypeLimits = async () => {
    const { data } = await axios.get('/api/adminapi/scheduling/roitypelimits/networks/');

    return data;
};

export const createSchedulerRoiTypeLimitsItem = async (network: any) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/roitypelimits/`, network);

    return data;
}

export const getSchedulerRoiTypeLimits = async ({ queryKey }) => {
    const [, selectRoom] = queryKey;

    let params = new URLSearchParams();
    params.append('network', selectRoom);

    const { data } = await axios.get(`/api/adminapi/scheduling/roitypelimits/`, { params: params });

    return data;
};

export const deleteSchedulerRoiTypeLimitsItem = async (network: any) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/roitypelimits/networks/${network}/`);

    return data;
}

export const updateSchedulerRoiTypeLimits = async ({ id, body }) => {
    const { data } = await axios.put(`/api/adminapi/scheduling/roitypelimits/${id}/`, body);

    return data;
};

export const importSchedulerRoiTypeLimits = async ({ body }) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/roitypelimits/clone/`, body);

    return data;
};

/**
 * Запросы расписаторов вкладка Турниры
 */
export const getSchedulerTournaments = async ({ queryKey }) => {
    const [, perPage, page, filters, search, curatorEstimate, customLevels, current, hidden, rangeBuyin, sortOptions] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('networkName', String(value.network)));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    if (search) {
        params.append('name', String(search));
    }

    if (curatorEstimate) {
        params.append('filter_scorings', 'true');
    }

    if (customLevels) {
        params.append('filter_levels', 'true');
    }

    if (current) {
        params.append('filter', 'current');
    }

    if (hidden) {
        params.append('filter_hidden', 'true');
    }

    if (rangeBuyin?.min_buyin) {
        params.append('minBuyin', rangeBuyin?.min_buyin);
    }

    if (rangeBuyin?.max_buyin) {
        params.append('maxBuyin', rangeBuyin?.max_buyin);
    }

    if (sortOptions?.sortModel?.length > 0) {
        params.append('orderBy', `${sortOptions?.sortModel[0].field}`);
        params.append('sort', `${sortOptions?.sortModel[0].sort.toUpperCase()}`);
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/tournamentsmanagement/tournamentgroups/', request);

    return data;
};

/**
 * Запросы расписаторов вкладка Results
 */
export const getPlayerTournamentsRoi = async ({ queryKey }) => {
    const [, perPage, page, filters, search, sortOptions] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('network', String(value.network)));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    if (search) {
        params.append('hash', String(search));
    }

    if (sortOptions?.sortModel?.length > 0) {
        params.append('orderBy', `${sortOptions?.sortModel[0].field}`);
        params.append('sort', `${sortOptions?.sortModel[0].sort.toUpperCase()}`);
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/tournamentsmanagement/playertournaments/roi/', request);

    return data;
};

export const getScheduledTournamentsGroup = async ({ queryKey }) => {
    const [, perPage, page, filters, id] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters) {
        params.append('day', String(filters.id));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/tournamentsmanagement/tournamentgroups/${id}/scheduledtournaments/`, request);

    return data;
};

export const getScheduledHiddenTournaments = async ({ queryKey }) => {
    const [, perPage, page, hash] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    params.append('tournament_group_hash', hash)

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/scheduling/hiddengroups/`, request);

    return data;
};

export const newSchedulediddenTournament = async ({ ...body }) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/hiddengroups/`, body);

    return data;
};

export const deleteScheduledHiddenTournament = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/hiddengroups/${id}/`);

    return data;
};

export const getScheduledEstimateTournaments = async ({ queryKey }) => {
    const [, hash] = queryKey;

    var params = new URLSearchParams();
    params.append('tournament_group_hash', hash)
    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/scheduling/customscorings/`, request);

    return data;
};

export const newScheduledEstimateTournaments = async ({ ...body }) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/customscorings/`, body);

    return data;
};

export const deleteScheduledEstimateTournaments = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/customscorings/${id}/`);

    return data;
};

export const getScheduledLevelTournaments = async ({ queryKey }) => {
    const [, hash] = queryKey;

    var params = new URLSearchParams();
    params.append('tournament_group_hash', hash)
    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/scheduling/customlevels/`, request);

    return data;
};

export const newScheduledLevelTournaments = async ({ ...body }) => {
    const { data } = await axios.post(`/api/adminapi/scheduling/customlevels/`, body);

    return data;
};

export const deleteScheduledLevelTournaments = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/customlevels/${id}/`);

    return data;
};

/**
 * Custom tournaments
 */

export const getCustomTournaments = async ({ queryKey }) => {
    const [, perPage, page, filters, search, showOld] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('networkName', String(value.network)));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    if (search) {
        params.append('name', String(search));
    }

    if (showOld) {
        params.append('show', 'all');
    } else {
        params.append('show', 'active');
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/scheduling/customtournaments/', request);

    return data;
};

export const createCustomTournament = async body => {
    const { data } = await axios.post('/api/adminapi/scheduling/customtournaments/', body);

    return data;
};

export const editCustomTournament = async ({ id, body }) => {
    const { data } = await axios.put(`/api/adminapi/scheduling/customtournaments/${id}/`, body);

    return data;
};

export const deleteCustomTournament = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/scheduling/customtournaments/${id}/`);

    return data;
};

export const createCustomTournamentsFromCsv = async body => {
    const { data } = await axios.post('/api/adminapi/scheduling/customtournaments/csvform/', body);

    return data;
};

/**
 * Запросы расписатора
 */

export const getScheduledTournaments = async ({ filters, startTime, startDay, endTime, endDay, player }) => {
    if (!player || !player.id) {
        return null;
    }

    const weekDays = [
        { value: 'Monday', label: 'Понедельник' },
        { value: 'Tuesday', label: 'Вторник' },
        { value: 'Wednesday', label: 'Среда' },
        { value: 'Thursday', label: 'Четверг' },
        { value: 'Friday', label: 'Пятница' },
        { value: 'Saturday', label: 'Суббота' },
        { value: 'Sunday', label: 'Воскресенье' },
    ];

    var params = new URLSearchParams();

    if (filters.rooms) {
        filters.rooms.map((item) => params.append('networks', item))
    }

    const startDayNum = weekDays.findIndex(day => day.value === startDay.value)
    const endDayNum = weekDays.findIndex(day => day.value === endDay.value)

    params.append('user_id', player.id)
    params.append('day_start', String(startDayNum))
    params.append('day_end', String(endDayNum))
    params.append('time_start', startTime.format('HH:mm:ss'))
    params.append('time_end', endTime.format('HH:mm:ss'))
    params.append('timezone', filters.tz.value)

    if (filters.biStart) {
        params.append('buyin_gte', filters.biStart)
    }
    if (filters.biEnd) {
        params.append('buyin_lte', filters.biEnd)
    }
    if (filters.minEntries) {
        params.append('entries_gte', filters.minEntries)
    }
    if (filters.maxEntries) {
        params.append('entries_lte', filters.maxEntries)
    }
    if (filters.typeFilterRegular) {
        params.append('show_regular', filters.typeFilterRegular)
    }
    if (filters.typeFilterTurbo) {
        params.append('show_turbo', filters.typeFilterTurbo)
    }
    if (filters.typeFilterFreezeout) {
        params.append('show_freezeout', filters.typeFilterFreezeout)
    }
    if (filters.typeFilterBounty) {
        params.append('show_bounty', filters.typeFilterBounty)
    }
    if (filters.typeFilterRebuy) {
        params.append('show_rebuy', filters.typeFilterRebuy)
    }
    if (filters.filterTop) {
        params.append('show_top', filters.filterTop)
    }
    if (filters.filterGood) {
        params.append('show_good', filters.filterGood)
    }
    if (filters.filterAverage) {
        params.append('show_avg', filters.filterAverage)
    }
    if (filters.filterBad) {
        params.append('show_bad', filters.filterBad)
    }
    if (filters.filterForbidden) {
        params.append('show_forbidden', filters.filterForbidden)
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/scheduling/scheduler/latest/', request);

    /*
    * Creates an array of days ordered from first to last
    */
    function getDaysArray(start, end) {
        let days = [];
        let day = start;

        while (true) {
            days.push(day);
            if (day === end) break;
            day = (day + 1) % 7;
        }

        return days;
    }
    const daysArray = getDaysArray(startDayNum, endDayNum)

    let tournaments = {
        items: data.entries.map(val => {
            const duration = moment.duration(val.duration, 'seconds');

            return {
                ...val,
                dayNum: daysArray.indexOf(val.day_number),
                timestamp: +moment(`${val.day_number} ${val.start_time}`, 'dddd HH:mm:ss', 'en'),
                type: val.tags,
                start_time: moment(`${val.start_time}`, 'HH:mm:ss').format('HH:mm'),
                duration: `${duration.get('hours')}ч. ${duration.get('minutes')}м.`,
            };
        })
    }

    return tournaments;
};

export const getScheduledTournamentsPlayers = async body => {
    const { data } = await axios.get('/api/adminapi/scheduling/scheduler/networks/', body);

    return data;
};

export const getScheduledCsv = async ({ filters, startTime, startDay, endTime, endDay, player }) => {
    if (!player || !player.id) {
        return null;
    }

    const weekDays = [
        { value: 'Monday', label: 'Понедельник' },
        { value: 'Tuesday', label: 'Вторник' },
        { value: 'Wednesday', label: 'Среда' },
        { value: 'Thursday', label: 'Четверг' },
        { value: 'Friday', label: 'Пятница' },
        { value: 'Saturday', label: 'Суббота' },
        { value: 'Sunday', label: 'Воскресенье' },
    ];

    var params = new URLSearchParams();

    if (filters.rooms) {
        filters.rooms.map((item) => params.append('networks', item))
    }

    params.append('user_id', player.id)
    params.append('day_start', String(weekDays.findIndex(day => day.value === startDay.value)))
    params.append('day_end', String(weekDays.findIndex(day => day.value === endDay.value)))
    params.append('time_start', startTime.format('HH:mm:ss'))
    params.append('time_end', endTime.format('HH:mm:ss'))
    params.append('timezone', filters.tz.value)

    if (filters.biStart) {
        params.append('buyin_gte', filters.biStart)
    }
    if (filters.biEnd) {
        params.append('buyin_lte', filters.biEnd)
    }
    if (filters.minEntries) {
        params.append('entries_gte', filters.minEntries)
    }
    if (filters.maxEntries) {
        params.append('entries_lte', filters.maxEntries)
    }
    if (filters.typeFilterRegular) {
        params.append('show_regular', filters.typeFilterRegular)
    }
    if (filters.typeFilterTurbo) {
        params.append('show_turbo', filters.typeFilterTurbo)
    }
    if (filters.typeFilterFreezeout) {
        params.append('show_freezeout', filters.typeFilterFreezeout)
    }
    if (filters.typeFilterBounty) {
        params.append('show_bounty', filters.typeFilterBounty)
    }
    if (filters.typeFilterRebuy) {
        params.append('show_rebuy', filters.typeFilterRebuy)
    }
    if (filters.filterTop) {
        params.append('show_top', filters.filterTop)
    }
    if (filters.filterGood) {
        params.append('show_good', filters.filterGood)
    }
    if (filters.filterAverage) {
        params.append('show_avg', filters.filterAverage)
    }
    if (filters.filterBad) {
        params.append('show_bad', filters.filterBad)
    }
    if (filters.filterForbidden) {
        params.append('show_forbidden', filters.filterForbidden)
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/scheduling/scheduler/latest/csv/`, request);

    return data;
};


export const getGroupStatsReport = async ({ queryKey }) => {
    const [, tournament_group_hash, day_number] = queryKey;

    var params = new URLSearchParams();

    params.append('tournament_group_hash', tournament_group_hash);
    params.append('day_number', day_number);

    const request = {
        params: params,
    };

    const { data } = await axios.get(`/api/adminapi/scheduling/reports/groupstats/`, request);

    return data;
};
