import { FC } from 'react';

import { Box, Breadcrumbs, Card, Link, Typography } from '@mui/material';

import EstimateTable from './EstimateTable';
import CustomLevelsTable from './CustomLevelsTable';

type Props = {
    /**
     * Имя турнира.
     */
    name: string;

    /**
     * Hash турнира.
     */
    hash: string;

    /**
     * Закрытие вкладки с ручной оценкой турниров.
     */
    close: () => void;
}

/**
 * Вкладка с ручной оценкой турниров в расписаторе.
 */
const Estimate: FC<Props> = ({ name, hash, close }) => {
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className='mb-4'>
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => close()}
                    className='cursor-pointer'
                >
                    Список турниров
                </Link>
                <Typography color="text.primary">{name}</Typography>
            </Breadcrumbs>
            <Box className='flex flex-col gap-5'>
                <Card><EstimateTable hash={hash} /></Card>
                <Card><CustomLevelsTable hash={hash} /></Card>
            </Box>
        </>
    );
}

export default Estimate;
