import React from 'react';
import * as yup from 'yup';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';

import { useMutation, useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getPLayers } from 'api/players';
import { getCuratorGroups, getCuratorsList } from 'api/curators';
import { sendMessage } from 'api/messenger';
import { getVideoAccessLevels } from 'api/videogallery';

const schema = yup.object().shape({
    message: yup.string().required().trim().min(10),
    players: yup.array(),
    levels: yup.array(),
});

const NewMessageDialog: React.FC<DialogProps> = (props) => {
    const [recipient, setRecipient] = React.useState('everyone');

    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            curator_group: '',
            levels: [],
            players: [],
            message: '',
        }
    });

    const messageMutation = useMutation(sendMessage, {
        onSuccess: () => {
            closeDialog();
        }
    });

    const curatorsQuery = useQuery('curators', getCuratorsList, {
        initialData: [],
    });
    const videoAccessLevelsQuery = useQuery('', getVideoAccessLevels, {initialData: []});
    const playersQuery = useQuery('players', getPLayers, { initialData: { players: [] } });

    React.useEffect(() => {
        setValue('curator_group', '');
        setValue('levels', []);
        setValue('players', []);
    }, [recipient, setValue]);

    const onSubmit = handleSubmit((data) => messageMutation.mutate({recipient, ...data as any}));

    const closeDialog = () => {
        props.onClose(null, "escapeKeyDown");
    };

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Отправить Сообщение</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <FormControl>
                        <FormLabel>Кому</FormLabel>
                        <RadioGroup row value={recipient} onChange={(e) => setRecipient(e.target.value)}>
                            <FormControlLabel value="everyone" control={<Radio />} label="Всем" />
                            <FormControlLabel value="players" control={<Radio />} label="Игрокам" />
                            <FormControlLabel value="curator_group" control={<Radio />} label="Группе" />
                            <FormControlLabel value="levels" control={<Radio />} label="Уровням" />
                        </RadioGroup>
                    </FormControl>
                    <div className="w-full" hidden={recipient !== 'curator_group'}>
                        <Controller
                            control={control}
                            name="curator_group"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Куратор"
                                    select
                                    fullWidth
                                    required
                                >
                                    {
                                        curatorsQuery.data.map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.curator_nickname}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            )}
                        />
                    </div>
                    <div className="w-full" hidden={recipient !== 'levels'}>
                        <Controller
                            control={control}
                            name="levels"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Группа"
                                    error={!!errors.curator_group?.message}
                                    helperText={errors.curator_group?.message}
                                    select
                                    fullWidth
                                    required
                                    SelectProps={{ multiple: true }}
                                >
                                    {
                                        videoAccessLevelsQuery.data.map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            )}
                        />
                    </div>
                    <div className="w-full" hidden={recipient !== 'players'}>
                        <Controller
                            control={control}
                            name="players"
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    multiple
                                    autoHighlight
                                    onChange={(_, data) => field.onChange(data)}
                                    options={playersQuery.data.players}
                                    getOptionLabel={(option: any) => option.username}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Игроки"
                                            placeholder="Имя..."
                                            variant="outlined"
                                            required
                                        />
                                    )}
                                />
                            )}
                        />
                    </div>
                    <Controller
                        name="message"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Сообщение"
                                rows={3}
                                error={!!errors.message?.message}
                                helperText={errors.message?.message}
                                multiline
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={messageMutation.isLoading}
                        >Отправить</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewMessageDialog;
