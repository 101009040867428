import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { getPlayerAccountRecords } from 'api/players';
import { updateTransferInfo } from 'api/transfers';
import { getRoomUsers } from 'api/pokerrooms';

interface EditSenderPropsInterface extends DialogProps {
    transferId: string;
    roomId: number;
    chipCurrencyId: number;
}

const EditSenderDialog: React.FC<EditSenderPropsInterface> = (props) => {
    const queryClient = useQueryClient();

    const { control, watch, setValue, handleSubmit, reset } = useForm({
        defaultValues: {
            receiver_id: null,
            receive_account_record: null,
        }
    });
    const watchSenderId = watch('receiver_id', null);

    const playersQuery = useQuery(['room-players', props.roomId], getRoomUsers, {
        enabled: !!props.roomId,
        initialData: []
    })

    const senderAccRecordsQuery = useQuery(['player-accountrecords', watchSenderId, true, null], getPlayerAccountRecords, {
        enabled: !!watchSenderId
    });

    const updateTransferMutation = useMutation(updateTransferInfo, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['transfer', props.transferId]);
            props.onClose({}, 'backdropClick');
            reset();
        }
    });

    // /***
    // Sets sender account record chip currency id to filter receiver account records with same currency.
    // ***/
    // React.useEffect(() => {
    //     senderAccRecordsQuery.data && senderAccRecordsQuery.data.forEach(i => {
    //         if (i.id === watchSenderAccountRecordId) {
    //             setSendChipCurrency(i.chip_currency.id);
    //         }
    //     })
    //
    // }, [watchSenderAccountRecordId]);

    const onSubmit = handleSubmit((data) => updateTransferMutation.mutate({ transferId: props.transferId, ...data }));

    // /***
    // Removes endtries with duplicate rooms from array.
    // ***/
    // const uniqueRooms = (arr) => {
    //     let found = [];
    //     let ids = [];
    //     arr.forEach(i => {
    //         if(!ids.includes(i.room_id) && i.is_active) {
    //             found.push(i);
    //             ids.push(i.room_id);
    //         }
    //     })
    //     return found;
    // };

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Изменить получателя</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="receiver_id"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                disablePortal
                                options={playersQuery.data}
                                getOptionLabel={(option: any) => option?.username}
                                renderInput={(params) =>
                                    <TextField
                                        label="Получатель трансфера"
                                        fullWidth
                                        required
                                        {...params}
                                    />
                                }
                                onChange={(_event, data) => setValue('receiver_id', data.id)}
                            />
                        )}
                    />
                    <Controller
                        name="receive_account_record"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Аккаунт зачисления"
                                select
                                fullWidth
                                required
                                disabled={!watchSenderId}
                                {...field}
                            >
                                {
                                    senderAccRecordsQuery.data?.filter(ar => ar.is_active && ar.room_id === props.roomId && ar.chip_currency.id === props.chipCurrencyId).map(ar => (
                                        <MenuItem key={ar.id} value={ar.id}>
                                            {ar.account_record_name}, {ar.chip_currency.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />

                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={updateTransferMutation.isLoading}
                        >Сохранить</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditSenderDialog;
