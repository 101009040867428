import React from 'react';

import { useQuery } from 'react-query';
import { Box, Card } from '@mui/material';

import MessagesInfoTable from './components/MessagesInfoTable';
import Message from './components/Message';
import { getMessagesInfo } from 'api/messaging';
import { MessagingQueryKeys } from "constants/QueryKeys/queryKeys";
import { loadOnceConfig } from "constants/QueryKeys/queryConfig";

const Messaging = () => {

    const { data: messages } = useQuery(MessagingQueryKeys.LATEST_MESSAGES_INFO, getMessagesInfo, loadOnceConfig)

    return (
        <>
            <Box className='flex flex-row gap-4'>
                <Message />
            </Box>
            {messages && <Card><MessagesInfoTable items={messages} /></Card>}
        </>
    );
};

export default Messaging;
