import { JWTToken } from 'types/JWTToken';

export const AUTH_AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const AUTH_LOGIN = 'AUTH/LOGIN';
export const AUTH_LOGOUT = 'AUTH/LOGOUT';
export const AUTH_SET_LOADING = 'AUTH/SET_LOADING';

export interface AuthAuthenticate { type: typeof AUTH_AUTHENTICATE; payload: JWTToken; }
export interface AuthLogout { type: typeof AUTH_LOGOUT; }
export interface AuthLogin { type: typeof AUTH_LOGIN; payload: { username: string, password: string } }
export interface AuthSetLoading { type: typeof AUTH_SET_LOADING; payload: boolean; }

interface AuthState {
    access: string;
    refresh: string;
    loading: boolean;
    permissions: string[];
}

type AuthActionTypes = AuthAuthenticate | AuthLogout | AuthSetLoading | AuthLogin;

export const auth_authenticate = (payload: JWTToken) => ({ type: AUTH_AUTHENTICATE, payload });
export const auth_login = (payload: { username: string, password: string }) => ({ type: AUTH_LOGIN, payload });
export const auth_logout = () => ({ type: AUTH_LOGOUT });
export const auth_set_loading = (payload: boolean) => ({ type: AUTH_SET_LOADING, payload });

const initialState: AuthState = {
    access: '',
    refresh: '',
    loading: false,
    permissions: []
};

const auth = (state: AuthState = initialState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case AUTH_AUTHENTICATE:
            return { ...state, ...action.payload, loading: false };
        case AUTH_LOGIN:
            return { ...state, loading: true };
        case AUTH_LOGOUT:
            return { ...state, access: '', refresh: '' };
        case AUTH_SET_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

export default auth;
