import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { updateTransferInfo } from 'api/transfers';

const amountSchema = yup.object().shape({
    amount: yup.number().required().min(0.1),
    fee: yup.number().required().min(0),
});

interface EditSenderPropsInterface extends DialogProps {
    transferId: string;
    amount: number;
    fee: number;
}

const EditAmountDialog: React.FC<EditSenderPropsInterface> = (props) => {
    const queryClient = useQueryClient();

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(amountSchema),
        defaultValues: {
            amount: props.amount,
            fee: props.fee,
        }
    });


    const updateTransferMutation = useMutation(updateTransferInfo, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['transfer', props.transferId]);
            props.onClose({}, 'backdropClick');
            reset();
        }
    });

    const onSubmit = handleSubmit((data) => updateTransferMutation.mutate({ transferId: props.transferId, ...data }));

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Изменить сумму трансфера</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={props.amount}
                        render={({ field }) => (
                            <TextField
                                label="Сумма"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="fee"
                        control={control}
                        defaultValue={props.fee}
                        render={({ field }) => (
                            <TextField
                                label="Комиссия"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />

                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={updateTransferMutation.isLoading}
                        >Сохранить</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditAmountDialog;
