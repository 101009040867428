import { Dispatch, FC, SetStateAction } from 'react';
import moment from 'moment';

import { Box, Stack, } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';

type Props = {
    items: any;
    pageSize: number;
    setPageSize: Dispatch<SetStateAction<number>>;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
};

const ScoringPlayerTable: FC<Props> = ({ items, pageSize, setPageSize, page, setPage }) => {
    const columns: GridColDef[] = [
        {
            field: 'evbb_15_60',
            headerName: 'EV15-60',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // var color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'evbb_60_plus',
            headerName: 'EV60+',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'roi',
            headerName: 'ROI',
            flex: 0.3,
            minWidth: 70,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 2.5 ? '#70db70' : value >= 1.5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value.toFixed(1)}
                    </Box>
                );
            },
        },
        {
            field: 'ts_month',
            headerName: 'TS/Mo',
            flex: 0.3,
            minWidth: 70,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value.toFixed(0)}
                    </Box>
                );
            },
        },
        {
            field: 'untracked_ts',
            headerName: 'TS/Unt',
            flex: 0.3,
            minWidth: 70,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'abi',
            headerName: 'ABI',
            flex: 0.3,
            minWidth: 60,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value.toFixed(1)}
                    </Box>
                );
            },
        },
        {
            field: 'current_bundle_result',
            headerName: 'Рез. Пакета',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value.toFixed(0)}
                    </Box>
                );
            },
        },
        {
            field: 'deductibles',
            headerName: 'Долги',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value.toFixed(0)}
                    </Box>
                );
            },
        },
        {
            field: 'current_bundle_start_at',
            headerName: 'Пакет',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                // const value = moment(params.value as string).format('DD.MM.YYYY HH:mm');
                const value = moment(params.value as string).format('DD.MM.YY');
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {/* {params.value} */}
                        {value}
                    </Box>
                );
            },
        },
        {
            field: 'bundles_count',
            headerName: '# Пакетов',
            flex: 0.3,
            minWidth: 90,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'score',
            headerName: 'Score',
            flex: 0.3,
            minWidth: 70,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'max_buyin',
            headerName: 'MaxBuyin',
            flex: 0.3,
            minWidth: 90,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = params.value as number;
                // const color = value >= 9 ? '#70db70' : value >= 5 ? '#ffdb4d' : value === null ? null : '#ff6666';
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.value}
                    </Box>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Ревизия',
            flex: 0.3,
            minWidth: 80,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const value = moment(params.value as string).format('DD.MM.YY');
                return (
                    <Box
                        sx={{
                            // backgroundColor: color,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value}
                    </Box>
                );
            },
        },
    ];

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell:not(:first-child)': {
                        padding: '0 !important',
                        margin: '0 !important',
                    },
                }}
                autoHeight={true}
                rows={items.max_buyins ?? []}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                disableColumnMenu
                page={page}
                rowCount={items.count ?? 0}
                paginationMode="server"
                onPageChange={newPage => setPage(newPage)}
                onPageSizeChange={newPageSize => {
                    setPageSize(newPageSize);
                    setPage(0);
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default ScoringPlayerTable;
