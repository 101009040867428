const RoomLogo: any = (props) => {
    const container = document.createElement('div');
    const logo = document.createElement('img');

    function roomLogo(name: string) {
        const cleanName = name.replace('(EUR)', '').replace('(ES-PT)', '').replace(/[^a-z0-9\\.]/ig, '').trim();

        return `${process.env.PUBLIC_URL}/assets/images/logos/${cleanName}.svg`;
    }

    logo.setAttribute('src', roomLogo(props.value));

    logo.style.height = '80%';

    container.classList.add('h-full', 'flex', 'items-center');

    container.appendChild(logo);

    return container;
};

export default RoomLogo;
