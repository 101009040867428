export const loadOnceConfig = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
}

export const refetchOnFocusConfig = {
    staleTime: 0,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
}
