import React from 'react';

const MaxNumberFilter = React.forwardRef((props: any, ref) => {
    const [, setCurrentValue] = React.useState(null);
    const inputRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            onParentModelChanged(parentModel) {
                if (!parentModel) {
                    inputRef.current.value = '';
                    setCurrentValue(null);
                } else {
                    inputRef.current.value = parentModel.filter + '';
                    setCurrentValue(parentModel.filter);
                }
            },
        };
    });

    const onInputBoxChanged = input => {
        if (input.target.value === '') {
            props.parentFilterInstance(instance => {
                instance.onFloatingFilterChanged(null, null);
            });

            return;
        }

        setCurrentValue(Number(input.target.value));

        props.parentFilterInstance(instance => {
            instance.onFloatingFilterChanged('lessThanOrEqual', input.target.value);
        });
    };

    return (
        <div className="flex items-center">
            <span className="mr-2">max</span>
            <input
                ref={inputRef}
                className="ag-input-field-input flex-1 ag-text-field-input"
                type="number"
                min="0"
                onInput={onInputBoxChanged}
            />
        </div>
    );
});

export default MaxNumberFilter;