import React from 'react';
import clsx from 'clsx';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import Add from '@mui/icons-material/Add';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { getPlayerAccounts, getPlayerEwallets } from 'api/players';
import { deleteBlockedTransfersRoom, getBlockedTransfersRooms } from 'api/blockedtransfersrooms';
import AddBlockedTransferRoomDialog from './AddBlockedRoomDialog';
import AddBlockedRoomDialog from './AddBlockedRoomDialog';
import Divider from 'components/Divider/Divider';

const BlockedTransfersRooms: React.FC = () => {
    const { playerId } = useParams();
    const queryClient = useQueryClient();

    const [addBlockedDialogOpen, setAddBlockedDialogOpen] = React.useState(false);

    const blockedTransfersQuery = useQuery(['blockedTransfersRooms', playerId], getBlockedTransfersRooms, {
        initialData: [],
    });

    const deleteBlockedQuery = useMutation(deleteBlockedTransfersRoom, {
        onSuccess: () => {
            queryClient.invalidateQueries(['blockedTransfersRooms', playerId]);
        },
    });

    if (blockedTransfersQuery.isLoading) return null;

    return (
        <React.Fragment>
            <div className="w-full col-span-12 mt-10">
                <Divider title="Румы с отключенными трансферами" />
            </div>
            {blockedTransfersQuery.data.map(val => (
                <div className="col-span-2" key={val.id}>
                    <div className={clsx('w-full h-full p-5 border-2 shadow-md cursor-pointer rounded', 'bg-orange-400 border-orange-400')}>
                        <div className={clsx('w-full flex items-center h-full rounded bg-orange-400')}>
                            <p className="text-base flex-1">
                                <span className="font-medium">{val.name}</span>
                            </p>
                            <div className="ml-4">
                                <Tooltip title="Удалить">
                                    <IconButton type="button" onClick={() => deleteBlockedQuery.mutate({ userId: playerId, roomId: val.id })}>
                                        <Cancel />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="col-span-2">
                <div
                    onClick={() => setAddBlockedDialogOpen(true)}
                    className="py-2 h-full flex items-center cursor-pointer justify-center border-4 border-dashed rounded"
                >
                    <Add className="text-gray-300" />
                </div>
            </div>

            <AddBlockedRoomDialog open={addBlockedDialogOpen} onClose={() => setAddBlockedDialogOpen(false)} />
        </React.Fragment>
    );
};

export default BlockedTransfersRooms;
