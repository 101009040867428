import React from 'react';
import moment from 'moment';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MenuItem from '@mui/material/MenuItem';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createPlayerDeductible } from 'api/players';
import { getDeductibleCategories } from 'api/deductibles';

const DeductibleAddDialog: React.FC<DialogProps> = props => {
    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const deductibleCatQuery = useQuery('deductibles-categories', getDeductibleCategories, {
        initialData: [],
    });

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            category_id: '',
            amount: 0,
            description: '',
        },
    });

    const { mutate, isLoading } = useMutation(createPlayerDeductible, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-deductibles', playerId]);
            queryClient.invalidateQueries(['player-deductible-categories-list', playerId]);

            props.onClose({}, 'backdropClick');

            reset();
        },
    });

    const onSubmit = handleSubmit((data: any) => {
        mutate({
            ...data,
            playerId: playerId,
        });
    });

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Долги</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="category_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Категория" select fullWidth required {...field}>
                                {deductibleCatQuery.data.map(cat => (
                                    <MenuItem key={cat.id} value={cat.id}>
                                        {cat.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                    <Controller name="amount" control={control} render={({ field }) => <TextField label="Сумма" fullWidth required {...field} />} />
                    <Controller name="description" control={control} render={({ field }) => <TextField label="Описание" fullWidth required {...field} />} />
                    <div className="pt-4 flex justify-between text-right">
                        <Button type="submit" variant="contained" size="large" loading={isLoading}>
                            Создать
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default DeductibleAddDialog;
