import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getNetworkNormRules } from 'api/normrules';
import { getRooms } from 'api/rooms';
import RoomsFilter from 'components/RoomsFilter';
import NormRulesTable from './NormRulesTable';
import { Card, CircularProgress } from '@mui/material';

/**
 * Вкладка с информацией о турнирных исключениях.
 */
const NormRules: FC = () => {
    const [filter, setFilter] = useState([]);
    const [result, setResult] = useState();

    const {data: rooms} = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: series, isFetching} = useQuery(['network-norm-rules-data'], getNetworkNormRules, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    useEffect(() => {
        if(filter.length > 0) {
            setResult(series.filter(({network_id}) => filter.find(item => item.id === network_id)));
        } else {
            setResult(series);
        }
    }, [series, filter]);

    return (
        <>
            <RoomsFilter setFilter={setFilter} data={filter} rooms={rooms} />
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><NormRulesTable items={result}/></Card>}
        </>
    );
}

export default NormRules;
