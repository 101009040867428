import { FC, useState, useReducer } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    Box,
    Checkbox,
    Stack,
    Link,
    Button,
    IconButton,
    Dialog,
    useMediaQuery,
    Tooltip
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { deleteHandleAssessment, putHandleAssessment, setPlayerOptedOut, setPlayerOptedIn } from 'api/handleassessment';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from 'components/DeleteDialog';
import ManualLabelsModal from './ManualLabelsModal';
import theme from 'themes/lightTheme';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Список элементов.
     */
    items: any;
};

/**
 * Отображает таблицу с списком игроков и ручными оценками.
 */
const ManualLabelsTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [pageSize, setPageSize] = useState<number>(25);
    const [deleteModal, setDeleteModal] = useReducer((value: boolean) => !value, false);
    const [open, setOpen] = useReducer((value: boolean) => !value, false);
    const [selectPlayer, setSelectPlayer] = useState<any>('');
    const [deleteItem, setDeleteItem] = useState('');

    const handleAssessmentMutation = useMutation(putHandleAssessment, {
        onSuccess: () => {
            queryClient.invalidateQueries(['assessment-data']);
        },
    });

    const setOptedOutMutation = useMutation(setPlayerOptedOut, {
        onSuccess: () => {
            queryClient.invalidateQueries(['assessment-data']);
        },
    });

    const setOptedInMutation = useMutation(setPlayerOptedIn, {
        onSuccess: () => {
            queryClient.invalidateQueries(['assessment-data']);
        },
    });

    const handleAssessmentMutationDelete = useMutation(deleteHandleAssessment, {
        onSuccess: () => {
            queryClient.invalidateQueries(['assessment-data']);
        },
    });

    const itemsId = items.result.map((item, index) => ({
        ...item,
        id: index,
    }));

    function handleUpdateLabel(days?: string, label_id?: string, player?: string, is_hidden?: boolean) {
        const data = {
            network: items.network,
            player: selectPlayer ? selectPlayer.player : player,
            label_id: label_id,
            is_hidden: selectPlayer ? selectPlayer.is_hidden : is_hidden,
            expires_days: days,
        }

        handleAssessmentMutation.mutate(data)
    }

    function handleUpdateOptedOut(isOptedOut: boolean, player: string) {
        const data = {
            network: items.network,
            player: player,
        }
        if (!isOptedOut) {
            setOptedOutMutation.mutate(data);
        } else {
            setOptedInMutation.mutate(data);
        }
    }

    function handleCloseDialog() {
        setSelectPlayer('');
        setOpen();
    }

    function deliteItem() {
        handleAssessmentMutationDelete.mutate(deleteItem);
        setDeleteModal();
    }

    function deleteDialogClose() {
        setDeleteItem('');
        setDeleteModal();
    }

    const columns: GridColDef[] = [
        {
            field: 'player',
            headerName: 'Игрок',
            flex: 1,
            minWidth: 150,
            sortable: true,
            renderCell: (params) => {
                return (
                    <Box>
                        <Link
                            underline="hover"
                            className='cursor-pointer'
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.sharkscope.com/#Player-Statistics//networks/${items.network}/players/${params.row.player}`}
                        >
                            {params.row.player}
                        </Link>
                    </Box>
                );
            }
        },
        {
            field: 'hands',
            headerName: 'Руки',
            flex: 1,
            minWidth: 150,
            sortable: false,
            valueGetter: (params) => params.row.stats.hands,
        },
        {
            field: 'vpip',
            headerName: 'vpip',
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueGetter: (params) => params.row.stats.vpip?.toFixed(2),
        },
        {
            field: 'limp_utgbu',
            headerName: 'limp_utgbu',
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueGetter: (params) => params.row.stats.limp_utgbu?.toFixed(2),
        },
        {
            field: 'vpip_pfr',
            headerName: 'vpip/pfr',
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueGetter: (params) => params.row.stats.vpip_pfr?.toFixed(2),
        },
        {
            field: 'wwsh_hu',
            headerName: 'wwsh_hu',
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueGetter: (params) => params.row.stats.wwsh_hu?.toFixed(2),
        },
        {
            field: 'auto_label',
            headerName: 'Авто метка',
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueGetter: (params) => params.row.auto_label?.name,
        },
        {
            field: 'manual_label',
            headerName: 'Ручная метка',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: (params) => {
                function selectDeliteItem(id: number) {
                    setDeleteItem(String(id));
                    setDeleteModal();
                }

                if (params.row.manual_label && params.row.manual_label.name) {
                    return (
                        <Box className='flex flex-row items-center gap-1'>
                            <IconButton onClick={() => selectDeliteItem(params.row.player_manual_label_id)} disabled={handleAssessmentMutationDelete.isLoading} size="small">
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            <Box>
                                {params.row.manual_label.name}
                            </Box>
                        </Box>
                    )
                }
                return;
            }
        },
        {
            field: 'manual_label_expires_at',
            headerName: 'Истекает',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                if (params.row.manual_label && !params.row.manual_label_expires_at) {
                    return '∞'
                }
                return params.row.manual_label_expires_at ? moment(params.row.manual_label_expires_at).format('DD.MM.YYYY') : ''
            }
        },
        {
            field: 'prev_manual_label',
            headerName: 'Старая',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                if (params.row.prev_manual_label && params.row.prev_manual_label.name) {
                    return (
                        <Box className='flex flex-row items-center gap-1'>
                            <Box>
                                {params.row.prev_manual_label.name}
                            </Box>
                        </Box>
                    )
                }
                return;
            }
        },
        {
            field: "action",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                function handleOpenDialog(item: any) {
                    setSelectPlayer({ player: item.player, is_hidden: item.is_hidden });
                    setOpen();
                }

                if (!!params.row.manual_label) {
                    return;
                }

                return (
                    <Box className='flex flex-row gap-2'>
                        <Tooltip title="Дублировать на 120 дней">
                            <Button style={{ minWidth: 0 }} size="small" onClick={() => handleUpdateLabel("120", params.row.auto_label.id, params.row.player, params.row.is_hidden)}>120</Button>
                        </Tooltip>
                        <Tooltip title="Дублировать бесконечно">
                            <Button style={{ minWidth: 0 }} size="small" onClick={() => handleUpdateLabel(null, params.row.auto_label.id, params.row.player, params.row.is_hidden)}>∞</Button>
                        </Tooltip>
                        <Tooltip title="Выбрать метку">
                            <Button style={{ minWidth: 0 }} size="small" onClick={() => handleOpenDialog(params.row)} startIcon={<AddCircleOutlineOutlinedIcon />}>add</Button>
                        </Tooltip>
                        <Tooltip title="Opted Out">
                            <Checkbox style={{ minWidth: 20 }} size="small" name="OptedOut" checked={params.row.is_hidden} onClick={() => handleUpdateOptedOut(
                                params.row.is_hidden,
                                params.row.player
                            )} />
                        </Tooltip>
                    </Box>
                );
            }
        }
    ];

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                autoHeight={true}
                rows={itemsId}
                columns={columns}
                pageSize={pageSize}
                rowHeight={50}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                disableColumnMenu
                initialState={{
                    sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />
            <Dialog fullWidth maxWidth='sm' open={open} onClose={() => handleCloseDialog()} fullScreen={fullScreen}>
                <ManualLabelsModal close={handleCloseDialog} handleUpdateLabel={handleUpdateLabel} loading={handleAssessmentMutation.isLoading} />
            </Dialog>
            <DeleteDialog
                close={deleteDialogClose}
                open={deleteModal}
                handleAgreeAction={deliteItem}
                text="Вы уверены что хотите удалить ручную метку?"
            />
        </Box>
    );
};

export default ManualLabelsTable;
