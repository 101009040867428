import { ChangeEvent, FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { getRooms } from 'api/rooms';
import { IconButton, DialogTitle, DialogContent, FormGroup, FormControlLabel, Checkbox, CircularProgress, Box } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { createSchedulerRoiGridItem, getSchedulerRoomsRoiGrid } from 'api/schedulersettings';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;
}

/**
 * Диалоговое окно для создания сетки ROI в расписаторе.
 */
const SchedulerRoiGridDialog: FC<Props> = ({ ...props }) => {
    const queryClient = useQueryClient();
    const [selectedRooms, setSelectedRooms] = useState([]);

    const { handleSubmit } = useForm();

    const { data: rooms, isFetching } = useQuery(['scheduler-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const { data: roiRooms, isFetching: isFetchingRoiRooms } = useQuery(['scheduler-roi-grid-rooms'], getSchedulerRoomsRoiGrid, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)
    
    const newItemMutation = useMutation(createSchedulerRoiGridItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-roi-grid-rooms');

            props.close();
        },
    });

    /**
     * Сохранить значение чекбокса.
     */
    function selectRoom(event: ChangeEvent<HTMLInputElement>) {
        let filter = selectedRooms.slice();
        const id = filter.findIndex(data => data.id === event.target.value);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            return setSelectedRooms([{ id: event.target.value, name: event.target.name }]);
        }

        setSelectedRooms(filter);
    }

    /**
     * Проверка элемента на выбор.
     */
    function checkSelected(id: string) {
        const ids = selectedRooms.map(el => el.id);
        return ids.includes(String(id));
    }

    /**
     * Функция сохранения формы.
     */
     const onSubmit = handleSubmit(data => {
        newItemMutation.mutate({ network: selectedRooms[0].name })
    });

    return (
        <>
            <DialogTitle>
                Новая сетка ROI
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {(isFetching || isFetchingRoiRooms) && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250 }}><CircularProgress /></Box>}
                {(!isFetching && !isFetchingRoiRooms) && (
                    <form className='flex flex-col gap-2' onSubmit={onSubmit}>
                        <FormGroup
                            onChange={selectRoom}
                        >
                            {rooms.filter(room => roiRooms.networks.every(item => item !== room.network))?.map((item) => (
                                <FormControlLabel
                                    className="pl-2 pr-2 m-0"
                                    key={item.id}
                                    control={
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            name={item.network}
                                            checked={checkSelected(item.id)}
                                        />
                                    }
                                    label={item.network}
                                    value={item.id}
                                />
                                
                            ))}
                        </FormGroup>
                        <div className='pt-4'>
                            <Button
                                fullWidth
                                variant='contained'
                                size='large'
                                type='submit'
                                loading={newItemMutation.isLoading}
                            >
                                Добавить
                            </Button>
                        </div>
                    </form>
                )}
            </DialogContent>
        </>
    );
};

export default SchedulerRoiGridDialog;
