import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { getSchedulerBuyin, updateSchedulerBuyin } from 'api/schedulersettings';

/**
 * Вкладка содержащая настройки байинов в расписаторе.
 */
const SchedulerBuyin: FC = () => {
    const queryClient = useQueryClient();

    const { data, isLoading } = useQuery(['schedule-buyin'], getSchedulerBuyin, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    const limitsMutation = useMutation(updateSchedulerBuyin, {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedule-buyin']);
        }
    });

    return (
        <div className="w-full relative shadow-md rounded overflow-hidden" style={{ height: 800 }}>
            <AgGridReact
                rowData={data || []}
                defaultColDef={{
                    flex: 1,
                    sortable: true,
                    floatingFilter: true,
                    suppressMenu: true,
                    floatingFilterComponentParams: { suppressFilterButton: true }
                }}
                onCellValueChanged={e => {
                    const {...rest} = e.data;
                    limitsMutation.mutate({...rest});
                }}
            >
                <AgGridColumn
                    headerName="Уровень"
                    field="level_name"
                    filter
                    sortable
                    sort={"desc"}
                    comparator={(valueA, valueB) => {
                        const a = valueA.split(" ");
                        const b = valueB.split(" ");
                        return Number(a[0]) - Number(b[0]);
                    }}
                />
                <AgGridColumn
                    headerName="Очень хорошие"
                    field="2"
                    editable
                    valueParser={params => Number(params.newValue)}
                />
                <AgGridColumn headerName="Хорошие" field="1" editable valueParser={params => Number(params.newValue)}/>
                <AgGridColumn headerName="Средние" field="0" editable valueParser={params => Number(params.newValue)}/>
                <AgGridColumn headerName="Плохие" field="-1" editable valueParser={params => Number(params.newValue)}/>
                <AgGridColumn headerName="Очень плохие" field="-2" editable valueParser={params => Number(params.newValue)}/>
            </AgGridReact>
            {
                isLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                        <CircularProgress />
                    </div>
                ) : null
            }
        </div>
    );
}

export default SchedulerBuyin;
