import { FC } from 'react';
import { Box } from '@mui/material';
import SchedulerBuyin from './components/SchedulerBuyin/SchedulerBuyin';
import SchedulerCoefficient from './components/SchedulerCoefficient/SchedulerCoefficient';
import SchedulerAbility from './components/SchedulerAbility/SchedulerAbility';
import SchedulerExceptions from './components/SchedulerExceptions/SchedulerExceptions';
import SchedulerSeries from './components/SchedulerSeries/SchedulerSeries';
import SchedulerRoiCorrection from './components/SchedulerRoiCorrection/SchedulerRoiCorrection';
import SchedulerRoiGrid from './components/SchedulerRoiGrid/SchedulerRoiGrid';
import SchedulerRoiTypeLimits from './components/SchedulerRoiTypeLimits/SchedulerRoiTypeLimits';
import CustomTournaments from './components/SchedulerCustomTournaments/CustomTournaments';

type Props = {
    /**
     * Активная вкладка
     */
    active: number;
}

/**
 * Вкладка содержащая настройки расписатора.
 */
const SchedulerSettings: FC<Props> = ({ active }) => {
    return (
        <Box>
            {active === 0 && <SchedulerBuyin />}
            {active === 1 && <SchedulerSeries />}
            {active === 2 && <SchedulerCoefficient />}
            {active === 3 && <SchedulerRoiCorrection />}
            {active === 4 && <SchedulerAbility />}
            {active === 5 && <SchedulerExceptions />}
            {active === 6 && <SchedulerRoiGrid />}
            {active === 7 && <SchedulerRoiTypeLimits />}
            {active === 8 && <CustomTournaments />}
        </Box>
    );
}

export default SchedulerSettings;
