import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    Box,
    Typography,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import DeleteDialog from 'components/DeleteDialog';
import { deleteGroupMark } from 'api/untrackedlabelratiogroups';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Свойства компонента.
 */
type Props = {
    /**
     * Детали группы.
     */
    data: any;

    /**
     * ID метки
     */
    markId: string;
};

/**
 * Таблица содержащая информацию о группах и значения долей неизвестных игроков
 */
const UntrackedPlayersDetails: FC<Props> = ({ data, markId }) => {
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<string>();

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(deleteGroupMark, {
        onSuccess: () => {
            queryClient.invalidateQueries('group-list-untracked-players-data');
        },
    });

    function deliteItem() {
        mutate({ group_id: markId, ratio_id: item });
        setOpen(false);
    }

    function openDialog(id: string) {
        setItem(id);
        setOpen(true);
    }
    return (
        <Box>
            <Box>
                {data.length === 0 && <Box className='flex items-center justify-center h-32'><Typography>Значения отсутствуют</Typography></Box>}
                {data.length > 0 && (
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={300}>Метка</TableCell>
                                <TableCell>Доля</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <Box className='flex flex-row items-center'>
                                            <Box>
                                                <IconButton onClick={() => openDialog(item.id)} disabled={isLoading} size="small">
                                                    <DeleteIcon />
                                                </IconButton>
                                                <DeleteDialog close={() => setOpen(false)} open={open} handleAgreeAction={() => deliteItem()} />
                                            </Box>
                                            <Typography variant='body1'>{item.label.name}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{item.ratio}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </Box>
    )
}

export default UntrackedPlayersDetails;
