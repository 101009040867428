import {
    ComponentPropsWithoutRef,
    FC,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from 'react';
  
import {
    ClickAwayListener,
    Popper,
    Card,
    Grow,
    Box,
    PopperPlacementType,
} from '@mui/material';
import TextFieldButton from 'components/TextFieldButton';

/**
 * Значения, передаваемые для отрисовки содержимого всплывающей области.
 */
type ContentOptions = {
    /**
     * Инициирует скрытие всплывающей области.
     */
    close: () => void;
};

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Box> & {
    /**
     * Отображает содержимое всплывающей области. Если это свойство установлено,
     * то значение свойства `content` будет проигнорировано.
     * @param options Параметры для отрисовки содержимого.
     */
    renderContent?: (options: ContentOptions) => ReactNode;

    /**
     * Обрабатывает изменение состояния видимости всплывающей области.
     * @param open Указывает, открыта ли всплывающая область.
     */
    onToggle?: (open: boolean) => void;

    /**
     * Обрабатывает событие скрытия всплывающей области.
     */
    onClose?: () => void;

    /**
     * Обрабатывает событие показа всплывающей области.
     */
    onOpen?: () => void;

    /**
     * Запрещает открытие или скрытие всплывающей области.
     */
    disabled?: boolean;

    /**
     * Указывает, показана ли всплывающая область в данный момент.
     */
    open?: boolean;

    /**
     * Позиция для открытия всплывающей области.
     */
    placement?: PopperPlacementType;

    /**
     * Заголовок кнопки.
     */
    btnLabel: string;

    /**
     * Значение кнопки.
     */
    btnValue?: string;

    /**
     * Размер дропдауна.
     */
    small?: boolean;
};

/**
 * Последний сгенерированный идентификатор всплывающей области.
 */
let lastId: number = 0;

/**
 * Генерирует уникальный идетификатор всплывающей области.
 */
function generateId() {
    lastId += 1;
    return `dropdown_${lastId}`;
}

/**
 * Отображает всплывающую область.
 */
const Dropdown: FC<Props> = ({
    renderContent,
    disabled,
    onToggle,
    onClick,
    onClose,
    onOpen,
    open: outerOpen,
    placement = 'bottom-end',
    btnLabel,
    btnValue,
    small,
}) => {
    const id = useMemo(generateId, []);

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const [open, setOpen] = useState<boolean>(Boolean(outerOpen));
    

    useEffect(() => setOpen(Boolean(outerOpen)), [outerOpen]);

    function toggle(nextOpen: boolean) {
        if (nextOpen) {
        onOpen?.();
        } else {
        onClose?.();
        }

        onToggle?.(nextOpen);
    }

    function handleClick(event: any) {
        if (disabled) {
            return;
        }

        setAnchor(event.currentTarget);

        toggle(true);
        onClick?.(event);

        if (outerOpen == null) {
            setOpen(true);
        }
    }

    function handleClose() {
        if (disabled) {
            return;
        }

        toggle(false);

        if (outerOpen == null) {
            setOpen(false);
        }
    }

    const contentOptions: ContentOptions = {
        close: handleClose,
    };

    const sizeStyle = small ? "mt-1 min-w-[100px] max-w-[25vh] overflow-auto" : "mt-1 min-w-[250px] max-w-[50vh] overflow-auto"

    return (
        <Box>
            <TextFieldButton
                label={btnLabel}
                value={btnValue ? btnValue : 'Любой'}
                onClick={handleClick}
                small={small}
            />
            <Popper
                className="z-50"
                anchorEl={anchor}
                open={open}
                id={id}
                transition
                disablePortal
                placement={placement}
            >
                {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClose}>
                    <Grow
                        {...TransitionProps}
                        className="origin-top-right"
                        timeout={0}
                    >
                    <Card
                        classes={{ root: sizeStyle }}
                        elevation={5}
                    >
                        {renderContent(contentOptions)}
                    </Card>
                    </Grow>
                </ClickAwayListener>
                )}
            </Popper>
        </Box>
    );
    
};

export default Dropdown;