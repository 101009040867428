import axios from 'helpers/axios';
import moment from 'moment';

/**
 * Список турниров.
 */
export const getCustomTournaments = async ({ queryKey }) => {
    const [, perPage, page, filters, search, showOld] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    var params = new URLSearchParams();

    if (filters.length !== 0) {
        filters.map(value => params.append('networkName', String(value.network)));
    }

    if (perPage) {
        params.append('limit', perPage);
    }

    if (offset) {
        params.append('offset', String(offset));
    }

    if (search) {
        params.append('name', String(search));
    }

    if (showOld) {
        params.append('show', 'all');
    } else {
        params.append('show', 'active');
    }

    const request = {
        params: params,
    };

    const { data } = await axios.get('/api/adminapi/grid/customtournaments/', request);

    return data;
};

/**
 * Create custom grid tournament.
 */
export const createCustomTournament = async body => {
    const { data } = await axios.post('/api/adminapi/grid/customtournaments/', body);

    return data;
};

/**
 * Delete custom grid tournament.
 */
export const deleteCustomTournament = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/customtournaments/${id}/`);

    return data;
};

export const createCustomTournamentsFromCsv = async body => {
    const { data } = await axios.post('/api/adminapi/grid/customtournaments/csvform/', body);

    return data;
};
