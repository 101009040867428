import axios from 'helpers/axios';

export const getPendingDeposits = async () => {
    // const { data } = await axios.post('/api/adminapi/getdeposits/', { select: 'pending' });
    const { data } = await axios.get('/api/adminapi/depositrequests/pending/');

    return data;
};

export const getAssignPlayers = async ({ queryKey }) => {
    const [, id] = queryKey;

    // const { data } = await axios.get(`/api/adminapi/deposits/${id}/assignplayers/`);
    const { data } = await axios.get(`/api/adminapi/depositrequests/${id}/assign/`);

    return data;
};


export const deleteDepositRequest = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/depositrequests/${id}/`);

    return data;
};
