import axios from 'helpers/axios';

export const sendPublicMessage = async (body: any) => {
    const { data } = await axios.post('/api/adminapi/publictelegrambot/messages/', body)

    return data;
};

export const getMessagesInfo = async () => {
    const { data } = await axios.get('/api/adminapi/publictelegrambot/messages/info/');

    return data;
}
