import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { makeBundlePayout, getBundlePayoutInfo } from 'api/bundles';
import { getPlayerDeductiblesCategories, getPlayerBonusInfo, getPlayerContractTournamentsNum } from 'api/players';
import { getCurrencies } from 'api/currencies';

const loginSchema = yup.object().shape({
    currency_id: yup.number().required(),
    amount: yup.number().required().min(0),
    comment: yup.string().required(),
    deductibles: yup.array().of(
        yup.object().shape({
            category_id: yup.number().required().positive(),
            amount: yup.number().required().min(0).default(0),
        })
    ),
    close_bundle: yup.boolean().required(),
});

type FormData = {
    currency_id: number;
    amount: number;
    comment: string;
    deductibles: {
        category_id: number;
        amount: number;
    }[];
    close_bundle: boolean;
};

interface BundlePayoutInterface extends DialogProps {
    bundleId: number;
}

const BundlePayoutDialog: React.FC<BundlePayoutInterface> = (props: BundlePayoutInterface) => {
    const { bundleId } = props;

    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const { control, handleSubmit, reset, setValue } = useForm<FormData>({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            currency_id: null,
            amount: 0,
            comment: 'Расчёт за пакет',
            deductibles: [],
            close_bundle: true,
        },
    });

    const { data: currenciesData, isLoading: currenciesIsLoading } = useQuery('currencies', getCurrencies);

    const payoutInfoQuery = useQuery(['bundle-payout-info', bundleId], getBundlePayoutInfo, {
        enabled: !!bundleId,
        // initialData: {'amount': null}
    });

    const categoriesQuery = useQuery(['player-deductible-categories-list', playerId], getPlayerDeductiblesCategories, {
        enabled: !!bundleId,
        initialData: [],
    });

    const bonusQuery = useQuery(['player-bonus', playerId], getPlayerBonusInfo, {
        enabled: !!bundleId,
        initialData: 0,
    });

    const tsNumQuery = useQuery(['player-contract-tournaments-num', playerId], getPlayerContractTournamentsNum, {
        enabled: !!bundleId,
        initialData: { current: 0, total: 0 },
    });

    const { mutate, isLoading } = useMutation(makeBundlePayout, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-personalfunds', playerId]);
            queryClient.invalidateQueries(['player-contracts', playerId]);
            queryClient.invalidateQueries(['player-deductibles', playerId]);
            props.onClose({}, 'backdropClick');
            reset();
        },
    });

    const onSubmit = handleSubmit(data => mutate({ ...data, bundleId }));

    React.useEffect(() => {
        setValue('currency_id', 1);
    }, [currenciesData?.data]);

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Выплата за пакет #{bundleId}</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="currency_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Валюта" select fullWidth required defaultValue={1} {...field}>
                                {currenciesData
                                    ?.filter(currency => ['USD', 'EUR'].includes(currency.name))
                                    .map(currency => (
                                        <MenuItem key={currency.id} value={currency.id}>
                                            {currency.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => <TextField label="Сумма (только > 0)" fullWidth required {...field} />}
                    />
                    <div className="space-y-1">
                        {payoutInfoQuery.data && <p className="font-bold">Выплата: {payoutInfoQuery.data.amount}</p>}
                        {<p>Бонус: ${bonusQuery.data}</p>}
                        {tsNumQuery.data && tsNumQuery.data.current <= tsNumQuery.data.total ? (
                            <p>
                                Турниры: {tsNumQuery.data.current}/{tsNumQuery.data.total}
                            </p>
                        ) : (
                            <p className="text-red-500">
                                Турниры: {tsNumQuery.data.current}/{tsNumQuery.data.total}
                            </p>
                        )}
                    </div>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => <TextField label="Комментарий" rows={3} multiline fullWidth required {...field} />}
                    />
                    <Divider className="pt-2 pb-2" />
                    <div>Вычеты</div>
                    {categoriesQuery.data
                        ?.filter(x => x.balance > 0)
                        .map((val, i) => {
                            return (
                                <React.Fragment key={val.id}>
                                    <div hidden>
                                        <Controller name={`deductibles.${i}.category_id`} control={control} defaultValue={val.id} render={() => <div />} />
                                    </div>
                                    <Controller
                                        name={`deductibles.${i}.amount`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                className="mb-4 w-full"
                                                defaultValue={0}
                                                label={`Категория ${val.name}, max: ${val.balance}`}
                                                variant="outlined"
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </React.Fragment>
                            );
                        })}
                    <Controller
                        name="close_bundle"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Закрыть пакет"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button type="submit" variant="contained" size="large" loading={isLoading}>
                            Создать выплату
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default BundlePayoutDialog;
