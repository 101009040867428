import { FC } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import TournamentFormatsTable from './TournamentFormatsTable';
import { getTournamentFormatsList } from 'api/tournamentformats';

/**
 * Вкладка с форматами турниров.
 */
const TournamentFormats: FC = () => {
    const {data, isFetching} = useQuery(['tournament-formats'], getTournamentFormatsList, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    return (
        <>
            {isFetching && !data && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && data && <Card><TournamentFormatsTable items={data}/></Card>}
        </>
    );
}

export default TournamentFormats;
