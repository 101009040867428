import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createAccountRecord } from 'api/accountrecords';
import { getPlayerEwallets } from 'api/players';
import { getChipCurrencies } from 'api/chipcurrencies';
import { getClubs } from 'api/clubs';

interface NewAccountRecordInterface extends DialogProps {
        accountId: number;
        roomId: number;
}

const NewAccountRecordDialog: React.FC<NewAccountRecordInterface> = (props) => {
    const { playerId } = useParams();
    const queryClient = useQueryClient();

    const chipCurrenciesQuery = useQuery('chip_currencies', getChipCurrencies, {
        initialData: []
    });

    const clubsQuery = useQuery(['room_clubs', props.roomId], getClubs, {
        initialData: []
    })

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            club_id: null,
            chip_currency_id: null,
            is_active: true,
        }
    });

    const { mutate, isLoading } = useMutation(createAccountRecord, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-accounts', playerId]);

            props.onClose({}, 'backdropClick');

            reset();
        }
    });

    const onSubmit = handleSubmit((data) => mutate({accountId: props.accountId, ...data}));

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить запись в аккаунт #{props.accountId}</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="chip_currency_id"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Валюта"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    chipCurrenciesQuery.data?.filter(cc => cc.is_active).map(cc => (
                                        <MenuItem key={cc.id} value={cc.id}>
                                            {cc.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="club_id"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Клуб"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    clubsQuery.data?.filter(club => club.is_active).map(club => (
                                        <MenuItem key={club.id} value={club.id}>
                                            {club.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="is_active"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Активен"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewAccountRecordDialog;
