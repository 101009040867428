import { FC, Dispatch, SetStateAction } from 'react';

import { ListItemIcon, MenuItem } from '@mui/material';
import Dropdown from 'components/Dropdown';
import { Check } from '@mui/icons-material';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Массив примененных фильтров.
     */
    data: any;
};

/**
 * Компонент содержит фильтр для страницы запланированных турниров.
 */
const ScheduledFilters: FC<Props> = ({ data, setFilter }) => {
    const days = [
        {
            id: 0,
            name: 'Понедельник'
        },
        {
            id: 1,
            name: 'Вторник'
        },
        {
            id: 2,
            name: 'Среда'
        },
        {
            id: 3,
            name: 'Четверг'
        },
        {
            id: 4,
            name: 'Пятница'
        },
        {
            id: 5,
            name: 'Суббота'
        },
        {
            id: 6,
            name: 'Воскресенье'
        }
    ]
    function changeFilter(item) {
        if(data && item.id === data.id) {
            setFilter(null);
        } else {
            setFilter(item);
        }
    }

    function filterCheck(dayId) {
        if (data) {
            const id = data.id === dayId;

            return id ? true : false;
        }
        return false
    }

    function filterValue(data?) {
        if(data) {
            return data.name;
        }
        return undefined;
    }

    return (
        <Dropdown
            btnLabel='День недели'
            btnValue={filterValue(data)}
            renderContent={() => (
                days?.map((item) => { 
                    return (
                        <MenuItem onClick={() => changeFilter(item)} key={item.id} >
                            {filterCheck(item.id) && 
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                            }
                            {item.name}
                        </MenuItem>
                    )
                })
            )}
        />
        
    );
};

export default ScheduledFilters;
