import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MenuItem from '@mui/material/MenuItem';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';

import { createUntrackedTournaments } from 'api/players';
import { getPokerRoomsForUser } from 'api/pokerrooms';

const schema = yup.object().shape({
    count: yup.number().required().min(1),
    datetime: yup.date().required(),
});

interface IProps {
    roomId: number | null;
    playerId: number;
    onClose: () => void;
}

const AddUntrackedTournamentsDialog: React.FC<IProps> = props => {

    const queryClient = useQueryClient();

    const roomsQuery = useQuery(['poker_rooms', props.playerId], getPokerRoomsForUser, {
        initialData: [],
    });

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            player_id: props.playerId,
            room_id: props.roomId,
            count: 0,
            datetime: null,
        },
    });

    const { mutate, isLoading } = useMutation(createUntrackedTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-untracked-tournaments', props.playerId]);

            props.onClose();

            reset();
        },
    });

    const onSubmit = handleSubmit((data: any) => {
        mutate({
            ...data,
            playerId: props.playerId,
            room_id: props.roomId,
            datetime: data.datetime.toISOString(),
        });
    });

    return (
        <>
            <DialogTitle>Турниры</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    {/*<Controller
                        name="room_id"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Room" select fullWidth required {...field}>
                                {roomsQuery.data
                                    ?.filter(room => room.active)
                                    .map(room => (
                                        <MenuItem key={room.id} value={room.id}>
                                            {room.short_name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    />*/}
                    <Controller name="count" control={control} render={({ field }) => <TextField label="Кол-во турниров" fullWidth required {...field} />} />
                    <Controller
                        name="datetime"
                        control={control}
                        render={({ field }) => (
                            <DateTimePicker {...field} renderInput={props => <TextField {...props} label="Дата внесения" required fullWidth />} />
                        )}
                    />
                    <div className="pt-4 flex justify-between text-right">
                        <Button type="submit" variant="contained" size="large" loading={isLoading}>
                            Создать
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default AddUntrackedTournamentsDialog;
