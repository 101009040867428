import { FC, useState } from 'react';
import { useMutation } from 'react-query';

import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import { Box, Select, MenuItem } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { getTournamentStats } from 'api/scoring_service';

type Props = {
    /**
     * Список румов
     */
    rooms: string[];
};

const TournamentStats: FC<Props> = ({ rooms }) => {
    const [tournamentStats, setTournamentStats] = useState(null);

    const { mutate: mutateStats, isLoading: statsIsLoading } = useMutation(getTournamentStats, {
        onSuccess: (data) => {
            setTournamentStats(data);
        }
    });

    const { control, handleSubmit } = useForm({
        defaultValues: {
            network: rooms[0],
            tournament_id: '',

        },
    });

    const onSubmit = handleSubmit(data => {
        mutateStats({
            network: data.network,
            tournament_id: data.tournament_id,
        });
    });

    return (
        <>
            <Box className='flex flex-col'>
                <Box className='flex flex-row flex-wrap gap-4 items-center mb-3'>
                    <form className='flex flex-row gap-4 pt-2' onSubmit={onSubmit}>
                        <Controller
                            control={control}
                            name='network'
                            render={({ field }) => (
                                <Select {...field} >
                                    {rooms.map((network) => (
                                        <MenuItem
                                            key={network}
                                            value={network}
                                        >
                                            {network}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="tournament_id"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Id турнира"
                                    required
                                    style={{ minWidth: '200px' }}
                                    {...field}
                                />
                            )}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={statsIsLoading}
                        >
                            Статы
                        </Button>

                    </form>
                </Box>
                <div>
                    {tournamentStats && <pre>{JSON.stringify(tournamentStats, null, 2)}</pre>}
                </div>
            </Box>
        </>
    );
}

export default TournamentStats;
