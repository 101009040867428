import React from 'react';
import clsx from 'clsx';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import PaymentsIcon from '@mui/icons-material/Payments';
import Add from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { getPlayerContracts } from 'api/players';
import { updateBundle } from 'api/bundles';
import { updateContract } from 'api/contracts';
import NewContractDialog from '../NewContractDialog/NewContractDialog';
import NewBundleDialog from '../NewBundleDialog/NewBundleDialog';
import BundlePayoutDialog from '../BundlePayout/BundlePayout';
import Divider from 'components/Divider/Divider';
import { Dialog, useMediaQuery } from '@mui/material';
import theme from 'themes/lightTheme';
import ContractEditFieldDialog from './ContractEditFieldDialog';

const Contracts: React.FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const [contract, setContract] = React.useState(null);
    const [bundle, setBundle] = React.useState(null);
    const [contractOpen, setContractOpen] = React.useState(false);
    const [bundleOpen, setBundleOpen] = React.useState(false);
    const [bundlePayoutOpen, setBundlePayoutOpen] = React.useState(false);
    const [selectedContract, setSelectedContract] = React.useState(null);
    const [editField, setEditField] = React.useState(null);

    const { data, isLoading } = useQuery(['player-contracts', playerId], getPlayerContracts, {
        enabled: !!playerId,
    });

    const bundleMutation = useMutation(updateBundle, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-contracts', playerId]);
        },
    });

    const contractMutation = useMutation(updateContract, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-contracts', playerId]);
        },
    });

    React.useEffect(() => {
        if (data && contract) {
            setContract(data.find(val => val.id === contract.id));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (isLoading) return null;

    function handleEditField(field: string, contract: any) {
        setEditField(field);
        setSelectedContract(contract);
    }

    function handleCloseEditField() {
        setEditField(null);
        setSelectedContract(null);
    }

    return (
        <>
            <div className="w-full col-span-12 mt-10">
                <Divider title="Контракты" />
            </div>
            {data.map(val => (
                <div className="col-span-4" key={val.id}>
                    <div
                        onClick={() => setContract(val)}
                        className={clsx(
                            'w-full h-full p-5 border-2 shadow-md cursor-pointer rounded',
                            val.active ? 'bg-lime-500 border-lime-500' : 'bg-gray-300 border-gray-300',
                            val.id === contract?.id ? 'border-red-500' : ''
                        )}
                    >
                        <p className="flex">
                            <div className="flex-1">
                                {val.active ? (
                                    <span>{val.start}:</span>
                                ) : (
                                    <span>
                                        {val.start} - {val.end}:
                                    </span>
                                )}
                                <span className="font-medium">&nbsp;{val.amount_games}</span>
                            </div>
                            <div className="ml-4 self-center">
                                <Tooltip title="Изменить статус контракта">
                                    <IconButton type="button" onClick={() => contractMutation.mutate({ id: val.id, active: !val.active })}>
                                        {val.active ? <Cancel /> : <CheckCircle />}
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </p>
                    </div>
                </div>
            ))}
            <div className="col-span-4">
                <div
                    onClick={() => setContractOpen(true)}
                    className="py-2 h-full flex items-center cursor-pointer justify-center border-4 border-dashed rounded"
                >
                    <Add className="text-gray-300" />
                </div>
            </div>
            <p className="col-span-12 text-lg font-medium">Пакеты</p>
            {contract ? (
                <React.Fragment>
                    {contract.bundles.map(val => (
                        <div className="col-span-4" key={val.id}>
                            <div
                                // onClick={() => setContract(val)}
                                className={clsx(
                                    'w-full h-full p-5 border-2 shadow-md cursor-pointer rounded',
                                    val.active ? 'bg-lime-500 border-lime-500' : 'bg-gray-300 border-gray-300'
                                )}
                            >
                                <p className="flex">
                                    <div className="w-1/2">
                                        <p>Games: {val.amount_games}</p>
                                        <p>Start: {val.start}</p>
                                        {!val.active ? <p>End: {val.end}</p> : null}
                                    </div>
                                    <div className="flex-1">
                                        <p>Share: <span onClick={() => handleEditField("personal_share", val)} className={clsx(val.active ? 'text-cyan-700 hover:underline decoration-blue-400' : '')}>{val.personal_share}%</span></p>
                                        <p>Payout: <span onClick={() => handleEditField("payout", val)} className={clsx(val.active ? 'text-cyan-700 hover:underline decoration-blue-400' : '')}>{val.payout}%</span></p>
                                        <p>Result: ${val.usd_result}</p>
                                    </div>
                                    <div className="ml-4 self-center">
                                        {val.active ? (
                                            <Tooltip title="Создать выплату по пакету">
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setBundle(val);
                                                        setBundlePayoutOpen(true);
                                                    }}
                                                >
                                                    <PaymentsIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                    <div className="ml-4 self-center">
                                        <Tooltip title="Изменить статус пакета">
                                            <IconButton type="button" onClick={() => bundleMutation.mutate({ id: val.id, active: !val.active })}>
                                                {val.active ? <Cancel /> : <CheckCircle />}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </p>
                            </div>
                        </div>
                    ))}
                    <div className="col-span-4">
                        <div
                            onClick={() => {
                                setBundleOpen(true);
                                queryClient.invalidateQueries(['last_bundle', playerId]);
                            }}
                            className="py-2 h-full flex items-center cursor-pointer justify-center border-4 border-dashed rounded"
                        >
                            <Add className="text-gray-300" />
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <p className="col-span-12 text-center">Выберите контракт</p>
            )}

            <NewContractDialog open={contractOpen} onClose={() => setContractOpen(false)} />

            <NewBundleDialog
                open={bundleOpen}
                onClose={() => {
                    setBundleOpen(false);
                }}
                contractId={contract?.id}
            />

            <BundlePayoutDialog open={bundlePayoutOpen} onClose={() => setBundlePayoutOpen(false)} bundleId={bundle?.id} />

            <Dialog fullWidth maxWidth='sm' open={!!editField} onClose={handleCloseEditField} fullScreen={fullScreen}>
                <ContractEditFieldDialog close={handleCloseEditField} field={editField} contract={selectedContract} />
            </Dialog>
        </>
    );
};

export default Contracts;
