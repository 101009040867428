import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef } from '@mui/x-data-grid';

import { updateAbility } from 'api/ability';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу ABI.
 */
const AbilityTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const editItemMutation = useMutation(updateAbility, {
        onSuccess: () => {
            queryClient.invalidateQueries('ability-data');
            queryClient.invalidateQueries('ability-data-turbo');
        },
    });

    const columns: GridColDef[] = [
      {
        field: 'buyin_name',
        headerName: 'Байин',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'separator_1',
        headerName: 'Очень хорошие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'separator_2',
        headerName: 'Хорошие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'separator_3',
        headerName: 'Средние',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'separator_4',
        headerName: 'Плохие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
    ];

    function editCell(params) {
        if(Number(params.formattedValue) !== Number(params.value)) {
            const editId = params.id;
            const editData = {
                [params.field] : params.value
            }

            editItemMutation.mutate({id: editId, body: editData})
        }
    }

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
      <DataGrid
          autoHeight={true}
          rows={items}
          columns={columns}
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          onCellEditCommit={(params: GridCellEditCommitParams) => editCell(params)}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default AbilityTable;
