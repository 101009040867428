import { FC } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { getPLayersV2 } from 'api/players';
import {
    Action,
    SET_PLAYER_ID_FILTER,
} from './state'

type Props = {
    playerFilter: any;
    dispatch: React.Dispatch<Action>;
};

const ScoringPlayerFilters: FC<Props> = ({
    playerFilter,
    dispatch,
}) => {
    const playersQuery = useQuery(['players-list', null], getPLayersV2, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const defaultPropsPlayers = {
        options: playersQuery.data ?? [],
        getOptionLabel: option => option.username ?? '',
    };

    return (
        <>
            <Box className="flex flex-row flex-wrap gap-2 ">
                <Autocomplete
                    {...defaultPropsPlayers}
                    disablePortal
                    id="players"
                    sx={{ width: 250 }}
                    disabled={playersQuery.isFetching}
                    value={playerFilter ?? null}
                    onChange={(event: any, newValue: any) => {
                        dispatch({ type: SET_PLAYER_ID_FILTER, payload: newValue })
                    }}
                    renderInput={params => <TextField {...params} label="Ник игрока" />}
                    className="bg-white"
                />
            </Box>
        </>
    );
};

export default ScoringPlayerFilters;
