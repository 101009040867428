import { FC, useState, useEffect } from 'react';

import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useQuery } from 'react-query';

import ChartsFilters from './ChartsFilters';
import StatContainer from './StatContainer';

import { SchedulerQueryKeys } from 'constants/QueryKeys/schedulerKeys';
import { loadOnceConfig } from 'constants/QueryKeys/queryConfig';
import { getGroupStatsReport } from 'api/schedulersettings';

type Props = {
    /**
     * Имя турнира.
     */
    hash: string;

    /**
     * ID турнира.
     */
    name: string;

    /**
     * Закрытие вкладки с ручной оценкой турниров.
     */
    close: () => void;
}

/**
 * График с оценкой группы турниров.
 */
const Charts: FC<Props> = ({ hash, name, close }) => {
    const [filter, setFilter] = useState({
        'day_number': null
    });
    const [rangeDate, setRangeDate] = useState();

    const { data: charts, isFetching } = useQuery(SchedulerQueryKeys.schedulerGroupStatsReport(hash, filter.day_number), getGroupStatsReport, {
        ...loadOnceConfig,
        enabled: filter.day_number !== null
    })

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className='mb-4'>
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => close()}
                    className='cursor-pointer'
                >
                    Список турниров
                </Link>
                <Typography color="text.primary">{name}</Typography>
            </Breadcrumbs>
            <ChartsFilters setFilter={setFilter} setRangeDate={setRangeDate} data={filter} rangeDate={rangeDate} />
            {charts?.report.stats && <StatContainer stats={charts?.report.stats} />}
        </>
    );
}

export default Charts;
