import { FC, useState } from 'react';
import moment from 'moment';

import {
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation, useQueryClient } from 'react-query';
import { deleteSeriesItem } from 'api/series';
import DeleteDialog from 'components/DeleteDialog';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу Серия турниров.
 */
const SeriesTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();
    const currentDate = moment().toISOString();

    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [item, setItem] = useState<number>(0);

    const { mutate, isLoading } = useMutation(deleteSeriesItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('series-data');
        },
    });

    const columns: GridColDef[] = [
      { field: 'network_name', headerName: 'Рум', flex: 0.3, minWidth: 100},
      {
        field: 'start_dt',
        headerName: 'Дата начала',
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.start_dt).format('DD.MM.YYYY HH:mm')}`,
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: 'end_dt',
        headerName: 'Дата окончания',
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.end_dt).format('DD.MM.YYYY HH:mm')}`,
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: 'comment',
        headerName: 'Комментарий',
        flex: 1,
        minWidth: 100,
      },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          function deliteItem(id: number) {
            mutate(item);
            setOpen(false);
          }

          function openDialog (id: number) {
            setItem(id);
            setOpen(true);
          }
            return (
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton onClick={() => openDialog(params.row.id)} disabled={isLoading}>
                  <DeleteIcon />
                </IconButton>
                <DeleteDialog close={() => setOpen(false)} open={open} loading={false} handleAgreeAction={() => deliteItem(params.row.id)}/>
              </Box>
            );
        }
      }
    ];

  return (
    <>
      <Box
        className="h-auto overflow-auto"
        sx={{
          '& .active': {
            backgroundColor: '#fcecd5',
            color: '#1a3e72',
          },
        }}
      >
        <DataGrid
            autoHeight={true}
            rows={items}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            disableSelectionOnClick
            disableColumnMenu
            getCellClassName={(params: GridCellParams<number>) => {
              return params.row.end_dt > currentDate && params.row.start_dt < currentDate ? 'active' : '';
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Данные отсутствуют
                </Stack>
              ),
            }}
        />
      </Box>

    </>
  );
};

export default SeriesTable;
