import React from 'react';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppOutlined from '@mui/icons-material/ExitToAppOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { useLocation, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import menu from 'constants/menu';
import lightTheme from 'themes/lightTheme';

import { auth_logout } from 'store/auth/auth';

import DashboardLink from './components/DashboardLink/DashboardLink';

import userPNG from 'assets/images/user.png';
import { useQueryClient } from 'react-query';

const DRAWER_BREAKPOINT = 1024;

const Dashboard: React.FC = () => {
    const queryClient = useQueryClient();
    
    const location = useLocation();
    const dispatch = useDispatch();

    const smallScreen = useMediaQuery(lightTheme.breakpoints.down('md'));

    const [open, setOpen] = React.useState(window.innerWidth < DRAWER_BREAKPOINT ? false : true);
    const [header, setHeader] = React.useState('Главная');
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        setHeader(menu.find(val => val.route === location.pathname)?.name || 'Главная');
    }, [location]);

    const handleLogout = () => {
        queryClient.cancelQueries();
        queryClient.clear();
        queryClient.invalidateQueries();
        
        setAnchorEl(null);

        dispatch(auth_logout());
    };

    const handleDrawerClose = () => {
        if (smallScreen) setOpen(false);
    };

    return (
        <main className="h-screen w-screen flex">
            <Drawer
                variant={smallScreen ? 'temporary' : 'persistent'}
                anchor="left"
                className={smallScreen ? '' : `drawer ${open ? 'drawer-width' : ''}`}
                classes={{ paper: `bg-header border-r-0 drawer-width` }}
                open={open}
                onClose={() => setOpen(prev => !prev)}
            >
                <div className="bg-main-500 flex items-center justify-center" style={{ height: '80px' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/logos/logo_kot.svg`}
                        alt="King of Tournaments"
                        className="h-1/2"
                    />
                    <p className="text-white font-medium text-lg leading-none ml-4">
                        Кабинет<br />
                        Администратора
                    </p>
                </div>
                {menu.map((val, i) => <DashboardLink key={i} route={val} handleDrawerClose={handleDrawerClose} />)}
            </Drawer>
            <div className="w-full flex-1 flex flex-col">
                <header className="bg-header w-full flex items-center justify-between lg:px-5 px-2" style={{ height: '80px' }}>
                    <div className="flex items-center">
                        <button type="button" onClick={() => setOpen(prev => !prev)}>
                            <MenuIcon className="text-white" />
                        </button>
                        <p className="text-white text-2xl ml-4 font-medium">{header}</p>
                    </div>
                    <div className="h-12 w-12 rounded-full border-white border-4 overflow-hidden cursor-pointer" onClick={e => setAnchorEl(e.currentTarget as any)}>
                        <img src={userPNG} alt="user" className="w-full h-full" />
                    </div>
                </header>
                <main className="w-full flex-1 overflow-y-auto">
                    <div className="lg:p-5 p-2 min-h-full flex flex-col w-full mx-auto" style={{ maxWidth: 1400 }}>
                        <Outlet />
                    </div>
                </main>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem>
                    <ListItemIcon>
                        <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={"UserName"} className="font-medium text-gray-700" />
                    {/* <ListItemText primary={profile?.username} className="font-medium text-gray-700" /> */}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Выйти" className="font-medium text-gray-700" />
                </MenuItem>
            </Menu>
        </main>
    )
}

export default Dashboard;
