import axios from 'helpers/axios';

export const getTransfers = async ({ queryKey }) => {
    // const { data } = await axios.get('/api/adminapi/transfers/');
    const [, selectType] = queryKey;
    const { data } = await axios.get('/api/adminapi/transfersv2/', {params: {select: selectType}});

    return data;
};

export const createTransferFromDeposit = async ({ deposit_request_id, ...body }/* { deposit_id, sender_id } */) => {
    // const { data } = await axios.post('/api/adminapi/deposits/createtransfer/', { deposit_id, sender_id });
    const { data } = await axios.post(`/api/adminapi/depositrequests/${deposit_request_id}/assign/`, body);

    return data;
};


export const createTransfer = async (body/* { deposit_id, sender_id } */) => {
    // const { data } = await axios.post('/api/adminapi/deposits/createtransfer/', { deposit_id, sender_id });
    const { data } = await axios.post('/api/adminapi/transfersv2/', body);

    return data;
};


export const getTransferInfo = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/transfers/${id}/`);

    return data;
};


export const updateTransferInfo = async ({ transferId, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/transfers/${transferId}/`, body);

    return data;
};


export const deleteTransfer = async ({ transferId }) => {
    const { data } = await axios.delete(`/api/adminapi/transfers/${transferId}/`);

    return data;
};



