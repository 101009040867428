import axios from 'helpers/axios';

/**
 * Список группы для неизвестных игроков.
 */
export const getGroupListUnknownPlayers = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/untrackedlabelratiogroups/');

    return data;
};

/**
 * Удалить группу
 */
export const deleteGroup = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/untrackedlabelratiogroups/${id}/`);

    return data;
};

/**
 * Создать группу для неизвестных игроков
 */
export const createGroup = async (rule) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/untrackedlabelratiogroups/`, rule);

    return data;
};

/**
 * Данные по конкретной группе.
 */
export const getGroupDetails = async ({ queryKey }) => {
    const [, id] = queryKey;
    if (!id) {
        return;
    }

    const { data } = await axios.get(`/api/adminapi/statsscoring/untrackedlabelratiogroups/${id}/`);

    return data;
};

/**
 * Добавить долю по метке в группе
 */
export const creatGroupRate = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/untrackedlabelratiogroups/${id}/ratios/`, body);

    return data;
};

/**
 * Удалить долю по метке в группе
 */
export const deleteGroupMark = async ({ group_id, ratio_id }) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/untrackedlabelratiogroups/${group_id}/ratios/${ratio_id}/`);

    return data;
};
