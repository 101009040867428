/**
 * Содержит низкоуровневые методы для работы с списком румов.
 */
 export default class RoomHelper {
    /**
     * Возвращает список румов без дефолтного рума.
     */
    public static withoutDefault(rooms: any) {
        if (!rooms) {
            return [];
        }
        
        return rooms.filter(item => item.network !== 'Default');
    }
}
