import { FC } from 'react';
import { Autocomplete, Box, Button, MenuItem, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { getPLayers, getCurators } from 'api/playerstats';

type Props = {
    /**
     * Выбранное значение фильтрации по игроку.
     */
    playerFilter: any;

    /**
     * Задать значение фильтрации по игроку.
     */
    setPlayerFilter: React.Dispatch<any>;

    /**
     * Выбранное значение фильтрации по куратору.
     */
    curatorFilter: any;

    /**
     * Задать значение фильтрации по куратору.
     */
    setCuratorFilter: React.Dispatch<any>;

    /**
     * Выбранное значение фильтрации по ревизии.
     */
    revision: string;

    /**
     * Задать значение фильтрации по ревизии.
     */
    setRevision: React.Dispatch<React.SetStateAction<string>>;

    /**
     * Задать флаг открытия модального окна по добавлению статистики
     */
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Фильтр статистики игроков.
 */
const EvStatsFilter: FC<Props> = ({ playerFilter, curatorFilter, revision, setPlayerFilter, setCuratorFilter, setRevision, setOpen }) => {
    const { data: players, isFetching: playersFetching } = useQuery(['ev-analysis-stats-players', curatorFilter], getPLayers, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const { data: curators, isFetching: curatorsFetching } = useQuery(['ev-analysis-stats-curators'], getCurators, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const defaultPropsPlayers = {
        options: players ?? [],
        getOptionLabel: (option) => option.username ?? '',
    };

    const defaultPropsCurators = {
        options: curators ?? [],
        getOptionLabel: (option) => option.curator_nickname ?? '',
    };

    return (
        <>
            <Box className='flex flex-row flex-wrap gap-2'>
                <Autocomplete
                    {...defaultPropsCurators}
                    disablePortal
                    id="curators"
                    sx={{ width: 250 }}
                    disabled={curatorsFetching}
                    value={curatorFilter ?? null}
                    onChange={(event: any, newValue: string | null) => {
                        setCuratorFilter(newValue);
                        setPlayerFilter(null);
                    }}
                    renderInput={(params) => <TextField {...params} label="Ник куратора" />}
                    className="bg-white"
                />
                <Autocomplete
                    {...defaultPropsPlayers}
                    disablePortal
                    id="players"
                    sx={{ width: 250 }}
                    disabled={playersFetching}
                    value={playerFilter ?? null}
                    onChange={(event: any, newValue: string | null) => {
                        setPlayerFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Ник игрока" />}
                    className="bg-white"
                />
                <Box className='w-[100px] bg-white'>
                    <TextField label='Ревизий' value={revision} onChange={({ target }) => setRevision(target.value)} fullWidth select>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                    </TextField>
                </Box>
                {playerFilter?.id && (
                    <Box className='flex flex-1 justify-end'>
                        <Button variant='contained' size='large' onClick={() => setOpen(true)}>
                            Добавить
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default EvStatsFilter;
