export const SET_PLAYER_ID_FILTER = "SET_PLAYER_ID_FILTER";
export const SET_ROOM_ID_FILTER = "SET_ROOM_ID_FILTER";
export const SET_START_DATE_FILTER = "SET_START_DATE_FILTER";
export const SET_END_DATE_FILTER = "SET_END_DATE_FILTER";

interface FilterState {
    playerIdFilter: any;
    roomIdFilter: any;
    startDateFilter: any;
    endDateFilter: any;
}

export const initialState: FilterState = {
    playerIdFilter: null,
    roomIdFilter: null,
    startDateFilter: null,
    endDateFilter: null,
};

export type Action =
    | { type: typeof SET_PLAYER_ID_FILTER, payload: any }
    | { type: typeof SET_ROOM_ID_FILTER, payload: any }
    | { type: typeof SET_START_DATE_FILTER, payload: any }
    | { type: typeof SET_END_DATE_FILTER, payload: any };

export const filterReducer = (state: FilterState, action: Action): FilterState => {
    switch (action.type) {
        case SET_PLAYER_ID_FILTER:
            return { ...state, playerIdFilter: action.payload };
        case SET_ROOM_ID_FILTER:
            return { ...state, roomIdFilter: action.payload };
        case SET_START_DATE_FILTER:
            return { ...state, startDateFilter: action.payload };
        case SET_END_DATE_FILTER:
            return { ...state, endDateFilter: action.payload };
        default:
            return state;
    }
};
