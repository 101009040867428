import { FC, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import EvStatsFilter from './EvStatsFilter';
import EvStatsTable from './EvStatsTable';
import EvStatsModal from './EvStatsModal';

/**
 * Страница с статистикой игроков.
 */
const EvStats: FC = () => {
    // Фильтр по игрокам
    const [playerFilter, setPlayerFilter] = useState<any>(null);
    // Фильтр по кураторам
    const [curatorFilter, setCuratorFilter] = useState<any>(null);
    // Фильтр по ревизии
    const [revision, setRevision] = useState<string>('1');
    // Флаг добавления новой статистики для выбранного игрока
    const [open, setOpen] = useState(false);

    return (
        <>
            <Box>
                <EvStatsFilter
                    playerFilter={playerFilter}
                    setPlayerFilter={setPlayerFilter}
                    curatorFilter={curatorFilter}
                    setCuratorFilter={setCuratorFilter}
                    revision={revision}
                    setRevision={setRevision}
                    setOpen={setOpen}
                />
            </Box>
            <Card className='mt-4'>
                {!playerFilter?.id &&
                    <Box className='flex flex-1 flex-col items-center justify-center h-80'>
                        <Typography variant="h5" component="span" className='text-slate-600'>Необходимо выбрать игрока</Typography>
                    </Box>
                }
                {playerFilter?.id && <EvStatsTable id={playerFilter?.id} revision={revision} />}
            </Card>
            {playerFilter && playerFilter.id && open && (
                <EvStatsModal playerId={playerFilter.id} open={open} onClose={() => setOpen(false)} />
            )}
        </>
    );
}

export default EvStats;
