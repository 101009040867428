import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRooms } from 'api/rooms';
import RoomsFilter from 'components/RoomsFilter';
import { Box, Card, CircularProgress, TextField } from '@mui/material';
import RoomHelper from 'helpers/RoomHelper';
import { getSchedulerSeries } from 'api/schedulersettings';
import SchedulerSeriesTable from './SchedulerSeriesTable';
import { DateTimePicker } from '@mui/lab';

/**
 * Вкладка с информацией о турнирных сериях в расписаторе.
 */
const SchedulerSeries: FC = () => {
    const [filter, setFilter] = useState([]);
    const [endAtFrom, setEndAtFrom] = useState(null);
    const [result, setResult] = useState();

    const { data: rooms } = useQuery(['scheduler-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const { data, isFetching } = useQuery(['scheduler-series', filter, endAtFrom], getSchedulerSeries, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    useEffect(() => {
        if (filter.length > 0) {
            setResult(data.series.filter(({network}) => filter.find(item => item.network === network)));
        } else {
            setResult(data?.series ?? []);
        }
    }, [data, filter]);

    return (
        <>
            <Box className='flex flex-row gap-4'>
                <RoomsFilter setFilter={setFilter} data={filter} rooms={RoomHelper.withoutDefault(rooms)} />
                <DateTimePicker
                    label="Турниры после"
                    value={endAtFrom}
                    onChange={(newValue) => setEndAtFrom(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="DD/MM/YYYY HH:mm"
                />
            </Box>
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><SchedulerSeriesTable items={result}/></Card>}
        </>
    );
}

export default SchedulerSeries;
