import axios from 'helpers/axios';

/**
 * Список меток.
 */
export const getMarks = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/labels/');

    return data;
};

/**
 * Удалить метку
 */
 export const deleteMark = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/labels/${id}/`);

    return data;
};

/**
 * Создать метку
 */
export const createMark = async (mark) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/labels/`, mark);

    return data;
};