import axios from 'helpers/axios';

export const getAvailableBalances = async ({ queryKey }) => {
    const [, roomId, chipCurrencyId, amount] = queryKey;

    let params = new URLSearchParams();

    if (roomId) {
        params.append('roomId', roomId);
    }
    if (chipCurrencyId) {
        params.append('chipCurrencyId', chipCurrencyId);
    }
    if (amount) {
        params.append('amount', amount);
    }

    const { data } = await axios.get('/api/adminapi/availablebalances/', { params: params });

    return data;
};
