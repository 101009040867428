import axios from 'helpers/axios';


export const getBlockedTransfersRooms = async ({ queryKey }) => {
    const [, userId] = queryKey;
    const { data } = await axios.get(`/api/adminapi/user/${userId}/blockedtransfersrooms/`);

    return data;
};

export const addBlockedTransfersRoom = async ({ userId, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/user/${userId}/blockedtransfersrooms/`, body);

    return data;
};

export const deleteBlockedTransfersRoom = async ({ userId, roomId }) => {
    const { data } = await axios.delete(`/api/adminapi/user/${userId}/blockedtransfersrooms/${roomId}/`);

    return data;
};
