import axios from 'helpers/axios';

export const getRevision = async () => {
    const { data } = await axios.get(`/api/adminapi/ggnetworkscoring/playermaxbuyins/revisions/`);

    return data;
};

export const getPlayersRevisionMaxBuyins = async ({ queryKey }) => {
    const [, revision, perPage, page] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    let params = new URLSearchParams();

    if (perPage) {
        params.append("limit", perPage);
    }

    if (offset) {
        params.append("offset", String(offset));
    }

    const request = {
        params: params,
    };

    if (revision && revision.id) {
        const { data } = await axios.get(`/api/adminapi/ggnetworkscoring/playermaxbuyins/revisions/${revision.id}/`, request);

        return data;
    }
};

export const getPlayersMaxBuyins = async ({ queryKey }) => {
    const [, playerId, perPage, page] = queryKey;
    const offset = page ? Math.ceil(Number(page) * Number(perPage)) : null;

    let params = new URLSearchParams();

    if (perPage) {
        params.append("limit", perPage);
    }

    if (offset) {
        params.append("offset", String(offset));
    }

    const request = {
        params: params,
    };

    if (playerId) {
        const { data } = await axios.get(`/api/adminapi/ggnetworkscoring/players/${playerId}/playermaxbuyins/`, request);

        return data;
    }
};

export const getPlayerGGNetworkMaxBuyinHistory = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/ggnetworkscoring/players/${playerId}/playermaxbuyins/history/`);

    return data;
}

export const getPlayerGGNetworkCurrentManualMaxBuyin = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/ggnetworkscoring/players/${playerId}/manualmaxbuyins/current/`);

    return data;
}

export const createPlayerGGNetworkManualMaxBuyin = async ({ playerId, maxBuyin }) => {
    const { data } = await axios.post(`/api/adminapi/ggnetworkscoring/players/${playerId}/manualmaxbuyins/`, {
        max_buyin: maxBuyin,
    });

    return data;
}

export const disablePlayerGGNetworkManualMaxBuyin = async ({ playerId }) => {
    const { data } = await axios.post(`/api/adminapi/ggnetworkscoring/players/${playerId}/manualmaxbuyins/disable/`);

    return data;
}

export const getDefaultMaxBuyins = async () => {
    const { data } = await axios.get(`/api/adminapi/ggnetworkscoring/maxbuyins/default/`);

    return data;
}

export const updateDefaultMaxBuyin = async ({ id, maxBuyin }) => {
    const { data } = await axios.put(`/api/adminapi/ggnetworkscoring/maxbuyins/${id}/`, maxBuyin);

    return data;
}
