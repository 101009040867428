import axios from 'helpers/axios';

/**
 * Список коэффициентов
 */
export const getCoefficient = async () => {
    const { data } = await axios.get('/api/adminapi/grid/abilitymodifiers/');

    return data;
};

/**
 * Удалить коэффициента
 */
export const deleteCoefficientItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/abilitymodifiers/${id}/`);

    return data;
};

/**
 * Создать коэффициента
 */
export const createCoefficientItem = async (coefficient) => {
    const { data } = await axios.post(`/api/adminapi/grid/abilitymodifiers/`, coefficient);

    return data;
};
