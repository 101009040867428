import moment from 'moment';

import axios from 'helpers/axios';

export const getPLayers = async () => {
    const { data } = await axios.get('/api/adminapi/players/');

    return data;
};

export const getPLayersV2 = async () => {
    const { data } = await axios.get('/api/adminapi/playersv2/');

    return data;
};

export const createPlayer = async (body: { username: string; password: string; player_level: string; telegram: string }) => {
    // const { data } = await axios.post('/api/adminapi/players/', body);
    const { data } = await axios.post('/api/adminapi/playersv2/', body);

    return data;
};

export const getPlayerAccounts = async ({ queryKey }) => {
    const [, id] = queryKey;

    // const { data } = await axios.get(`/api/adminapi/player/${id}/accounts/`);
    const { data } = await axios.get(`/api/adminapi/user/${id}/roomaccounts/`);

    return data;
};

export const getPlayerAccountRecords = async ({ queryKey }) => {
    const [, id, isActive, chipCurrencyId] = queryKey;

    var params = new URLSearchParams();

    if (isActive) {
        params.append('isActive', isActive);
    }
    if (chipCurrencyId) {
        params.append('chipCurrencyId', chipCurrencyId);
    }

    const { data } = await axios.get(`/api/adminapi/user/${id}/accountrecords/`, { params: params });

    return data;
};

export const getPlayerContracts = async ({ queryKey }) => {
    const [, id] = queryKey;

    // const { data } = await axios.get(`/api/adminapi/player/${id}/contracts/`);
    const { data } = await axios.get(`/api/adminapi/user/${id}/contracts/`);

    return data.map(contract => ({
        ...contract,
        start: moment(contract.start_datetime).format('DD MMM YYYY HH:mm'),
        end: moment(contract.end_datetime).format('DD MMM YYYY HH:mm'),
        bundles: contract.bundles.map(bundle => ({
            ...bundle,
            start: moment(bundle.start_datetime).format('DD MMM YYYY HH:mm'),
            end: moment(bundle.end_datetime).format('DD MMM YYYY HH:mm'),
        })),
    }));
};

export const getPlayerGraphs = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/userstats/${id}/`);

    return data;
};

export const getPlayerInfo = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/player/${id}/`);

    return data;
};

//old
export const updatePlayerInfo = async ({ playerId, ...body }) => {
    const { data } = await axios.put(`/api/adminapi/players/${playerId}/`, body);

    return data;
};

export const updateUserInfo = async ({ playerId, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/user/${playerId}/`, body);

    return data;
};

export const getPlayerEwallets = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${id}/ewallets/`);

    return data;
};

export const getPlayerPersonalFunds = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${id}/personalfunds/`);

    return data;
};

export const getUserInfo = async ({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${id}/`);

    return data;
};

export const getUserTransfersAsSender = async ({ queryKey }) => {
    const [, userId, sent, roomId] = queryKey;
    const { data } = await axios.get(`/api/adminapi/user/${userId}/transfers/sender/`, { params: { sent: sent, roomId: roomId } });

    return data;
};

export const getPlayerFundFlowList = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/fundflowlist/`);

    return data;
};

export const getPlayerActiveVacation = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/vacations/?include=current&include=future&include=past`);

    return data;
};

export const createVacation = async ({ playerId, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/user/${playerId}/vacations/`, body);

    return data;
};

export const deleteVacation = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/vacations/${id}/`);

    return data;
};

export const updateVacation = async ({ vacationId, ...body }) => {
    const { data } = await axios.put(`/api/adminapi/vacations/${vacationId}/`, body);

    return data;
};

export const migrateUserToNewTerms = async (playerId: string) => {
    const { data } = await axios.post(`/api/adminapi/user/${playerId}/updateterms/`);

    return data;
};

export const getPlayerUntrackedTournaments = async ({ queryKey }) => {
    const [, playerId, roomId, startDate, endDate] = queryKey;

    var params = new URLSearchParams();

    if (roomId) {
        params.append('roomId', roomId);
    }
    if (startDate) {
        params.append('startDate', startDate);
    }
    if (endDate) {
        params.append('endDate', endDate);
    }

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/untrackedtournaments/`, { params: params });

    return data;
};

export const getPlayerUntrackedTournamentsCount = async ({ queryKey }) => {
    const [, playerId, roomId, startDate, endDate] = queryKey;

    var params = new URLSearchParams();

    if (roomId) {
        params.append('roomId', roomId);
    }
    if (startDate) {
        params.append('startDate', startDate);
    }
    if (endDate) {
        params.append('endDate', endDate);
    }

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/untrackedtournaments/count/`, { params: params });

    return data;
};

export const getPlayerProfit = async ({ queryKey }) => {
    const [, playerId, roomId, startDate, endDate] = queryKey;

    var params = new URLSearchParams();

    if (roomId) {
        params.append('roomId', roomId);
    }
    if (startDate) {
        params.append('startDate', startDate);
    }
    if (endDate) {
        params.append('endDate', endDate);
    }

    const { data } = await axios.get(`/api/adminapi/users/${playerId}/profit/`, { params: params });

    return data;
};

export const createUntrackedTournaments = async ({ playerId, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/user/${playerId}/untrackedtournaments/`, body);

    return data;
};

export const deleteUntrackedTournaments = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/untrackedtournaments/${id}/`);

    return data;
};

export const patchUntrackedTournaments = async ({ tournamentId, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/untrackedtournaments/${tournamentId}/`, body);

    return data;
};

export const getPlayerDeductibles = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/deductibles/`);

    return data;
};

export const createDeductible = async ({ playerId, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/user/${playerId}/deductibles/`, body);

    return data;
};

export const deleteDeductible = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/deductibles/${id}/`);

    return data;
};

export const createPlayerDeductible = async ({ playerId, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/user/${playerId}/deductibles/`, body);

    return data;
};

export const getPlayerDeductiblesCategories = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/deductibles/categories/`);

    return data;
};

export const getPlayerBonusInfo = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/bonus/`);

    return data;
};

export const getPlayerContractTournamentsNum = async ({ queryKey }) => {
    const [, playerId] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${playerId}/contracttournaments/`);

    return data;
};

export const createAccountBalanceTransfer = async ({ ...body }) => {
    const { data } = await axios.post(`/api/adminapi/accountrecordbalances/transfer/`, body);

    return data;
}
