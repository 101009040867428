import { FC, useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getRoomsAbility, getAbility, deleteAbilityItem } from 'api/ability';
import { Box, Card, CircularProgress, Typography, Button, Dialog, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import theme from 'themes/lightTheme';

import RoomsFilter from 'components/RoomsFilter/RoomsFilter';
import AbilityTable from './AbilityTable';
import DeleteDialog from 'components/DeleteDialog';
import ImportDialog from './ImportDialog';

/**
 * Вкладка с информацией ABI.
 */
const Ability: FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const defaultValue = {
        "id": 13,
        "network": "Default"
    }
    const [filter, setFilter] = useState([defaultValue]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);

    const selectRoom = filter[0] ?? defaultValue;
    const queryClient = useQueryClient();

    useEffect(() => {
        if(!filter[0]) {
            setFilter([defaultValue]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const {data: rooms} = useQuery(['ability-rooms'], getRoomsAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    const {data: abilityTurbo, isFetching: abilityTurboFetching} = useQuery(['ability-data-turbo', selectRoom, 'turbo'], getAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    const {data: abilityRebuy, isFetching: abilityRebuyFetching} = useQuery(['ability-data-rebuy', selectRoom, 'rebuy'], getAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    const {data: ability, isFetching: abilityFetching} = useQuery(['ability-data-regular', selectRoom, 'regular'], getAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    const delItemMutation = useMutation(deleteAbilityItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('ability-data-regular');
            queryClient.invalidateQueries('ability-data-turbo');
            queryClient.invalidateQueries('ability-data-rebuy');
            queryClient.invalidateQueries('ability-rooms');
            setFilter([defaultValue]);
        },
    });

    function deliteItem(id: number) {
        delItemMutation.mutate(id);
        setDeleteDialog(false);
    }

    return (
        <>
            {abilityTurboFetching && abilityFetching && abilityRebuyFetching && !abilityRebuy && !abilityTurbo && !ability && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {ability && abilityTurbo && abilityRebuy &&
                <>
                    <Box className='flex flex-row justify-between gap-2 items-center'>
                        <Box>
                            <RoomsFilter setFilter={setFilter} data={filter} rooms={rooms} single />
                        </Box>
                        <Box>
                            <Button variant="contained" size='large' type='submit' endIcon={<ImportExportIcon />} onClick={() => setImportDialog(true)} className="mr-2">
                                Импорт
                            </Button>
                            <Button variant="outlined" size='large' type='submit' color="error" startIcon={<DeleteIcon />} onClick={() => setDeleteDialog(true)}>
                                Удалить
                            </Button>
                        </Box>
                    </Box>
                    <DeleteDialog close={() => setDeleteDialog(false)} open={deleteDialog} loading={false} handleAgreeAction={() => deliteItem(filter[0].id)}/>
                    <Box className="flex flex-col gap-2 mt-2 lg:flex-row">
                        <Box className='flex-auto'>
                            <Typography variant='h5'>Обычные турниры</Typography>
                            <Card>
                                <AbilityTable items={ability} />
                            </Card>
                        </Box>
                    </Box>
                    <Box className="flex flex-col gap-2 mt-2 lg:flex-row">
                        <Box className='flex-auto'>
                            <Typography variant='h5'>Турбо турниры</Typography>
                            <Card>
                                <AbilityTable items={abilityTurbo} />
                            </Card>
                        </Box>
                        <Box className='flex-auto'>
                            <Typography variant='h5'>Ребай турниры</Typography>
                            <Card>
                                <AbilityTable items={abilityRebuy} />
                            </Card>
                        </Box>
                    </Box>
                    <Dialog fullWidth maxWidth='md' open={importDialog} onClose={() => setImportDialog(false)} fullScreen={fullScreen} >
                        <ImportDialog close={() => setImportDialog(false)} active={filter} />
                    </Dialog>
                </>
            }
        </>
    );
}

export default Ability;
