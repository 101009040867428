import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import DateTimePicker from '@mui/lab/DateTimePicker'

import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createContract } from 'api/contracts';

const NewContractDialog: React.FC<DialogProps> = (props) => {
    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            amount_games: 3000,
            start_datetime: ''
        }
    });

    const { mutate, isLoading } = useMutation(createContract, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-contracts', playerId]);

            props.onClose({}, 'backdropClick');

            reset();
        }
    });

    const onSubmit = handleSubmit((data: any) => {
        mutate({ ...data, start_datetime: data.start_datetime.toISOString(), user_id: playerId });
    });

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить контракт</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="amount_games"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Кол-во игр"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="start_datetime"
                        control={control}
                        render={({ field }) => (
                            <DateTimePicker
                                {...field}
                                renderInput={props => (
                                    <TextField {...props} label="Дата начала" required fullWidth />
                                )}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewContractDialog;
