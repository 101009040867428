import axios from 'helpers/axios';

/**
 * Список событий серии
 */
export const getSeries = async ({ queryKey }) => {
    const [, showOld ] = queryKey;

    var params = new URLSearchParams();

    if (showOld) {
        params.append("show", "all");
    } else {
        params.append("show", "active")
    }

    const request = {
        params: params
    };

    const { data } = await axios.get('/api/adminapi/grid/series/', request);

    return data;
};

/**
 * Удалить событие серии
 */
export const deleteSeriesItem = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/grid/series/${id}/`);

    return data;
};

/**
 * Создать событие серии
 */
export const createSeriesItem = async (series) => {
    const { data } = await axios.post(`/api/adminapi/grid/series/`, series);

    return data;
};
