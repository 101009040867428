import { FC, useState } from 'react';

import { Breadcrumbs, Card, Link, Typography } from '@mui/material';

import ChartsBody from './ChartsBody';
import ChartsFilters from './ChartsFilters';

type Props = {
    /**
     * Имя турнира.
     */
    name: string;

    /**
     * ID турнира.
     */
    id: number;

    /**
     * Закрытие вкладки с ручной оценкой турниров.
     */
    close: () => void;
}

/**
 * График с оценкой группы турниров.
 */
const Charts: FC<Props> = ({ name, id, close }) => {
    const [filter, setFilter] = useState([{'id': -1, 'name': 'Общий'}]);
    const [rangeDate, setRangeDate] = useState();
   
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className='mb-4'>
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => close()}
                    className='cursor-pointer'
                >
                    Список турниров
                </Link>
                <Typography color="text.primary">{name}</Typography>
            </Breadcrumbs>
            <ChartsFilters setFilter={setFilter} setRangeDate={setRangeDate} data={filter} rangeDate={rangeDate} />
            <Card className='mt-2'><ChartsBody id={id} filters={filter} rangeDate={rangeDate} /></Card>
        </>
    );
}

export default Charts;
