import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface TimeSeries {
    value: number;
    date: string;
}

interface Change {
    two_updates: number;
    five_updates: number;
    ten_updates: number;
}

interface Stat {
    name: string;
    current_value: number;
    change: Change;
    time_series: TimeSeries[];
}

interface StatsComponentProps {
    stats: Stat[];
}

const StatsComponent: React.FC<StatsComponentProps> = ({ stats }) => {
    const valueDisplay = (value: number, isPercentage: boolean = false): JSX.Element => {
        let colorClass = "text-gray-500";
        let arrow = "";
        let formattedValue = isPercentage ? `${Math.round(Math.abs(value * 100))}%` : value.toFixed(2);

        if (value > 0 && isPercentage) {
            colorClass = "text-green-500";
            arrow = "↑";
        } else if (value < 0 && isPercentage) {
            colorClass = "text-red-500";
            arrow = "↓";
        }

        return (
            <span className={`${colorClass} flex items-center`}>
                <span className={`font-bold ${isPercentage ? "mr-1" : ""}`}>{arrow}</span>{formattedValue}
            </span>
        );
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
            {stats.map((stat, index) => (
                <div key={index} className="border p-4 rounded-lg shadow-md flex flex-col">
                    <h5 className="text-lg font-bold mb-4 text-center">{stat.name}</h5>
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={stat.time_series.map(ts => ({ ...ts, date: ts.date.substring(0, 10) }))}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="value" stroke="#4338ca" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="mt-4 flex justify-between text-sm">
                        {/* Current Value: Displayed as a regular number */}
                        <div>Current Value: {valueDisplay(stat.current_value)}</div>
                        {/* Changes: Displayed as percentages */}
                        <div>2d: {valueDisplay(stat.change.two_updates, true)}</div>
                        <div>5d: {valueDisplay(stat.change.five_updates, true)}</div>
                        <div>10d: {valueDisplay(stat.change.ten_updates, true)}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StatsComponent;
