import { useState, FC } from 'react';
import { Box, Chip } from '@mui/material';
import { useQuery } from 'react-query';
import { getRulesRoomList } from 'api/tournamentsrules';
import ManualLabels from './components/ManualLabels/ManualLabels';

const ManualLabelsElem: FC = () => {

    const [tabIndex, setTabIndex] = useState(0);

    function handleChangeTab(newIndex: number) {
        if (tabIndex === newIndex) {
            return;
        }

        setTabIndex(newIndex);
    }

    const { data: rooms, isFetching } = useQuery(['tournaments-rulesets-rooms'], getRulesRoomList, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    return (
        <>
            <Box className='flex flex-row justify-between'>
                <Box className='flex flex-col gap-4'>
                    <Box className='flex gap-1'>
                        <Chip label='Оценка' color={tabIndex === 0 ? 'primary' : 'default'} onClick={() => handleChangeTab(0)} clickable disabled={isFetching} />
                    </Box>
                </Box>
            </Box>
            <Box className="mt-4 p-1">
                {tabIndex === 0 && rooms && <ManualLabels rooms={rooms.networks} />}
            </Box>
        </>
    );
}

export default ManualLabelsElem;
