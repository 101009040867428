import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getSeries } from 'api/series';
import { getRooms } from 'api/rooms';
import RoomsFilter from 'components/RoomsFilter';
import SeriesTable from './SeriesTable';
import { Card, CircularProgress } from '@mui/material';
import RoomHelper from 'helpers/RoomHelper';
import SeriesFilters from './SeriesFilters';

/**
 * Вкладка с информацией о турнирных сериях.
 */
const Series: FC = () => {
    const [filter, setFilter] = useState([]);
    const [result, setResult] = useState();
    // Old series
    const [showOld, setShowOld] = useState<boolean>(false);

    const {data: rooms} = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const {data: series, isFetching} = useQuery(['series-data', showOld], getSeries, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    useEffect(() => {
        if(filter.length > 0) {
            setResult(series.filter(({network_id}) => filter.find(item => item.id === network_id)));
        } else {
            setResult(series);
        }
    }, [series, filter]);

    return (
        <>
            <RoomsFilter setFilter={setFilter} data={filter} rooms={RoomHelper.withoutDefault(rooms)} />
            <SeriesFilters
                showOld={showOld}
                setShowOld={setShowOld}
            />
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><SeriesTable items={result}/></Card>}
        </>
    );
}

export default Series;
