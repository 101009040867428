import { FC, useReducer, useState } from 'react';
import {
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation, useQueryClient } from 'react-query';
import { deleteSchedulerExceptionItem } from 'api/schedulersettings';
import DeleteDialog from 'components/DeleteDialog';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу исключений в расписаторе.
 */
const SchedulerExceptionsTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();
    const [pageSize, setPageSize] = useState<number>(10);
    const [deleteModal, setDeleteModal] = useReducer((value: boolean) => !value, false);
    const [deleteItem, setDeleteItem] = useState<number>();

    const { mutate, isLoading } = useMutation(deleteSchedulerExceptionItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-exceptions');
        },
    });

    function deliteItem() {
        mutate(deleteItem);
        setDeleteModal();
    }

    const columns: GridColDef[] = [
      { field: 'network', headerName: 'Рум', flex: 0.3, minWidth: 100},
      { field: 'value', headerName: 'Значение', flex: 0.3, minWidth: 100 },
      { field: 'is_regex', headerName: 'Regex', flex: 0.3, minWidth: 100 },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            function selectDeliteItem(id: number) {
                setDeleteItem(id);
                setDeleteModal();
            }

            return (
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton onClick={() => selectDeliteItem(params.row.id)} disabled={isLoading} size="small">
                    <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            );
        }
      }
    ];

  return (
    <Box className="h-auto overflow-auto">
      <DataGrid
          autoHeight={true}
          rows={items}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
      <DeleteDialog
            close={setDeleteModal}
            open={deleteModal}
            handleAgreeAction={deliteItem}
        />
    </Box>
  );
};

export default SchedulerExceptionsTable;
