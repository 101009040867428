import axios from 'helpers/axios';
import moment from 'moment';
import { groupBy } from 'lodash';

const buildEvStats = (items: any, username?: string) => {
    const data = items.map(val => ({
        ...val,
        revision_date: moment(val.revision_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        revision_id: val.revision,
    }));

    const columns = data.map(val => ({ id: val.revision_id, revision_date: val.revision_date }));

    return {
        columns,
        username,
        data: Object.values(
            groupBy(
                Object.values(
                    groupBy(
                        data
                            .map(revision => {
                                return revision.ev_stats.map(stat => ({
                                    ...stat,
                                    revision_date: revision.revision_date,
                                }));
                            })
                            .flat(),
                        item => [item.order]
                    )
                ).map(group => {
                    return {
                        columns: [group[0].name, ...group],
                    };
                }),
                'order'
            )
        ).map(val => ({
            rows: val.map(row => row.columns),
        })),
    } as any;
};

export const getEvStats = async ({ queryKey }) => {
    const [, id, revision] = queryKey;

    return axios.get(`/api/adminapi/users/${id}/evanalysis/?numRev=${revision}`).then(({ data }) => [buildEvStats(data)]);
};

export const getEvStatsFields = async () => {
    const { data } = await axios.get('/api/adminapi/evanalysis/stats/');

    return Object.values(groupBy(data, 'order'));
};

export const createEvStats = async ({ playerId, ...body }) => {
    return axios.post(`/api/adminapi/users/${playerId}/evanalysis/`, body);
};

export const deleteRevisionEvStats = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/evanalysis/revisions/${id}/`);

    return data;
};

// export const editCustomStats = async ({ id, ...body }) => {
//     return axios.put(`/api/adminapi/evanalysis/${id}/`, body);
// };

// export const getStatsFieldInfo = async ({ queryKey }) => {
//     const [, id] = queryKey;
//
//     const { data } = await axios.get(`/api/adminapi/customstats/${id}/`);
//
//     return data;
// };
