import { FC, useState } from 'react';

import {
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef/* , GridValueGetterParams */ } from '@mui/x-data-grid';
// import moment from 'moment';
// import { useMutation, useQueryClient } from 'react-query';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу правил нормализации турниров.
 */
const NormRulesTable: FC<Props> = ({ items }) => {
    const [pageSize, setPageSize] = useState<number>(10);

    const columns: GridColDef[] = [
      { field: 'network_name', headerName: 'Рум', flex: 0.2, minWidth: 100 },
      { field: 'pattern', headerName: 'Regex', flex: 0.6, minWidth: 100 },
      { field: 'replace', headerName: 'Замена', flex: 0.2, minWidth: 100 },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        hide: true,
      }
    ];

  return (
    <Box className="h-auto overflow-auto">
      <DataGrid
          autoHeight={true}
          rows={items}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default NormRulesTable;
