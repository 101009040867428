import { FC, Dispatch, SetStateAction } from 'react';

import { Box, Button, FormControlLabel, IconButton, InputBase, ListItemIcon, MenuItem, Paper, Switch } from '@mui/material';
import Dropdown from 'components/Dropdown';
import { Check } from '@mui/icons-material';
import Search from '@mui/icons-material/Search';
import { Controller, useForm } from 'react-hook-form';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Массив примененных фильтров.
     */
    data: any;

    /**
     * Массив румов.
     */
    rooms: [{ id: string; network: string }];

    /**
     * Функция поиска турнира.
     */
    setSearch: Dispatch<SetStateAction<string>>;

    setCsvUploadOpen: () => void;

    /**
     * Имя турнира в поиске.
     */
    search: string;
    showOld: boolean;
    setShowOld: Dispatch<SetStateAction<boolean>>;
};

/**
 * Компонент содержит фильтр румов.
 */
const CustomTournamentsFilters: FC<Props> = ({ data, setFilter, setSearch, rooms, search, setCsvUploadOpen, showOld, setShowOld }) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: search,
        },
    });

    function changeFilter(item) {
        let filter = data.slice();
        const id = filter.findIndex(data => data.id === item.id);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            filter.push(item);
        }

        setFilter(filter);
    }

    function filterCheck(roomId) {
        const id = data.filter(val => val.id === roomId);

        return id[0] ? true : false;
    }

    function filterValue(data?) {
        if (data && data.length > 0) {
            const separator = data.length > 1 ? ' ,' : '';
            return data.map(x => x.network) + separator;
        }
        return undefined;
    }

    const onSubmit = handleSubmit(data => {
        setSearch(data.name);
    });

    return (
        <Box className="flex flex-col">
            <Box className="flex flex-row flex-wrap gap-4 items-center mb-3">
                {/* Фильтр по румам */}
                <Dropdown
                    btnLabel="Рум"
                    btnValue={filterValue(data)}
                    renderContent={() =>
                        rooms?.map(item => {
                            return (
                                <MenuItem onClick={() => changeFilter(item)} key={item.id}>
                                    {filterCheck(item.id) && (
                                        <ListItemIcon>
                                            <Check />
                                        </ListItemIcon>
                                    )}
                                    {item.network}
                                </MenuItem>
                            );
                        })
                    }
                />
                {/* Поиск по имени турнира */}
                <Paper component="form" className="flex w-[280px] h-[48px] bg-gray-50 items-center" sx={{ p: '2px 4px' }} onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => {
                            return (
                                <>
                                    <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Поиск турнира" {...field} />
                                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                        <Search />
                                    </IconButton>
                                </>
                            );
                        }}
                    />
                </Paper>
                {/* Фильтр показывающий старые серии */}
                <FormControlLabel
                    control={<Switch checked={showOld} onChange={() => setShowOld(showOld ? false : true)} name="favorite" color="primary" />}
                    label="Показывать прошедшие"
                />
                {/* Загрузка csv */}
                <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    size="large"
                    onClick={() => {
                        const link = document.createElement('a');
                        link.download = `customtournaments.csv`;
                        link.href = './customtournaments.csv';
                        link.click();
                    }}
                >
                    Скачать форму CSV
                </Button>
                <Button variant="outlined" startIcon={<UploadIcon />} component="label" size="large" onClick={() => setCsvUploadOpen()}>
                    Загрузить CSV
                </Button>
            </Box>
        </Box>
    );
};

export default CustomTournamentsFilters;
