import axios from 'helpers/axios';

export const createPersonalFundTransfer = async ({user_id, ...body}) => {
    // const { data } = await axios.post('/api/adminapi/accounts/', body);
    const { data } = await axios.post(`/api/adminapi/user/${user_id}/personalfunds/`, body);

    return data;
};


export const getPlayerPersonalFundsUSD = async({ queryKey }) => {
    const [, id] = queryKey;

    const { data } = await axios.get(`/api/adminapi/user/${id}/personalfunds/usd/`);

    return data;
}


