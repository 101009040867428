import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRooms } from 'api/rooms';
import RoomsFilter from 'components/RoomsFilter';
import { Card, CircularProgress } from '@mui/material';
import { getSchedulerExceptions } from 'api/schedulersettings';
import SchedulerExceptionsTable from './SchedulerExceptionsTable';

/**
 * Вкладка содержащая исключения в расписаторе.
 */
const SchedulerExceptions: FC = () => {
    const [filter, setFilter] = useState([]);
    const [result, setResult] = useState();

    const { data: rooms } = useQuery(['scheduler-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    const { data, isFetching } = useQuery(['scheduler-exceptions'], getSchedulerExceptions, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    useEffect(() => {
        if(filter.length > 0) {
            setResult(data.tournaments.filter(({ network }) => filter.find(item => item.network === network)));
        } else {
            setResult(data?.tournaments ?? []);
        }
    }, [data, filter]);

    return (
        <>
            <RoomsFilter setFilter={setFilter} data={filter} rooms={rooms} />
            {isFetching && !result && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {result && <Card><SchedulerExceptionsTable items={result}/></Card>}
        </>
    );
}

export default SchedulerExceptions;

