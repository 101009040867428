import { omitBy } from 'lodash';

import axios from 'helpers/axios';

export const sendMessage = async ({recipient, ...body}) => {
    // const { data } = await axios.post(
    //     '/api/adminapi/messagev2/',
    //     omitBy(
    //         {
    //             ...body,
    //             players: body.players.map(val => val.id).join(','),
    //             curator_groups: body.curatorGroups.join(',')
    //         },
    //         value => !value
    //     ),
    //     // {params: {msgType: recipient}}
    // );

    const { data } = await axios.post('/api/adminapi/messagev2/',
        {
            ...body,
            players: body.players.map(val => val.id),
        },
        {params: {msgType: recipient}}
)

    return data;
};
