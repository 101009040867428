import { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import UnknownPlayersTable from './UnknownPlayersTable';
import { getGroupListUnknownPlayers } from 'api/labelratiogroups';

type Props = {
    /**
     * ID группы для детального просмотра
     */
    groupView: string; 
    
    /**
     * Изменить группу для детального просмотра
     */
    setGroupView: Dispatch<SetStateAction<string>>;
}

/**
 * Вкладка с распределением неизвестных игроков.
 */
const UnknownPlayers: FC<Props> = ({ groupView, setGroupView }) => {
    const {data: list, isFetching} = useQuery(['group-list-unknown-players'], getGroupListUnknownPlayers, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    return (
        <>
            {isFetching && !list && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && list && <Card><UnknownPlayersTable items={list} groupView={groupView} setGroupView={setGroupView} /></Card>}
        </>
    );
}

export default UnknownPlayers;
