import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import CircularProgress from '@mui/material/CircularProgress';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { getBuyinLimits, updateBuyinLimits } from 'api/tournaments';

interface BuyinLimitsProps {
        groupId: number | null;
}

const LimitsTable: React.FC<BuyinLimitsProps> = (props: BuyinLimitsProps) => {
    const queryClient = useQueryClient();

    const biLimitsQuery = useQuery(['buyin-limits', props.groupId], getBuyinLimits, {
        enabled: !!props.groupId,
        initialData: []
    })

    const limitsMutation = useMutation(updateBuyinLimits, {
        onSuccess: () => {
            queryClient.invalidateQueries(['buyin-limits', props.groupId]);
        }
    });

    return (
        props.groupId ? <div className="w-full relative shadow-md rounded overflow-hidden" style={{ height: 800 }}>
            <AgGridReact
                rowData={biLimitsQuery.data || []}
                defaultColDef={{
                    flex: 1,
                    sortable: true,
                    floatingFilter: true,
                    suppressMenu: true,
                    floatingFilterComponentParams: { suppressFilterButton: true }
                }}
                onCellValueChanged={e => {
                    const {level_id, level_name, ...rest} = e.data;
                    limitsMutation.mutate({groupId: props.groupId, levelId: level_id, ...rest});
                }}
            >
                <AgGridColumn
                    headerName="Уровень"
                    field="level_name"
                    filter
                    sortable
                    sort={"desc"}
                    comparator={(valueA, valueB, nodeA, nodeB, isDescending) => {
                        const a = valueA.split(" ");
                        const b = valueB.split(" ");
                        return Number(a[0]) - Number(b[0]);
                    }}
                />
                <AgGridColumn
                    headerName="Очень хорошие"
                    field="2"
                    editable
                    valueParser={params => Number(params.newValue)}
                />
                <AgGridColumn headerName="Хорошие" field="1" editable valueParser={params => Number(params.newValue)}/>
                <AgGridColumn headerName="Средние" field="0" editable valueParser={params => Number(params.newValue)}/>
                <AgGridColumn headerName="Плохие" field="-1" editable valueParser={params => Number(params.newValue)}/>
                <AgGridColumn headerName="Очень плохие" field="-2" editable valueParser={params => Number(params.newValue)}/>
            </AgGridReact>
            {
                biLimitsQuery.isLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                        <CircularProgress />
                    </div>
                ) : null
            }
        </div> : null
    );
}

export default LimitsTable;
