import { ChangeEvent, FC, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/lab';
import { getRooms } from 'api/rooms';
import { IconButton, DialogTitle, DialogContent, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import RoomHelper from 'helpers/RoomHelper';
import { createSchedulerSeriesItem } from 'api/schedulersettings';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;
}

/**
 * Диалоговое окно для создания записи в таблице серий расписатора.
 */
const SchedulerSeriesDialog: FC<Props> = ({ ...props }) => {
    const queryClient = useQueryClient();
    const [selectedRooms, setSelectedRooms] = useState([]);

    const { data: rooms } = useQuery(['scheduler-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const { control, handleSubmit } = useForm({
        defaultValues: {
            start_at: moment().format(),
            end_at: moment().format(),
            name: '',
        },
    });

    const newItemMutation = useMutation(createSchedulerSeriesItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-series');

            props.close();
        },
    });

    /**
     * Сохранить значение чекбокса.
     */
    function selectRoom(event: ChangeEvent<HTMLInputElement>) {
        let filter = selectedRooms.slice();
        const id = filter.findIndex(data => data.id === event.target.value);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            filter.push({ id: event.target.value, name: event.target.name });
        }

        setSelectedRooms(filter);
    }

    /**
     * Функция сохранения формы.
     */
    const onSubmit = handleSubmit(data => {
        const series = {
            network: selectedRooms.map(item => item.name),
            start_at: moment(data.start_at).format('YYYY-MM-DDTHH:mm:ssZ'),
            end_at: moment(data.end_at).format('YYYY-MM-DDTHH:mm:ssZ'),
            ...data
        }

        newItemMutation.mutate(series);
    });

    /**
     * Проверка элемента на выбор.
     */
    function checkSelected(id: string) {
        const ids = selectedRooms.map(el => el.id);
        return ids.includes(String(id));
    }

    return (
        <>
            <DialogTitle>
                Новая серия
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name='name'
                        render={({ field }) => <TextField {...field} label='Название серии' fullWidth />}
                    />
                    <Controller
                        name='start_at'
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Дата начала серии:'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <Controller
                        name='end_at'
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Дата окончания серии:'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <FormGroup
                        onChange={selectRoom}
                    >
                        {RoomHelper.withoutDefault(rooms).map((item) => (
                            <FormControlLabel
                                className="pl-2 pr-2 m-0"
                                key={item.id}
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        name={item.network}
                                        checked={checkSelected(item.id)}
                                    />
                                }
                                label={item.network}
                                value={item.id}
                            />
                            
                        ))}
                    </FormGroup>
                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={newItemMutation.isLoading}
                        >
                            Добавить
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default SchedulerSeriesDialog;
