import { FC, useState } from 'react';
import { useTheme, Card, IconButton, Box, CircularProgress, Stack } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { getPlayersHandHistories, downloadHandHistory } from 'api/handhistories';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
    playerId?: string | null;
};

const HandsTable: FC<Props> = ({ playerId }) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const handsQuery = useQuery(['players-hand-histories', pageSize, page, playerId], getPlayersHandHistories, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const downloadHhMutation = useMutation(downloadHandHistory, {
        onSuccess: () => { },
    });

    if (handsQuery.isFetching) {
        return (
            <Box className="flex flex-1 flex-col items-center justify-center h-80">
                <CircularProgress color="primary" />
            </Box>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'player_name',
            headerName: 'Игрок',
            sortable: false,
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: 'month',
            headerName: 'Месяц',
            sortable: false,
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'hh_type',
            headerName: 'Тип',
            sortable: false,
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'created_datetime',
            headerName: 'Дата загрузки',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.created_datetime).format('DD.MM.YYYY HH:mm')}`,
            sortable: false,
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 180,
            renderCell: params => {
                function selectItem(params: any) {
                    const data = {
                        id: params.row.id,
                        name: params.row.name,
                    };
                    return data;
                }
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {params.row.is_uploaded && (
                            <IconButton onClick={() => downloadHhMutation.mutate({ hhId: params.row.id })} disabled={downloadHhMutation.isLoading}>
                                <DownloadIcon />
                            </IconButton>
                        )}
                    </Box>
                );
            },
        },
    ];

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    return (
        <Box>
            {handsQuery.data && (
                <Card>
                    <Box
                        className="h-auto overflow-auto"
                        sx={{
                            '& .notuploaded': {
                                backgroundColor: '#c9c1c1',
                                // color: '#1a3e72',
                            },
                        }}
                    >
                        <DataGrid
                            autoHeight={true}
                            rows={handsQuery.data.data ?? []}
                            columns={columns}
                            pageSize={pageSize}
                            rowCount={handsQuery.data.count ?? 0}
                            page={page}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => {
                                setPageSize(newPageSize);
                                setPage(0);
                            }}
                            rowsPerPageOptions={[25, 50, 100]}
                            pagination
                            disableSelectionOnClick
                            disableColumnMenu
                            getCellClassName={(params: GridCellParams<number>) => {
                                return !params.row.is_uploaded ? 'notuploaded' : '';
                            }}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Данные отсутствуют
                                    </Stack>
                                ),
                            }}
                            componentsProps={{
                                pagination: {
                                    ActionsComponent: TablePaginationActions,
                                },
                            }}
                        />
                    </Box>
                </Card>
            )}
        </Box>
    );
};

export default HandsTable;
