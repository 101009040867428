import { FC, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { IconButton, DialogTitle, DialogContent, Stack, Chip, Box, Typography } from '@mui/material';

import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { getSchedulerRoomsRoiTypeLimits, importSchedulerRoiTypeLimits } from 'api/schedulersettings';
import { SchedulerQueryKeys } from 'constants/QueryKeys/schedulerKeys';
import { loadOnceConfig } from 'constants/QueryKeys/queryConfig';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * Активный рум.
     */
    active: string[];
}

const SchedulerRoiTypeLimitsImportDialog: FC<Props> = ({ active, ...props }) => {
    const queryClient = useQueryClient();
    const [selectedRooms, setSelectedRooms] = useState(active);

    const { handleSubmit } = useForm();

    const { data } = useQuery(SchedulerQueryKeys.roiTypeLimitsNetworks(), getSchedulerRoomsRoiTypeLimits, loadOnceConfig);

    const importItemMutation = useMutation(importSchedulerRoiTypeLimits, {
        onSuccess: () => {
            queryClient.invalidateQueries(SchedulerQueryKeys.ROI_TYPE_LIMITS_KEY);

            props.close();
        },
    });

    /**
     * Функция сохранения формы.
     */
    const onSubmit = handleSubmit(_ => {
        const importData = {
            to_network: active[0],
            from_network: selectedRooms[0],
        }

        importItemMutation.mutate({ body: importData })
    });

    return (
        <>
            <DialogTitle>
                Импорт данных сетки ROI
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4' onSubmit={onSubmit}>
                    <Box>
                        <Typography className='mb-1'>Импортировать из</Typography>
                        <Stack direction="row" spacing={1}>
                            {data.networks.map((item: any, index: any) => {
                                return <Chip key={index} label={item} color={item === selectedRooms[0] ? 'primary' : 'default'} onClick={() => setSelectedRooms([item])} clickable />
                            })}
                        </Stack>
                    </Box>
                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={importItemMutation.isLoading}
                        >
                            Импортировать
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default SchedulerRoiTypeLimitsImportDialog;
