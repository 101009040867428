export const SET_PLAYER_ID_FILTER = "SET_PLAYER_ID_FILTER";

interface FilterState {
    playerIdFilter: any;
}

export const initialState: FilterState = {
    playerIdFilter: null,
};

export type Action =
    | { type: typeof SET_PLAYER_ID_FILTER, payload: any }

export const filterReducer = (state: FilterState, action: Action): FilterState => {
    switch (action.type) {
        case SET_PLAYER_ID_FILTER:
            return { ...state, playerIdFilter: action.payload };
        default:
            return state;
    }
};
