import axios from 'helpers/axios';

export const getManualResults = async ({ queryKey }) => {
    const [, userId, roomId] = queryKey;

    var params = new URLSearchParams();
    if (roomId) {
        params.append('roomId', roomId);
    }

    const { data } = await axios.get(`/api/adminapi/users/${userId}/manualresults/`, { params: params });

    return data;
}

export const createManualResults = async ({ user_id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/users/${user_id}/manualresults/`, body);

    return data;
};

export const deletemanualResults = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/manualresults/${id}/`);

    return data;
};
