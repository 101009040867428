import { useState, FC } from 'react';
import { Box, Button, Chip, Dialog, useMediaQuery } from '@mui/material';
import Series from './components/Series/Series';
import SeriesDialog from './components/Series/SeriesDialog';
import theme from 'themes/lightTheme';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Coefficient from './components/Coefficient/Coefficient';
import CoefficientDialog from './components/Coefficient/CoefficientDialog';
import Exceptions from './components/Exceptions/Exceptions';
import NormRules from './components/NormRules/NormRules';
import ExceptionsDialog from './components/Exceptions/ExceptionsDialog'
import Ability from './components/Ability/Ability';
import AbilityDialog from './components/Ability/AbilityDialog';
import Tournaments from './components/Tournaments/Tournaments';
import CustomTournaments from './components/CustomTournaments/CustomTournaments';
import TournamentDialog from './components/CustomTournaments/TournamentDialog';

/**
 * Страница с настройкой турнирных таблиц.
 */
const TournamentsGrid: FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [tabIndex, setTabIndex] = useState(0);
    const [open, setOpen] = useState(false);

    function handleChangeTab(newIndex: number) {
        if (tabIndex === newIndex) {
            return;
        }

        setTabIndex(newIndex);
    }

    return (
        <>
            <Box className='flex flex-row justify-between'>
                <Box className='flex gap-1'>
                    <Chip label='Серия' color={tabIndex === 0 ? 'primary' : 'default'} onClick={() => handleChangeTab(0)} clickable />
                    <Chip label='Коэффициент' color={tabIndex === 1 ? 'primary' : 'default'} onClick={() => handleChangeTab(1)} clickable />
                    <Chip label='Сетка Абилити' color={tabIndex === 2 ? 'primary' : 'default'} onClick={() => handleChangeTab(2)} clickable />
                    <Chip label='Исключения' color={tabIndex === 3 ? 'primary' : 'default'} onClick={() => handleChangeTab(3)} clickable />
                    <Chip label='Нормализация' color={tabIndex === 4 ? 'primary' : 'default'} onClick={() => handleChangeTab(4)} clickable />
                    <Chip label='Турниры' color={tabIndex === 5 ? 'primary' : 'default'} onClick={() => handleChangeTab(5)} clickable />
                    <Chip label='Кастомные турниры' color={tabIndex === 6 ? 'primary' : 'default'} onClick={() => handleChangeTab(6)} clickable />
                </Box>
                <Box>
                    {tabIndex === 0 && <Button variant="contained" onClick={()=>setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая серия</Button>}
                    {tabIndex === 1 && <Button variant="contained" onClick={()=>setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новый коэффициент</Button>}
                    {tabIndex === 2 && <Button variant="contained" onClick={()=>setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новая сетка ABI</Button>}
                    {tabIndex === 3 && <Button variant="contained" onClick={()=>setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новое исключение</Button>}
                    {tabIndex === 6 && <Button variant="contained" onClick={()=>setOpen(!open)} className="align-middle rounded-full" endIcon={<AddBoxOutlinedIcon />}>Новый турнир</Button>}
                </Box>
            </Box>
            <Box className="mt-4 p-1">
                {tabIndex === 0 && <Series />}
                {tabIndex === 1 && <Coefficient />}
                {tabIndex === 2 && <Ability />}
                {tabIndex === 3 && <Exceptions />}
                {tabIndex === 4 && <NormRules />}
                {tabIndex === 5 && <Tournaments />}
                {tabIndex === 6 && <CustomTournaments />}
            </Box>
            <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
                {tabIndex === 0 && <SeriesDialog close={() => setOpen(false)} />}
                {tabIndex === 1 && <CoefficientDialog close={() => setOpen(false)} />}
                {tabIndex === 2 && <AbilityDialog close={() => setOpen(false)} />}
                {tabIndex === 3 && <ExceptionsDialog close={() => setOpen(false)} />}
                {tabIndex === 6 && <TournamentDialog open={open} close={() => setOpen(false)} />}
            </Dialog>
      </>
    );
}

export default TournamentsGrid;
