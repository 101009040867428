import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { updateSchedulerAbility } from 'api/schedulersettings';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу ABI в расписаторе.
 */
const SchedulerAbilityTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const editItemMutation = useMutation(updateSchedulerAbility, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-ability-data-regular');
            queryClient.invalidateQueries('scheduler-ability-data-turbo');
            queryClient.invalidateQueries('scheduler-ability-data-rebuy');
        },
    });

    const columns: GridColDef[] = [
      {
        field: 'buyin',
        headerName: 'minbi-maxbi',
        flex: 1,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => `${params.row.min_buyin}-${params.row.max_buyin}`,
      },
      {
        field: 'separator_1',
        headerName: 'Очень хорошие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'separator_2',
        headerName: 'Хорошие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'separator_3',
        headerName: 'Средние',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
      {
        field: 'separator_4',
        headerName: 'Плохие',
        flex: 1,
        minWidth: 100,
        type: 'number',
        editable: true,
      },
    ];

    function editCell(params) {
        if(Number(params.formattedValue) !== Number(params.value)) {
            const editId = params.id;
            const body = {
              separator: params.field.charAt(params.field.length - 1),
              value: params.value,
            }

            editItemMutation.mutate({id: editId, body})
        }
    }

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
      <DataGrid
          autoHeight={true}
          rows={items ?? []}
          columns={columns}
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          onCellEditCommit={(params: GridCellEditCommitParams) => editCell(params)}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default SchedulerAbilityTable;
