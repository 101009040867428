import { FC } from 'react';
import { useQuery } from 'react-query';

import { Box, Card, CircularProgress, Typography } from '@mui/material';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { getChartsTournaments } from 'api/tournamentsgroups';
import DateHelper from 'helpers/DateHelper';

type Props = {
    /**
     * ID турнира.
     */
    id: number;

    /**
     * Список примененных фильтров.
     */
    filters: any;

    /**
     * Объект примененного диапазона дат.
     */
    rangeDate: any;
}

/**
 * Тело отвечающее за отображение графиков.
 */
const ChartsBody: FC<Props> = ({ id, filters, rangeDate }) => {
    const {data: charts, isFetching} = useQuery(['charts-tournaments-data', id, filters, rangeDate], getChartsTournaments, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    function getColor(day: number | string){
        switch (day) {
            case 0:
                return '#376617';
            case 1:
                return '#e3d056';
            case 2:
                return '#d9a04a';
            case 3:
                return '#b85625';
            case 4:
                return '#1e9c67';
            case 5:
                return '#1e729c';
            case 6:
                return '#321887';
            default:
                return '#edb6b2';
        }
    }

    return (
        <>
            {isFetching && !charts && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && charts.length === 0 && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><Typography variant='h5'>Данные отсутствуют</Typography></Card>}
            {charts && charts.length > 0 &&
                <Box className='flex flex-row justify-center flex-wrap gap-10 pt-4'>
                    {
                        charts.map((item) => {
                            return (
                                <Box key={item.name} className="flex flex-col items-center gap-2 w-screen xl:w-[600px]">
                                    <Typography>{item.name}</Typography>
                                    <ResponsiveContainer height={350} className="w-[99%] 2xl:w-[600px]">
                                        <LineChart>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="datetime" type="category" allowDuplicatedCategory={false} />
                                            <YAxis dataKey="value" type='number' domain={['auto' , 'auto']} />
                                            <Tooltip />
                                            <Legend />
                                            {item.data.map((s) => (
                                                <Line
                                                    dataKey="value"
                                                    data={s.data}
                                                    name={`${DateHelper.convertNumToDay(s.day)}`}
                                                    key={s.day}
                                                    strokeWidth={2}
                                                    dot={false}
                                                    stroke={getColor(s.day)}
                                                />
                                            ))}
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                            )
                        })
                    }
                </Box>
            }
        </>
    );
};

export default ChartsBody;
