import { FC, useEffect } from 'react';

import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IconButton, DialogTitle, DialogContent, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { getPlayerAccountRecords, createAccountBalanceTransfer } from 'api/players';

const loginSchema = yup.object().shape({
    cashout_account_record: yup.number().required(),
    deposit_account_record: yup.number().required(),
    amount: yup.number().required().min(0),
});

type Props = {
    close: () => void;
    playerId: string;
    cashoutAccountRecordId: number;
    chipCurrencyId: number;
}

const TransferBalanceDialog: FC<Props> = ({ cashoutAccountRecordId, playerId, chipCurrencyId, ...props }) => {
    const queryClient = useQueryClient();

    const { control, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            cashout_account_record: cashoutAccountRecordId,
            deposit_account_record: null,
            amount: null,
        },
    });

    const accRecordsQuery = useQuery(['player-accountrecords', playerId, true, chipCurrencyId], getPlayerAccountRecords, {
        initialData: [],
    });

    const newTransferMutation = useMutation(createAccountBalanceTransfer, {
        onSuccess: _ => {
            queryClient.invalidateQueries(['player-accounts', playerId]);
            props.close();
            reset();
        },
    });

    const onSubmit = handleSubmit(data => {
        newTransferMutation.mutate(data);
    });

    return (
        <>
            <DialogTitle>
                Перевод средств с записи №{cashoutAccountRecordId}
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        name="deposit_account_record"
                        control={control}
                        render={({ field }) => (
                            <TextField label="Перевод на аккаунт" select fullWidth required disabled={accRecordsQuery.isLoading} {...field}>
                                {accRecordsQuery.data
                                    .map((ar: any) => (
                                        <MenuItem key={ar.id} value={ar.id}>
                                            {ar.account_record_name} | {ar.room_name} {ar.chip_currency.name}{' '}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    />
                    <Controller
                        control={control}
                        name="amount"
                        render={({ field }) => <TextField {...field} required label="Сумма" type="number" fullWidth />}
                    />
                    <div className='pt-4'>
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            loading={newTransferMutation.isLoading}
                        >
                            Создать
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default TransferBalanceDialog;
