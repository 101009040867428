import { FC, Dispatch, SetStateAction } from 'react';

import { Box, FormControlLabel, Switch } from '@mui/material';

type Props = {
    /**
     * Show old series
     */
    showOld: boolean;
    /**
     * Setter for showing old series
     */
    setShowOld: Dispatch<SetStateAction<boolean>>;
};

/**
 * Компонент содержит фильтр румов.
 */
const SeriesFilters: FC<Props> = (
    {
        setShowOld,
        showOld,
    }) => {

    return (
        <Box className='flex flex-col'>
            <Box className='flex flex-row flex-wrap gap-4 items-center mb-3'>
                {/* Фильтр показывающий старые серии */}
                <FormControlLabel
                    control={
                        <Switch
                            checked={showOld}
                            onChange={() => setShowOld(showOld ? false : true)}
                            name="favorite"
                            color="primary"
                        />
                    }
                    label="Показывать прошедшие"
                />
            </Box>
        </Box>
    );
};

export default SeriesFilters;
