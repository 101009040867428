/**
 * Содержит низкоуровневые методы для работы с датой/временем.
 */
export default class DateHelper {
    /**
     * Возвращает день недели в теестовом виде.
     */
    public static convertNumToDay(value: number | 'overall') {
        switch (value) {
            case 0:
                return 'Понедельник';
            case 1:
                return 'Вторник';
            case 2:
                return 'Среда';
            case 3:
                return 'Четверг';
            case 4:
                return 'Пятница';
            case 5:
                return 'Суббота';
            case 6:
                return 'Воскресенье';
            case 'overall':
                return 'Общий'
            default:
                return 'error';
        }
    }
}
