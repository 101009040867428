import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createAccount } from 'api/accounts';
import { getPlayerEwallets } from 'api/players';
import { getPokerRooms } from 'api/pokerrooms';

const NewAccountDialog: React.FC<DialogProps> = (props) => {
    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const roomsQuery = useQuery('poker_rooms', getPokerRooms, {
        initialData: []
    });

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            name: '',
            room: '',
            ewallet: '',
            create_default_account_record: true,
        }
    });

    const { mutate, isLoading } = useMutation(createAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-accounts', playerId]);

            props.onClose({}, 'backdropClick');

            reset();
        }
    });

    const { data: ewalletsData, isLoading: isLoadingEwallets } = useQuery(['player-ewallets', playerId], getPlayerEwallets, {
        enabled: !!playerId
    });

    const onSubmit = handleSubmit((data) => mutate({...data, user: playerId }));

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить аккаунт</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Nickname"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="room"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Room"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    roomsQuery.data?.filter(room => room.active).map(room => (
                                        <MenuItem key={room.id} value={room.id}>
                                            {room.short_name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="ewallet"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Ewallet"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    ewalletsData?.map(ewallet => (
                                        <MenuItem key={ewallet.id} value={ewallet.id}>
                                            {ewallet.type.name}: {ewallet.address} ({ewallet.currency.name})
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="create_default_account_record"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Создать дефолтную запись"
                                control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewAccountDialog;
