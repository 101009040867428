import React, { FC } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { range } from 'lodash';
import lightTheme from 'themes/lightTheme';
import { createEvStats, getEvStatsFields } from 'api/playerevstats';

type Props = {
    /**
     * ID игрока
     */
    playerId: number;

    /**
     * Состояние диалогового окна
     */
    open: boolean;

    /**
     * Закрытие диалогового окна
     */
    onClose: () => void;
}

/**
 * Модальное окно с созданием кастомных статистик игрока
 */
const EvStatsModal: FC<Props> = ({ playerId, open, onClose }) => {
    const queryClient = useQueryClient();

    const fullScreen = useMediaQuery(lightTheme.breakpoints.down('sm'));

    const { control, handleSubmit } = useForm();

    const { data } = useQuery('ev-analysis-stats-fields', getEvStatsFields, {
        initialData: [],
    });

    const { mutate, isLoading } = useMutation(createEvStats, {
        onSuccess: () => {
            queryClient.invalidateQueries('stats-players');

            closeDialog();
        },
    });

    const closeDialog = () => {
        onClose();
    };

    const onSubmit = handleSubmit(data => {
        mutate({
            ...data,
            ev_stats: data.ev_stats.filter(val => !!val.hands_value),
            playerId,
        });
    });

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth='sm' fullWidth fullScreen={fullScreen}>
            <DialogTitle>
                Добавить статистику
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ overflow: 'hidden', display: 'flex' }}>
                <form className='flex-1 flex flex-col overflow-hidden' onSubmit={onSubmit}>
                    <Paper className="mb-3 p-3" variant="outlined" square>
                        {/*<Typography className="opacity-70" variant="caption" color="disabled">
                            Добавляем базы от 80к раздач, не реже раза в два месяца. Если за 2 месяца отыграно меньше 80к рук, то берем последние 80к раздач, даже если часть из них была в прошлом разборе.
                        </Typography>*/}
                    </Paper>
                    <div className='space-y-3 flex flex-col overflow-y-auto'>
                        {/*<Controller
                            control={control}
                            name='note'
                            defaultValue=''
                            render={({ field }) => <TextField {...field} label='Комментарий' size='small' fullWidth />}
                        />*/}
                        {data.map((group, groupI) => (
                            <React.Fragment key={""}>
                                {group.map((val, i) => {
                                    const index =
                                        range(0, groupI).reduce((acc, item) => {
                                            return data[item].length + acc;
                                        }, 0) + i;

                                    return (
                                        <React.Fragment key={val.id}>
                                            <Controller
                                                control={control}
                                                name={`ev_stats[${index}].stat_id`}
                                                defaultValue={val.id}
                                                render={() => <div hidden />}
                                            />
                                            <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                <Controller
                                                    control={control}
                                                    name={`ev_stats[${index}].hands_value`}
                                                    defaultValue=''
                                                    render={({ field }) => (
                                                        <TextField {...field} label={val.name + " Hands"} size='small' type='number' fullWidth style={{ marginRight: '8px', flex: 1 }} />
                                                    )}
                                                />
                                                <Controller
                                                    control={control}
                                                    name={`ev_stats[${index}].ev_value`}
                                                    defaultValue=''
                                                    render={({ field }) => (
                                                        <TextField {...field} label={val.name + " EV"} size='small' type='number' fullWidth style={{ flex: 1 }} />
                                                    )}
                                                />
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='w-full pt-4'>
                        <LoadingButton type='submit' variant='contained' loading={isLoading} fullWidth>
                            Создать
                        </LoadingButton>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EvStatsModal;
