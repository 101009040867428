import React from 'react';
import moment from 'moment';

import Button from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { getTransferInfo, updateTransferInfo } from 'api/transfers';
import EditSenderDialog from './components/EditSenderDialog';
import EditReceiverDialog from './components/EditReceiverDialog';
import EditAmountDialog from './components/EditAmountDialog';
import ConfirmTransferDeleteDialog from './components/ConfirmTransferDeleteDialog';


const Transfer: React.FC = () => {
    const queryClient = useQueryClient();

    const { transferId } = useParams();

    const [editSenderDialogOpen, setEditSenderDialogOpen] = React.useState(false);
    const [editReceiverDialogOpen, setEditReceiverDialogOpen] = React.useState(false);
    const [editAmountDialogOpen, setEditAmountDialogOpen] = React.useState(false);
    const [confirmTransferDeleteDialogOpen, setConfirmTransferDeleteDialogOpen] = React.useState(false);

    const { control, watch, setValue, handleSubmit, reset } = useForm({
        defaultValues: {
            is_sent: false,
            is_received: false,
            admin_comment: null,
        }
    });

    const transferQuery = useQuery(['transfer', transferId], getTransferInfo, {
        enabled: !!transferId,
        initialData: {},
    })

    const updateTransferMutation = useMutation(updateTransferInfo, {
        onSuccess: (_) => {
            queryClient.invalidateQueries(['transfer', transferId]);
            reset();
        }
    });

    const onSubmit = handleSubmit((data) => updateTransferMutation.mutate({ transferId, ...data }));

    React.useEffect(() => {
        if (transferQuery) {
            setValue('admin_comment', transferQuery.data.admin_comment);
            setValue('is_sent', transferQuery.data.is_sent);
            setValue('is_received', transferQuery.data.is_received);
        }
    }, [transferQuery.data, setValue]);

    return (
        <>
            <div className="col-span-12">
                <div className="w-full h-full bg-white shadow-md p-5 rounded">
                    <p className="text-lg font-medium mb-8">Трансфер №{transferId}</p>
                    <p className="text-lg font-light mb-4">Дата создания: {moment(transferQuery.data?.created_datetime).format('DD MMM YYYY HH:mm')}</p>
                    <p className="text-lg font-light mb-4">Рум: {transferQuery.data?.room_name}</p>
                    <p className="text-lg font-light mb-4">
                        Сумма: {transferQuery.data?.amount}
                        <Button
                            type="submit"
                            loading={transferQuery.isLoading}
                            onClick={() => setEditAmountDialogOpen(true)}
                        >Изменить</Button>
                    </p>
                    <p className="text-lg font-light mb-4">Комиссия: {transferQuery.data?.fee}</p>
                    <p className="text-lg font-light mb-4">
                        Отправитель: {transferQuery.data?.sender_name}, {transferQuery.data?.send_account_name}
                        <Button
                            type="button"
                            loading={transferQuery.isLoading}
                            onClick={() => setEditSenderDialogOpen(true)}
                        >Изменить</Button>
                    </p>
                    <p className="text-lg font-light mb-4">
                        Получатель: {transferQuery.data?.receiver_name}, {transferQuery.data?.receive_account_name}
                        <Button
                            type="submit"
                            loading={transferQuery.isLoading}
                            onClick={() => setEditReceiverDialogOpen(true)}
                        >Изменить</Button>
                    </p>
                    {/*<p className="text-lg font-light mb-4">Отправлен: {
                        transferQuery.data?.is_sent ? moment(transferQuery.data?.sent_datetime).format('DD MMM YYYY HH:mm') : null
                    }</p>
                    <p className="text-lg font-light mb-4">Получен: {
                        transferQuery.data?.is_received ? moment(transferQuery.data?.received_datetime).format('DD MMM YYYY HH:mm') : null
                    }</p>*/}
                    <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                        <Controller
                            name="is_sent"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    label="Отправлен"
                                    control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                                />
                            )}
                        />
                        <Controller
                            name="is_received"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    label="Получен"
                                    control={<Checkbox {...field} checked={!!field.value} onChange={({ target }) => field.onChange(target.checked)} />}
                                />
                            )}
                        />
                        <br /><br />
                        <p className="text-lg font-light mb-4">Комментарий отправителя: {transferQuery.data?.sender_comment}</p>
                        <p className="text-lg font-light mb-4">Комментарий получателя: {transferQuery.data?.receiver_comment}</p>
                        <Controller
                            name="admin_comment"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Комментарий администратора"
                                    rows={3}
                                    multiline
                                    fullWidth
                                    required
                                    {...field}
                                />
                            )}
                        />
                        <div className="pt-4 space-x-4 text-right">
                            <Button
                                type="submit"
                                variant="contained"
                                loading={updateTransferMutation.isLoading}
                            >Сохранить</Button>
                            <Button
                                variant="contained"
                                type="submit"
                                color="error"
                                onClick={() => setConfirmTransferDeleteDialogOpen(true)}
                            // loading={userMutation.isLoading}
                            >Удалить</Button>
                        </div>
                    </form>
                </div>
            </div>

            <EditSenderDialog
                open={editSenderDialogOpen}
                transferId={transferId}
                roomId={transferQuery.data?.room_id}
                chipCurrencyId={transferQuery.data?.chip_currency_id}
                amount={transferQuery.data?.amount}
                onClose={() => setEditSenderDialogOpen(false)}
            />

            <EditReceiverDialog
                open={editReceiverDialogOpen}
                transferId={transferId}
                roomId={transferQuery.data?.room_id}
                chipCurrencyId={transferQuery.data?.chip_currency_id}
                onClose={() => setEditReceiverDialogOpen(false)}
            />

            <EditAmountDialog
                open={editAmountDialogOpen}
                transferId={transferId}
                amount={transferQuery.data?.amount}
                fee={transferQuery.data?.fee}
                onClose={() => setEditAmountDialogOpen(false)}
            />

            <ConfirmTransferDeleteDialog
                open={confirmTransferDeleteDialogOpen}
                transferId={transferId}
                onClose={() => setConfirmTransferDeleteDialogOpen(false)}
            />

        </>

    )
};

export default Transfer;
