import React from 'react';

import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
//import { useNavigate } from 'react-router-dom';

import { getAssignPlayers } from 'api/deposists';
import { createTransferFromDeposit } from 'api/transfers';

interface AssignPlayerDialogProps {
    depositId: number;

    onClose: () => void;
}

const AssignPlayerDialog: React.FC<AssignPlayerDialogProps> = ({ depositId, ...props }) => {
    const queryClient = useQueryClient();
    //const navigate = useNavigate();

    const { data, isLoading } = useQuery(['assing-players', depositId], getAssignPlayers, {
        enabled: !!depositId
    });

    const createTransferMutation = useMutation(createTransferFromDeposit, {
        onSuccess: () => {
            queryClient.invalidateQueries('transfers');
            queryClient.invalidateQueries('pending-deposits');
            props.onClose();
        }
    });

    const getPlayerClass = ({ data }: any) => {
        if (data.online) return 'bg-green-500';

        return '';
    };

    const createTransfer = ({ data }) => {
        createTransferMutation.mutate({deposit_request_id: depositId, account_record_id: data.account_record_id}/* { deposit_id: depositId, sender_id: data.player_id } */);

        //navigate('/transfers');
    };

    function customComparator(valueA, valueB, nodeA, nodeB, isInverted) {
        const onlineA = nodeA.data['online'];
        const onlineB = nodeB.data['online'];
        if (onlineA && !onlineB) {
            return 1;
        }
        if (!onlineA && onlineB) {
            return -1;
        }
        return (valueA > valueB) ? 1 : -1;
    }

    return (
        <Dialog open={!!depositId} onClose={props.onClose} maxWidth="xl" fullWidth>
            {/* <DialogContent> */}
                <div className="ag-theme-material w-full relative shadow-md rounded overflow-hidden" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={data || []}
                        defaultColDef={{
                            flex: 1,
                            sortable: true,
                            floatingFilter: true,
                            suppressMenu: true,
                            floatingFilterComponentParams: { suppressFilterButton: true }
                        }}
                        onRowClicked={createTransfer}
                    >
                        <AgGridColumn headerName="Игрок" field="player_name" cellClass={getPlayerClass} filter />
                        <AgGridColumn headerName="Аккаунт" field="account_record_name" filter />
                        <AgGridColumn headerName="Баланс" field="balance" comparator={customComparator} sort='desc' />
                        <AgGridColumn headerName="Доля игрока" field="personal_share" />
                        <AgGridColumn headerName="ABI" field="abi" />
                        <AgGridColumn headerName="# Accs" field="num_account_records" />
                        <AgGridColumn headerName="Ср-ва команды" field="team_funds" />
                        <AgGridColumn headerName="online" field="online" hide />
                        {/* <AgGridColumn headerName="Telegram" field="telegram_username" /> */}
                    </AgGridReact>
                    {
                        isLoading ? (
                            <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                                <CircularProgress />
                            </div>
                        ) : null
                    }
                </div>
            {/* </DialogContent> */}
        </Dialog>
    );
};

export default AssignPlayerDialog
