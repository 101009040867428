import { FC, useState } from 'react';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

import { IconButton, Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { deleteUntrackedTournaments } from 'api/players';
import { useParams } from 'react-router-dom';
import DeleteDialog from 'components/DeleteDialog';
import EditUntrackedTournamentDialog from './EditUntrackedTournamentDialog';

type Props = {
    items: any;
};

const UntrackedTournamentsTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();
    const { playerId } = useParams();

    const [pageSize, setPageSize] = useState<number>(5);

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const [untrackedId, setUntrackedId] = useState(null);

    const deleteMutation = useMutation(deleteUntrackedTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-untracked-tournaments', playerId]);
            setDeleteDialog(false);
        },
    });

    const columns: GridColDef[] = [
        {
            field: 'room_name',
            headerName: 'Рум',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'count',
            headerName: 'Кол-во',
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'datetime',
            headerName: 'Дата',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.datetime).format('DD.MM.YYYY HH:mm')}`,
            sortable: true,
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: params => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setUntrackedId(params.row.id);
                                setEditDialog(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setUntrackedId(params.row.id);
                                setDeleteDialog(true);
                            }}
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                autoHeight={true}
                rows={items}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                disableSelectionOnClick
                disableColumnMenu
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />

            <DeleteDialog
                close={() => setDeleteDialog(false)}
                open={deleteDialog}
                loading={deleteMutation.isLoading}
                handleAgreeAction={() => deleteMutation.mutate(untrackedId)}
            />
            <EditUntrackedTournamentDialog
                open={editDialog}
                onClose={() => setEditDialog(false)}
                tournamentId={untrackedId}
            />
        </Box>
    );
};

export default UntrackedTournamentsTable;
