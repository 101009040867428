import axios from 'helpers/axios';

export const getPlayerLabels = async (request_data) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/players/labels/?include_stats=true`, request_data);

    return data;
};


export const getTournamentLabels = async (request_data) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/tournaments/labels/`, request_data);

    return data;
};


export const getTournamentStats = async (request_data) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/tournaments/stats/`, request_data);

    return data;
};


export const getPlayersLabelsCsv = async ({ network }) => {
    var params = new URLSearchParams();

    params.append('network', network);

    const { data } = await axios.get(`/api/adminapi/statsscoring/players/csv/`, { params: params });

    return data;
};
