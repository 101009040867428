import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from 'store/configureStore';

interface DashboardLinkProps {
    handleDrawerClose: () => void;
    route: {
        icon: any;
        name: string;
        route: string;
        permission: string;
    };
}

const DashboardLink: React.FC<DashboardLinkProps> = ({ route: val, handleDrawerClose }) => {
    const permissions = useSelector((state: AppState) => state.auth.permissions);

    if (val.permission && !permissions.includes(val.permission)) return null;

    return (
        <NavLink onClick={handleDrawerClose} to={val.route} end>
            <div className="router-link">
                <val.icon />
                <p>{val.name}</p>
            </div>
        </NavLink>
    );
};

export default DashboardLink;