const AccuracyLogo: any = (props) => {
    const container = document.createElement('div');
    const logo = document.createElement('img');

    function getAccuracyLogoUrl(name: string) {
        return `${process.env.PUBLIC_URL}/assets/images/tournament_accuracy/${name}.svg`
    }
    
    logo.setAttribute('src', getAccuracyLogoUrl(props.value));

    logo.style.height = '80%';

    container.classList.add('h-full', 'flex', 'items-center');

    container.appendChild(logo);

    return container;
};

export default AccuracyLogo;
