import { Dispatch, FC, SetStateAction } from 'react';

import {
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;

  /**
   * Кол-во элементов в таблице.
   */
  pageSize: number;

  /**
   * Задать кол-во элементов в таблице.
   */
  setPageSize: Dispatch<SetStateAction<number>>;

  /**
   * Страница.
   */
  page: number;

  /**
   * Задать страницу
   */
  setPage: Dispatch<SetStateAction<number>>;
};

/**
 * Отображает таблицу риска игроков.
 */
const RiskTable: FC<Props> = ({ items, pageSize, setPageSize, page, setPage }) => {
    const columns: GridColDef[] = [
      { 
        field: 'player_username', 
        headerName: 'Игрок', 
        flex: 0.3, 
        minWidth: 200, 
        sortable: false,
      },
      {
        field: 'fair_roi_low_afs',
        headerName: 'Fair ROI LAFS',
        flex: 0.3,
        minWidth: 120,
        valueGetter: (params: GridValueGetterParams) => `${((params.row.fair_roi_low_afs) * 100).toFixed(2)} %`,
        sortable: false,
      },
      {
        field: 'abi_30d',
        headerName: 'ABI 30d',
        flex: 0.3,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'afs',
        headerName: 'Кругов',
        flex: 0.3,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'high_afs_ratio',
        headerName: '% килополей',
        flex: 0.3,
        minWidth: 120,
        valueGetter: (params: GridValueGetterParams) => `${((params.row.high_afs_ratio) * 100).toFixed(2)} %`,
        sortable: false,
      },
      {
        field: 'entries',
        headerName: 'Входы',
        flex: 0.3,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'risk_to_income',
        headerName: 'Risk / Income',
        flex: 0.3,
        minWidth: 120,
        sortable: false,
      },
      {
        field: 'tournaments_per_month',
        headerName: 'T/month',
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'roi_low_afs',
        headerName: 'ROI LAFS',
        flex: 0.3,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => `${((params.row.roi_low_afs) * 100).toFixed(2)} %`,
        sortable: false,
      },
      {
        field: 'abi',
        headerName: 'ABI',
        flex: 0.3,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'roi_high_afs',
        headerName: 'ROI 1k+',
        flex: 1,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => `${((params.row.roi_high_afs) * 100).toFixed(2)} %`,
        sortable: false,
      },
      {
        field: 'finishes_early',
        headerName: 'OE',
        flex: 1,
        minWidth: 80,
        valueGetter: (params: GridValueGetterParams) => `${((params.row.finishes_early) * 100).toFixed(2)} %`,
        sortable: false,
      },
      {
        field: 'finishes_late',
        headerName: 'OL',
        flex: 1,
        minWidth: 80,
        valueGetter: (params: GridValueGetterParams) => `${((params.row.finishes_late) * 100).toFixed(2)} %`,
        sortable: false,
      },
      {
        field: 'ev_usd_per_m',
        headerName: 'EV $/M',
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'max_makeup_bi',
        headerName: 'MAX Makeup BI',
        flex: 1,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'max_makeup_usd',
        headerName: 'MAX Makeup $',
        flex: 1,
        minWidth: 130,
        sortable: false,
      },
    ];

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
      <DataGrid
          autoHeight={true}
          rows={items.data ?? []}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          page={page}
          rowCount={items.count ?? 0}
          paginationMode="server"
          onPageChange={newPage => setPage(newPage)}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPage(0);
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
          }}
      />
    </Box>
  );
};

export default RiskTable;
