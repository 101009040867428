import axios from 'helpers/axios';

export const createContract = async (body) => {
    const { data } = await axios.post('/api/adminapi/contractsv2/', body);

    return data;
};

export const updateContract = async ({ id, ...body }) => {
    const { data } = await axios.patch(`/api/adminapi/contractsv2/${id}`, body);

    return data;
};
