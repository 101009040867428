import { FC, useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Card, Button, Dialog, useMediaQuery, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import theme from 'themes/lightTheme';
import DeleteDialog from 'components/DeleteDialog';
import { deleteSchedulerRoiGridItem, getSchedulerRoiGrid, getSchedulerRoiGridlimits, getSchedulerRoomsRoiGrid } from 'api/schedulersettings';
import SchedulerRoiGridImportDialog from './SchedulerRoiGridImportDialog';
import SchedulerRoiGridLimitTable from './SchedulerRoiGridLimitTable';
import SchedulerRoiGridTable from './SchedulerRoiGridTable';
import SchedulerRoomsFilter from 'components/SchedulerRoomFilter/SchedulerRoomFilter';

/**
 * Вкладка с сеткой ROI в расписаторе.
 */
const SchedulerRoiGrid: FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const defaultValue = "Default";

    const [filter, setFilter] = useState([defaultValue]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);

    const selectRoom = filter[0] ?? defaultValue;
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!filter[0]) {
            setFilter([defaultValue]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const { data: rooms } = useQuery(['scheduler-roi-grid-rooms'], getSchedulerRoomsRoiGrid, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    })

    const { data: roiGridData } = useQuery(['scheduler-roi-grid-data', selectRoom], getSchedulerRoiGrid, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    const { data: roiGridLimitData } = useQuery(['scheduler-roi-grid-limits-data', selectRoom], getSchedulerRoiGridlimits, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        cacheTime: 0,
    })

    const delItemMutation = useMutation(deleteSchedulerRoiGridItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-roi-grid-rooms');
            queryClient.invalidateQueries('scheduler-roi-grid-data');
            queryClient.invalidateQueries('scheduler-roi-grid-limits-data');
            setFilter([defaultValue]);
        },
    });

    function deliteItem(network: string) {
        delItemMutation.mutate(network);
        setDeleteDialog(false);
    }

    return (
        <>
            <Box className='flex flex-row justify-between gap-2 items-center'>
                <Box>
                    {rooms && rooms.networks && <SchedulerRoomsFilter setFilter={setFilter} data={filter} rooms={rooms.networks} />}
                </Box>
                <Box>
                    <Button variant="contained" size='large' type='submit' endIcon={<ImportExportIcon />} onClick={() => setImportDialog(true)} className="mr-2">
                        Импорт
                    </Button>
                    <Button variant="outlined" size='large' type='submit' color="error" startIcon={<DeleteIcon />} onClick={() => setDeleteDialog(true)}>
                        Удалить
                    </Button>
                </Box>
            </Box>
            {roiGridLimitData && roiGridLimitData.limits && (
                <Box className="mt-2">
                    <Typography variant='h6' className='mb-1'>ROI Grid Limits</Typography>
                    <Card>
                        <SchedulerRoiGridLimitTable items={roiGridLimitData.limits} />
                    </Card>
                </Box>
            )}
            {roiGridData && roiGridData.grids && (
                <Box className="mt-4">
                    <Typography variant='h6' className='mb-1'>ROI Grid</Typography>
                    <Card>
                        <SchedulerRoiGridTable items={roiGridData.grids} />
                    </Card>
                </Box>
            )}
            <Dialog fullWidth maxWidth='md' open={importDialog} onClose={() => setImportDialog(false)} fullScreen={fullScreen} >
                <SchedulerRoiGridImportDialog close={() => setImportDialog(false)} active={filter} />
            </Dialog>
            <DeleteDialog close={() => setDeleteDialog(false)} open={deleteDialog} loading={false} handleAgreeAction={() => deliteItem(filter[0])} />
        </>
    );
}

export default SchedulerRoiGrid;
