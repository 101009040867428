import axios from 'helpers/axios';

/**
 * Список исключений
 */
export const getNetworkNormRules = async () => {
    const { data } = await axios.get('/api/adminapi/grid/normrules/');

    return data;
};
