import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getEstimateTournaments, newEstimateTournaments, editEstimateTournaments, deleteEstimateTournaments } from 'api/tournamentsgroups';
import { Box, Card, CircularProgress, MenuItem, Typography } from '@mui/material';
import Dropdown from 'components/Dropdown';

type Props = {
  /**
   * ID турнира.
   */
  id: number;
}

/**
 * Отображает таблицу ручной оценки турниров.
 */
const EstimateTable: FC<Props> = ({ id }) => {
    const queryClient = useQueryClient();

    const {data: tournaments, isFetching} = useQuery(['estimate-tournaments-data', id], getEstimateTournaments, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    const { mutate: mutateNewEstimate } = useMutation(newEstimateTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries('estimate-tournaments-data');
        },
    });

    const { mutate: mutateEditEstimate } = useMutation(editEstimateTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries('estimate-tournaments-data');
        },
    });

    const { mutate: mutateDeleteEstimate } = useMutation(deleteEstimateTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries('estimate-tournaments-data');
        },
    });

    function checkValue(day: number) {
        return tournaments?.filter(data => data.day_number === day)[0]?.score ?? '—';
    }

    const rate = [-2, -1, 0, 1, 2];
    const days = [
        {
            day_number: 0,
            name: 'Понедельник',
            value: checkValue(0),
        },
        {
            day_number: 1,
            name: 'Вторник',
            value: checkValue(1),
        },
        {
            day_number: 2,
            name: 'Среда',
            value: checkValue(2),
        },
        {
            day_number: 3,
            name: 'Четверг',
            value: checkValue(3),
        },
        {
            day_number: 4,
            name: 'Пятница',
            value: checkValue(4),
        },
        {
            day_number: 5,
            name: 'Суббота',
            value: checkValue(5),
        },
        {
            day_number: 6,
            name: 'Воскресенье',
            value: checkValue(6),
        }
    ]

    function editItem(day: number, value: number | string, close: Function, oldValue: number | string) {
        if (oldValue !== value) {
            if (oldValue === '—') {
                const data = { day_number: day, score: value };

                mutateNewEstimate({ id, ...data});
            } else {
                const rowId = tournaments?.filter(data => data.day_number === day)[0]?.id;
                const data = { score: value };

                mutateEditEstimate({ rowId, ...data })
            }
        }
        close();
    }

    function deleteItem(day: number, close: Function, oldValue: number | string) {
        if (oldValue !== '—') {
            const rowId = tournaments?.filter(data => data.day_number === day)[0]?.id;
            mutateDeleteEstimate(rowId);
        }
        close();
    }

    return (
        <>
            {isFetching && !tournaments && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {tournaments &&
                <Box className='px-4 py-2'>
                    <Typography>Оценка турнира куратором</Typography>
                    <Box className='flex flex-row flex-wrap gap-4 mt-2 xl:justify-between'>
                        {days.map((val) => (
                            <Dropdown
                                btnLabel={val.name}
                                btnValue={String(val.value)}
                                key={val.name}
                                small={true}
                                renderContent={({ close }) => (
                                    <>
                                    <MenuItem onClick={() => deleteItem(val.day_number, close, val.value)}>
                                        {'—'}
                                    </MenuItem>
                                    {rate.map((rate) => {
                                        return (
                                            <MenuItem key={`${rate} + ${val.name}`} onClick={() => editItem(val.day_number, rate, close, val.value)}>
                                                {rate}
                                            </MenuItem>
                                        );
                                    })}
                                    </>
                                )}
                            />
                        ))}
                    </Box>
                </Box>
            }
        </>
    );
};

export default EstimateTable;
