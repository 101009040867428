import { FC } from 'react';
import * as yup from 'yup';
import moment from 'moment';

import { getRooms } from 'api/rooms';
import { getCurrencies } from 'api/currencies';
import { getPlayerLevels } from 'api/playerlevels';
import { createCustomTournament, editCustomTournament } from 'api/schedulersettings';
import { IconButton, DialogTitle, DialogContent, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import Button from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import { TimePicker } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { OperationalQueryKeys, SchedulerQueryKeys } from 'constants/QueryKeys/queryKeys';
import { loadOnceConfig } from 'constants/QueryKeys/queryConfig';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    close: () => void;

    /**
     * Данные турнира при его редактировании
     */
    editData?: any;
}

const tournamentSchema = yup.object().shape({
    network: yup.string().required(),
    name: yup.string().required().test('len', 'short', val => val.length > 5),
    days: yup.string().required(),
    time: yup.string().required(),
    buyin: yup.number().required(),
    currency: yup.string().required(),
    tags: yup.array().required(),
    min_level: yup.number().nullable(),
    t_eval: yup.number().required().min(-2).max(2),
    start_at: yup.date().nullable(),
    expire_at: yup.date().nullable(),
});

/**
 * Диалоговое окно для создания нового кастомного турнирa.
 */
const SchedulerCustomTournamentDialog: FC<Props> = ({ editData, ...props }) => {
    const queryClient = useQueryClient();

    const roomsQuery = useQuery(OperationalQueryKeys.networksList(), getRooms, loadOnceConfig);

    const currenciesQuery = useQuery(OperationalQueryKeys.currenciesList(), getCurrencies, loadOnceConfig);

    const levelsQuery = useQuery(OperationalQueryKeys.playerLevelsList(), getPlayerLevels, loadOnceConfig)

    const { control, handleSubmit, setValue, getValues, watch } = useForm({
        resolver: yupResolver(tournamentSchema),
        defaultValues: {
            network: editData ? editData.network : null,
            name: editData ? editData.name : '',
            days: editData ? editData.days.join(',') : '',
            time: editData ? moment(editData.time, 'HH:mm:ss').toDate() : '',
            buyin: editData ? editData.buyin : '',
            currency: editData ? editData.currency : null,
            tags: editData ? editData.tags : [],
            min_level: editData ? editData.min_level : 0,
            t_eval: editData ? editData.t_eval : '',
            start_at: editData ? editData.start_at : null,
            expire_at: editData ? editData.expire_at : null,
        }
    });

    const watchTags = watch('tags');

    const newItemMutation = useMutation(createCustomTournament, {
        onSuccess: () => {
            queryClient.invalidateQueries(SchedulerQueryKeys.CUSTOM_TOURNAMENTS_LIST_KEY);

            props.close();
        },
    });

    const editItemMutation = useMutation(editCustomTournament, {
        onSuccess: () => {
            queryClient.invalidateQueries(SchedulerQueryKeys.CUSTOM_TOURNAMENTS_LIST_KEY);

            props.close();
        },
    });

    const onSubmit = handleSubmit((data) => {
        if (editData) {
            console.log(data.time);
            const body = {
                ...data,
                time: moment.utc(data.time).format("HH:mm"),
                days: data.days.split(',').map(item => Number(item.trim())),
                start_at: data.start_at === null ? null : data.start_at.toISOString(),
                expire_at: data.expire_at === null ? null : data.expire_at.toISOString(),
            }
            console.log(body.time);

            editItemMutation.mutate({ id: editData.id, body })
        } else {
            newItemMutation.mutate({
                ...data,
                time: moment(data.time).format("HH:mm"),
                days: data.days.split(',').map(item => Number(item.trim())),
                start_at: data.start_at === null ? null : data.start_at.toISOString(),
                expire_at: data.expire_at === null ? null : data.expire_at.toISOString(),
            })
        }
    });

    return (
        <>
            <DialogTitle>
                {editData ? 'Редактирование турнира' : 'Новый турнир'}
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        name="network"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Рум"
                                select
                                fullWidth
                                required
                                defaultValue={null}
                                {...field}
                            >
                                {
                                    roomsQuery.data?.map((room: any) => (
                                        <MenuItem key={room.id} value={room.network}>
                                            {room.network}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Название"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="days"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Дни (0-6, через запятую)"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="time"
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <TimePicker
                                inputFormat='HH:mm'
                                mask={'__:__'}
                                label="Время начала UTC"
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <Controller
                        name="buyin"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Байин"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="currency"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Валюта байина"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    currenciesQuery.data?.map((c: any) => (
                                        <MenuItem key={c.id} value={c.name}>
                                            {c.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="min_level"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Минимальный уровень игрока"
                                select
                                fullWidth
                                defaultValue={0}
                                {...field}
                            >
                                <MenuItem key={0} value={null}>
                                    -
                                </MenuItem>
                                {
                                    levelsQuery.data?.map((l: any) => (
                                        <MenuItem key={l.id} value={l.name}>
                                            {l.name} {l.group.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="t_eval"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Оценка (от -2 до 2)"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="start_at"
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Дата появления в расписаторе'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <Controller
                        name="expire_at"
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Показывается в расписаторе до даты'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <div className="pl-2 pr-2 m-0">
                        <FormControlLabel
                            className="pl-2 pr-2 m-0"
                            key={'turbo'}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    name={'Turbo'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let a = [...watchTags]
                                            a.push('T');
                                            setValue('tags', a);
                                        } else {
                                            const idx = watchTags.indexOf('T')
                                            if (idx !== -1) {
                                                let a = [...watchTags];
                                                a.splice(idx, 1)
                                                setValue('tags', a);
                                            }
                                        }
                                        return;
                                    }}
                                    checked={watchTags.includes('T')}
                                />
                            }
                            label={'Turbo'}
                            value={false}
                        />
                        <FormControlLabel
                            className="pl-2 pr-2 m-0"
                            key={'rebuy'}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    name={'Rebuy'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let a = [...watchTags]
                                            a.push('R');
                                            setValue('tags', a);
                                        } else {
                                            const idx = watchTags.indexOf('R')
                                            if (idx !== -1) {
                                                let a = [...getValues('tags')];
                                                a.splice(idx, 1)
                                                setValue('tags', a);
                                            }
                                        }
                                        return;
                                    }}
                                    checked={watchTags.includes('R')}
                                />
                            }
                            label={'Rebuy'}
                            value={false}
                        />
                        <FormControlLabel
                            className="pl-2 pr-2 m-0"
                            key={'bounty'}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    name={'Bounty'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let a = [...watchTags]
                                            a.push('B');
                                            setValue('tags', a);
                                        } else {
                                            const idx = watchTags.indexOf('B')
                                            if (idx !== -1) {
                                                let a = [...watchTags];
                                                a.splice(idx, 1)
                                                setValue('tags', a);
                                            }
                                        }
                                        return;
                                    }}
                                    checked={watchTags.includes('B')}
                                />
                            }
                            label={'Bounty'}
                            value={false}
                        />
                    </div>
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={newItemMutation.isLoading || editItemMutation.isLoading}
                        >{editData ? 'Редактировать' : 'Создать'}</Button>
                    </div>
                </form>
            </DialogContent>
        </>
    );
};

export default SchedulerCustomTournamentDialog;
