import { FC, Dispatch, SetStateAction } from 'react';

import { Box, Button, ListItemIcon, MenuItem, TextField } from '@mui/material';
import Dropdown from 'components/Dropdown';
import { Check } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { DatePicker } from '@mui/lab';

type Props = {
    /**
     * Функция записывающая массив фильтров дней недели для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Функция записывающая диапазон дат в фильтре.
     */
    setRangeDate: Dispatch<SetStateAction<any>>;

    /**
     * Массив примененных фильтров дней недели.
     */
    data: any;

    /**
     * Объект фильтра дат.
     */
    rangeDate: any;
};

/**
 * Компонент содержит фильтр для страницы графиков.
 */
const ChartsFilters: FC<Props> = ({ data, rangeDate, setFilter, setRangeDate}) => {
    const days = [
        {
            id: -1,
            name: 'Общий'
        },
        {
            id: 0,
            name: 'Понедельник'
        },
        {
            id: 1,
            name: 'Вторник'
        },
        {
            id: 2,
            name: 'Среда'
        },
        {
            id: 3,
            name: 'Четверг'
        },
        {
            id: 4,
            name: 'Пятница'
        },
        {
            id: 5,
            name: 'Суббота'
        },
        {
            id: 6,
            name: 'Воскресенье'
        }
    ]

    const { control, handleSubmit } = useForm({
        defaultValues: {
            start_dt: null,
            end_dt: null,
        },
    });

    function changeFilter(item) {
        let filter = data.slice();
        const id = filter.findIndex(data => data.id === item.id);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            filter.push(item);
        }

        setFilter(filter);
    }

    function filterCheck(dayId) {
        const id = data.filter(val => val.id === dayId);

        return id[0] ? true : false;
    }

    function filterValue(data?) {
        if(data && data.length > 0) {
            const separator = data.length > 1 ? ' ,' : '';
            return data.map(x => x.name) + separator;
        }
        return undefined;
    }

    function rangeValue(data?) {
        if (!data || (!data.start_dt && !data.end_dt)) {
            return 'Общий';
        }

        if (data.start_dt && data.end_dt) {
            return `${moment(data.start_dt).format('DD.MM.YY')} - ${moment(data.end_dt).format('DD.MM.YY')}`
        }

        if (data.start_dt) {
            return moment(data.start_dt).format('DD.MM.YY');
        }

        if (data.end_dt) {
            return moment(data.end_dt).format('DD.MM.YY');
        }
    }

    /**
     * Функция сохранения формы.
     */
     const onSubmit = handleSubmit(data => {
        setRangeDate(data);
    });

    return (
        <Box className='flex flex-row gap-4'>
            {/* Фильтр по дням недели */}
            <Dropdown
                btnLabel='День недели'
                btnValue={filterValue(data)}
                renderContent={() => (
                    days?.map((item) => { 
                        return (
                            <MenuItem onClick={() => changeFilter(item)} key={item.id} >
                                {filterCheck(item.id) && 
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                }
                                {item.name}
                            </MenuItem>
                        )
                    })
                )}
            />

            {/* Фильтр по диапазону дат */}
            <Dropdown
                btnLabel='Диапазон дат'
                btnValue={rangeValue(rangeDate)}
                renderContent={() => (
                    <form className='flex flex-col gap-4 p-4' onSubmit={onSubmit}>
                        <Controller
                            name='start_dt'
                            control={control}
                            render={({ field: { ref: _, ...rest } }) => (
                                <DatePicker
                                    inputFormat='DD/MM/yyyy'
                                    mask={'__/__/____'}
                                    label='От:'
                                    renderInput={params => <TextField {...params} />}
                                    {...rest}
                                />
                            )}
                        />
                        <Controller
                            name='end_dt'
                            control={control}
                            render={({ field: { ref: _, ...rest } }) => (
                                <DatePicker
                                    inputFormat='DD/MM/yyyy'
                                    mask={'__/__/____'}
                                    label='До:'
                                    renderInput={params => <TextField {...params} />}
                                    {...rest}
                                />
                            )}
                        />
                        <div className='pt-2'>
                            <Button
                                fullWidth
                                variant='contained'
                                size='large'
                                type='submit'
                            >
                                Применить
                            </Button>
                        </div>
                    </form>
                )}
            />
        </Box>
    );
};

export default ChartsFilters;
