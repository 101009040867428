import { FC } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { getPLayers } from 'api/handhistories';

type Props = {
    playerFilter: any;
    setPlayerFilter: React.Dispatch<any>;
};

const HandsFilter: FC<Props> = ({ playerFilter, setPlayerFilter }) => {
    const playersQuery = useQuery(['handhistories-players', null], getPLayers, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const defaultPropsPlayers = {
        options: playersQuery.data ?? [],
        getOptionLabel: option => option.username ?? '',
    };

    return (
        <>
            <Box className="flex flex-row flex-wrap gap-2 ">
                <Autocomplete
                    {...defaultPropsPlayers}
                    disablePortal
                    id="players"
                    sx={{ width: 250 }}
                    disabled={playersQuery.isFetching}
                    value={playerFilter ?? null}
                    onChange={(event: any, newValue: string | null) => {
                        setPlayerFilter(newValue);
                    }}
                    renderInput={params => <TextField {...params} label="Ник игрока" />}
                    className="bg-white"
                />
            </Box>
        </>
    );
};

export default HandsFilter;
