export namespace SchedulerQueryKeys {
    const base = "scheduler";

    export const ROI_TYPE_LIMITS_NETWORKS_KEY = `${base}-roi-type-limits-networks`;
    export const ROI_TYPE_LIMITS_KEY = `${base}-roi-type-limits`;
    export const SCHEDULER_NETWORKS_KEY = `${base}-networks`;
    export const CUSTOM_TOURNAMENTS_LIST_KEY = `${base}-custom-tournaments-list`;
    export const SCHEDULER_PLAYER_TOURNAMENTS_ROI = `${base}-player-tournaments-roi`;
    export const SCHEDULER_GROUP_STATS_REPORT = `${base}-group-stats-report`;

    export const roiTypeLimitsNetworks = () => [ROI_TYPE_LIMITS_NETWORKS_KEY];
    export const roiTypeLimits = (network: string) => [ROI_TYPE_LIMITS_KEY, network];
    export const schedulerNetworks = () => [SCHEDULER_NETWORKS_KEY];
    export const customTournamentsList = (pageSize, page, filters, search, showOld) => [CUSTOM_TOURNAMENTS_LIST_KEY, pageSize, page, filters, search, showOld];
    export const schedulerPlayerTournamentsRoi = (pageSize, page, filters, search, sortOptions) => [SCHEDULER_PLAYER_TOURNAMENTS_ROI, pageSize, page, filters, search, sortOptions];
    export const schedulerGroupStatsReport = (tournament_group_hash: string, day_number: number | null) => [SCHEDULER_GROUP_STATS_REPORT, tournament_group_hash, day_number];
}
