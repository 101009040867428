export namespace OperationalQueryKeys {
    const base = "main";

    export const CURRENCIES_LIST_KEY = `${base}-currencies-list`;
    export const NETWORKS_LIST_KEY = `${base}-networks-list`;
    export const PLAYER_LEVELS_LIST = `${base}-player-levels-list`;

    export const currenciesList = () => [CURRENCIES_LIST_KEY];
    export const networksList = () => [NETWORKS_LIST_KEY];
    export const playerLevelsList = () => [PLAYER_LEVELS_LIST];
}
