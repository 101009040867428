import React, { useState } from "react";

import * as yup from 'yup';
import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';

import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { sendPublicMessage } from 'api/messaging';

const schema = yup.object().shape({
    message: yup.string().required().trim().min(10),
});

const Message = () => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            message: '',
        }
    });

    const [displayMessage, setDisplayMessage] = useState('');

    const messageMutation = useMutation(sendPublicMessage, {
        onSuccess: () => {
            toast.success('Сообщение отправлено');
            reset({ message: '' });
            setDisplayMessage('');
        }
    });

    const onSubmit = handleSubmit((data) => {
        messageMutation.mutate(data);
        setDisplayMessage(data.message.replace(/\n/g, '<br />')); // Replacing line breaks with <br>
    });

    return (
        <div className="flex w-full mb-4">
            <div className="flex-1">
                <form className="flex flex-col space-y-1 pt-1" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="message"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Сообщение"
                                rows={3}
                                error={!!errors.message?.message}
                                helperText={errors.message?.message}
                                multiline
                                fullWidth
                                required
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setDisplayMessage(e.target.value.replace(/\n/g, '<br />'));
                                }}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="small"
                            loading={messageMutation.isLoading}
                        >Отправить</Button>
                    </div>
                </form>
            </div>
            <div className="flex-1 m-4">
                <div
                    dangerouslySetInnerHTML={{ __html: displayMessage }}
                />
            </div>
        </div>
    )
}

export default Message;
