import React from 'react';
import moment from 'moment';

import Button from '@mui/material/Button';

import Security from '@mui/icons-material/Security';
import PersonAdd from '@mui/icons-material/PersonAdd'
import PersonOutline from '@mui/icons-material/PersonOutline'
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton'

import { useQuery, useMutation, useQueryClient } from 'react-query';

import InfoCard from 'components/InfoCard/InfoCard';
import Table from 'components/Table/Table';

import { getGeneralStats } from 'api/stats';
import { getPendingDeposits } from 'api/deposists';
import AssignmentIcon from '@mui/icons-material/Assignment';

import NewPlayerDialog from './components/NewPlayerDialog/NewPlayerDialog';
import NewMessageDialog from './components/NewMessageDialog/NewMessageDialog';
import HhUploadDialog from './components/HhUploadDialog/HhUploadDialog';
import AssignPlayerDialog from './components/AssignPlayerDialog/AssignPlayerDialog';
import { DataGrid, GridColDef, GridSortModel, GridValueGetterParams, GridCellParams, GridRowParams } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import Assignment from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from 'components/DeleteDialog';
import { deleteDepositRequest } from 'api/deposists';



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme-bez-vodi': {
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    '&:hover': {
        backgroundColor: lighten(theme.palette.warning.main, 0.7),
    },
  },
}));


const Home: React.FC = () => {
    const queryClient = useQueryClient();

    const statsQuery = useQuery('stats', getGeneralStats);
    const deposistsQuery = useQuery('pending-deposits', getPendingDeposits);

    const [playerDialogOpen, setPlayerDialogOpen] = React.useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
    const [hhUploadDialogOpen, setHhUploadDialogOpen] = React.useState(false);

    const [depositId, setDepositId] = React.useState<number>(null);
    const [depositToDeleteId, setDepositToDeleteId] = React.useState<number>(null);
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    const deleteDepositRequestMutation = useMutation(deleteDepositRequest, {
        onSuccess: (_) => {
            setDeleteDialog(false);
            queryClient.invalidateQueries('pending-deposits');
        }
    });

    const columns: GridColDef[] = [
        {   field: 'player_name',
            headerName: 'Игрок',
            sortable: false,
            flex: 0.3,
            minWidth: 100
        },
        {   field: 'room_name',
            headerName: 'Рум',
            sortable: false,
            flex: 0.2,
            minWidth: 100
        },
        {   field: 'account_record_name',
            headerName: 'Аккаунт',
            sortable: false,
            flex: 0.2,
            minWidth: 100
        },
        {   field: 'amount',
            headerName: 'Сумма',
            sortable: false,
            flex: 0.2,
            minWidth: 100
        },
        {
            field: 'chip_currency_name',
            headerName: 'Валюта',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'created_datetime',
            headerName: 'Дата',
            sortable: false,
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.created_datetime).format('DD.MM.YYYY HH:mm')}`,
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'id',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <IconButton onClick={() => setDepositId(params.row.id)}>
                            <Assignment color={params.row.is_modified ? 'warning' : 'inherit'} />
                        </IconButton>
                        <IconButton onClick={() => {
                            setDepositToDeleteId(params.row.id);
                            setDeleteDialog(true);
                        }}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    </Box>
                );
            }
        }
    ];

    const renderDepositActions = (val) => (
        <Button type="button" onClick={() => setDepositId(val)}>Назначить</Button>
    );

    const deleteDeposit = (depositId: number) => {
        deleteDepositRequestMutation.mutate(depositId);
    }

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-end space-x-4">
                <Button type="button" variant="contained" onClick={() => setHhUploadDialogOpen(true)}>Загрузить HH</Button>
                <Button type="button" variant="contained" onClick={() => setPlayerDialogOpen(true)}>Добавить Игрока</Button>
                <Button type="button" variant="outlined" onClick={() => setMessageDialogOpen(true)}>Отправить Сообщение</Button>
            </div>
            <div className="flex space-x-4">
                <InfoCard color="bg-blue-500" loading={statsQuery.isFetching}>
                    <div className="flex items-center">
                        <PersonAdd className="text-5xl mr-5" />
                        <div className="flex-1">
                            <p className="font-bold text-3xl">{statsQuery.data?.users?.active}</p>
                            <p className="text-lg">Активных Игроков</p>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard color="bg-purple-500" loading={statsQuery.isFetching}>
                    <div className="flex items-center">
                        <PersonOutline className="text-5xl mr-5" />
                        <div className="flex-1">
                            <p className="font-bold text-3xl">{statsQuery.data?.users?.inactive}</p>
                            <p className="text-lg">Неактивных Игроков</p>
                        </div>
                    </div>
                </InfoCard>
                <InfoCard color="bg-orange-500" loading={statsQuery.isFetching}>
                    <div className="flex items-center">
                        <Security className="text-5xl mr-5" />
                        <div className="flex-1">
                            <p className="font-bold text-3xl">{statsQuery.data?.users?.staff}</p>
                            <p className="text-lg">Администраторов</p>
                        </div>
                    </div>
                </InfoCard>
            </div>

            <Card>
                <Box
                    className="h-auto overflow-auto"
                    sx={{
                        '& .active': {
                            backgroundColor: '#fcecd5',
                            color: '#1a3e72',
                        },
                    }}
                >
                    <StyledDataGrid
                        autoHeight={true}
                        rows={deposistsQuery.data ?? []}
                        columns={columns}
                        // pageSize={50}
                        // rowCount={tournaments.count ?? 0}
                        // page={page}
                        // paginationMode="server"
                        // sortingMode="server"
                        // onPageChange={(newPage) => setPage(newPage)}
                        // onPageSizeChange={(newPageSize) => { setPageSize(newPageSize); setPage(0) }}
                        // rowsPerPageOptions={[25, 50, 100]}
                        // pagination
                        disableSelectionOnClick
                        disableColumnMenu
                        getRowClassName={(params: any) => {
                            return params.row.video_level_id === 16 ? 'super-app-theme-bez-vodi' : ''
                        }}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Данные отсутствуют
                                </Stack>
                            ),
                        }}
                        // componentsProps={{
                        //     pagination: {
                        //         ActionsComponent: TablePaginationActions
                        //     }
                        // }}
                    />
                </Box>
            </Card>

            <NewPlayerDialog
                open={playerDialogOpen}
                onClose={() => setPlayerDialogOpen(false)}
            />

            <NewMessageDialog
                open={messageDialogOpen}
                onClose={() => setMessageDialogOpen(false)}
            />
            <HhUploadDialog
                open={hhUploadDialogOpen}
                close={() => setHhUploadDialogOpen(false)}
            />

            <AssignPlayerDialog
                depositId={depositId}
                onClose={() => setDepositId(null)}
            />

            <DeleteDialog
                close={() => setDeleteDialog(false)}
                open={deleteDialog}
                loading={deleteDepositRequestMutation.isLoading}
                handleAgreeAction={() => deleteDeposit(depositToDeleteId)}/>

        </div>
    );
};

export default Home;
