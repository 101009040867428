import axios from 'helpers/axios';

export const getClubs = async ({ queryKey }) => {
    const [, roomId] = queryKey;

    var params = new URLSearchParams();
    if (roomId) {
        params.append("roomId", roomId);
    }

    const { data } = await axios.get('/api/adminapi/clubs/', {params: params});

    return data;
};
