import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

import { getRooms } from 'api/rooms';
import { Box, Card, CircularProgress, IconButton, Stack, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';
import ClearAll from '@mui/icons-material/ClearAll';
import Scheduled from './Scheduled/Scheduled';
import Estimate from './Estimate/Estimate';
import Hidden from './Hidden/Hidden';
import Charts from './Charts/Charts';
import SchedulerTournamentsFilters from './SchedulerTournamentsFilters';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { getSchedulerTournaments } from 'api/schedulersettings';
import { darken, lighten, styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .error': {
        backgroundColor: lighten(theme.palette.error.main, 0.8),
        '&:hover': {
            backgroundColor: lighten(theme.palette.warning.main, 0.7),
        },
    },
    '& .warning': {
        backgroundColor: lighten(theme.palette.warning.main, 0.8),
        '&:hover': {
            backgroundColor: lighten(theme.palette.warning.main, 0.7),
        },
    },
}));

/**
 * Отображает таблицу турниров в расписаторе.
 */
const SchedulerTournaments: FC = () => {
    // Фильтр по дням недели
    const [filters, setFilter] = useState([]);
    // Строка поиска
    const [search, setSearch] = useState('');
    // Турниры с оценкой от куратора
    const [curatorEstimate, setCuratorEstimate] = useState<boolean>(false);
    // Турниры с кастомными уровнями
    const [customLevels, setCustomLevels] = useState<boolean>(false);
    // Только текущие турниры
    const [current, setCurrent] = useState<boolean>(false);
    // Только скрытые турниры
    const [hidden, setHidden] = useState<boolean>(false);
    // Запланированные турниры
    const [scheduledTournaments, setScheduledTournaments] = useState<{ hash: string; name: string } | undefined>();
    // Ручная оценка турнира
    const [editEstimante, setEditEstimante] = useState<{ hash: string; name: string } | undefined>();
    // Редактирование видимости турнира
    const [editHidden, setEditHidden] = useState<{ hash: string; name: string } | undefined>();
    // Чарты
    const [showCharts, setShowCharts] = useState<{ hash: string; name: string } | undefined>();
    // Диапазон байинов
    const [rangeBuyin, setRangeBuyin] = useState();
    // Правила сортировки.
    const [sortOptions, setSortOptions] = useState({ sortModel: null });

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions({ sortModel: [...sortModel] });
    }, []);

    const { data: rooms } = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const { data: tournaments, isFetching } = useQuery(
        ['scheduler-tournaments-data', pageSize, page, filters, search, curatorEstimate, customLevels, current, hidden, rangeBuyin, sortOptions],
        getSchedulerTournaments,
        {
            refetchOnWindowFocus: true,
            keepPreviousData: true,
        }
    );

    const columns: GridColDef[] = [
        {
            field: 'network',
            headerName: 'Рум',
            sortable: false,
            flex: 0.3,
            minWidth: 100,
            renderCell: params => {
                const handleCopy = () => {
                    navigator.clipboard.writeText(params.row.hash);
                };

                return (
                    <div>
                        <span onClick={handleCopy} className="text-blue-500 hover:underline cursor-pointer">{params.row.network}</span>
                    </div>
                )
            }
        },
        {
            field: 'buyin',
            headerName: 'Байин',
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'currency',
            headerName: 'Валюта',
            sortable: false,
            flex: 0.2,
            minWidth: 60,
        },
        {
            field: 'last_at',
            headerName: 'Последний',
            valueGetter: (params: GridValueGetterParams) => `${moment(params.row.last_at).format('DD.MM.YYYY HH:mm')}`,
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: 'start_at',
            headerName: 'Время начала',
            flex: 0.2,
            minWidth: 120,
        },
        {
            field: 'name',
            headerName: 'Турнир',
            sortable: false,
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'hash',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 180,
            renderCell: params => {
                function selectItem(params: any) {
                    const data = {
                        hash: params.row.hash,
                        name: params.row.name,
                    };
                    return data;
                }
                return (
                    <Box
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton onClick={() => setEditHidden(selectItem(params))}>
                            <BlockIcon color={params.row.is_hidden ? 'warning' : 'inherit'} />
                        </IconButton>
                        <IconButton onClick={() => setEditEstimante(selectItem(params))}>
                            <SettingsOutlined color={params.row.has_levels || params.row.has_scores ? 'warning' : 'inherit'} />
                        </IconButton>
                        <IconButton>
                            <InsertChartOutlined onClick={() => setShowCharts(selectItem(params))} />
                        </IconButton>
                        <IconButton onClick={() => setScheduledTournaments(selectItem(params))}>
                            <ClearAll />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    if (editEstimante) {
        return <Estimate hash={editEstimante.hash} name={editEstimante.name} close={() => setEditEstimante(undefined)} />;
    }

    if (scheduledTournaments) {
        return <Scheduled hash={scheduledTournaments.hash} name={scheduledTournaments.name} close={() => setScheduledTournaments(undefined)} />;
    }

    if (editHidden) {
        return <Hidden hash={editHidden.hash} name={editHidden.name} close={() => setEditHidden(undefined)} />;
    }

    if (showCharts) {
        return <Charts hash={showCharts.hash} name={showCharts.name} close={() => setShowCharts(undefined)} />;
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    function getRowId(row) {
        return row.hash;
    }

    return (
        <>
            <SchedulerTournamentsFilters
                setFilter={setFilter}
                data={filters}
                rooms={rooms}
                setSearch={setSearch}
                search={search}
                curatorEstimate={curatorEstimate}
                setCuratorEstimate={setCuratorEstimate}
                customLevels={customLevels}
                setCustomLevels={setCustomLevels}
                current={current}
                setCurrent={setCurrent}
                hidden={hidden}
                setHidden={setHidden}
                rangeBuyin={rangeBuyin}
                setRangeBuyin={setRangeBuyin}
            />
            {isFetching && !tournaments && (
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                    <CircularProgress />
                </Card>
            )}
            {tournaments && (
                <Card>
                    <Box
                        className="h-auto overflow-auto"
                        sx={{
                            '& .active': {
                                backgroundColor: '#fcecd5',
                                color: '#1a3e72',
                            },
                        }}
                    >
                        <StyledDataGrid
                            autoHeight={true}
                            rows={tournaments.groups ?? []}
                            columns={columns}
                            pageSize={pageSize}
                            rowCount={tournaments.count ?? 1000}
                            page={page}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={newPage => setPage(newPage)}
                            onPageSizeChange={newPageSize => {
                                setPageSize(newPageSize);
                                setPage(0);
                            }}
                            onSortModelChange={handleSortModelChange}
                            rowsPerPageOptions={[25, 50, 100]}
                            getRowId={getRowId}
                            getRowClassName={(params: any) => {
                                if (params.row.coverage === undefined || params.row.coverage >= 0.75) {
                                    return '';
                                } else if (params.row.coverage < 0.67) {
                                    return 'error';
                                } else {
                                    return 'warning';
                                }
                            }}
                            pagination
                            disableSelectionOnClick
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Данные отсутствуют
                                    </Stack>
                                ),
                            }}
                            componentsProps={{
                                pagination: {
                                    ActionsComponent: TablePaginationActions,
                                },
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: sortOptions?.sortModel,
                                },
                            }}
                        />
                    </Box>
                </Card>
            )}
        </>
    );
};

export default SchedulerTournaments;
