import { FC, useState } from 'react';

import { Breadcrumbs, Card, Link, Typography } from '@mui/material';
import ScheduledFilters from './ScheduledFilters';
import ScheduledTable from './ScheduledTable';

type Props = {
    /**
     * Имя турнира.
     */
    name: string;

    /**
     * ID турнира.
     */
    id: number;

    /**
     * Закрытие вкладки с информацией о запланированных турнирах.
     */
    close: () => void;
}

/**
 * Вкладка с информацией о запланированных турнирах.
 */
const Scheduled: FC<Props> = ({ name, id, close }) => {
    const [filter, setFilter] = useState([]);

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className='mb-4'>
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => close()}
                    className='cursor-pointer'
                >
                    Список турниров
                </Link>
                <Typography color="text.primary">{name}</Typography>
            </Breadcrumbs>
            <ScheduledFilters setFilter={setFilter} data={filter} />
            <Card className='mt-2'><ScheduledTable filters={filter} id={id} /></Card>
        </>
    );
}

export default Scheduled;
