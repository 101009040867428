import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    Box,
    Stack,
} from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef } from '@mui/x-data-grid';
import { updateDefaultMaxBuyin } from 'api/ggnetworkscoring';

type Props = {
    items: any;
};

const DefaultMaxBuyinTable: FC<Props> = ({ items }) => {
    const queryClient = useQueryClient();

    const columns: GridColDef[] = [
        {
            field: 'level',
            headerName: 'Уровень',
            flex: 1,
            minWidth: 100,
            align: 'left',
            headerAlign: 'left',
            filterable: true,
        },
        {
            field: 'min_score',
            headerName: 'Min Score',
            flex: 1,
            minWidth: 100,
            type: 'number',
            editable: false,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'max_score',
            headerName: 'Max Score',
            flex: 1,
            minWidth: 100,
            type: 'number',
            editable: false,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'max_buyin',
            headerName: 'Max Buyin',
            flex: 1,
            minWidth: 100,
            type: 'number',
            editable: true,
            align: 'left',
            headerAlign: 'left',
        },
    ];

    const editItemMutation = useMutation(updateDefaultMaxBuyin, {
        onSuccess: () => {
            queryClient.invalidateQueries('ggnetworkscoring-default-max-buyins');
        },
    });


    function editCell(params: any) {
        if (Number(params.formattedValue) !== Number(params.value)) {
            const editId = params.id;
            const maxBuyin = {
                min_score: params.row.min_score,
                max_score: params.row.max_score,
                max_buyin: Number(params.value),
            }

            editItemMutation.mutate({ id: editId, maxBuyin })
        }
    }

    return (
        <Box
            className="h-auto overflow-auto"
            sx={{
                '& .active': {
                    backgroundColor: '#fcecd5',
                    color: '#1a3e72',
                },
            }}
        >
            <DataGrid
                autoHeight={true}
                rows={items ?? []}
                columns={columns}
                filterMode='client'
                disableColumnFilter={false}
                hideFooter
                disableSelectionOnClick
                disableColumnMenu={false}
                onCellEditCommit={(params: GridCellEditCommitParams) => editCell(params)}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Данные отсутствуют
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default DefaultMaxBuyinTable;
