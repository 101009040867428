import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { Box, Button, Card, CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import { getHandleAssessment } from 'api/handleassessment';
import { Controller, useForm } from 'react-hook-form';
import ManualLabelsTable from './ManualLabelsTable';

const filterArray = [
    {
        name: 'Все игроки',
        value: 'all',
    },
    {
        name: 'Игроки с ручным лейблом',
        value: 'labeled',
    },
    {
        name: 'Игроки без лейбла',
        value: 'unlabeled',
    }
];

type Props = {
    /**
     * Список румов
     */
    rooms: string[];
};

/**
 * Вкладка с ручной оценкой.
 */
const ManualLabels: FC<Props> = ({ rooms }) => {
    const [search, setSearch] = useState({ network: '', tournament_id: '' });
    const [filter, setFilter] = useState(filterArray[0].value)

    const { data, isFetching } = useQuery(['assessment-data', search.network, search.tournament_id, filter], getHandleAssessment, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    })

    const { control, handleSubmit } = useForm({
        defaultValues: {
            network: rooms[0],
            tournament_id: '',
        },
    });

    const onSubmit = handleSubmit(data => {
        setSearch({
            network: data.network,
            tournament_id: data.tournament_id,
        });
    });

    return (
        <>
            <Box className='flex flex-col'>
                <Box className='flex flex-row flex-wrap gap-4 items-center mb-3'>
                    <form className='flex flex-row gap-4 pt-2' onSubmit={onSubmit}>
                        <Controller
                            control={control}
                            name='network'
                            render={({ field }) => (
                                <Select {...field} >
                                    {rooms.map((network) => (
                                        <MenuItem
                                            key={network}
                                            value={network}
                                        >
                                            {network}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="tournament_id"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Id турнира"
                                    required
                                    style={{ minWidth: '200px' }}
                                    {...field}
                                />
                            )}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            size='large'
                            type='submit'
                            disabled={isFetching}
                        >
                            Показать игроков
                        </Button>
                    </form>
                </Box>
            </Box>
            {isFetching && !data && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {data && (
                <>
                    <Select value={filter} onChange={(event) => setFilter(event.target.value)} disabled={isFetching}>
                        {filterArray.map((item) => (
                            <MenuItem
                                key={item.value}
                                value={item.value}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Card className='mt-4'>
                        <ManualLabelsTable items={data} />
                    </Card>
                </>
            )}
        </>
    );
}

export default ManualLabels;
