import { FC, ComponentPropsWithoutRef } from 'react';

import { Box, Typography, ButtonBase } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof ButtonBase>,
  'children'
> & {
  /**
   * Заголовок кнопки.
   */
  label: string;

  /**
   * Текст на кнопке.
   */
  value: string;

  /**
   * Размер кнопки.
   */
  small?: boolean;
};

/**
 * Отображает кнопку, которая стилизована под поле ввода текста.
 */
const TextFieldButton: FC<Props> = ({
  label,
  value,
  small,
  ...props
}) => {
  const btnSize = small ? 'w-[140px]' : 'w-[190px]';

  return (
    <ButtonBase
      component="button"
      {...props}
      className={`flex flex-row items-center px-2 py-1 ${btnSize} bg-gray-100 border border-gray-300 border-solid rounded-lg ease-in text-left focus:bg-gray-50`}
      title={value}
      focusRipple
    >
      <Box className="flex flex-1 flex-col whitespace-nowrap overflow-hidden" component="span">
        <Typography
          variant="subtitle1"
          component="span"
          className="text-sm text-gray-500 leading-5"
        >
          {label}
        </Typography>
        <Typography className="text-base text-ellipsis overflow-hidden" component="span">
          {value}
        </Typography>
      </Box>
      <Box className="p-0" component="span">
        <ExpandMore />
      </Box>
    </ButtonBase>
  );
};

export default TextFieldButton;