import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getPlayerLevels } from 'api/playerlevels';
import { Box, MenuItem, Typography } from '@mui/material';
import Dropdown from 'components/Dropdown';
import { deleteScheduledLevelTournaments, getScheduledLevelTournaments, newScheduledLevelTournaments } from 'api/schedulersettings';

type Props = {
  /**
   * Hash турнира.
   */
  hash: string;
}

/**
 * Отображает таблицу ручной оценки турниров в расписаторе.
 */
const CustomLevelsTable: FC<Props> = ({ hash }) => {
    const queryClient = useQueryClient();

    const { data: tournaments } = useQuery(['scheduler-customlevels-tournaments-data', hash], getScheduledLevelTournaments, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const playerLevelsQuery = useQuery('player-levels-list', getPlayerLevels, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const { mutate: mutateNewLevel } = useMutation(newScheduledLevelTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-customlevels-tournaments-data');
            queryClient.invalidateQueries('scheduler-tournaments-data');
        },
    });

    const { mutate: mutateDeleteLevel } = useMutation(deleteScheduledLevelTournaments, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-customlevels-tournaments-data');
            queryClient.invalidateQueries('scheduler-tournaments-data');
        },
    });

    function checkValue(day: number) {
        return tournaments?.levels?.filter(data => data.day_number === day)[0]?.max_level ?? '—';
    }

    const days = [
        {
            day_number: 0,
            name: 'Понедельник',
            value: checkValue(0),
        },
        {
            day_number: 1,
            name: 'Вторник',
            value: checkValue(1),
        },
        {
            day_number: 2,
            name: 'Среда',
            value: checkValue(2),
        },
        {
            day_number: 3,
            name: 'Четверг',
            value: checkValue(3),
        },
        {
            day_number: 4,
            name: 'Пятница',
            value: checkValue(4),
        },
        {
            day_number: 5,
            name: 'Суббота',
            value: checkValue(5),
        },
        {
            day_number: 6,
            name: 'Воскресенье',
            value: checkValue(6),
        }
    ]

    function editItem(day: number, level_id: number, close: Function) {
        mutateNewLevel({ tournament_group_hash: hash, day_number: day, max_level: level_id });
        close();
    }

    function deleteItem(day: number, close: Function, oldValue: number | string) {
        if (oldValue !== '—') {
            const rowId = tournaments?.levels?.filter(data => data.day_number === day)[0]?.id;
            mutateDeleteLevel(rowId);
        }
        close();
    }

    return (
        <>
            {tournaments &&
                <Box className='px-4 py-2'>
                    <Typography>Уровни турниров</Typography>
                    <Box className='flex flex-row flex-wrap gap-2 mt-2 xl:justify-between'>
                        {days.map((val) => (
                            <Dropdown
                                btnLabel={val.name}
                                btnValue={String(val.value)}
                                key={val.name}
                                small={true}
                                renderContent={({ close }) => (
                                    <>
                                    <MenuItem onClick={() => deleteItem(val.day_number, close, val.value)}>
                                        {'—'}
                                    </MenuItem>
                                    {playerLevelsQuery.data?.sort((a, b) => {
                                            return parseInt(a.name) > parseInt(b.name);
                                        }).map((item) => {
                                        return (
                                            <MenuItem key={`${item.id} + ${val.name}`} onClick={() => editItem(val.day_number, item.name, close )}>
                                                {`${item.group.name}(${item.name})`}
                                            </MenuItem>
                                        );
                                    })}
                                    </>
                                )}
                            />
                        ))}
                    </Box>
                </Box>
            }
        </>
    );
};

export default CustomLevelsTable;
