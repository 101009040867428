import { FC, Dispatch, SetStateAction } from 'react';

import { Box, ListItemIcon, MenuItem } from '@mui/material';
import { Check } from '@mui/icons-material';
import Dropdown from 'components/Dropdown';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Массив примененных фильтров.
     */
    data: any;

    /**
     * Массив румов.
     */
    rooms: [{ id: string, network: string }];

    /**
     * Разрешен выбор только одного значения.
     */
    single?: boolean;
};

/**
 * Компонент содержит фильтр румов.
 */
const RoomsFilter: FC<Props> = ({ data, setFilter, rooms, single }) => {
    function changeFilter(item) {
        let filter = data.slice();
        const id = filter.findIndex(data => data.id === item.id);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            if (single) {
                return setFilter([item])
            }
            filter.push(item);
        }

        setFilter(filter);
    }

    function filterCheck(roomId) {
        const id = data.filter(val => val.id === roomId);

        return id[0] ? true : false;
    }

    function filterValue(data?) {
        if (data && data.length > 0) {
            const separator = data.length > 1 ? ' ,' : '';
            return data.map(x => x.network) + separator;
        }
        return undefined;
    }

    return (
        <Box className='mb-3'>
            <Dropdown
                btnLabel='Рум'
                btnValue={filterValue(data)}
                renderContent={() => (
                    rooms?.map((item) => {
                        return (
                            <MenuItem onClick={() => changeFilter(item)} key={item.id} >
                                {filterCheck(item.id) &&
                                    <ListItemIcon>
                                        <Check />
                                    </ListItemIcon>
                                }
                                {item.network}
                            </MenuItem>
                        )
                    })
                )}
            />
        </Box>
    );
};

export default RoomsFilter;
