import axios from 'helpers/axios';

/**
 * Список распределения призов по группам.
 */
 export const getPrizeDistributionList = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/prizedistributiongroups/');

    return data;
};

/**
 * Удалить группу
 */
 export const deletePrizeDistributionGroup = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/prizedistributiongroups/${id}/`);

    return data;
};

/**
 * Создать группу для распределения призов
 */
export const createPrizeDistributionGroup= async (group) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/prizedistributiongroups/`, group);

    return data;
};

/**
 * Данные по конкретной группе.
 */
export const getPrizeDistributionGroupDetails = async ({ queryKey }) => {
    const [, id] = queryKey;
    if (!id) {
        return;
    }

    const { data } = await axios.get(`/api/adminapi/statsscoring/prizedistributiongroups/${id}/`);

    return data;
};

/**
 * Добавить долю призов по метке в группе 
 */
export const creatPrizeDistributionGroupRate = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/prizedistributiongroups/${id}/prizedistributions/`, body);

    return data;
};

/**
 * Удалить долю призов по метке в группе
 */
export const deletePrizeDistributionGroupMark = async ({ group_id, ratio_id }) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/prizedistributiongroups/${group_id}/prizedistributions/${ratio_id}/`);

    return data;
};