import React from 'react';
import moment from 'moment';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import DateTimePicker from '@mui/lab/DateTimePicker'

import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createBundle, getLastPlayerBundle } from 'api/bundles';

const NewBundleDialog: React.FC<DialogProps & { contractId: number }> = ({ contractId, ...props }) => {
    const { playerId } = useParams();

    const queryClient = useQueryClient();

    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            amount_games: 400,
            start_datetime: null,
            personal_share: 0,
            payout: 0,
        }
    });

    const lastBundleQuery = useQuery(['last_bundle', playerId], getLastPlayerBundle, {
        initialData: null,
    })

    const { mutate, isLoading } = useMutation(createBundle, {
        onSuccess: () => {
            queryClient.invalidateQueries(['player-contracts', playerId]);

            props.onClose({}, 'backdropClick');

            reset();
        }
    });

    React.useEffect(() => {
        if (lastBundleQuery.data) {
            if (lastBundleQuery.data.end_datetime) {
                setValue('start_datetime', moment(lastBundleQuery.data.end_datetime));
            } else {
                setValue('start_datetime', null);
            }
            setValue('personal_share', lastBundleQuery.data.personal_share);
            setValue('payout', lastBundleQuery.data.payout);
            // reset({
            //     created_datetime: lastBundleQuery.data.end_datetime,
            // });
        }
    }, [lastBundleQuery.data, setValue]);

    const onSubmit = handleSubmit((data: any) => {
        mutate({
            ...data,
            start_datetime: data.start_datetime.toISOString(),
            player_id: playerId,
            contract_id: contractId
        });
    });

    return (
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>Добавить bundle</DialogTitle>
            <DialogContent>
                <form className="flex flex-col space-y-4 pt-2" onSubmit={onSubmit} noValidate>
                    <Controller
                        name="amount_games"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Кол-во игр"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="start_datetime"
                        control={control}
                        render={({ field }) => (
                            <DateTimePicker
                                {...field}
                                renderInput={props => (
                                    <TextField {...props} label="Дата начала" required fullWidth />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="personal_share"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Player share, %"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="payout"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Player payout, %"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewBundleDialog;
