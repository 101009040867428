import axios from 'helpers/axios';

/**
 * Список всех правил.
 */
export const getAllRules = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/rulegroups/');

    return data;
};

/**
 * Данные по конкретному правилу.
 */
 export const getRule = async ({ queryKey }) => {
    const [, id] = queryKey;
    if (!id) {
        return;
    }

    const { data } = await axios.get(`/api/adminapi/statsscoring/rulegroups/${id}/`);

    return data;
};

/**
 * Удалить правило
 */
 export const deleteRule = async (id: number) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/rulegroups/${id}/`);

    return data;
};

/**
 * Создать правило
 */
export const createRule = async (rule) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/rulegroups/`, rule);

    return data;
};

/**
 * Список стат.
 */
export const getStatRules = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/stats/');

    return data;
};

/**
 * Добавить правило по метке
 */
export const createRuleMarks = async ({ id, ...body }) => {
    const { data } = await axios.post(`/api/adminapi/statsscoring/rulegroups/${id}/rulesets/`, body);

    return data;
};

/**
 * Удалить правило по метке
 */
export const deleteRuleMarks = async ({ rulegroup_id, ruleset_id }) => {
    const { data } = await axios.delete(`/api/adminapi/statsscoring/rulegroups/${rulegroup_id}/rulesets/${ruleset_id}/`);

    return data;
};

/**
 * Список румов для статистики.
 */
export const getRulesRoomList = async () => {
    const { data } = await axios.get('/api/adminapi/statsscoring/networks/supported/');

    return data;
};

