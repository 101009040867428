import { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import PrizeDistributionTable from './PrizeDistributionTable';
import { getPrizeDistributionList } from 'api/prizedistributiongroups';

type Props = {
    /**
     * ID группы призов для детального просмотра
     */
    prizeGroupView: string; 
    
    /**
     * Изменить группу призов для детального просмотра
     */
    setPrizeGroupView: Dispatch<SetStateAction<string>>;
}

/**
 * Вкладка с распределением призов.
 */
const PrizeDistribution: FC<Props> = ({ prizeGroupView, setPrizeGroupView }) => {
    const {data, isFetching} = useQuery(['prize-distribution'], getPrizeDistributionList, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    return (
        <>
            {isFetching && !data && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && data && <Card><PrizeDistributionTable items={data} prizeGroupView={prizeGroupView} setPrizeGroupView={setPrizeGroupView} /></Card>}
        </>
    );
}

export default PrizeDistribution;
