import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const StatsCardList = ({ stats }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {stats.map((stat, index) => (
                <Card key={index} className="p-2">
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {stat.name}
                        </Typography>
                        <Typography variant="body2">
                            {stat.value}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default StatsCardList;
