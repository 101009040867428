import { ChangeEvent, FC, useState } from 'react';
import * as yup from 'yup';
import moment from 'moment';

import { getRooms } from 'api/rooms';
import { getCurrencies } from 'api/currencies';
import { getPlayerLevels } from 'api/playerlevels';
import { createCustomTournament } from 'api/customgridtournaments';
import { IconButton, DialogTitle, DialogContent, TextField, Dialog, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import Button from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import { TimePicker } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

type Props = {
    /**
     * Функция закрытия диалогового окна.
     */
    open: boolean;
    close: () => void;
}

const tournamentSchema = yup.object().shape({
    network: yup.number().required(),
    name: yup.string().required().test('len', 'short', val => val.length > 5),
    days: yup.string().required(),
    time: yup.string().required(),
    buyin: yup.number().required(),
    buyin_currency: yup.number().required(),
    tags: yup.array().required(),
    min_player_level: yup.number(),
    score: yup.number().required().min(-2).max(2),
});

/**
 * Диалоговое окно для создания нового кастомного турнирa.
 */
const TournamentDialog: FC<Props> = ({ ...props }) => {
    const queryClient = useQueryClient();

    const roomsQuery = useQuery(['series-rooms'], getRooms, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const currenciesQuery = useQuery(['currencies'], getCurrencies, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const levelsQuery = useQuery(['player-levels-list'], getPlayerLevels, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
        resolver: yupResolver(tournamentSchema),
        defaultValues: {
            network: null,
            name: '',
            days: '',
            time: '',
            buyin: '',
            buyin_currency: null,
            tags: [],
            min_player_level: 0,
            score: '',
            begin_datetime: null,
            expire_datetime: null,
        }
    });

    const watchTags = watch('tags');

    const newItemMutation = useMutation(createCustomTournament, {
        onSuccess: () => {
            queryClient.invalidateQueries('custom-tournaments-data');

            props.close();
        },
    });

    const onSubmit = handleSubmit((data) => {
        newItemMutation.mutate({
            ...data,
            days: data.days.split(',').map(item => Number(item.trim())),
            time: moment(data.time).format("HH:mm"),
            min_player_level: data.min_player_level === 0 ? null : data.min_player_level,
            begin_datetime: data.begin_datetime === null ? null : data.begin_datetime.toISOString(),
            expire_datetime: data.begin_datetime === null ? null : data.expire_datetime.toISOString(),
        })
    });

    return (
        <>
        <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle>
                Новый турнир
                <IconButton
                    aria-label='close'
                    onClick={() => {
                        props.close();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form className='flex flex-col gap-4 pt-2' onSubmit={onSubmit}>
                    <Controller
                        name="network"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Рум"
                                select
                                fullWidth
                                required
                                defaultValue={null}
                                {...field}
                            >
                                {
                                    roomsQuery.data?.map(room => (
                                        <MenuItem key={room.id} value={room.id}>
                                            {room.network}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Название"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="days"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Дни (0-6, через запятую)"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="time"
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <TimePicker
                                inputFormat='HH:mm'
                                mask={'__:__'}
                                label="Время начала UTC"
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <Controller
                        name="buyin"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Байин"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="buyin_currency"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Валюта байина"
                                select
                                fullWidth
                                required
                                {...field}
                            >
                                {
                                    currenciesQuery.data?.map(c => (
                                        <MenuItem key={c.id} value={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="min_player_level"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Минимальный уровень игрока"
                                select
                                fullWidth
                                defaultValue={0}
                                {...field}
                            >
                                <MenuItem key={0} value={0}>
                                    -
                                </MenuItem>
                                {
                                    levelsQuery.data?.map(l => (
                                        <MenuItem key={l.id} value={l.id}>
                                            {l.name} {l.group.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        )}
                    />
                    <Controller
                        name="score"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Оценка (от -2 до 2)"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name='begin_datetime'
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Дата появления в расписаторе'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <Controller
                        name='expire_datetime'
                        control={control}
                        render={({ field: { ref: _, ...rest } }) => (
                            <DateTimePicker
                                inputFormat='DD/MM/yyyy HH:mm'
                                mask={'__/__/____ __:__'}
                                label='Показывается в расписаторе до даты'
                                renderInput={params => <TextField {...params} />}
                                {...rest}
                            />
                        )}
                    />
                    <div className="pl-2 pr-2 m-0">
                        <FormControlLabel
                            className="pl-2 pr-2 m-0"
                            key={'turbo'}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    name={'Turbo'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let a = [...watchTags]
                                            a.push('T');
                                            setValue('tags', a);
                                        } else {
                                            const idx = watchTags.indexOf('T')
                                            if (idx !== -1) {
                                                let a = [...watchTags];
                                                a.splice(idx, 1)
                                                setValue('tags', a);
                                            }
                                        }
                                        return;
                                    }}
                                    checked={watchTags.includes('T')}
                                />
                            }
                            label={'Turbo'}
                            value={false}
                        />
                        <FormControlLabel
                            className="pl-2 pr-2 m-0"
                            key={'rebuy'}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    name={'Rebuy'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let a = [...watchTags]
                                            a.push('R');
                                            setValue('tags', a);
                                        } else {
                                            const idx = watchTags.indexOf('R')
                                            if (idx !== -1) {
                                                let a = [...getValues('tags')];
                                                a.splice(idx, 1)
                                                setValue('tags', a);
                                            }
                                        }
                                        return;
                                    }}
                                    checked={watchTags.includes('R')}
                                />
                            }
                            label={'Rebuy'}
                            value={false}
                        />
                        <FormControlLabel
                            className="pl-2 pr-2 m-0"
                            key={'bounty'}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    name={'Bounty'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let a = [...watchTags]
                                            a.push('B');
                                            setValue('tags', a);
                                        } else {
                                            const idx = watchTags.indexOf('B')
                                            if (idx !== -1) {
                                                let a = [...watchTags];
                                                a.splice(idx, 1)
                                                setValue('tags', a);
                                            }
                                        }
                                        return;
                                    }}
                                    checked={watchTags.includes('B')}
                                />
                            }
                            label={'Bounty'}
                            value={false}
                        />
                    </div>
                    <div className="pt-4 text-right">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={newItemMutation.isLoading}
                        >Создать</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
        </>
    );
};

export default TournamentDialog;
