import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import authReducer from './auth/auth';

import sagaWatchers from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const SET_FILTER = 'TOURNAMENTS/SET_FILTER';

interface SetFilter { type: typeof SET_FILTER; payload: any; }

interface authState {
    biStart: number;
    biEnd: number | null;
    minEntries: number;
    maxEntries: number | null;
    rooms: string[];
    filterAverage: boolean;
    filterBad: boolean;
    filterTop: boolean;
    filterGood: boolean;
    filterForbidden: boolean;
    typeFilterBounty: boolean;
    typeFilterFreezeout: boolean;
    typeFilterRebuy: boolean;
    typeFilterRegular: boolean;
    typeFilterTurbo: boolean;
    tz: { value: string; label: string; };
}

export const setFilter = (payload: any) => ({ type: SET_FILTER, payload });

const initialState: authState = {
    biStart: 0,
    biEnd: null,
    minEntries: 0,
    maxEntries: null,
    rooms: [],
    filterAverage: false,
    filterBad: false,
    filterGood: true,
    filterTop: true,
    filterForbidden: false,
    typeFilterBounty: true,
    typeFilterFreezeout: true,
    typeFilterRebuy: true,
    typeFilterRegular: true,
    typeFilterTurbo: true,
    tz: { value: 'Europe/Moscow', label: 'Europe/Moscow' }
};

const tournaments = (state: authState = initialState, action: SetFilter): authState => {
    switch (action.type) {
        case SET_FILTER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const STATE_KEY = 'STATEv1';

const composeEnhancers =
    (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reduxState =
    process.env.NODE_ENV === 'production'
        ? applyMiddleware(sagaMiddleware)
        : composeEnhancers(applyMiddleware(...middleware));

const reducer = combineReducers({
    auth: authReducer,
    tournaments: tournaments,
});

export type AppState = ReturnType<typeof reducer>;

const persistState = (state: AppState) => {
    try {
        const jsonState = JSON.stringify(state);

        localStorage.setItem(STATE_KEY, jsonState);
    } catch (e) {
        console.error(e);
    }
};

const loadState = () => {
    try {
        const jsonState = localStorage.getItem(STATE_KEY);

        return jsonState ? JSON.parse(jsonState) : undefined;
    } catch (e) {
        console.error(e);
    }
};

const store = createStore(reducer, loadState(), reduxState);

store.subscribe(() => persistState(store.getState()));

sagaWatchers.forEach(watcher => sagaMiddleware.run(watcher));

export default store;
