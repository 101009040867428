import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { getScoring } from 'api/scoring';
import { Card, CircularProgress } from '@mui/material';
import ScoringFilters from './ScoringFilters';
import ScoringTable from './ScoringTable';

/**
 * Таблица скоринга.
 */
const Scoring: FC = () => {
    // Фильтр по ревизии
    const [revision, setRevision] = useState();
    // Поиск игрока
    const [search, setSearch] = useState('');
    // Фильтр по кураторам
    const [curatorFilter, setCuratorFilter] = useState<any>(null);
    // Пагинация
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const {data: risk, isFetching} = useQuery(['player-scoring', revision, search, curatorFilter, pageSize, page], getScoring, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },)

    return (
        <>
            <ScoringFilters
                setSearch={setSearch}
                search={search}
                curatorFilter={curatorFilter}
                setCuratorFilter={setCuratorFilter}
                revision={revision}
                setRevision={setRevision}
                
            />
            {isFetching && !risk && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {risk && 
                <Card>
                    <ScoringTable 
                        items={risk} 
                        pageSize={pageSize} 
                        setPageSize={setPageSize} 
                        page={page}
                        setPage={setPage}
                    />
                </Card>
            }
       </>
    );
}

export default Scoring;
