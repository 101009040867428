import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

import { getScheduledTournaments } from 'api/tournamentsgroups';
import { Box, Card, CircularProgress, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DateHelper from 'helpers/DateHelper';

type Props = {
  /**
   * Список примененных фильтров.
   */
  filters: any;

  /**
   * ID турнира.
   */
  id: number;
}

/**
 * Отображает таблицу запланированных турниров.
 */
const ScheduledTable: FC<Props> = ({ filters, id }) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const {data: tournaments, isFetching} = useQuery(['scheduled-tournaments-data', pageSize, page, filters, id], getScheduledTournaments, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    const columns: GridColDef[] = [
      {
        field: 'tournament_id',
        headerName: 'ID',
        flex: 0.2,
        minWidth: 150,
      },
      {
        field: 'name',
        headerName: 'Имя',
        flex: 0.7,
        minWidth: 150,
      },
      {
        field: 'start_datetime',
        headerName: 'Начало',
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.start_datetime).format('DD.MM.YYYY HH:mm')}`,
        flex: 0.3,
        minWidth: 150,
      },
      { field: 'flags', headerName: 'Флаг', flex: 0.2, minWidth: 120},
      { field: 'day_number', headerName: 'День недели', flex: 0.2, minWidth: 120, valueGetter: (params: GridValueGetterParams) => `${DateHelper.convertNumToDay(Number(params.row.day_number))}`,},
      { field: 'entrants', headerName: 'Участников', flex: 0.2, minWidth: 80},
      { field: 'guarantee', headerName: 'Гарантия', flex: 0.2, minWidth: 120},
      // { field: 'num_rebuys', headerName: 'Ребаев', flex: 0.2, minWidth: 80},
      { field: 're_entries', headerName: 'Реентри', flex: 0.2, minWidth: 80},
      { field: 'prize_pool', headerName: 'Призовые', flex: 0.2, minWidth: 120},
      { field: 'avg_ability', headerName: 'AVG', flex: 0.1, minWidth: 60},
    ];

  return (
    <>
      {isFetching && !tournaments && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
      {tournaments &&
        <Box
          className="h-auto overflow-auto"
          sx={{
            '& .active': {
              backgroundColor: '#fcecd5',
              color: '#1a3e72',
            },
          }}
        >
          <DataGrid
              autoHeight={true}
              rows={tournaments.data ?? []}
              columns={columns}
              pageSize={pageSize}
              rowCount={tournaments.count ?? 0}
              page={page}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => { setPageSize(newPageSize); setPage(0) }}
              rowsPerPageOptions={[25, 50, 100, 250]}
              pagination
              disableSelectionOnClick
              disableColumnMenu
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    Данные отсутствуют
                  </Stack>
                ),
              }}
          />
        </Box>
      }
    </>
  );
};

export default ScheduledTable;
