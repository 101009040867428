import { FC, Dispatch, SetStateAction } from 'react';

import { Box, Button, FormControlLabel, IconButton, InputBase, ListItemIcon, MenuItem, Paper, Switch, TextField } from '@mui/material';
import Dropdown from 'components/Dropdown';
import { Check } from '@mui/icons-material';
import Search from '@mui/icons-material/Search';
import { Controller, useForm } from 'react-hook-form';

type Props = {
    /**
     * Функция записывающая массив фильтров для запроса.
     */
    setFilter: Dispatch<SetStateAction<any[]>>;

    /**
     * Массив примененных фильтров.
     */
    data: any;

    /**
     * Массив румов.
     */
    rooms: [{ id: string; network: string }];

    /**
     * Функция поиска турнира.
     */
    setSearch: Dispatch<SetStateAction<string>>;

    /**
     * Имя турнира в поиске.
     */
    search: string;

    /**
     * Турниры только с оценкой куратора.
     */
    curatorEstimate: boolean;

    /**
     * Функция показа турниров только с оценкой куратора.
     */
    setCuratorEstimate: Dispatch<SetStateAction<boolean>>;

    /**
     * Турниры только с кастомными уровнями.
     */
    customLevels: boolean;

    /**
     * Объект примененного диапазона байинов.
     */
    rangeBuyin: any;

    /**
     * Только текущие турниры.
     */
    current: boolean;

    /**
     * Только скрытые турниры.
     */
    hidden: boolean;

    /**
     * Функция показа турниров только с кастомными уровнями.
     */
    setCustomLevels: Dispatch<SetStateAction<boolean>>;

    /**
     * Функция показа турниров только с кастомными уровнями.
     */
    setCurrent: Dispatch<SetStateAction<boolean>>;

    /**
     * Функция показа турниров только с кастомными уровнями.
     */
    setHidden: Dispatch<SetStateAction<boolean>>;

    /**
     * Функция записывающая диапазон байинов в фильтре.
     */
    setRangeBuyin: Dispatch<SetStateAction<any>>;
};

/**
 * Компонент содержит фильтр румов.
 */
const TournamentsFilters: FC<Props> = ({
    data,
    setFilter,
    setSearch,
    setCuratorEstimate,
    setCustomLevels,
    setCurrent,
    setHidden,
    rooms,
    search,
    current,
    hidden,
    curatorEstimate,
    customLevels,
    rangeBuyin,
    setRangeBuyin,
}) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: search,
            min_buyin: (rangeBuyin?.min_buyin as number) || '',
            max_buyin: (rangeBuyin?.max_buyin as number) || '',
        },
    });

    function changeFilter(item) {
        let filter = data.slice();
        const id = filter.findIndex(data => data.id === item.id);

        if (id >= 0) {
            filter.splice(id, 1);
        } else {
            filter.push(item);
        }

        setFilter(filter);
    }

    function filterCheck(roomId) {
        const id = data.filter(val => val.id === roomId);

        return id[0] ? true : false;
    }

    function filterValue(data?) {
        if (data && data.length > 0) {
            const separator = data.length > 1 ? ' ,' : '';
            return data.map(x => x.network) + separator;
        }
        return undefined;
    }

    const onSubmit = handleSubmit(data => {
        setSearch(data.name);
    });

    const onSubmitRange = handleSubmit(data => {
        setRangeBuyin(data);
    });

    function rangeValueBuyin(data?) {
        if (!data || (!data.min_buyin && !data.max_buyin)) {
            return 'Все';
        }

        if (data.min_buyin && data.max_buyin) {
            return `${data.min_buyin} - ${data.max_buyin}`;
        }

        if (data.min_buyin) {
            return data.min_buyin;
        }

        if (data.max_buyin) {
            return data.max_buyin;
        }
    }

    return (
        <Box className="flex flex-col">
            <Box className="flex flex-row flex-wrap gap-4 items-center mb-3">
                {/* Фильтр по румам */}
                <Dropdown
                    btnLabel="Рум"
                    btnValue={filterValue(data)}
                    renderContent={() =>
                        rooms?.map(item => {
                            return (
                                <MenuItem onClick={() => changeFilter(item)} key={item.id}>
                                    {filterCheck(item.id) && (
                                        <ListItemIcon>
                                            <Check />
                                        </ListItemIcon>
                                    )}
                                    {item.network}
                                </MenuItem>
                            );
                        })
                    }
                />
                {/* Фильтр по диапазону байинов */}
                <Dropdown
                    btnLabel="Диапазон байинов"
                    btnValue={rangeValueBuyin(rangeBuyin)}
                    renderContent={({ close }) => (
                        <form className="flex flex-col gap-4 p-4" onSubmit={onSubmitRange}>
                            <Controller
                                name="min_buyin"
                                control={control}
                                render={({ field: { ref: _, ...rest } }) => <TextField label="Минимальный:" type="number" {...rest} />}
                            />
                            <Controller
                                name="max_buyin"
                                control={control}
                                render={({ field: { ref: _, ...rest } }) => <TextField label="Максимальный:" type="number" {...rest} />}
                            />
                            <div className="pt-2">
                                <Button fullWidth variant="contained" size="large" type="submit" onClick={() => close()}>
                                    Применить
                                </Button>
                            </div>
                        </form>
                    )}
                />
                {/* Поиск по имени турнира */}
                <Paper component="form" className="flex w-[280px] h-[48px] bg-gray-50 items-center" sx={{ p: '2px 4px' }} onSubmit={onSubmit}>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => {
                            return (
                                <>
                                    <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Поиск турнира" {...field} />
                                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                        <Search />
                                    </IconButton>
                                </>
                            );
                        }}
                    />
                </Paper>
            </Box>
            <Box className="flex flex-row flex-wrap grap-4 mb-2">
                {/* Фильтр показывающий турниры с оценкой куратора */}
                <FormControlLabel
                    control={
                        <Switch checked={curatorEstimate} onChange={() => setCuratorEstimate(curatorEstimate ? false : true)} name="favorite" color="primary" />
                    }
                    label="C оценкой кураторов"
                />
                {/* Фильтр показывающий турниры c измененными уровнями */}
                <FormControlLabel
                    control={<Switch checked={customLevels} onChange={() => setCustomLevels(customLevels ? false : true)} name="favorite" color="primary" />}
                    label="C измененными уровнями"
                />
                {/* Фильтр показывающий текущие турниры */}
                <FormControlLabel
                    control={<Switch checked={current} onChange={() => setCurrent(current ? false : true)} name="favorite" color="primary" />}
                    label="Только текущие турниры"
                />
                {/* Фильтр показывающий скрытые турниры */}
                <FormControlLabel
                    control={<Switch checked={hidden} onChange={() => setHidden(hidden ? false : true)} name="favorite" color="primary" />}
                    label="Только скрытые турниры"
                />
            </Box>
        </Box>
    );
};

export default TournamentsFilters;
