import { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { Card, CircularProgress } from '@mui/material';
import { getAllRules } from 'api/tournamentsrules';
import TournamentsRulesTable from './TournamentsRulesTable';

type Props = {
    /**
     * ID правила для детального просмотра
     */
    rulesView: string; 
    
    /**
     * Изменить правило для детального просмотра
     */
    setRulesView: Dispatch<SetStateAction<string>>;
}

/**
 * Вкладка с оценкой турниров.
 */
const TournamentsRules: FC<Props> = ({ rulesView, setRulesView }) => {
    const {data: rules, isFetching} = useQuery(['tournaments-rules-data'], getAllRules, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    },)

    return (
        <>
            {isFetching && !rules && <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}><CircularProgress /></Card>}
            {!isFetching && rules && <Card><TournamentsRulesTable items={rules} rulesView={rulesView} setRulesView={setRulesView} /></Card>}
        </>
    );
}

export default TournamentsRules;
