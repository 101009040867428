import { FC, useState } from 'react';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

import {
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCoefficientItem } from 'api/coefficient';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список элементов.
   */
  items: any;
};

/**
 * Отображает таблицу личных средств.
 */
const CashFlowTable: FC<Props> = ({ items }) => {

    const [pageSize, setPageSize] = useState<number>(5);

    const currentDate = moment().toISOString();

    const columns: GridColDef[] = [
      { field: 'author', headerName: 'Автор', flex: 0.3, minWidth: 100},
      { field: 'amount', headerName: 'Сумма', flex: 0.3, minWidth: 100},
      { field: 'currency', headerName: 'Валюта', flex: 0.3, minWidth: 100},
      { field: 'comment', headerName: 'Комментарий', flex: 0.3, minWidth: 100},
      {
        field: 'created_datetime',
        headerName: 'Дата создания',
        valueGetter: (params: GridValueGetterParams) => `${moment(params.row.created_datetime).format('DD.MM.YYYY HH:mm')}`,
        flex: 0.3,
        minWidth: 150,
      },
    ];

  return (
    <Box
      className="h-auto overflow-auto"
      sx={{
        '& .active': {
          backgroundColor: '#fcecd5',
          color: '#1a3e72',
        },
      }}
    >
        <DataGrid
            autoHeight={true}
            rows={items}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            disableSelectionOnClick
            disableColumnMenu
            getCellClassName={(params: GridCellParams<number>) => {
            return params.row.end_dt > currentDate && params.row.start_dt < currentDate ? 'active' : '';
            }}
            components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Данные отсутствуют
              </Stack>
            ),
            }}
        />
    </Box>
  );
};

export default CashFlowTable;
