import { FC, useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Card, Typography, Button, Dialog, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import theme from 'themes/lightTheme';
import DeleteDialog from 'components/DeleteDialog';
import { deleteSchedulerAbilityItem, getSchedulerAbility, getSchedulerRoomsAbility } from 'api/schedulersettings';
import SchedulerAbilityTable from './SchedulerAbilityTable';
import SchedulerAbilityImportDialog from './SchedulerAbilityImportDialog';
import SchedulerRoomsFilter from 'components/SchedulerRoomFilter/SchedulerRoomFilter';

/**
 * Вкладка с информацией ABI в расписаторе.
 */
const SchedulerAbility: FC = () => {
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const defaultValue = "Default";
    const [filter, setFilter] = useState([defaultValue]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);

    const selectRoom = filter[0] ?? defaultValue;
    const queryClient = useQueryClient();

    useEffect(() => {
        if(!filter[0]) {
            setFilter([defaultValue]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const { data: rooms } = useQuery(['scheduler-ability-rooms'], getSchedulerRoomsAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
    },)

    const {data: abilityTurbo} = useQuery(['scheduler-ability-data-turbo', selectRoom, 'turbo'], getSchedulerAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        cacheTime: 0,
    },)

    const {data: abilityRebuy} = useQuery(['scheduler-ability-data-rebuy', selectRoom, 'rebuy'], getSchedulerAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        cacheTime: 0,
    },)

    const {data: ability} = useQuery(['scheduler-ability-data-regular', selectRoom, 'regular'], getSchedulerAbility, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        cacheTime: 0,
    },)

    const delItemMutation = useMutation(deleteSchedulerAbilityItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('scheduler-ability-data-regular');
            queryClient.invalidateQueries('scheduler-ability-data-turbo');
            queryClient.invalidateQueries('scheduler-ability-data-rebuy');
            queryClient.invalidateQueries('scheduler-ability-rooms');
            setFilter([defaultValue]);
        },
    });

    function deliteItem(network: string) {
        delItemMutation.mutate(network);
        setDeleteDialog(false);
    }

    return (
        <>
            <Box className='flex flex-row justify-between gap-2 items-center'>
                <Box>
                    {rooms && rooms.networks && <SchedulerRoomsFilter setFilter={setFilter} data={filter} rooms={rooms.networks} />}
                </Box>
                <Box>
                    <Button variant="contained" size='large' type='submit' endIcon={<ImportExportIcon />} onClick={() => setImportDialog(true)} className="mr-2">
                        Импорт
                    </Button>
                    <Button variant="outlined" size='large' type='submit' color="error" startIcon={<DeleteIcon />} onClick={() => setDeleteDialog(true)}>
                        Удалить
                    </Button>
                </Box>
            </Box>
            <DeleteDialog close={() => setDeleteDialog(false)} open={deleteDialog} loading={false} handleAgreeAction={() => deliteItem(filter[0])}/>
            <Box className="flex flex-col gap-2 mt-2 lg:flex-row">
                <Box className='flex-auto'>
                    <Typography variant='h5'>Обычные турниры</Typography>
                    <Card>
                        <SchedulerAbilityTable items={ability?.ability_grids} />
                    </Card>
                </Box>
            </Box>
            <Box className="flex flex-col gap-2 mt-2 lg:flex-row">
                <Box className='flex-auto'>
                    <Typography variant='h5'>Турбо турниры</Typography>
                    <Card>
                        <SchedulerAbilityTable items={abilityTurbo?.ability_grids} />
                    </Card>
                </Box>
                <Box className='flex-auto'>
                    <Typography variant='h5'>Ребай турниры</Typography>
                    <Card>
                        <SchedulerAbilityTable items={abilityRebuy?.ability_grids} />
                    </Card>
                </Box>
            </Box>
            <Dialog fullWidth maxWidth='md' open={importDialog} onClose={() => setImportDialog(false)} fullScreen={fullScreen} >
                <SchedulerAbilityImportDialog close={() => setImportDialog(false)} active={filter} />
            </Dialog>
        </>
    );
}

export default SchedulerAbility;
